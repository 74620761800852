<div class="inner-wraper">
    <section class="lesson-cover-sect">
        <div class="container">
            <div class="lektionen-cover-new">
                <article>
                  <div class="lekt-title" style="display: block !important;">
                    <div class="d-flex">
                      <a href="javascript:;" (click)="location.back()"><i class="ri-arrow-left-s-line"></i></a>
                      <h2 class="">{{ global.termsArray["lbl_gift_card"]  }}</h2>
                    </div>
                  </div>
          
                    <div class="list-sentens-box p-2">
                        <div class="gift-card-list" >
                            <ng-container  *ngIf="giftCards && giftCards?.length>0; else nogiftcards">
                            <div class="gift-card-box mt-2" *ngFor="let card of giftCards">
                                <div class="gift-card-head d-flex">
                                    <h4>{{global.termsArray['lbl_gift_card']}}</h4>
                                    <div class="gift-card-id ms-auto">
                                        <span>#{{ card?.giftCode }}</span>
                                        <span>{{unlimitedSubscriptionInfo?.name}}</span>
                                    </div>
                                </div>
                                <div class="gift-card-logo">
                                  <img src="assets/images/birlingo-logo.jpg" width="200px"/>
                                    <!-- <svg width="405" height="136" viewBox="0 0 405 136" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="404.773" height="135.759" rx="20" fill="white"/>
                                        <path d="M56.7012 105.727C53.0237 105.759 49.374 105.092 45.9479 103.763C42.7713 102.519 39.8952 100.622 37.5089 98.1946C32.4339 92.7381 29.7409 85.5045 30.0197 78.0779V30H40.9768V60.899C42.7686 57.8569 45.356 55.3544 48.464 53.6574C51.5719 51.9605 55.0843 51.1325 58.6278 51.2615C71.1307 51.2615 83.7859 60.4736 83.7859 78.0779C83.7859 94.3682 72.6473 105.727 56.7012 105.727ZM56.802 61.7364C47.4847 61.7364 40.9768 68.6276 40.9768 78.4966C40.9768 88.3657 47.4937 95.2591 56.802 95.2591C66.1797 95.2591 72.7302 88.3679 72.7302 78.5011C72.7302 68.6343 66.1797 61.7364 56.802 61.7364Z" fill="#8D5182"/>
                                        <path d="M104.32 103.504H92.7198V52.4995H104.32V103.504ZM98.6274 45.2832C97.6019 45.3167 96.5801 45.1431 95.624 44.7731C94.6678 44.403 93.7969 43.8441 93.0641 43.13C92.3313 42.416 91.7516 41.5617 91.3601 40.6187C90.9687 39.6758 90.7735 38.6637 90.7865 37.6436C90.7735 36.6236 90.9687 35.6115 91.3601 34.6685C91.7516 33.7255 92.3313 32.8712 93.0641 32.1572C93.7969 31.4432 94.6678 30.8842 95.624 30.5142C96.5801 30.1441 97.6019 29.9706 98.6274 30.0041C99.6529 29.9709 100.674 30.1446 101.63 30.5148C102.587 30.885 103.457 31.4439 104.19 32.1579C104.923 32.8719 105.502 33.726 105.894 34.6689C106.286 35.6117 106.481 36.6236 106.468 37.6436C106.481 38.6636 106.286 39.6755 105.894 40.6184C105.502 41.5612 104.923 42.4154 104.19 43.1293C103.457 43.8433 102.587 44.4022 101.63 44.7724C100.674 45.1426 99.6529 45.3163 98.6274 45.2832Z" fill="#8D5182"/>
                                        <path d="M113.469 52.0277C113.469 37.174 122.103 30 134.801 30C147.498 30 156.034 37.174 156.034 52.2282V52.6313H145.417V52.0277C145.417 43.4394 141.161 40.1051 134.801 40.1051C128.441 40.1051 124.184 43.4371 124.184 52.0277V81.2273H113.469V52.0277Z" fill="#8D5182"/>
                                        <path d="M163.034 103.5H174.235V30H163.034V103.5Z" fill="#8D5182"/>
                                        <path d="M194.769 103.504H183.169V52.4995H194.769V103.504ZM189.077 45.2832C188.051 45.3167 187.029 45.1431 186.073 44.7731C185.117 44.403 184.246 43.8441 183.513 43.13C182.78 42.416 182.201 41.5617 181.809 40.6187C181.418 39.6758 181.223 38.6637 181.236 37.6436C181.223 36.6236 181.418 35.6115 181.809 34.6685C182.201 33.7255 182.78 32.8712 183.513 32.1572C184.246 31.4432 185.117 30.8842 186.073 30.5142C187.029 30.1441 188.051 29.9706 189.077 30.0041C190.102 29.9709 191.124 30.1446 192.08 30.5148C193.036 30.885 193.906 31.4439 194.639 32.1579C195.372 32.8719 195.952 33.726 196.343 34.6689C196.735 35.6117 196.93 36.6236 196.918 37.6436C196.93 38.6636 196.735 39.6755 196.343 40.6184C195.952 41.5612 195.372 42.4154 194.639 43.1293C193.906 43.8433 193.036 44.4022 192.08 44.7724C191.124 45.1426 190.102 45.3163 189.077 45.2832Z" fill="#8D5182"/>
                                        <path d="M203.918 55.2617C203.918 38.5884 213.816 30 228.561 30C243.306 30 253.204 38.4881 253.204 55.2617V81.2273H242.296V55.2617C242.296 45.1588 236.843 40.1163 228.561 40.1163C220.279 40.1163 214.826 45.1677 214.826 55.2617V81.2273H203.918V55.2617Z" fill="#8D5182"/>
                                        <path d="M287.417 105.728C281.748 105.923 276.146 104.464 271.3 101.532C266.631 98.4482 263.296 93.735 261.954 88.3242H273.113C274.125 90.8637 276.05 92.9386 278.514 94.1463C281.292 95.4551 284.342 96.0894 287.414 95.9972C297.928 95.9972 303.038 90.4067 303.038 78.9073V73.7178C301.366 76.72 298.867 79.1861 295.836 80.8272C292.591 82.5443 288.961 83.4134 285.286 83.3529C278.921 83.435 272.764 81.1003 268.07 76.8248C265.44 74.2367 263.383 71.1302 262.033 67.7047C260.683 64.2793 260.068 60.6109 260.229 56.9353C260.081 49.763 262.798 42.8247 267.785 37.6404C270.213 35.2059 273.118 33.2939 276.321 32.0232C279.788 30.659 283.487 29.9724 287.215 30.0009C303.242 30.0009 313.995 40.9902 313.995 57.3495V78.4841C313.995 87.7117 311.381 94.7655 306.221 99.4494C300.967 103.812 294.255 106.053 287.417 105.728ZM287.215 40.3599C277.837 40.3599 271.287 47.1754 271.287 56.9353C271.207 59.059 271.553 61.1772 272.307 63.166C273.061 65.1547 274.206 66.9739 275.676 68.5171C277.207 69.9752 279.018 71.1127 281 71.8622C282.982 72.6117 285.096 72.9579 287.215 72.8803C289.326 72.9622 291.431 72.6193 293.405 71.8722C295.38 71.1251 297.182 69.9892 298.703 68.5327C300.16 66.9828 301.293 65.1612 302.038 63.1733C302.782 61.1854 303.123 59.0705 303.04 56.9509C303.167 52.5757 301.617 48.3161 298.703 45.0349C297.225 43.499 295.438 42.289 293.459 41.4837C291.48 40.6783 289.353 40.2954 287.215 40.3599Z" fill="#8D5182"/>
                                        <path d="M347.884 83.4554C344.171 83.4845 340.487 82.804 337.032 81.4509C333.841 80.1941 330.944 78.2989 328.519 75.8827C326.062 73.3378 324.133 70.3361 322.843 67.0494C321.553 63.7627 320.927 60.2555 321.001 56.7282C320.928 53.2001 321.556 49.6923 322.848 46.4056C324.14 43.1188 326.071 40.1176 328.531 37.5736C330.951 35.1587 333.845 33.2635 337.032 32.0054C340.487 30.6524 344.171 29.9718 347.884 30.0009C351.597 29.9719 355.281 30.6525 358.736 32.0054C361.927 33.2623 364.825 35.1575 367.249 37.5736C369.707 40.1184 371.636 43.1201 372.926 46.4068C374.216 49.6935 374.842 53.2008 374.767 56.7282C374.84 60.2562 374.213 63.764 372.921 67.0507C371.629 70.3374 369.698 73.3387 367.238 75.8827C364.814 78.2989 361.916 80.1941 358.725 81.4509C355.274 82.8025 351.593 83.483 347.884 83.4554ZM347.884 40.2798C338.542 40.2798 332.019 47.044 332.019 56.7282C332.019 66.4123 338.542 73.1766 347.884 73.1766C350.012 73.2407 352.131 72.8606 354.102 72.0608C356.073 71.2609 357.854 70.059 359.33 68.5327C362.226 65.2865 363.771 61.0659 363.651 56.7282C363.771 52.3904 362.226 48.1698 359.33 44.9236C357.854 43.3973 356.073 42.1954 354.102 41.3956C352.131 40.5957 350.012 40.2156 347.884 40.2798Z" fill="#8D5182"/>
                                    </svg>                                     -->
                                </div>
                                <div class="gift-card-footer d-flex align-items-center">
                                    <div class="gift-card-footer-left">
                                        <h4>€{{card?.amount }}</h4>
                                        <span [ngStyle]="card?.is_redeemed || card?.status==0 ? {'background-color': 'white',color:'black'} : {}">
                                            {{card?.status==0 ? global.termsArray['lbl_not_active'] : card?.is_redeemed ? global.termsArray['lbl_redeemed'] : global.termsArray['lbl_active']}}</span>
                                    </div>
                                    <div class="gift-card-footer-right">
                                      <button class="cp copy-btn" *ngIf="!card?.is_redeemed && card.status!=0" (click)="sharingGiftCode=card?.giftCode;openModal(shareGiftCodeTemp, 'big-model-dialog2 modal-dialog-centered') ">{{global.termsArray["lbl_share"]? global.termsArray["lbl_share"]:'Share Code'}}</button>
                                        <button class="cp copy-btn ml-2" *ngIf="!card?.is_redeemed && card.status!=0" (click)="copyText(card?.giftCode)">{{global.termsArray["lbl_copy"]}}</button>
                                    </div>
                                </div>
                            </div>
                            <button class="btn btn-blue mb-4 " (click)="openModal(choosePaymentType, 'big-model-dialog2 modal-dialog-centered') "  >  {{this.global.termsArray["lbl_purchase_more_gift_card"]? this.global.termsArray["lbl_purchase_more_gift_card"]:'Kaufen Sie weitere Geschenkkarten' }}  </button>
                        </ng-container>
                            <ng-template #nogiftcards>
                                <div class="no-cards">
                                    <p>{{this.global.termsArray["lbl_no_gift_card"]? this.global.termsArray["lbl_no_gift_card"]:'No gift card right now!' }}  </p>
                                    <button class="btn btn-blue mb-4" (click)="openModal(choosePaymentType, 'big-model-dialog2 modal-dialog-centered') ">  {{this.global.termsArray["lbl_purchase_gift_card"]? this.global.termsArray["lbl_purchase_gift_card"]:'Geschenkkarte kaufen' }}  </button>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    
                </article>
                <app-app></app-app>
            </div>
        </div>
    </section>
</div>


<ng-template #choosePaymentType>
  <div class="modal-header payment-options-modal-header" style="border-bottom-width: 0px">
    <h4 class="modal-title" style="font-size: 25px; font-family: Akkurat-Bold; margin: 0; color: white;">{{ global.termsArray["lbl_payment_method"] }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modalRef.hide(); resetForm(paymentOptionsForm)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
 
  
  <div class="modal-footer coupon-modal-footer bg-white payment-options-modal-body">
    <form class="w-100 text-center" [formGroup]="paymentOptionsForm" (ngSubmit)="subscribeMyplan()">
      <ul class="w-100 payment_options">
        <li *ngIf="isActiveMethod('card')">
          <div class="pay_option_container">
            <img src="assets/images/credit-card.png" alt="card.png" class="credit-card">
            {{ global.termsArray["lbl_credit_card"]?global.termsArray["lbl_credit_card"]:"Credit Card" }}
            <input type="radio" formControlName="payment_method" id="radio_card" value="card" />
            <label for="radio_card"></label>
          </div>
        </li>
        <li *ngIf="enableSofort && isActiveMethod('sepa')">
          <div class="pay_option_container">
            <img src="assets/images/sepa.png" alt="sepa.png">
            {{ global.termsArray["lbl_sepa_debit"]?global.termsArray["lbl_sepa_debit"]:"SEPA" }}
            <input type="radio" formControlName="payment_method" id="radio_sepa_debit" value="sepa_debit" />
            <label for="radio_sepa_debit"></label>
          </div>
        </li>
        <li *ngIf="enableSofort && isActiveMethod('sofort')">
          <div class="pay_option_container">
            <img src="assets/images/sofort.png" alt="sofort.png">
            {{ global.termsArray["lbl_sofort"]?global.termsArray["lbl_sofort"]:"Sofort" }}
            <input type="radio" formControlName="payment_method" id="radio_sofort" value="sofort" />
            <label for="radio_sofort"></label>
          </div>
        </li>
        <li *ngIf="userCountry == 'Germany' && isActiveMethod('giropay')">
            <div class="pay_option_container">
              <img src="assets/images/giropay.png" alt="giropay.png">
              {{ global.termsArray["lbl_giropay"]?global.termsArray["lbl_giropay"]:"Giropay" }}
              <input type="radio" formControlName="payment_method" id="radio_giropay" value="giropay" />
              <label for="radio_giropay"></label>
            </div>
        </li>
        <li *ngIf="userCountry == 'Austria' && isActiveMethod('eps')">
            <div class="pay_option_container">
              <img src="assets/images/eps.png" alt="eps.png">
              {{ global.termsArray["lbl_eps"]?global.termsArray["lbl_eps"]:"EPS" }}
              <input type="radio" formControlName="payment_method" id="radio_eps" value="eps" />
              <label for="radio_eps"></label>
            </div>
          </li>
          <li *ngIf="isActiveMethod('paypal')">
            <div class="pay_option_container">
              <img src="assets/images/paypal.png" alt="paypal.png">
              {{ global.termsArray["lbl_paypal"]?global.termsArray["lbl_paypal"]:"Paypal" }}
              <input type="radio" formControlName="payment_method" id="radio_paypal" value="paypal" />
              <label for="radio_paypal"></label>
            </div>
          </li>
      </ul>
      <button class="btn sky-btn mt-4 mb-3" type="submit" [attr.disabled]="paymentOptionsForm.invalid?true:null">{{ global.termsArray["lbl_proceed"] }}</button>
    </form>
  </div>
</ng-template>
<ng-template #enterAmount>
  <div class="modal-header" style="border-bottom-width: 0px">
    <h4 class="modal-title" style="font-size: 25px; font-family: Akkurat-Bold; margin: 0; color: white;">
      {{ global.termsArray["lbl_gift_card_amount"] ? global.termsArray["lbl_gift_card_amount"] :'Gift Card Amount' }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modalRef.hide();giftCardAmount=null;">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <div class="icon_inner_bx">
        <input type="text" class="form-control" [(ngModel)]="giftCardAmount" [placeholder]="global.termsArray['lbl_enter_gift_card_amount']" (keydown)="validateInput($event)" [max]="unlimitedSubscriptionInfo?.price" autofocus style="font-size: 14px; height: 46px; background: #8c5182; color: #fff; border: none;" />
      </div>
    </div>
    <!-- Additional Information Section -->
    <!-- style="font-size: 14px; color: white; background: #6d3f6c; padding: 10px; border-radius: 5px;" -->
    <div class="info-section" style="font-size: 16px;padding-left: 25px;">
      <ul style="list-style: disc;">
        <li>{{global.termsArray["amount_description_1"]}}</li>
        <li>{{global.termsArray["amount_description_2"]}}</li>
        <li>{{global.termsArray["amount_description_3"]}}</li>
    </ul>
    </div>
  </div>
  <div class="modal-footer coupon-modal-footer">
    <button type="button" class="btn btn-primary paypal_config_none" (click)="validateGiftCardAmount()" style="font-size: 15px; font-family: Akkurat-Bold; background-color: rgb(83, 179, 214); color: white;">
      {{ global.termsArray["lbl_ok"] }}
    </button>
  </div>
</ng-template>

  
<ng-template #couponcode>
  <div class="modal-header" style="border-bottom-width: 0px">
    <h4 class="modal-title" style="font-size: 25px; font-family: Akkurat-Bold; margin: 0; color: white;">{{ global.termsArray["lbl_enter_promo_code"] }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modalRef.hide();giftCardAmount=null">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group pb-4">
      <div class="icon_inner_bx" >
        <!-- <label for="username">{{ global.termsArray['lbl_name'] ? global.termsArray['lbl_name'] : "Benutzername" }}</label> -->
        <input type="email"  class="form-control" id="exampleInputEmail1" [(ngModel)]="couponCode" [placeholder]="global.termsArray['lbl_promo_code']"  autofocus style="font-size: 14px;height: 46px;background: #8c5182;color:#fff;border:none" />
      </div>
    </div>
  </div>
  <div class="modal-footer coupon-modal-footer">
    <!-- Button for paypal -->
      <ng-container *ngIf="paymentMethod == 'paypal'; else showStripeOption;">
        <div class="paypal_config_box">
          <button type="button" class="btn btn-basic paypal_config_none"
            style="font-size: 15px; font-family: Akkurat-Bold; color: #3d76ce">
            {{ global.termsArray["lbl_skip"] }}
          </button>
          <div id="paypal_button_container" class="paypal_custom_button paypal_config_trigger"></div>
        </div>
      </ng-container>
      <!-- Button for paypal -->
      <ng-template #showStripeOption>
        <button type="button" class="btn btn-basic paypal_config_none" (click)="purchaseGiftCards()" style="font-size: 15px; font-family: Akkurat-Bold; color: #3d76ce">
          {{ global.termsArray["lbl_skip"] }}
        </button>
      </ng-template>
    <button type="button" class="btn btn-primary" style="font-size: 15px;font-family: Akkurat-Bold;background-color: rgb(83, 179, 214);color: white;" (click)="applyCouponCode(couponCode, couponDiscountResult)">
      {{ global.termsArray["lbl_Submit"] }}
    </button>
  </div>
</ng-template>



<ng-template #couponDiscountResult>
  <div class="modal-header" style="border-bottom-width: 0px">
    <h4 class="modal-title" style="font-size: 25px; font-family: Akkurat-Bold; margin: 0; color: white;">{{ global.termsArray["lbl_your_order"] }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modalRef.hide();giftCardAmount=null">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body body-parent">
    <div class="child-body">
      <h3>{{ this.global.termsArray["lbl_total_price"] }}</h3>
      <h3>{{ this.global.termsArray["lbl_subscription_amount"] }}</h3>
      <h3>{{ this.global.termsArray["lbl_discount"] }}</h3>
    </div>
    <div class="child-body" *ngIf="couponCodeData">
      <h3>: {{ couponCodeData["total"] | currency: "EUR":"symbol":"1.2-2" }}</h3>
      <h3>: {{ couponCodeData["discount_less"] | currency: "EUR":"symbol":"1.2-2" }}</h3>
      <h3>: {{ couponCodeData["discount_amount"] > couponCodeData["total"] ? (couponCodeData["total"] | currency: "EUR":"symbol":"1.2-2") :( couponCodeData["discount_amount"] | currency: "EUR":"symbol":"1.2-2") }}</h3>
    </div>
  </div>
 
  
  <div class="modal-footer coupon-modal-footer">
    <p *ngIf="couponCodeData && couponCodeData['discount_amount'] > couponCodeData['total']" style="margin-top: -50px; margin-bottom: 20px;" > {{ global.termsArray['lbl_rest_gift_amount']}}</p>
    <!-- Button for paypal -->
    <ng-container *ngIf="couponCodeData.discount_less>0;else manualiftCardPurchase;">
      <ng-container *ngIf="paymentMethod == 'paypal'; else showStripeOkOption;">
        <div class="paypal_config_box">
          <button type="button" class="btn btn-primary paypal_config_none" style="font-size: 15px;font-family: Akkurat-Bold;background-color: rgb(83, 179, 214);color: white;">
            {{ global.termsArray["lbl_ok"] }}
          </button>
          <div id="paypal_button_container2" class="paypal_custom_button paypal_config_trigger"></div>
        </div>
      </ng-container>
      <!-- Button for paypal -->
      <ng-template #showStripeOkOption>
        <button type="button" class="btn btn-primary paypal_config_none" (click)="purchaseGiftCards()" style="font-size: 15px;font-family: Akkurat-Bold;background-color: rgb(83, 179, 214);color: white;">
          {{ global.termsArray["lbl_ok"] }}
        </button>
      </ng-template>
    </ng-container>

    <ng-template #manualiftCardPurchase>
      <button type="button" class="btn btn-primary paypal_config_none" (click)="manualPurchaseGiftCards()" style="font-size: 15px;font-family: Akkurat-Bold;background-color: rgb(83, 179, 214);color: white;">
        {{ global.termsArray["lbl_ok"] }}
      </button>
    </ng-template>
  </div>

</ng-template>


  
<ng-template #shareGiftCodeTemp>
  <div class="modal-header" style="border-bottom-width: 0px">
    <h4 class="modal-title" style="font-size: 25px; font-family: Akkurat-Bold; margin: 0; color: white;">{{ global.termsArray["lbl_share_gift_code"] }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modalRef.hide();recieverEmail=''">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group pb-4">
      <div class="icon_inner_bx" >
        <input type="email"  class="form-control" id="exampleInputEmail2" [(ngModel)]="recieverEmail" [placeholder]="global.termsArray['lbl_enter_reciever_email']"  autofocus style="font-size: 14px;height: 46px;background: #8c5182;color:#fff;border:none" />
      </div>
    </div>
  </div>
  <div class="modal-footer coupon-modal-footer">
    <button type="button" class="btn btn-primary" style="font-size: 15px;font-family: Akkurat-Bold;background-color: rgb(83, 179, 214);color: white;" (click)="shareGiftCode()">
      {{ global.termsArray["lbl_Submit"] }}
    </button>
  </div>
</ng-template>