import { Component, OnInit, Inject, Input } from "@angular/core";
import { GlobalService } from "../global.service";
import { Router } from "@angular/router";
import { Title, Meta } from "@angular/platform-browser";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { resolve } from "url";
declare var $: any;
import { environment } from "src/environments/environment";
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators, FormBuilder, RequiredValidator, } from "@angular/forms";


@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.sass']
})

export class PriceComponent implements OnInit {

  public formData;
  public content: any;
  public testimonials: any;
  public video_url: any;
  public video_image: any;
  public deviceInfo = null;
  public max_767_width;
  public isPhone: any = false;
  public carosalHide = true;
  public subscriptionList: any;
  public priceContent: any;
  public selectCard: any;
  public isShowPriceBox: boolean = false;
  public error: any;
  public selectedCardId: any;
  public selectedCardType: number;

  constructor(
    private router: ActivatedRoute,
    private metaTagService: Meta,
    public global: GlobalService,
    public route: Router,
    public breakpointObserver: BreakpointObserver,
    private titleService: Title,
    private formBuilder: FormBuilder,
    @Inject(DOCUMENT) private doc
  ) {
    let user = localStorage.getItem(btoa("user")) ? atob(localStorage.getItem(btoa("user"))) : null;
    user = JSON.parse(user);
    if (this.global.isPhone) {
      setTimeout(() => {
        this.carosalHide = false;
      }, 2000);
    } else {
      this.carosalHide = false;
    }

    this.getLearningLanguage();
    // this.getSettingData();
    // this.getTestimonials();
    this.getSubscriptionData();
    this.getPricePageContent().then((promise) => {
      $(document).ready(() => {
        setTimeout(() => {
          $(".carousel").swipe({
            swipe: (event, direction, distance, duration, fingerCount, fingerData) => {
              if (direction == "left") {
                $("#box-next").trigger("click");
              }
              if (direction == "right") {
                $("#box-pre").trigger("click");
              }
            },
            allowPageScroll: "vertical",
          });
        }, 1000);
      });
    });

  }

  ngOnInit() {
    localStorage.removeItem(btoa("routeParams"));
    this.global.routeParams = null;

    this.breakpointObserver
      .observe(["(max-width: 767px)"])
      .subscribe((result) => {
        if (result.matches) {
          this.max_767_width = true;
        } else {
          this.max_767_width = false;
        }
      });
  }

  getPricePageContent() {
    return new Promise((resolve, reject) => {
      let body = {};
      body["language_id"] = this.global.selectLanguage;
      this.global.post("price", JSON.stringify(body), (data) => {
        if (data.success) {
          this.priceContent = data.data;

          this.titleService.setTitle(data.data["meta_title"]);
          this.metaTagService.updateTag({ property: "og:site_name", content: "Price" });
          this.metaTagService.updateTag({ property: "og:type", content: "website" });
          this.metaTagService.updateTag({ property: "og:url", content: environment.wesiteUrl + "price" });
          this.metaTagService.updateTag({ property: "og:title", content: this.priceContent['meta_title'] })
          this.metaTagService.updateTag({ property: "og:description", content: data.data["meta_description"] });
          this.metaTagService.updateTag({ property: "og:image", content: this.priceContent.imageUrl + this.priceContent.images.banner_image });
          this.metaTagService.updateTag({ property: "og:languages_human_speak_text", content: this.priceContent.languages_human_speak_text["languages_human_speak_text"] });
          this.createLinkForCanonicalURL();
          if(document.getElementById("subscription_pricing_texts")){
            document.getElementById("subscription_pricing_texts").innerHTML = this.priceContent["subscription_pricing_texts"];
          }
          if(document.getElementById("left_learning_guarantee_texts")){
            document.getElementById("left_learning_guarantee_texts").innerHTML = this.priceContent["left_learning_guarantee_texts"];
          }
          if(document.getElementById("languages_human_speak_text")){
            document.getElementById("languages_human_speak_text").innerHTML = this.priceContent["languages_human_speak_text"];
          }
          if(document.getElementById("below_price_card_left_text")){
            document.getElementById("below_price_card_left_text").innerHTML = this.priceContent["below_price_card_left_text"];
          }
          if(document.getElementById("below_price_card_right_text")){
            document.getElementById("below_price_card_right_text").innerHTML = this.priceContent["below_price_card_right_text"];
          }
          resolve(1);
        } else {
          this.global.showDangerToast(this.global.termsArray[data.message]);
          reject(0);
        }
      },
        (err) => {
          this.global.showDangerToast(err.message);
          reject(0);
        }, true);
    })
  }

  // getSettingData() {
  //   this.global.get("getSettingData", data => {
  //     this.video_url = data.data.method_video_url
  //     this.video_image = data.data.method_video_url_image
  //   },
  //     err => { });
  // }

  // open(url): void {
  //   let urls = this.getId(this.video_url)
  //   $("#test").attr("src", urls)
  //   $('.modal').modal({ backdrop: "static" })
  // }

  getLearningLanguage() {
    this.global.get("learningLanguages/" + this.global.selectLanguage, (data) => {
      this.global.learningLanguages = data.data.filter((ele) => {
        return ele._id != this.global.selectLanguage;
      });
    }, (err) => { });
  }

  // getTestimonials() {
  //   this.global.post("testimonials", JSON.stringify({ language_id: this.global.selectLanguage }), (data) => {
  //     this.testimonials = data.data;
  //   }, (err) => {
  //     this.global.showDangerToast[err.message];
  //   }, true);
  // }

  // setPageTitle(title: string) {
  //   this.titleService.setTitle(title);
  // }

  selectLanguage(id) {
    this.global.learningLanguage = id;
    this.route.navigate(["register"]);
  }

  generateFaq(id, answer) {
    if(document.getElementById(id)){
      document.getElementById(id).innerHTML = answer;
    }
  }

  // getId(str) {
  //   var res = str.split("=");
  //   var embeddedUrl = "https://www.youtube.com/embed/" + res[1] + "?autoplay=1&rel=0";
  //   return embeddedUrl;
  // }

  closeModal() {
    $('.modal iframe').attr('src', '');
  }

  getSubscriptionData() {
    let body = {};
    body["language_id"] = this.global.selectLanguage;
    this.global.post("subscriptionsForPrice", JSON.stringify(body), (data) => {
      // console.log("subscriptions reponse: ", data);
      if (data.success) {
        this.subscriptionList = data.data.map((ele) => { return ele; });
        this.isShowPriceBox = true;
        console.log("subscriptionList: ", this.subscriptionList);
      } else {
        this.isShowPriceBox = false;
        this.global.showDangerToast("Error", this.global.languageArray[data.message]);
      }
    }, (err) => {
      this.global.showDangerToast("Error", err.message);
    }, true);
  }

  // twoDigitFloatValue(n) {
  //   return n.toFixed(2);
  // }

  createLinkForCanonicalURL() {
    const link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    link.setAttribute('href', environment.wesiteUrl + "price");
  }

  cardClick(id, isFree = 0) {
    // console.log("this is selected card id", this.selectedCardId != id);
    if (this.selectedCardId != null && this.selectedCardId != id) {
      $("#customCheck2-" + this.selectedCardId).prop("checked", false);
      let currentCard = document.getElementById("error_" + this.selectedCardId);
      if (currentCard) {
        currentCard.textContent = "";
        currentCard.classList.add("hide");
      }
    }

    if (isFree) {
      $("#customCheck2-" + this.selectedCardId).prop("checked", false);
      let freePreviousCard = document.getElementById("error-free-card");
      if (freePreviousCard) {
        freePreviousCard.textContent = "";
        freePreviousCard.classList.add("hide");
      }
      this.selectedCardId = null;
    } else {
      if ($("#customCheck1").prop("checked")) $("#customCheck1").prop("checked", false);
      let freeCurrentCard = document.getElementById("error-free-card");
      if (freeCurrentCard) {
        freeCurrentCard.textContent = "";
        freeCurrentCard.classList.add("hide");
      }
      if ($("#customCheck2-" + id).prop("checked")) {
        let currentCard = document.getElementById("error_" + this.selectedCardId);
        if (currentCard) {
          currentCard.textContent = "";
          currentCard.classList.add("hide");
        }
        this.selectedCardId = id;
        this.selectedCardType = 1;
      } else {
        this.selectedCardId = null;
        this.selectedCardType = 0;
      }
    }
  }

  choosePlan(id, isFree = 0) {
    if (isFree) {
      $("#customCheck2-" + this.selectedCardId).prop("checked", false);
      let currentCard = document.getElementById("error_" + this.selectedCardId);
      if (currentCard) {
        currentCard.textContent = "";
        currentCard.classList.add("hide");
      }
      this.selectedCardId = null;
      if ($("#customCheck1").prop("checked")) {
        this.global.navigate(["/register"], { type: 2 });
      } else {
        let freeCurrentCard = document.getElementById("error-free-card");
        if (freeCurrentCard) {
          freeCurrentCard.textContent = "Please agree to the terms.";
          freeCurrentCard.classList.remove("hide");
        }
      }
    } else {
      if ($("#customCheck1").prop("checked")) $("#customCheck1").prop("checked", false);
      let freePreviousCard = document.getElementById("error-free-card");
      if (freePreviousCard) {
        freePreviousCard.textContent = "";
        freePreviousCard.classList.add("hide");
      }

      if (this.selectedCardId == id) {
        if($("#customCheck2-" + id).prop("checked")) this.global.navigate(["/register"], { type: 1, product_id: this.selectedCardId });
      } else {
        if (this.selectedCardId != "" && this.selectedCardId != id) {
          $("#customCheck2-" + this.selectedCardId).prop("checked", false);
          let previousCardErr = document.getElementById("error_" + this.selectedCardId);
          if (previousCardErr) {
            previousCardErr.textContent = "";
            previousCardErr.classList.add("hide");
          }
        }

        this.selectedCardId = id;
        let currentCard = document.getElementById("error_" + id);
        currentCard.textContent = "Please agree to the terms.";
        currentCard.classList.remove("hide");
      }
    }
  }

}
