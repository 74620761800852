import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DeviceDetectorModule } from "ngx-device-detector";
import { RouterModule, Routes } from "@angular/router";
import { AppComponent } from "./user.component";
import { AppRoutingModule } from "./app-routing.module";
import { ChooseLessonComponent } from "./choose-lesson/choose-lesson.component";
import { LessonDescriptionComponent } from "./lesson-description/lesson-description.component";
import { UserSettingComponent } from "./user-setting/user-setting.component";
import { ProfileComponent } from "./profile/profile.component";
import { ChooseLanguageComponent } from "./choose-language/choose-language.component";
import { TermsComponent } from "./terms/terms.component";
import { PrivacyComponent } from "./privacy/privacy.component";
import { RateComponent } from "./rate/rate.component";
import { DownloadsComponent } from "./downloads/downloads.component";
import { DownloadDescriptionComponent } from "./download-description/download-description.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LessonComponent } from "./lesson/lesson.component";
import {
  MatButtonModule,
  MatListModule,
  MatSliderModule,
  MatIconModule,
  MatToolbarModule,
  MatCardModule,
} from "@angular/material";
import { NgCircleProgressModule } from "ng-circle-progress";
import { SupportComponent } from "./support/support.component";
import { NoDataFoundComponent } from "./no-data-found/no-data-found.component";
import { ChangeLanguageComponent } from "./change-language/change-language.component";
import { CounterDirective } from "../counter.directive";
import { AccountComponent } from "./account/account.component";
import { ProgressBarModule } from "angular-progress-bar";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { SusbscriptionComponent } from "./susbscription/susbscription.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { NgOtpInputModule } from "ng-otp-input";
import {
  NgxUiLoaderConfig,
  NgxUiLoaderModule,
  SPINNER,
  POSITION,
  PB_DIRECTION,
} from "ngx-ui-loader";
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { CancellationFeedbackComponent } from './cancellation-feedback/cancellation-feedback.component';
import { MatSelectModule } from '@angular/material/select';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { SendConcernComponent } from './send-concern/send-concern.component';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { FavoritesComponent } from './favorites/favorites.component';
import { LastPlayedLessonsComponent } from './last-played-lessons/last-played-lessons.component';
import { PlayFavLessonsComponent } from './play-fav-lessons/play-fav-lessons.component';
import { NewLessonComponent } from "./new-lesson/new-lesson.component";
import { NotifiationsComponent } from './notifiations/notifiations.component';
import { RepeatedLessonsComponent } from './repeated-lessons/repeated-lessons.component';
import { SetLearningGoalComponent } from './set-learning-goal/set-learning-goal.component';
import { MyGoalsComponent } from './my-goals/my-goals.component';
import { AwardsComponent } from './awards/awards.component';
import { RepeatedLessonDescriptionComponent } from './repeated-lesson-description/repeated-lesson-description.component';
import { NewRatingComponent } from './new-rating/new-rating.component';
import { BadgeProgressComponent } from './common-components/badge-progress/badge-progress.component';

import { GiftCardsComponent } from './gift-cards/gift-cards.component';
const modules = [
  MatButtonModule,
  MatListModule,
  MatSliderModule,
  MatIconModule,
  MatToolbarModule,
  MatCardModule,
  NgOtpInputModule,
  NgxUiLoaderModule,
  MatSelectModule,
];
@NgModule({
  declarations: [
    AppComponent,
    ChooseLessonComponent,
    LessonDescriptionComponent,
    UserSettingComponent,
    ProfileComponent,
    ChooseLanguageComponent,
    TermsComponent,
    PrivacyComponent,
    RateComponent,
    DownloadsComponent,
    DownloadDescriptionComponent,
    LessonComponent,
    SupportComponent,
    NoDataFoundComponent,
    ChangeLanguageComponent,
    CounterDirective,
    AccountComponent,
    ChangePasswordComponent,
    SusbscriptionComponent,
    WelcomePageComponent,
    TransactionHistoryComponent,
    CancellationFeedbackComponent,
    ThankYouComponent,
    SendConcernComponent,
    FavoritesComponent,
    LastPlayedLessonsComponent,
    PlayFavLessonsComponent,
    NewLessonComponent,
    GiftCardsComponent,
    SendConcernComponent,
    NotifiationsComponent,
    RepeatedLessonsComponent,
    SetLearningGoalComponent,
    MyGoalsComponent,
    AwardsComponent,
    RepeatedLessonDescriptionComponent,
    NewRatingComponent,
    BadgeProgressComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    modules,
    DeviceDetectorModule,
    ProgressBarModule,
    InfiniteScrollModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
      imageSrc: "assets/images/play.svg",
    }),
  ],
  exports: [RouterModule],
  bootstrap: [AppComponent],
})
export class UserModuleModule {}
