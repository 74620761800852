<div class="inner-wraper login-bg-cover">
  <section class="abg-form-sect singup-pdd">
    <div class="container">
      <div class="login-in-color">
        <div class="setting-arrow">
          <!-- <a href="javascript:;" style="width: fit-content; word-break: initial" [routerLink]="['/login']"> -->
            <a href="javascript:void(0);" style="width: fit-content; word-break: initial" (click)="goToBack()">
            <i class="fas fa-long-arrow-alt-left"></i>
            <h6>Zurück</h6>
          </a>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="log-wd-max">
              <form id="register-form" [formGroup]="formData" (ngSubmit)="onClickSubmit(formData.value)" autocomplete="off">
                <h1 class="heading-log">
                  {{ socialId ? global.termsArray["lbl_complete_profile"] : global.termsArray["lbl_signup"] }}
                </h1>
                <p>{{ global.termsArray["lbl_signup_sub_title"] }}</p>

                <div class="dropdown inner-drop mb-4">
                  <p>{{ global.termsArray["lbl_learning_language_new"] }}</p>
                  <!-- <p>{{ global.termsArray["lbl_learning_language"] }}</p> -->
                  <button class="btn btn-secondary dropdown-toggle mb-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <!-- {{ formData.value["selectLanguage"] != "" ? formData.controls["selectLanguageTerm"].value : global.termsArray["lbl_choose_language"] }} -->
                    {{ selectLanguageTermFlag }}
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" (click)="selectLanguage(language)" *ngFor="let language of global.learningLanguages">{{ language.termtext }}</a>
                  </div>
                  <span class="glyphicon glyphicon-envelope form-control-feedback"></span>
                  <div *ngIf="formData.controls['selectLanguage'].value == '' && this.formSubmitAttempt">
                    <span class="error validation_err validation_err_color" *ngIf="formData.controls['selectLanguage'].value == ''">
                      {{ global.termsArray["err_select_language"] }}
                    </span>
                  </div>
                </div>

                <div class="form-group pb-4">
                  <input type="name" class="form-control autofill" id="exampleInputEmail1" style="text-transform: capitalize;"
                  [placeholder]="global.termsArray['lbl_name']" formControlName="username" name="username" maxlength="55"  ng-keydown="$event.keyCode != 32 ? $event:$event.preventDefault()"/>
                  <div *ngIf=" formData.controls['username'].invalid && (formData.controls['username'].dirty || formData.controls['username'].touched || this.formSubmitAttempt)">
                    <div *ngIf="formData.controls['username'].errors.required" class="validation_err validation_err_color" style="font-size: 14px;">
                      {{ global.termsArray["err_enter_name"] }}
                    </div>
                    <div *ngIf="formData.controls['username'].errors.cannotContainSpace" class="validation_err validation_err_color" style="font-size: 14px;">
                      {{global.termsArray['lbl_username_space_not_allowed']?global.termsArray['lbl_username_space_not_allowed']:'Username can not start with space.'}}
                    </div>
                  </div>
                </div>

                <!-- First name and surname fields are not replaced with user name -->

                <div class="form-group pb-4">
                  <input type="email" class="form-control" id="exampleInputEmail2"
                  [placeholder]="global.termsArray['lbl_email_address']" formControlName="emailId" name="emailId" maxlength="80"
                   />
                  <div *ngIf="
                  formData.controls['emailId'].invalid &&
                  (formData.controls['emailId'].dirty ||
                  formData.controls['emailId'].touched ||
                  this.formSubmitAttempt)
                  ">
                  <div *ngIf="formData.controls['emailId'].errors.required" class="validation_err validation_err_color" style="font-size: 14px;">
                    {{ global.termsArray["msg_enter_email"] }}
                  </div>
                  <div *ngIf="formData.controls['emailId'].errors.pattern" class="validation_err validation_err_color" style="font-size: 14px;">
                    {{ global.termsArray["err_valid_email"] }}
                  </div>
                </div>
              </div>
                <div class="form-group pb-4">
                  <div class="password-container">
                    <input type="password" class="form-control password" id="exampleInputPassword1" #passwordField [placeholder]="global.termsArray['lbl_password']" formControlName="password" name="password" />
                    <div class="password-view-wrapper tooltip-trigger" #toggleButton (click)="togglePassword(passwordField,toggleButton,toolTip)">
                      <span class="custom-tooltip" [attr.data-title]="global.termsArray['msg_show_password'] ? global.termsArray['msg_show_password']:'Passwort anzeigen'" #toolTip></span>
                      <i class="ri-eye-off-fill"></i>
                      <i class="ri-eye-fill"></i>
                    </div>
                  </div>
                  <div *ngIf="formData.controls['password'].invalid && (formData.controls['password'].dirty || formData.controls['password'].touched || this.formSubmitAttempt)">
                    <div *ngIf="formData.controls['password'].errors.required" class="validation_err validation_err_color" style="font-size: 14px;">
                      {{ global.termsArray["err_enter_password"] }}
                    </div>
                    <div *ngIf="formData.controls['password'].errors.minlength" class="validation_err validation_err_color" style="font-size: 14px;">
                      {{ global.termsArray["err_pass_length"] }}
                    </div>
                    <div *ngIf="formData.controls['password'].errors.maxlength" class="validation_err validation_err_color" style="font-size: 14px;">
                      {{ global.termsArray["err_pass_length"] }}
                    </div>
                    <div *ngIf="formData.controls['password'].errors.pattern" class="validation_err validation_err_color" style="font-size: 14px;">
                      {{global.termsArray['lbl_password_space_not_allowed']?global.termsArray['lbl_password_space_not_allowed']:'Password can not contain space.'}}
                    </div>

                  </div>
                </div>


                <!-- Select country for sofort -->
                <div class="dropdown inner-drop mb-4">
                  <button class="btn btn-secondary dropdown-toggle mb-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <!-- {{ formData.value["selectLanguage"] != "" ? formData.controls["selectLanguageTerm"].value : global.termsArray["lbl_choose_language"] }} -->
                    {{ selectCountryTermFlag }}
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" *ngFor="let country of countries" 
                    (click)="selectCountry(country)">{{ global.termsArray[country.term] }}</a>
                  </div>
                  <span class="glyphicon glyphicon-envelope form-control-feedback"></span>
                  <div *ngIf="formData.controls['selectCountry'].value == '' && this.formSubmitAttempt">
                    <span class="error validation_err validation_err_color" *ngIf="formData.controls['selectCountry'].value == ''">
                      {{ global.termsArray["err_select_country"]?global.termsArray["err_select_country"]:"Please select country" }}
                    </span>
                  </div>
                </div>


                <div class="guidelines-sec">
                  <a class="dch-pb" routerLink="/privacy-policy">{{ global.termsArray['lbl_Data_protection_guidelines'] }}</a>
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheck1" formControlName="acceptTerms"  name="acceptTerms">
                    <label class="custom-control-label" for="customCheck1">{{global.termsArray['msg_privacy_policy_web']}}</label>
                    <a href="javascript:;" [routerLink]="['/term-condition']">{{global.termsArray['msg_privacy_policy_link']}}</a>
                  </div>
                  <div *ngIf="(this.formSubmitAttempt || formData.controls['acceptTerms'].touched) && formData.controls['acceptTerms'].invalid && formData.controls['acceptTerms'].errors.pattern" class="validation_err validation_err_color" style="margin-bottom: 10px;">{{ global.termsArray["err_terms_condition"] }}</div>
                </div>

                <button type="submit" class="btn btn-primary mb-3">{{ global.termsArray["lbl_signup"] }}</button>
              </form>

              <div class="already-have-sec">
                <h6>{{global.termsArray["lbl_already_account"]}}
                  <a href="javascript:void(0);" routerLinkActive="active" (click)="setPageTitle('Login')">{{ this.global.termsArray["lbl_login"] ? this.global.termsArray["lbl_login"] : "Login" }}</a>
                  <!-- <a [routerLink]="['/login']" routerLinkActive="active" (click)="setPageTitle('Login')">{{ this.global.termsArray["lbl_login"] ? this.global.termsArray["lbl_login"] : "Login" }}</a> -->
                </h6>

                <a [routerLink]="['/forgot']" routerLinkActive="active">{{ global.termsArray["lbl_forgot_password"] }}</a>
              </div>

            </div>
          </div>

          <div class="col-md-6 top-space-log">
            <div class="log-item-right" (click)="markAs()">
              <h4>{{global.termsArray['lbl_login_heading_text']}}</h4>
              <h6>{{global.termsArray['lbl_login_desc_text']}}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>