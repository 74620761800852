<div class="inner-wraper lesson-pdd-sect" [ngClass]="isMenuIconsShow ? 'moveNavigationUp' : ''">
  <section class="lesson-cover-sect lesson-cover-sect-100 new_chgs02">
    <div class="container">
      <article *ngIf="loader">
        <div class="setting-arrow">
          <a href="javascript:;" (click)="navigate()">
            <i class="fas fa-chevron-left"></i>
            <h6>{{ global.termsArray["lbl_go_back"] }}</h6>
          </a>
        </div>
        <app-no-data-found *ngIf="lessons.length == 0" style="display: flex; justify-content: center; align-items: center"></app-no-data-found>
      </article>


      <!-- If data found -->
      <div class="lessons-slider" *ngIf="!loader">
        <div class="outs-box-fram" *ngIf="lessons.length > 0" [ngClass]="backgroundClass">

          <div class="slides-footer">
            <div class="down-navs">

              <!-- Labels for the nav bar -->
              <ul>
                <li>
                  <a href="javascript:void(0);" [ngClass]="{'max_767_width': this.lessons[currentIndex].type == 'title', 'color-white-icon1': this.lessons[currentIndex].type != 'title', 'active newCss2': hearStatus == 1, 'newCss2': hearStatus != 1}" (click)="hearStatus = 1; slidesFooter('active')" data-target="#video-carousel-example2" attr.data-slide-to="{{ 0 }}">
                    <i class="ri-eye-line"></i>
                    <p>{{ global.termsArray["lbl_hearActively"] }}</p>
                  </a>
                  
                  <div class="progress">
                    <span class="progress-bar" role="progressbar" [style.width]="(saveHistory['active'].max / saveHistory.active['total']) * 100 + '%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                    </span>
                  </div>
                </li>

                <li>
                  <a href="javascript;:" [ngClass]="{'max_767_width':this.lessons[currentIndex].type == 'title', 'color-white-icon1':this.lessons[currentIndex].type != 'title', 'active newCss2':hearStatus == 3, 'newCss2':hearStatus != 3}" (click)="hearStatus = 3;  currentIndex = speakIndex - 1; slidesFooter('speak')" data-target="#video-carousel-example2" attr.data-slide-to="{{ speakIndex-1 }}">
                    <i class="ri-chat-4-line"></i>
                    <p>{{global.termsArray["lbl_speak"] }}</p>
                  </a>
                  
                  <div class="progress">
                    <span class="progress-bar" role="progressbar" [style.width]="(saveHistory['speak'].max / saveHistory.speak['total']) * 100 + '%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></span>
                  </div>
                </li>

                <li>
                  <a href="javascript:void(0);" [ngClass]="{'max_767_width':this.lessons[currentIndex].type == 'title', 'color-white-icon1':this.lessons[currentIndex].type != 'title', 'active newCss2':hearStatus == 4, 'newCss2':hearStatus != 4}" (click)="hearStatus = 4; currentIndex = repeatIndex; slidesFooter('repeat')" data-target="#video-carousel-example2" attr.data-slide-to="{{ repeatIndex }}">
                    <i class="fas fa-redo"></i>
                    <p>{{ global.termsArray["lbl_repetition"] }}</p>
                  </a>

                  <div class="progress">
                    <span class="progress-bar" role="progressbar" [style.width]="(saveHistory['repeat'].max / saveHistory.repeat['total']) * 100 + '%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></span>
                  </div>
                </li>

                <li>
                  <a href="javascript:;" [ngClass]="{'max_767_width':this.lessons[currentIndex].type == 'title', 'color-white-icon1':this.lessons[currentIndex].type != 'title', 'active newCss2':hearStatus == 2, 'newCss2':hearStatus != 2}" (click)="hearStatus = 2; currentIndex = songLoopIndex - 1; slidesFooter('passive');" data-target="#video-carousel-example2" attr.data-slide-to="{{ lessonType == 'sentences' ? songLoopIndex-1 : songLoopIndex-4 }}">
                    <i class="ri-headphone-line"></i>
                    <p>{{ global.termsArray["lbl_listen_passively"] }}</p>
                  </a>
                 
                  <div class="progress">
                    <span class="progress-bar" role="progressbar" [style.width]="percent() + '%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                    </span>
                  </div>
                </li>
              </ul>
            </div>
           
          </div>
          
          <div id="video-carousel-example2" class="carousel slide carousel-fade" [ngClass]="isShowfakeBtn ? 'carousel-sec-fake' : ''" data-ride="carousel" data-interval="false" [hidden]="(!lessons && lessons.length == 0) || carosalHide">
          
           
              <div class="button-and-slider-sec">
                <div class="button-and-slider-sec-left">
                   <!-- Lesson description breadcrumbs -->
                  <div class="info-container-card">
                    <div class="cross-btn-sec"><button type="button" class="close-btn cp" aria-hidden="true" (click)="goBack()">&times;</button></div>
                    <div class="info-container">
                      <div class="lesson-title">
                        {{ lessonTitle }}
                      </div>
                      <div class="bread-crumb">
                        {{ breadCrumb }}
                      </div>
                    
                    </div>
                  </div>
                  <!-- Show play button if lesson type is mp3 -->
                  <div class="view-icon fake-btns" *ngIf="isShowfakeBtn">
                    <span class="mr-3">
                      <a>
                        <circle-progress id="playButtonProgress" (click)="fakeCircleBtn()" [percent]="percent()" [startFromZero]="false"
                          [maxPercent]="100" [showZeroOuterStroke]="true" [radius]="30" [space]="-2" [outerStrokeWidth]="0"
                          [innerStrokeWidth]="0" [outerStrokeColor]="percent() > 0 ? '#3399FF' : '#c3d6eb'"
                          [innerStrokeColor]="'#c3d6eb'" [animation]="true" [animationDuration]="300"
                          [backgroundStrokeWidth]="0" [outerStrokeLinecap]="'round'" [space]="-7" [showUnits]="false"
                          [showTitle]="false" [showSubtitle]="false" [showImage]="true" [showInnerStroke]="true"
                          [showZeroOuterStroke]="true" [imageSrc]="circleImage[fakeBtnData?.toggle]" [imageHeight]="25"
                          [imageWidth]="25" [responsive]="false" [class]="'circle-progress'" [backgroundColor]="'#fff'">
                        </circle-progress>
                      </a>
                    </span>
                    <div class="dropdown1">
                      <button class="speed_dropdown" id="speedDrop" type="button" data-toggle="dropdown" aria-expanded="false">
                        <span class="button_view">
                          <img height="10" width="10" src="assets/images/playIcon.svg"/>
                        </span>
                        <span class="selected_text">{{defaultSpeed.toFixed(1)}}x</span>
                        <i class="ri-arrow-down-s-line"></i>
                      </button>
                      <div class="dropdown-menu speed_options">
                        <a class="dropdown-item" [ngClass]="{active:option?.selected}" *ngFor="let option of speedOptions;" (click)="selectOption(option)">
                          <i class="ri-check-fill"></i>
                          <span class="sl_value" [attr.data-value]="option?.value">{{option?.value.toFixed(1)}}x</span>
                        </a>
                      </div>
                    </div>
  
                <span class="alarm_icon" (click)="setTimerPopup(true)" *ngIf="lessons[currentIndex].type=='songLoop'">
                  <i class="ri-alarm-line"></i>
                  <p class="mb-0 ml-3 mt-2" style="white-space: nowrap;" id="alarmTime">{{getAlarmTimeString()}}</p>
                </span>
                  </div>

                </div>


                <div class="blue-screen-controls-sec">
                  <a class="carousel-control-prev" href="javascript:;" *ngIf="currentIndex > 0" (click)="updateIndex(false);slideChanged();">
                    <span class="carousel-control-prev-icon" href="#video-carousel-example2" role="button" data-slide="prev" aria-hidden="true" [ngClass]="lessons[currentIndex].type == 'title' ? 'white-color' : ''"></span>
                    <span class="sr-only">Previous</span>
                  </a>
  
                  <a class="carousel-control-next" href="javascript:void(0);" *ngIf="currentIndex < lessons.length - 1 || currentIndex == 0" (click)="updateIndex(true);slideChanged();">
                    <span class="carousel-control-next-icon" href="#video-carousel-example2" role="button" data-slide="next" aria-hidden="true" [ngClass]="lessons[currentIndex].type == 'title' ? 'white-color' : ''"></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>

                <div class="divider"></div>
                 
              </div>

            <div class="carousel-inner lesson-sec-pdd" role="listbox">
              <div *ngFor="let lesson of lessons; let i = index" [ngClass]="(i == 0 ? 'carousel-item active ' + 'list_' + i : 'carousel-item ') + 'list_' + i">
              
                <div class="bg-carousel-box d-none">
                  <ol class="carousel-indicators scroo-dev-fix">
                    <li [ngClass]="j == 0 ? 'active' : ' ' + 'lists_' + j" [ngStyle]="{'margin-left': j == 0 && this.lessons.length > 38 ? (this.lessons.length - 38) * 31 + 'px' : '0px'}" id="scroll-dot-{{ j }}" *ngFor="let dropdown of lessons; let j = index" data-target="#video-carousel-example2" attr.data-slide-to="{{ j }}"></li>
                  </ol>
                </div>
                
                <!-- If lesson type is title -->
                <div class="view finger-sec demo-lektion" *ngIf="lesson.type == 'title'">
                  <h5 [ngClass]="lesson.type == 'title' ? 'color-white' : '' " *ngIf="lesson.heading && lessonType == 'sentences' && i == 0; else falsecondition" style="font-family: Roboto;font-weight: 400;">
                    <p style="font-size:28px">{{ global.termsArray["lbl_welcome_to"] }} : {{ lesson.lessonID }} <br><br></p>
                    {{ global.termsArray[lesson.heading] }}
                  </h5>
                  <ng-template #falsecondition>
                    <h5 *ngIf="lesson.lessonID == 'lbl_demo' && lesson.heading != 'lbl_congratulations'" [ngClass]="lesson.type == 'title' ? 'color-white' : '' ">
                      {{ global.termsArray[lesson.heading] }}
                    </h5>
                    <h5 *ngIf="lesson.lessonID != 'lbl_demo'" [ngClass]="lesson.type == 'title' ? 'color-white' : '' ">
                      {{ global.termsArray[lesson.heading] }}
                    </h5>
                  </ng-template>

                  <h5 *ngIf="lesson.state != 'passive'" [ngClass]="lesson.type == 'title' ? 'color-white' : ''">
                    {{ lessonType == "sentences" ? global.termsArray[lesson.message] : lesson.title }}
                  </h5>
                  <h5 *ngIf="lesson.state == 'passive' && lesson.lessonID == 'lbl_demo'"
                    [ngClass]="lesson.type == 'title' ? 'color-white' : ''">
                    {{ lessonType == "sentences" ? global.termsArray[lesson.message] : lesson.title }}
                  </h5>
                  <h5
                    *ngIf="lesson.state == 'passive' && lesson.lessonID != 'lbl_demo' && lesson.message != 'msg_passive'"
                    [ngClass]="lesson.type == 'title' ? 'color-white' : ''">
                    {{ lessonType == "sentences" ? global.termsArray[lesson.message] : lesson.title }}
                  </h5>

                 
                  <div *ngIf="i==0" class="text-center finger-check">
                    <img src="assets/images/finger.png" />
                  </div>
                  <div *ngIf="lessons.length==(i+1)" class="text-center btn-blue" (click)="navigate('lesson')">
                    <a class="btn" href="javascript:;">{{ global.termsArray["lbl_to_lesson"] }}</a>
                  </div>
                  <span *ngIf="lesson?.button">
                    <a class="btn-flex-column">
                      <div class="bttn-navi-ph" (click)="navigate('lesson-description')">
                        <a class="btn" href="javascript:;">{{ global.termsArray["lbl_free_trail"] }}</a>
                      </div>
                     
                    </a>
                  </span>

                </div>


                <!-- If lesson type is imagination -->
                <div class="view" *ngIf="lesson.type == 'imagination'">
                  <div class="listeng">
                    <a href="javascript:;"><img src="assets/images/Icon-top-situation.svg"
                        alt="icon top situation image" /></a>
                    <h3>{{ global.termsArray[lesson?.heading] }}</h3>
                  </div>
                  <div class="masks text-acaslon row">
                    <h2>{{ lesson?.sentence }}</h2>
                  </div>

                </div>

              
                <!-- Lesson type is compare -->
                <div class="view" *ngIf="lesson.type == 'compare'">
                  <div class="listeng">
                    <a href="javascript:;"><img src="assets/images/Icon-top-compare.svg"
                        alt="icon top comapre image" /></a>
                    <h3>{{ global.termsArray[lesson?.heading] }}</h3>
                  </div>
                  <div class="masks">
                    <div class="masks-inner-fxd">
                      <div class="row">
                        <div class="column"
                          style="background-color: rgb(255 255 255 / 21%); padding: 5px 2.5px 5px 2.5px; margin: 5px 2.5px 5px 2.5px; "
                          *ngFor="let sentence of lesson?.sentenceArray; let last = last; let first = first; let in = index ">
                          <h2
                            style="font-size: 22px; color: #fff; font-family: ACaslonPro-Bold; font-weight: 900; margin-top: 5px; ">
                            {{ sentence?.sentence1 }}
                          </h2>
                          <h2 *ngIf="sentence?.sentence3"
                            style="font-size: 18px; color: #fff; font-family: ACaslonPro-Bold; ">
                            {{ sentence?.sentence3 }}</h2>
                          <h2 style="font-family: ACaslonPro-Regular; font-size: 24px; margin-top: 20px; ">
                            {{ sentence?.sentence2 }}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Lesson type is repeat -->
                <div class="view" *ngIf="lesson.type == 'repeat'">
                  <div class="listeng">
                    <a href=""><img src="assets/images/Icon-top-repeat.svg" alt="icon top repeat image" /></a>
                    <h3>{{ global.termsArray[lesson?.heading] }}</h3>
                  </div>

                  <div class="masks text-acaslon">
                    <h2>{{ lesson?.sentence }}</h2>
                    <div class="row">
                      <div style="background-color: rgb(255 255 255 / 21%); height: max-content; padding: 5px 2.5px 5px 2.5px;margin: 5px 2.5px 5px 2.5px;" *ngFor="let item of splitMyWord(lesson?.sentence1)">
                        <h2 style="font-size: 26px; color: #fff; font-family: ACaslonPro-Bold; font-weight: 900;">{{ item }}</h2>
                      </div>
                    </div>
                  </div>
                </div>

                <ng-container *ngIf="lesson.type == 'song'">
                  <div class="view">
                    <div class="listeng">
                      <a href="javascript:;"><img src="assets/images/Icon-top-listen.svg"
                          alt="icon top listen image" /></a>
                      <h3>{{ global.termsArray[lesson?.heading] }}</h3>
                    </div>
                    <div class="masks">
                      <div class="masks-inner-fxd">
                        <div class="row">
                          <div class="column"
                            style="background-color: rgb(255 255 255 / 21%);/* background-color: none; */padding: 5px 2.5px 5px 2.5px;margin: 5px 2.5px 5px 2.5px;"
                            *ngFor="let sentence of lesson?.sentenceArray; let last = last; let first = first; let in = index">
                            <h2 style="font-size: 26px; color: #fff; font-family: ACaslonPro-Bold; font-weight: 900;">
                              {{ sentence?.sentence1 }}
                            </h2>
                            <h2 *ngIf="sentence?.sentence3"
                              style="font-size: 18px; color: #fff; font-family: ACaslonPro-Bold;">
                              {{ sentence?.sentence3 }}
                            </h2>
                            <h2 style="font-family: ACaslonPro-Regular; font-size: 22px; margin-top: 20px;color: #fff;">
                              {{ sentence?.sentence2 }}
                            </h2>
                          </div>
                        </div>
                        <div style="min-height: 100px; max-height: 100px; width: 100%; display: flex;"></div>
                      </div>
                    </div>
                  </div>
                  
                </ng-container>

                <ng-container *ngIf="lesson.type == 'songLoop'">
                  <div class="view" *ngIf="lesson.type == 'songLoop'">
                    <div class="listeng">
                      <h3 style="font-size: 20px">
                        {{ global.termsArray[lesson?.heading] }}
                      </h3>
                    </div>

                    <div class="time-section-less">
                      <span>
                        <ng-container [counter]="loopStopWatchTime" [interval]="900" [status]="lesson?.toggle == 'play' ? true : false" (value)="value = $event">
                          <h3 style="color: rgb(195, 214, 235)">
                            {{ value?.value }}
                          </h3>
                        </ng-container>
                      </span>
                    </div>
                  </div>
                  </ng-container>

                <ng-container *ngIf="lesson.type == 'fast_song_with_sentence'">
                  <div class="view">
                    <div class="listeng">
                      <a href="javascript:;"><img src="assets/images/Icon-top-listen.svg"
                          alt="icon top listen image" /></a>
                      <h3>{{ global.termsArray[lesson?.heading] }}</h3>
                    </div>
                    <div class="masks newCss">
                      <div class="masks-inner-fxd">
                        <div class="row touch-event" *ngFor="let data of lesson.data; let j = index"
                          (click)="playSpeakTabAudios(j); $event.stopPropagation()"
                          id="scroll-{{ i + '' + j }}" [ngClass]="j == audioLoopIndex ? 'playing' : 'not-playing'"
                          style="border-bottom: solid 1px white; height: fit-content; margin-left: 0px; cursor: pointer;">
                          <div class="column"
                            style="background-color: none; padding: 5px 2.5px 5px 2.5px; margin: 5px 2.5px 5px 2.5px;"
                            *ngFor="let sentence of data?.sentenceArray;let last = last;let first = first;let in = index">
                            <h3 style="font-family: ACaslonPro-Bold; font-size: 20px; font-weight: 700;color: #fff;">
                              {{ sentence?.sentence1 }}</h3>
                            <h3 *ngIf="sentence?.sentence3"
                              style="font-family: ACaslonPro-Regular; font-size: 20px;color: #fff;">
                              {{ sentence?.sentence3 }}</h3>
                          </div>
                        </div>
                        <div style="height: 90px; min-height: 90px; max-height: 90px; width: 100%; display: flex;">
                        </div>
                      </div>
                    </div>
                  </div>
                  </ng-container>

                <ng-container *ngIf="lesson.type == 'slow_song_with_sentence'">
                  <div class="view">
                    <div class="listeng">
                      <a href="javascript:;"><img src="assets/images/Icon-top-listen.svg"
                          alt="icon top listen image" /></a>
                      <h3>{{ global.termsArray[lesson?.heading] }}</h3>
                    </div>
                    <div class="masks newCss">
                      <div class="masks-inner-fxd">
                        <div class="row touch-event" *ngFor="let data of lesson.data; let j = index"
                          (click)="playSpeakTabAudios(j); $event.stopPropagation()"
                          id="scroll-{{ i + '' + j }}" [ngClass]="j == audioLoopIndex ? 'playing' : 'not-playing' "
                          style="width: fit-content; border-bottom: solid 1px white; height: fit-content; margin-left: 0px; cursor: pointer; ">
                          <div class="column"
                            style="background-color: none; padding: 5px 2.5px 5px 2.5px; margin: 5px 2.5px 5px 2.5px;"
                            *ngFor="let sentence of data?.sentenceArray; let last = last; let first = first; let in = index ">
                            <h3 style="font-family: ACaslonPro-Bold; font-size: 20px;">
                              {{ sentence?.sentence1 }}
                            </h3>
                            <h3 *ngIf="sentence?.sentence3" style="font-family: ACaslonPro-Regular; font-size: 20px;">
                              {{ sentence?.sentence3 }}
                            </h3>
                          </div>
                        </div>
                        <div style="height: 90px; min-height: 90px; max-height: 90px; width: 100%; display: flex;">
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </ng-container>
                <div class="list-sentens text-center" *ngIf="lesson.type == 'sentenceList'">
                  <div class="in-list-leson view">
                    <div class="listeng">
                      <a href="javascript:;" [hidden]="true"><img src="assets/images/Icon-top-listen.svg" alt="icon top listen image" /></a>
                      <h3 class="in-list-leson">{{ global.termsArray["lbl_sentencelist"] }}</h3>

                    </div>
                    <div class="masks ov_auto">
                      <div class="point-sect" *ngFor="let sen of lesson?.data; let j = index;">
                        <a href="javascript:;" class="repeat-lesson d-flex" (click)="playRepeatTabAudio(sen?.index, j)">
                          <span class="loading-audio" [ngClass]="repeatSelectedIndex == j && repeatPlayedIndex == j? 'mini-loader-active': repeatSelectedIndex == j ? 'pause' : repeatPlayedIndex == j ? 'refresh' : 'play'">
                            <img class="mini-loader" src="assets/images/loader.gif" width="16px" height="16px"/>
                            <i class="fas fa-pause"></i>
                            <i class="fas fa-play"></i>
                            <i class="fa fa-refresh"></i>
                            <p class="dummy-space m-0 p-0"></p>
                            <!-- <i class="repeatSelectedIndex == j && repeatPlayedIndex == j? 'd-none': repeatSelectedIndex == j ? 'fas fa-pause' : repeatPlayedIndex == j ? 'fa fa-refresh' : 'fas fa-play' "></i>                            <i [ngClass]="repeatSelectedIndex == j && repeatPlayedIndex == j? 'd-none': repeatSelectedIndex == j ? 'fas fa-pause' : repeatPlayedIndex == j ? 'fa fa-refresh' : 'fas fa-play' "></i> -->
                          </span>                         
                        <div class="list-sentens">
                          <div class="column" style="background-color: rgb(255 255 255 / 21%);padding: 5px 2.5px 5px 2.5px;margin: 5px 2.5px 5px 2.5px;" *ngFor="let item of splitMyWord(sen?.sentence)">
                            <h2 style="font-size: 26px; color: #fff; font-family: ACaslonPro-Bold; font-weight: 900;">{{ item }}</h2>
                          </div>
                        </div>
                        </a>
                      </div>
                    </div>

                  </div>
                </div>
                
              </div>
              

            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</div>
<div class="modal fade" id="myModal1" role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog big-model-dialog-lesson">
    <div class="modal-content" style="background-color: white">
      <div class="modal-body" style="font-size: 20px; font-family: Akkurat-Bold; margin: 0; color: #4782c5; ">
        {{ this.global.termsArray["lbl_confirm_repeat_msg"] }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" style="font-size: 15px; font-family: Akkurat-Bold"
          data-dismiss="modal" data-toggle="modal" data-target="#myModal2">
          {{ global.termsArray["lbl_no"]}}
        </button>
        <button type="button" class="btn btn-primary" style="font-size: 15px; font-family: Akkurat-Bold"
          data-dismiss="modal" (click)="modalConfirmationFill()" data-target="#video-carousel-example2">
          {{ global.termsArray["lbl_yes"] }}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="myModal2" role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog big-model-dialog-lesson">
    <div class="modal-content" style="background-color: white">
      <div class="modal-body" style="font-size: 20px; font-family: Akkurat-Bold; margin: 0; color: #4782c5; ">
        {{ this.global.termsArray["lbl_easily_repeat"] }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" style="font-size: 15px; font-family: Akkurat-Bold"
          data-target="#video-carousel-example2" attr.data-slide-to="{{ currentIndex -1}}" data-dismiss="modal">
          {{ global.termsArray["lbl_ok"]}}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade app-download-popup" id="myModal3" role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog big-model-dialog-lesson">
    <div class="modal-content" style="background-color: white">
      <div class="modal-head">
        <span data-target="#myModal3" data-dismiss="modal" class="text-right"><i class="fas fa-times"></i></span>
      </div>
      <div class="modal-body" style="font-size: 20px; font-family: Akkurat-Bold; margin: 0; color: #4782c5; ">
        <h3 class="text-center">{{ global.termsArray["lbl_app_download_msg_1"] }}</h3>
        <h3 class="text-center">{{ global.termsArray["lbl_app_download_msg_2"] }}</h3>
      </div>
      <div class="modal-footer">
        <div class="button-gp-d space-margin">
          <a href="javascript:;" class="btn btn-ios d-flex" (click)="openUrl(1)">
            <figure>
              <img src="assets/images/google-play.svg" alt="google play image" />
            </figure>
            <figcaption>
              <span>{{ global.termsArray["lbl_home_get_it_on"] }}</span>
              <strong>
                {{ global.termsArray["lbl_home_google_play"] }}</strong>
            </figcaption>
          </a>
          <a href="javascript:;" class="btn btn-ios d-flex" (click)="openUrl(2)">
            <figure>
              <i class="fab fa-apple"></i>
            </figure>
            <figcaption>
              <span>
                {{ global.termsArray["lbl_home_download_on_the"] }}</span>
              <strong> {{ global.termsArray["lbl_home_app_store"] }}</strong>
            </figcaption>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade app-download-popup" id="myModal4" role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog big-model-dialog-lesson">
    <div class="modal-content" style="background-color: white">
      <div class="modal-head">
        <span data-target="#myModal4" data-dismiss="modal" class="text-right"><i class="fas fa-times"></i></span>
      </div>
      <div class="modal-body" style="font-size: 20px; font-family: Akkurat-Bold; margin: 0; color: #4782c5; ">
        <h3 class="text-center">{{ global.termsArray["safari_mobile_text_1"] }}</h3>
        <h3 class="text-center">{{ global.termsArray["safari_mobile_text_2"] }}</h3>
      </div>
      <div class="modal-footer">
        <div class="button-gp-d space-margin">
          <a href="javascript:;" class="btn btn-ios d-flex" (click)="openUrl(1)">
            <figure>
              <img src="assets/images/google-play.svg" alt="google play image" />
            </figure>
            <figcaption>
              <span>{{ global.termsArray["lbl_home_get_it_on"] }}</span>
              <strong>
                {{ global.termsArray["lbl_home_google_play"] }}</strong>
            </figcaption>
          </a>
          <a href="javascript:;" class="btn btn-ios d-flex" (click)="openUrl(2)">
            <figure>
              <i class="fab fa-apple"></i>
            </figure>
            <figcaption>
              <span>
                {{ global.termsArray["lbl_home_download_on_the"] }}</span>
              <strong> {{ global.termsArray["lbl_home_app_store"] }}</strong>
            </figcaption>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade app-download-popup" id="myModal5" role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog big-model-dialog-lesson">
    <div class="modal-content" style="background-color: white">
      <div class="modal-head">
        <span data-target="#myModal5" data-dismiss="modal" class="text-right"><i class="fas fa-times"></i></span>
      </div>
      <div class="modal-body" style="font-size: 20px; font-family: Akkurat-Bold; margin: 0; color: #4782c5; ">
        <h3 class="text-center">{{ global.termsArray["safari_desktop_text_1"] }}</h3>
        <h3 class="text-center">{{ global.termsArray["safari_desktop_text_2"] }}</h3>
      </div>
      <div class="modal-footer">
        <div class="button-gp-d space-margin">
          <a href="javascript:;" class="btn btn-ios d-flex" (click)="openUrl(1)">
            <figure>
              <img src="assets/images/google-play.svg" alt="google play image" />
            </figure>
            <figcaption>
              <span>{{ global.termsArray["lbl_home_get_it_on"] }}</span>
              <strong>
                {{ global.termsArray["lbl_home_google_play"] }}</strong>
            </figcaption>
          </a>
          <a href="javascript:;" class="btn btn-ios d-flex" (click)="openUrl(2)">
            <figure>
              <i class="fab fa-apple"></i>
            </figure>
            <figcaption>
              <span>
                {{ global.termsArray["lbl_home_download_on_the"] }}</span>
              <strong> {{ global.termsArray["lbl_home_app_store"] }}</strong>
            </figcaption>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>



<button type="button" class="d-none" id="mobile-app-dwn-popup" data-dismiss="modal" data-toggle="modal"
  data-target="#myModal3"></button>

<button type="button" class="d-none" id="safari-mobile-app-dwn-popup" data-dismiss="modal" data-toggle="modal"
  data-target="#myModal4"></button>

<button type="button" class="d-none" id="safari-desktop-app-dwn-popup" data-dismiss="modal" data-toggle="modal"
  data-target="#myModal5"></button>


<!-- <div id="speed_container_mobile" *ngIf="isMobileDevice && isShowfakeBtn">
  <div class="dropdown">
    <button class="speed_dropdown" id="speedDrop" type="button" data-toggle="dropdown" aria-expanded="false">
      <span class="button_view">
        <img height="10" width="10" src="assets/images/playIcon.svg"/>
      </span>
      <span class="selected_text">{{defaultSpeed.toFixed(1)}}x</span>
      <i class="ri-arrow-down-s-line"></i>
    </button>
    <ul class="dropdown-menu speed_options">
      <li class="dropdown-item" [ngClass]="{active:option?.selected}" *ngFor="let option of speedOptions;" (click)="selectOption(option)">
        <i class="ri-check-fill"></i>
        <span class="sl_value" [attr.data-value]="option?.value">{{option?.value.toFixed(1)}}x</span>
      </li>
    </ul>
  </div>
</div>  -->


<div class="alarm_container closePopup" *ngIf="showTimerPopup" (click)="setTimerPopup(false,$event);">
  <div class="alarm_content anim_popup">
    <h3>Set timer</h3>
    <p class="w-100">Your lesson history will automaticly saved after {{alarmTime}} minutes and the audio will stop.</p>
    <div class="d-flex mt-3">
      <button type="button" class="alarm_btns alarm_minus" (click)="adjustAlarmTime();">
        <i class="ri-subtract-line"></i>
      </button>
      <input type="number" class="form-control mx-1" min="1" max="10" [(ngModel)]="alarmTime"
        (input)="checkAlarmTime($event);">
      <button type="button" class="alarm_btns alarm_plus" (click)="adjustAlarmTime('+')">
        <i class="ri-add-line"></i>
      </button>
    </div>
    <div class="d-flex mt-5">
      <button class="btn btn-outline-danger mr-3" (click)="setTimerPopup(false);resetAlarm();">Cancel</button>
      <button class="btn btn-outline-success" (click)="setTimerPopup(false);setAlarm();">Set</button>
    </div>
  </div>
</div>