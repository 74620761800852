<ng-container *ngIf="!global.mobileCms">
  <footer class="footer-bg" *ngIf="!global.isLogin">
    <div class="container" style="width: 100%;">
      <div class="d-flex justify-content-between">
        <a (click)="srcTop()" [routerLink]="['/']"><img src="assets/images/white-logo.svg" alt="home logo image" /></a>
        <img class="award-img" src="assets/images/AppAward.png" alt="App award image"/>
       
        <div class="footer-bg-in">
          <p class="disp-none"
            style="color: #fff; font-size: 16px; font-weight: 400; padding: 0px 20px; text-decoration: none; ">
            {{ copyRight }}
          </p>
          <ul class="foot-links">
            <li>
              <a [routerLink]="['imprint']" routerLinkActive="active" class="nav-link" (click)="setPageTitle('Imprint')">{{ this.global.termsArray["lbl_imprint"] }}</a>
            </li>
            <li>
              <a [routerLink]="['privacy-policy']" routerLinkActive="active" class="nav-link" (click)="setPageTitle('Privacy Policy')">{{ this.global.termsArray["lbl_privacy_policy"] }}</a>
            </li>
            <li>
              <a [routerLink]="['agb']" routerLinkActive="active" class="nav-link" (click)="setPageTitle('AGB')">{{ this.global.termsArray["lbl_agb"] }}</a>
            </li>
            <li>
              <a [routerLink]="['contact']" routerLinkActive="active" (click)="setPageTitle('Contact')">{{ this.global.termsArray["lbl_contact"] }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</ng-container>