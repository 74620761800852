<div class="inner-wraper">
    <section class="abg-sect">
        <div class="container">
            <app-no-data-found *ngIf="!contentLoader"></app-no-data-found>
            <article>
                <h1>{{ this.global.termsArray["lbl_agb"] ? this.global.termsArray["lbl_agb"] : 'AGB' }}</h1>
                <div id="cmspages_id"></div>
            </article>
        </div>
    </section>
</div>

  