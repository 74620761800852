<div class="inner-wraper">
    <section class="lesson-cover-sect" >
      <div class="container">
        <div class="lektionen-cover-new">
          <div class="lekt-title" style="display: block !important;">
            <div class="d-flex">
              <a href="javascript:;" (click)="location.back()"><i class="ri-arrow-left-s-line"></i></a>
              <h2>{{ global.termsArray["lbl_my_repeations"] }}</h2>
            </div>
          </div>
  
          <!-- <div class="list-sentens-box p-2" infiniteScroll [scrollWindow]="false" [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [immediateCheck]="true" (scrolled)="onScroll()"> -->
          <div class="list-sentens-box p-2" infiniteScroll [scrollWindow]="false" [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [immediateCheck]="true">
            <div class="levels-list">
              <ul class="nav not">
                <li class="nav-item" [ngClass]="level == 'all' ? 'li-active' : ''" (click)="filterLevel('all')">
                  <a class="nav-link" href="javascript:;" [ngClass]="level == 'all' ? 'text-active' : ''">{{ global.termsArray["lbl_allLevels"] ? global.termsArray["lbl_allLevels"] : "All level" }}</a>
                </li>
                <li class="nav-item" [ngClass]="level == 1 ? 'li-active' : ''" (click)="filterLevel(1)">
                  <a class="nav-link" href="Javascript:;" [ngClass]="level == 1 ? 'text-active' : ''">{{ global.termsArray["lbl_level_one"] ? global.termsArray["lbl_level_one"] : "Level A1" }}</a>
                </li>
                <li class="nav-item" [ngClass]="level == 2 ? 'li-active' : ''" (click)="filterLevel(2)">
                  <a class="nav-link" href="javascript:;" [ngClass]="level == 2 ? 'text-active' : ''"> 
                    {{ global.termsArray["lbl_level_two"] ? global.termsArray["lbl_level_two"] : "Levels A2" }}</a>
                </li>
                <li class="nav-item" [ngClass]="level == 3 ? 'li-active' : ''" (click)="filterLevel(3)">
                  <a class="nav-link" href="javascript:;" [ngClass]="level == 3 ? 'text-active' : ''">{{ global.termsArray["lbl_level_three"] ? global.termsArray["lbl_level_three"] : "Levels B1" }}</a>
                </li>
              </ul>
            </div>
  
            <div class="leisure-lp-lists">
              <div class="level-point" *ngFor="let list of LessonList; let i = index">
                <div class="level-point-in">
                  <div class="mb-2">
                    <div class="fav-css">
                      <a href="javascript:;" class="mr-2" (click)="toggleDelete(list.lesson_id,1) ">
                        <figure>
                          <i class="ri-close-line"></i></figure>
                      </a>
                      
                      <a *ngIf="(list.is_free && list.is_demo!=1) || (list.is_demo==2 && (isSubscribed || isWithinFreeTrial()))" (click)="list.is_favorite==0 ? addFavorites(list.lesson_id) : openModal(favorites,'big-model-dialog2');favLessonId=list.lesson_id">
                        <figure><i [ngClass]="list.is_favorite == 1 ? 'fav-lesson ri-heart-line' : 'ri-heart-line'"></i></figure>
                      </a>
                    </div>
                    <div class="lock-css">
                      <a class="h6-css newCss">{{ global.termsArray[returnLevel(list.level_id)]  ? global.termsArray[returnLevel(list.level_id)]
                          : "name not inserted"}}</a>
                      <img class="unlock-icon"  *ngIf="!list?.is_free && (!isSubscribed  && !isWithinFreeTrial())" src="assets/images/unlock@3x.png"  alt="unlock icon image"/>
                    </div>
                  </div>
                  <h4  (click)="getDueDate(list)">{{ list?.lessonTitle ? list?.lessonTitle : "Name not insert" }}</h4>
  
                  <div class="progress-bar-sec">
                    <div class="lesson-hints">
                      <span class="w-100 lesson-label" [ngClass]="list.active_progress == 0 ? 'lesson-label-red' : 'lesson-label-green' ">
                        <h3 class="text-center">
                          {{list.active_progress == 0 ? global.termsArray['lbl_not_started_yet'] : global.termsArray['lbl_your_progress']}}
                        </h3>
                      </span>
                    </div>
  
                    <div class="down-navs">
                      <ul>
                        <li>
                          <div class="progress">
                            <span class="progress-bar active-tab" role="progressbar" [style.width]="100 + '%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></span>
                          </div>
                          <a href="javascript:;" class="#">{{ global.termsArray["lbl_hearActively"] ? global.termsArray["lbl_hearActively"] : "Hear actively" }}</a>
                        </li>
  
                        <li>
                          <div class="progress">
                            <span class="progress-bar" role="progressbar" [style.width]="list?.speak_progress + '%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </span>
                          </div>
                          <a href="javascript:;" class="#">{{ global.termsArray["lbl_method_speak"] ? global.termsArray["lbl_method_speak"] : "Speak" }}</a>
                        </li>
  
                        <li>
                          <div class="progress">
                            <span class="progress-bar" role="progressbar" [style.width]="list?.repeat_progress + '%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </span>
                          </div>
                          <a href="javascript:;" class="#">{{ global.termsArray["lbl_repetition"] ? global.termsArray["lbl_repetition"] : "Repeat" }}</a>
                        </li>
  
                        <li>
                          <div class="progress">
                            <span class="progress-bar" role="progressbar" [style.width]="list?.passive_progress + '%'"
                              aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </span>
                          </div>
                          <a href="javascript:;" class="active">{{ global.termsArray["lbl_listen_passively"] ? global.termsArray["lbl_listen_passively"] : "listen passively" }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="bttn-blue" [ngClass]="list.active_progress == 0 ? 'Beginnen' : 'Fortfahren' ">
                    <a class="btn d-flex align-items-center" href="javascript:;" *ngIf="list.repeating_complete==false" (click)="setLessonTitle(list); (i==0) ?navigate(list, template, 0):navigate(list, template, i)" 
                    [ngClass]="(list.active_progress == 100 && list.passive_progress == 100 && list.repeat_progress == 100 && list.speak_progress == 100 ) ?'repeatation' : ''">
                    {{global.termsArray["lbl_repetitions"] }} {{ list.repeating_cycle.toString() + "/2"}}   
                    <div class="badege-number">
                        <span><i class="ri-calendar-line"></i></span>
                        <span class="notification-badge repeatation " [ngClass]="(getDueDate(list)<0) ?'pending' : ''" >{{(getDueDate(list) || getDueDate(list)==0) ?getDueDate(list) :''}}</span>
                      </div>
                    </a>
                    <a class="btn repeatation repeate-btn-2" href="javascript:;" *ngIf="list.repeating_complete==true" (click)="setLessonTitle(list); (i==0) ?navigate(list, template, 0):navigate(list, template, i)">
                      {{ global.termsArray["lbl_completed_repeat"]  }}<span>{{  global.termsArray["lbl_click_start"] }} </span>
                    </a>

                    <div class="badege-icon d-flex">
                      <app-badge-progress [scale]="0.8" [value]="list.repeating_cycle==2 ? 100 : list.current_percentage > 100 ? 100: list.current_percentage " [height]="40" width="40"></app-badge-progress>
                      <app-badge-progress [scale]="0.8" [value]="list.repeating_cycle==1 ? 0 : list.current_percentage > 100 ? 100:list.current_percentage" [height]="40" width="40"></app-badge-progress>
                    </div>
                  </div>
                </div>
              </div>
              <div class="level-point" *ngIf="LessonList && LessonList?.length == 0">
                <div class="level-point-in d-flex justify-content-center align-items-center">
                  <div>
                      {{ global.termsArray['lesson_not_found'] ? global.termsArray['lesson_not_found'] : "Lesson not found." }}
                  </div>
                </div>
              </div>
            </div>
            <ng-container *ngIf="deletedLessonList.length>0">
              <div class="level-point">
                <h4  class="delete-heading">{{global.termsArray["lbl_deleted_repeats"] ? global.termsArray["lbl_deleted_repeats"] :'Gelöschte Wiederholungen'}}</h4>
                <ul class="delete-lession-list" *ngFor="let list of deletedLessonList; let i = index">
                  <li>
                    <a href="javascript:;" class="mr-2 d-flex" (click)="toggleDelete(list.lesson_id,0) ">
                      <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.10118 12.4293H1.55164C0.691773 12.4293 0 11.7368 0 10.8759V2.59141C0 1.96359 0.37498 1.39403 0.956845 1.15456C1.53871 0.91508 2.20462 1.04453 2.65072 1.49111L5.34023 4.18359C11.0037 -1.41495 20.1261 -1.39553 25.7572 4.24831C31.4143 9.91157 31.4143 19.0893 25.7572 24.7526C20.1002 30.4158 10.9326 30.4158 5.27558 24.7526C4.46743 23.9435 4.46743 22.6296 5.27558 21.8206C6.08372 21.0116 7.39615 21.0116 8.2043 21.8206C12.245 25.8658 18.7942 25.8658 22.835 21.8206C26.8757 17.7754 26.8757 11.219 22.835 7.17379C18.8136 3.14802 12.3161 3.12861 8.26895 7.10907L10.9261 9.77566C11.3722 10.2222 11.5015 10.8889 11.2623 11.4714C11.0231 12.0539 10.4542 12.4293 9.82706 12.4293H2.10118Z" fill="white"/>
                        </svg>
                         {{ list?.lessonTitle ? list?.lessonTitle : "Name not insert" }}
                    </a>
                  </li>
                </ul>
              </div>
      </ng-container>
          </div>
        </div>
        <app-app></app-app>
      </div>
    </section>
  </div>
  
  <ng-template #template>
    <div class="modal-body like-sec" style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%; border-color: black;">
      <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()" style="align-self: flex-start">
        <span aria-hidden="true" style="color: black">&times;</span>
      </button>
      <h6 class="modal-title">{{ global.termsArray["msg_first_14_days_free"] }}</h6>
      <div class="d-flex">
        <button type="submit" class="btn btn-info modal-button" (click)="navigateToSubscription(); modalRef.hide()">{{ global.termsArray["lbl_see_pricing"] }}</button>
      </div>
    </div>
  </ng-template>
  
  
  
  <ng-template #favorites>
    <div class="modal-header" style="border-bottom-width: 0px">
      <h4 class="modal-title" style="font-size: 25px; font-family: Akkurat-Bold; margin: 0; color: white;">{{ global.termsArray["lbl_rmv_fav"] }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modalRef.hide();favLessonId=null" style="font-family: Akkurat-Bold;  color: white;">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="font-family: Akkurat-Bold;  color: white;">
      <p>{{ global.termsArray["lbl_confirm_rmv_fav"] }}</p>
    </div>
    
    <div class="modal-footer coupon-modal-footer">
      <button type="button" class="btn btn-basic" style="font-size: 15px; font-family: Akkurat-Bold; color: #3d76ce" (click)="modalRef.hide();favLessonId=null">
        {{ global.termsArray["lbl_no"] }}
      </button>
      <button type="button" class="btn btn-primary" style="font-size: 15px;font-family: Akkurat-Bold;background-color: rgb(83, 179, 214);color: white;" (click)="removeFavorites();modalRef.hide();">
        {{ global.termsArray["lbl_yes"] }}
      </button>
    </div>
  </ng-template>