import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/global.service';

@Component({
  selector: 'app-send-concern',
  templateUrl: './send-concern.component.html',
  styleUrls: ['./send-concern.component.sass']
})
export class SendConcernComponent implements OnInit {
  concernDescription:string = ''
  emailRegex = /info@birlingo\.de/g; 
  emailWithLink = '<a class="text-white text-decoration-underline" href="mailto:info@birlingo.de">info@birlingo.de</a>';
  constructor(public route:Router,public global: GlobalService) { }

  ngOnInit() {
    let description = this.global.termsArray["send_concern_description"]
    this.concernDescription = description.replace(this.emailRegex, this.emailWithLink);
    console.log(this.concernDescription)
     
  }

  goBack(){
    this.route.navigate(['rating'], { replaceUrl: true })
  }
}
