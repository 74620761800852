
<div class="inner-wraper home-sec">
  <!-- <div class="slider-banner-in" [style.background-size]="'cover'" id="tabName2" style="background-repeat: no-repeat !important; margin: auto !important">
    <picture >
     <img  class="slider-banner-in" [appLazyLoadImage]="homeContentImages.imageUrl ? homeContentImages?.imageUrl + homeContentImages?.images?.banner_image : null" 
       style="background-size: cover;" alt="Flowers" style="width:auto;">
    </picture>
</div> -->

  <div class="slider-banner-in" [ngStyle]="{background: 'url(' + (homeContentImages.imageUrl ? homeContentImages?.imageUrl + homeContentImages?.images?.banner_image : null) + ')' }" [style.background-size]="'cover'" id="tabName2" style="background-repeat: no-repeat !important; margin: auto !important">
      <div class="banner-container-full">
      <div class="banner-title">
        <span>
          <h1>{{ global.termsArray["lbl_home_heading1"] }}</h1>
        </span>
      </div>
    </div>
  </div>

  <section class="select-language-sec">
    <div class="container">
      <div class="language-list" *ngIf="global.languageArray.length > 0">
        <div class="list-bg">
          <div class="list-bg-title">
            <h2>{{ global.termsArray["lbl_home_try_free"] }}</h2>
            <h5>{{ global.termsArray["lbl_no_credit_card_requied"] }}</h5>
          </div>
        </div>
        <ul>
          <li *ngFor="let language of global.learningLanguages">
            <a href="javascript:;" [ngClass]="language._id == global.learningLanguage ? 'dropdown-item active' : 'dropdown-item'" (click)="selectLanguage(language._id)">{{ global.termsArray[language?.term] }}</a>
          </li>
        </ul>
      </div>
    </div>
  </section>

  <section class="languages-intuitively-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="main-title">
            <h2>{{ global.termsArray["lbl_home_learn_language_​​intuitively"] }}</h2>
          </div>
        </div>
        <div class="col-md-6">
          <div class="intuitively-info-text">
            <div id="learning_language_texts"></div>
            <div class="intuitively-btns-bx">
              <a href="javascript:void(0);" class="btn outline-btn" [routerLink]='["/method"]'>{{global.termsArray['lbl_method']}}</a>
              <a href="javascript:void(0);" class="btn" [routerLink]='["/register"]'>{{ global.termsArray["lbl_graffiti_lessons"] }}</a>
              <h6>{{global.termsArray['lbl_no_credit_card_requied']}}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="video-sec-bg">
    <div class="container">

      <div class="video-md-5">
        <figure>
          <iframe id="test" width="100%" height="450px"></iframe>
        </figure>
      </div>
    </div>
  </section>

  <section class="methode-levels-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-4" *ngFor="let data of homeContent.method_texts">
          <div class="levels-mult-bx">
            <article>
              <div class="levels-bg">
                <h3>{{ data.heading }}</h3>
              </div>
              <figcaption>
                <p>{{ data.text }}</p>
                <div class="icon-center"><i class="fas fa-check"></i></div>
              </figcaption>
            </article>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="birkenbihl-language-sec new-carousel-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
            <ol class="carousel-indicators">
              <li *ngFor="let item of methodImages; let i = index;" [ngClass]="i === 0 ? 'active' : ''" 
                attr.data-bs-slide-to="{{i}}" data-bs-target="#carouselExampleIndicators"></li>
            </ol>
            <div class="carousel-inner">
              <div class="carousel-item" *ngFor="let image of methodImages; let i = index;" 
                [ngClass]="i === 0 ? 'active' : ''">
                <img [src]="content.imageUrl + image" [alt]="image">
              </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"><i class="ri-arrow-left-s-line"></i></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"><i class="ri-arrow-right-s-line"></i></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
        <div class="col-md-6">
          <div class="birkenbihl-right-sec">
            <div class="hear_actively_texts" id="hear_actively_texts"></div>
            <a class="btn outline-btn" [routerLink]="['/method']">{{ global.termsArray['lbl_method'] }}</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <section class="new-quick-sec Methode-sec-box">
    <div class="container">
      <div class="birlingo-course-check">
        <ul class="row courses-head">
          <li class="col-md-6"></li>
          <li class="col-md-3 d-lg-none d-md-none d-xl-none">
            <h4>Vergleich Birlingo mit klassischem Sprachkurs</h4>
          </li>
          <li class="col-md-3 d-lg-block d-md-block d-xl-block d-none d-sm-none d-xs-none">
            <h4><img _ngcontent-serverapp-c1="" alt="home logo image" src="assets/images/logo.svg"></h4>
          </li>
          <li class="col-md-3 d-lg-block d-md-block d-xl-block d-none d-sm-none d-xs-none">
            <h4>Sprachschulen</h4>
          </li>
        </ul>
        <ul class="row courses-rw">
          <li class="col-md-6">
            <h6>Zeitaufwand</h6>
          </li>
          <li class="col-md-3">
            <div class="bg-blue-cr">ab 10 min. pro Tag</div>
          </li>
          <li class="col-md-3">
            <div class="bg-blueli-cr">hoch</div>
          </li>
        </ul>
        <ul class="row courses-rw">
          <li class="col-md-6">
            <h6>Disziplin erforderlich</h6>
          </li>
          <li class="col-md-3">
            <div class="bg-blue-cr">wenig</div>
          </li>
          <li class="col-md-3">
            <div class="bg-blueli-cr">viel</div>
          </li>
        </ul>
        <ul class="row courses-rw">
          <li class="col-md-6">
            <h6>Keine Vokabeln pauken</h6>
          </li>
          <li class="col-md-3">
            <div class="bg-blue-cr"><span _ngcontent-serverapp-c4=""><img _ngcontent-serverapp-c4="" alt="check white image" src="assets/images/check-white.svg"></span></div>
          </li>
          <li class="col-md-3">
            <div class="bg-blueli-cr"><i class="fas fa-times"></i></div>
          </li>
        </ul>
        <ul class="row courses-rw">
          <li class="col-md-6">
            <h6>Keine Grammatikregeln pauken</h6>
          </li>
          <li class="col-md-3">
            <div class="bg-blue-cr"><span _ngcontent-serverapp-c4=""><img _ngcontent-serverapp-c4="" alt="check white image" src="assets/images/check-white.svg"></span></div>
          </li>
          <li class="col-md-3">
            <div class="bg-blueli-cr"><i class="fas fa-times"></i></div>
          </li>
        </ul>
        <ul class="row courses-rw">
          <li class="col-md-6">
            <h6>Korrekte Aussprache</h6>
          </li>
          <li class="col-md-3">
            <div class="bg-blue-cr"><span _ngcontent-serverapp-c4=""><img _ngcontent-serverapp-c4="" alt="check white image" src="assets/images/check-white.svg"></span></div>
          </li>
          <li class="col-md-3">
            <div class="bg-blueli-cr">vielleicht</div>
          </li>
        </ul>
        <ul class="row courses-rw">
          <li class="col-md-6">
            <h6>Eigenes Lerntempo</h6>
          </li>
          <li class="col-md-3">
            <div class="bg-blue-cr"><span _ngcontent-serverapp-c4=""><img _ngcontent-serverapp-c4="" alt="check white image" src="assets/images/check-white.svg"></span></div>
          </li>
          <li class="col-md-3">
            <div class="bg-blueli-cr"><i class="fas fa-times"></i></div>
          </li>
        </ul>
        <ul class="row courses-rw">
          <li class="col-md-6">
            <h6>Inhalte selber wählen</h6>
          </li>
          <li class="col-md-3">
            <div class="bg-blue-cr">
              <span _ngcontent-serverapp-c4="">
                <img _ngcontent-serverapp-c4="" alt="check white image" src="assets/images/check-white.svg">
              </span>
            </div>
          </li>
          <li class="col-md-3">
            <div class="bg-blueli-cr"><i class="fas fa-times"></i></div>
          </li>
        </ul>
        <ul class="row courses-rw">
          <li class="col-md-6">
            <h6>Kurze Lektionen, flexible Lernzeiten</h6>
          </li>
          <li class="col-md-3">
            <div class="bg-blue-cr"><span _ngcontent-serverapp-c4=""><img _ngcontent-serverapp-c4="" alt="check white image" src="assets/images/check-white.svg"></span></div>
          </li>
          <li class="col-md-3">
            <div class="bg-blueli-cr"><i class="fas fa-times"></i></div>
          </li>
        </ul>
        <ul class="row courses-rw">
          <li class="col-md-6">
            <h6>Ort</h6>
          </li>
          <li class="col-md-3">
            <div class="bg-blue-cr">überall</div>
          </li>
          <li class="col-md-3">
            <div class="bg-blueli-cr">Anreise erforderlich</div>
          </li>
        </ul>
        <ul class="row courses-rw">
          <li class="col-md-6">
            <h6>Lerngarantie</h6>
          </li>
          <li class="col-md-3">
            <div class="bg-blue-cr"><span _ngcontent-serverapp-c4=""><img _ngcontent-serverapp-c4="" alt="check white image" src="assets/images/check-white.svg"></span></div>
          </li>
          <li class="col-md-3">
            <div class="bg-blueli-cr"><i class="fas fa-times"></i></div>
          </li>
        </ul>
        <ul class="row courses-rw">
          <li class="col-md-6">
            <h6>Preis</h6>
          </li>
          <li class="col-md-3">
            <div class="bg-blue-cr">€</div>
          </li>
          <li class="col-md-3">
            <div class="bg-blueli-cr">€€€</div>
          </li>
        </ul>
      </div>
    </div>
  </section>
  
  <section class="subscription-pricing-sec">
    <div class="container">
      <div class="subscription-pricing-box">
        <figure>
          <img src="assets/images/mobile-subscription.webp">
        </figure>
        <figcaption>
          <h3>{{ global.termsArray['lbl_subscription_pricing'] }}</h3>
          <p id="lady_texts">{{homeContent.lady_texts}}</p>
          <a href="javascript:;" [routerLink]="['/price']">{{ global.termsArray['lbl_subscription_and_pricing'] }}</a>
        </figcaption>
      </div>
    </div>
  </section>
  
  <section class="unsere-App-box unsere-App-bg">
    <div class="container">
      <div class="row">
        <div class="col-md-5 main-taitle-none">
          <h2>{{global.termsArray['lbl_learn_when_and_where_you_want']}}</h2>

          <div class="app-ser-title" *ngFor="let loop of homeContent?.online_or_offline_texts">
            <p>
              <a href="#">{{ global.termsArray[loop?.heading] }}</a>
              <ng-container *ngIf="!global.termsArray['lbl_home_wether_online_offline']"><br /></ng-container>
              {{ loop?.title }}
            </p>
          </div>

          <div class="button-gp-d">
            <a href="javascript:;" class="btn btn-ios d-flex" (click)="openUrl(1)">
              <figure>
                <img src="assets/images/google-play.svg" alt="google play image" />
              </figure>
              <figcaption>
                <span>{{ global.termsArray["lbl_home_get_it_on"] }}</span>
                <strong>{{ global.termsArray["lbl_home_google_play"] }}</strong>
              </figcaption>
            </a>
            <a class="btn btn-ios d-flex" (click)="openUrl(2)">
              <figure><i class="fab fa-apple"></i></figure>
              <figcaption>
                <span>{{ global.termsArray["lbl_home_download_on_the"] }}</span>
                <strong>{{ global.termsArray["lbl_home_app_store"] }}</strong>
              </figcaption>
            </a>
            <a href="javascript:;" routerLink="/register"  class="btn am-computer">
              <figure>
                <i class="fas fa-laptop"></i>
              </figure>
              <figcaption>
                <span>
                  {{global.termsArray['lbl_At_the_computer']}}
                </span>
              </figcaption>
            </a>
          </div>
        </div>
        <div class="col-md-7 button-gp-none">
          <figure>
            <img src="assets/images/iPhone-Mockup1.webp" alt="iPhone Mockup">
          </figure>
          <div class="button-gp-d">
            <a href="javascript:;" class="btn btn-ios d-flex" (click)="openUrl(1)">
              <figure>
                <img src="assets/images/google-play.svg" alt="google play image" />
              </figure>
              <figcaption>
                <span>{{ global.termsArray["lbl_home_get_it_on"] }}</span>
                <strong>
                  {{ global.termsArray["lbl_home_google_play"] }}</strong>
              </figcaption>
            </a>
            <a href="javascript:;" class="btn btn-ios d-flex" (click)="openUrl(2)">
              <figure>
                <i class="fab fa-apple"></i>
              </figure>
              <figcaption>
                <span>
                  {{ global.termsArray["lbl_home_download_on_the"] }}</span>
                <strong> {{ global.termsArray["lbl_home_app_store"] }}</strong>
              </figcaption>
            </a>
            <a href="javascript:;" class="btn am-computer">
              <figure>
                <i class="fas fa-laptop"></i>
              </figure>
              <figcaption>
                <span>
                  {{global.termsArray['lbl_At_the_computer']}}
                </span>
              </figcaption>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <script type="text/javascript">
    $(".home-carousel").owlCarousel({
      loop: true,
      margin: 10,
      nav: true,
      dot: true,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 1,
        },
        1000: {
          items: 1,
        },
      },
    });

    // header sticky
    $(window).scroll(function () {
      var body = $("body"),
        scroll = $(window).scrollTop();
      if (scroll >= 5) {
        body.addClass("fixed");
      } else {
        body.removeClass("fixed");
      }
    });
  </script>

  <div class="video">
    <div class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog bd-example-modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <button (click)="closeModal()" type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="modal-body" style>
            <iframe id="test" width="100%" height="450px">

            </iframe>
          </div>
        </div>
      </div>
    </div>
  </div>