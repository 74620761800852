<div class="inner-wraper">
  <section class="lesson-cont-sect wrap-space">
    <div class="container">
      <div class="lektionen-cover-new">
          <article>
            <div class="setting-arrow inner-arrow">
              <a href="javascript:;" (click)="goBack()">
                <i class="ri-arrow-left-s-line"></i>
                <h6>{{ global.termsArray["lbl_Settings"] }}</h6>
              </a>
            </div>
            <div class="list-sentens text-center">
              <div class="in-list-setting">
                <div class="account-title">
                  <h4>{{ this.global.termsArray["lbl_change_password"] }}</h4>
                </div>
                <div class="profile-line-box">
                  <form class="form-horizontal" role="form" [formGroup]="formData" (ngSubmit)="onClickSubmit(formData.value)">
                    <div class="form-group pb-4">
                      <div class="password-container">
                        <input type="password" class="form-control" id="exampleInputEmail1" #currentPasswordField [placeholder]="global.termsArray['lbl_old_password']" formControlName="oldpassword" maxlength="55" autofocus />
                        <div class="password-view-wrapper tooltip-trigger" #toggleButton (click)="togglePassword(currentPasswordField,toggleButton,toolTip)">
                          <span class="custom-tooltip" style="left: -50px;" [attr.data-title]="global.termsArray['msg_show_password'] ? global.termsArray['msg_show_password']:'Passwort anzeigen'" #toolTip></span>
                          <i class="ri-eye-off-fill"></i>
                          <i class="ri-eye-fill"></i>
                        </div>
                      </div>
                      <div
                        *ngIf="
                          formData.controls['oldpassword'].invalid &&
                          (formData.controls['oldpassword'].dirty ||
                            formData.controls['oldpassword'].touched ||
                            this.formSubmitAttempt)
                        "
                      >
                        <p
                          *ngIf="formData.controls['oldpassword'].errors.required"
                          class="validation_err validation_err_color text-left mb-0"
                        >
                          {{ global.termsArray["err_enter_old_pass"] }}
                        </p>
                      </div>
                    </div>

                    <div class="form-group pb-4">
                      <div class="password-container">
                        <input
                          type="password"
                          class="form-control"
                          id="exampleInputEmail1"
                          [placeholder]="global.termsArray['lbl_NewPass']"
                          autofocus
                          formControlName="password"
                          maxlength="55"
                          #newPasswordField
                        />
                        <div class="password-view-wrapper tooltip-trigger" #newToggleButton (click)="togglePassword(newPasswordField,newToggleButton,toolTip)">
                          <span class="custom-tooltip" style="left: -50px;" [attr.data-title]="global.termsArray['msg_show_password'] ? global.termsArray['msg_show_password']:'Passwort anzeigen'" #toolTip></span>
                          <i class="ri-eye-off-fill"></i>
                          <i class="ri-eye-fill"></i>
                        </div>
                      </div>
                      <div
                        *ngIf="
                          formData.controls['password'].invalid &&
                          (formData.controls['password'].dirty ||
                            formData.controls['password'].touched ||
                            this.formSubmitAttempt)
                        "
                      >
                        <div
                          *ngIf="formData.controls['password'].errors.required"
                          class="validation_err validation_err_color text-left"
                        >
                          {{ global.termsArray["err_enter_new_password"] }}
                        </div>
                        <div
                          *ngIf="formData.controls['password'].errors.minlength"
                          class="validation_err validation_err_color text-left"
                        >
                          {{ global.termsArray["err_pass_length"] }}
                        </div>
                        <div
                          *ngIf="formData.controls['password'].errors.maxlength"
                          class="validation_err validation_err_color text-left"
                        >
                          {{ global.termsArray["err_pass_length"] }}
                        </div>
                      </div>
                    </div>

                    <div class="form-group pb-4">
                      <div class="password-container">
                        <input
                          type="password"
                          class="form-control"
                          id="exampleInputEmail1"
                          [placeholder]="global.termsArray['lbl_ConfirmPass']"
                          formControlName="copassword"
                          #confirmNewPasswordField
                        />
                        <div class="password-view-wrapper tooltip-trigger" #confirmNewToggleButton (click)="togglePassword(confirmNewPasswordField,confirmNewToggleButton,toolTip)">
                          <span class="custom-tooltip" style="left: -50px;" [attr.data-title]="global.termsArray['msg_show_password'] ? global.termsArray['msg_show_password']:'Passwort anzeigen'" #toolTip></span>
                          <i class="ri-eye-off-fill"></i>
                          <i class="ri-eye-fill"></i>
                        </div>
                      </div>
                      <div
                        *ngIf="
                          !formData.controls['password'].untouched ||
                          this.formSubmitAttempt ||
                          !formData.controls['copassword'].untouched
                        "
                      >
                        <div
                          *ngIf="
                            formData.controls['copassword'].errors &&
                            formData.controls['copassword'].errors.required
                          "
                          class="validation_err validation_err_color text-left"
                        >
                          {{ global.termsArray["err_enter_confirmPassword"] }}
                        </div>
                      </div>
                      <div
                        *ngIf="
                          !(
                            formData.controls['copassword'].errors &&
                            formData.controls['copassword'].errors.required
                          ) &&
                          formData.errors &&
                          formData.errors != null &&
                          formData.errors.passwordsNotEqual
                        "
                        class="validation_err validation_err_color text-left"
                      >
                        {{ global.termsArray["err_password_not_match"] }}
                      </div>
                    </div>
                  </form>
                </div>
                <div class="bttn-blue">
                  <button
                    class="btn btn-blue"
                    (click)="onClickSubmit(formData.value)"
                  >
                    {{ global.termsArray["lbl_update"] }}
                  </button>
                </div>
              </div>
            </div>
          </article>
          <app-app></app-app>
      </div>
    </div>
  </section>
</div>
