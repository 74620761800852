<div class="inner-wraper method_sec">
  <div class="method-banner-in"
    [ngStyle]="{background: 'url(' + (content ? content.imageUrl + content.images.image_one : null) + ')'}"
    [style.background-size]="'cover'" id="tabName2"
    style="background-repeat: no-repeat !important; margin: auto !important">
    <div class="banner-container-full">
      <div class="method-banner-title">
        <span>
          <h2>{{ global.termsArray["lbl_method_heading1"] }}</h2>
        </span>
      </div>
    </div>
  </div>

  <section class="new-language-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-6 basieren-left" id="left_steps_to_new_lang_text">
        </div>
        <div class="col-md-6 basieren-right" id="right_steps_to_new_lang_text"></div>
      </div>
    </div>
  </section>

  <section class="schritt-same schritt-1-bg">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="schritt-left-texts">
            <h6>Schritt 1/3</h6>
            <h4 id="step_2_listen_actively_title">{{ global.termsArray["lbl_hearActively"] }}</h4>
            <div id="hear_actively_texts"></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="meth-device-img">
            <figure>
              <img *ngIf="content?.imageUrl && content?.images?.image_three"
                   src="{{ content.imageUrl + content.images.image_three }}"
                   alt="{{ content.images.image_three_text ? content.images.image_three_text : 'active hearely text content image' }}" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="schritt-same schritt-2-bg">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="meth-device-img">
            <figure>
              <img *ngIf="content?.imageUrl && content?.images?.image_four"
                   src="{{ content.imageUrl + content.images.image_four }}"
                   alt="{{ content.images.image_four_text ? content.images.image_four_text : 'active hearely text content image' }}" />
            </figure>

          </div>
        </div>
        <div class="col-md-6">
          <div class="schritt-left-texts">
            <h6>Schritt 2/3</h6>
            <h4 id="step_3_have_a_say_title">{{ global.termsArray["lbl_have_a_say"] }}</h4>
            <div id="step_3_speak_texts"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="schritt-same schritt-3-bg">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="schritt-left-texts">
            <h6>Schritt 3/3</h6>
            <h4 id="step_4_listen_passively_title">{{ global.termsArray["lbl_listen_passively"] }}</h4>
            <div id="step_4_hear_passively_texts"></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="meth-device-img">
            <figure>
              <img *ngIf="content?.imageUrl && content?.images?.image_five"
                   src="{{ content.imageUrl + content.images.image_five }}"
                   alt="{{ content.images.image_five_text ? content.images.image_five_text : 'active hearely text content image' }}" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- <section class="video-sec-bg mt-5">
    <div class="container">

      <div class="video-md-5">
        <figure>
          <iframe id="test" width="100%" height="450px"></iframe>
        </figure>
      </div>
    </div>
  </section> -->

  <section class="steps-secs">
    <div class="container">
      <div class="steps-free-box">
        <div class="steps-text-box">
          <h4>{{global.termsArray['lbl_3_steps_for_free_now']}}</h4>
          <h6>{{global.termsArray['lbl_no_credit_card_requied']}}</h6>
          <a class="btn black-btn" href="javascript:void(0);"
            [routerLink]="['/register']">{{global.termsArray['lbl_just_get_started']}} </a>
        </div>
      </div>
    </div>
  </section>

  <section class="learning-guarantee-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="guarantee-texts">
            <div id="left_learning_guarantee_texts" class="main-title"></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="guarantee-texts">
            <div id="right_learning_guarantee_texts" class="guarantee-dote-here"></div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="graf-cover">
            <figure>
              <img *ngIf="content?.imageUrl && content?.images?.image_two"
                   src="{{ content.imageUrl + content.images.image_two }}"
                    />
            </figure>
  
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="wieviele-sec">
    <div class="container">
      <div id="languages_human_speak_text" class="sprachen-texts"></div>
    </div>
  </section>

  <div class="Our-mission-sec">
    <div class="google-widget-sec" name="google-widget-sec"></div>
  </div>

  <section class="inner_faq_sec">
    <div class="faq_list-box">
      <div class="container">
        <h2>FAQ</h2>
        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="false">
          <div class="panel panel-default" *ngFor="let faq of content?.faqs; let i = index">
            <div class="panel-heading panel-heading2" role="tab" id="heading{{ i }}"
              (click)="generateFaq('collapses' + i, faq.answer)">
              <h4 class="panel-title">
                <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapse{{ i }}"
                  aria-expanded="false" attr.aria-controls="collapse{{ i }}">
                  {{ faq.question }}
                </a>
              </h4>
            </div>
            <div id="collapse{{ i }}" class="panel-collapse collapse in" role="tabpanel"
              attr.aria-labelledby="heading{{ i }}" data-parent="#accordion">
              <div class="panel-body">
                <div id="collapses{{ i }}"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="mission-language-sec">
    <div class="container">
      <div class="mission-outline">
        <div class="mission-outline-in">
          <div class="row">
            <div class="col-md-6">
              <div class="try-can-learn">
                <!-- <h4>Diese Sprachen können Sie mit Birlingo lernen:</h4> -->
                <!-- <p>Englisch, Französich, Italienisch, Spanisch, Russisch, Chinesisch, Niederländisch, Protugiesisch</p> -->
                <h4>{{ global.termsArray["lbl_Diese_Sprachen_kannst_du_mit_Birlingo_lernen:"] }}</h4>
                <p id="learn_these_lan_with_birlingo">{{ global.termsArray["learn_these_lan_with_birlingo"] }</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="try-can-learn">
                <a class="btn black-btn" href="javascript:;" [routerLink]="['/register']"
                  target="-blank">{{global.termsArray['lbl_N_days_test']}}</a>
                <h6>{{global.termsArray['lbl_no_credit_card_requied']}}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<a [hidden]="true" id="box-pre" class="carousel-control-prev" href="#carouselExampleIndicators2" role="button"
  data-slide="prev">
  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
  <span class="sr-only">Previous</span>
</a>

<a [hidden]="true" id="box-next" class="carousel-control-next" href="#carouselExampleIndicators2" role="button"
  data-slide="next">
  <span class="carousel-control-next-icon" aria-hidden="true"></span>
  <span class="sr-only">Next</span>
</a>

<div class="video">
  <div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog bd-example-modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <button (click)="closeModal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body" style>
          <iframe id="test" width="100%" height="400px"></iframe>
        </div>
      </div>
    </div>
  </div>
</div>