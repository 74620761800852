import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WeeklyGoalService {
  private goalAchievedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private awardAchievedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private pauseIntervalSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  setGoalAchieved(achieved: boolean) {
    this.goalAchievedSubject.next(achieved);
  }

  getGoalAchieved(): Observable<boolean> {
    return this.goalAchievedSubject.asObservable();
  }

  setAwardAchieved(award:any) {
    this.awardAchievedSubject.next(award);
  }

  getAwardAchieved(): Observable<boolean> {
    return this.awardAchievedSubject.asObservable();
  }

  setPauseInterval(value:any) {
    this.awardAchievedSubject.next(value);
  }

  getPauseInterval(): Observable<boolean> {
    return this.awardAchievedSubject.asObservable();
  }
}
