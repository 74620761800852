import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Location } from "@angular/common";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalService } from "../../global.service";
import { EnvService } from 'src/app/env.service';
import { NgxNavigationWithDataComponent } from "ngx-navigation-with-data";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import {
  EmailValidation,
  PasswordValidation,
  RepeatPasswordEStateMatcher,
  RepeatPasswordValidator,
} from "../../validator.service";

import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";

declare var paypal: any;
@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {

  formData;
  passwordsMatcher = new RepeatPasswordEStateMatcher();
  formSubmitAttempt = false;
  haveStripeSource: Boolean = this.global.user && this.global.user.customer_id ? true : false;
  selectCountryTermFlag: any = this.global.termsArray[this.global.user && this.global.user.country && this.global.user.country.term ? this.global.user.country.term : 'lbl_choose_country'];
  userCountry: any = this.global.user && this.global.user.country && this.global.user.country.term ? this.global.user.country : "";
  countries: any = [];
  cardInfo: any = {};
  unlimitedSubscriptionInfo: any = {};
  subscriptionList: any = [];
  SUBSCRIBED_FROM_WEB = "website";
  subscribed: boolean = false;
  subscribedFrom: string = null;
  userOldEmail: String = "";
  show: boolean = false;
  modalRef: BsModalRef;
  public subData: any;
  paymentOptionsForm: any;
  paymentMethod: string = "";
  activePaymentOptions: any = [];
  giftCardAmount:any;
  couponCode;
  couponCodeId;
  couponCodeData;
  enableSofort: boolean = false;
  @ViewChild('enterAmount', { static: false }) enterAmountModelRef: TemplateRef<HTMLElement>;
  @ViewChild('choosePaymetType', { static: false }) choosePaymentTypeRef: TemplateRef<HTMLElement>;
  @ViewChild('couponcode',{static: false}) couponcodeModelRef:TemplateRef<HTMLElement>;
  
  constructor(
    private route: Router,
    public global: GlobalService,
    private formBuilder: FormBuilder,
    public location: Location,
    private ngxService: NgxUiLoaderService,
    public envservice: EnvService,
    private modalService: BsModalService,
  ) {
    this.userOldEmail = this.global.user.email;
    this.formData = this.formBuilder.group({
      email: EmailValidation,
      username: new FormControl("", Validators.required),
      selectCountry: new FormControl("", Validators.required),
      password: new FormControl(""),
      // surname: new FormControl("", Validators.required),
      // name: new FormControl("", Validators.required),
      //gender: new FormControl(""),
    });
    this.getProfileData();
    this.global.profileTab = 2;
  }


  ngOnInit() {
    this.getCountries();
    if (this.haveStripeSource) {
      this.getCardInfo();
    }
    this.getSubscriptionData();
    this.global.get("getSettingData", (response: any) => {
      this.activePaymentOptions = response && response.data && response.data.payment_options;
    }, (err) => { });
    this.userCountry = this.global && this.global.user && this.global.user.country && this.global.user.country.name ? this.global.user.country.name : "";
    this.enableSofort = this.userCountry == "Austria" || this.userCountry == "Germany" ? true : false;
    this.paymentOptionsForm = this.formBuilder.group({
      payment_method: ['', Validators.required]
    });
  }
  /** Edit card info >>>>> */
  getCardInfo() {
    this.global.get(`getCardInfo?cust_id=${this.global.user.customer_id}`, (response: any) => {
      this.cardInfo = response.data;
    }, (err: any) => {
      this.global.showDangerToast("", this.global.termsArray[err.message]);
    })
  }
  editCardInfo(cardToken: string) {
    this.global.post('/editCardInfo', { token: cardToken }, (response: any) => {
      if (response.success) {
        this.global.showToast("", this.global.termsArray[response.message]);
        this.getCardInfo();
      } else {
        this.global.showDangerToast("", this.global.termsArray[response.message]);
      }
    }, (err: any) => {
      this.global.showDangerToast("", this.global.termsArray[err.message]);
    })
  }
  openEditCard() {
    this.global.loader = true;
    this.ngxService.start();
    var handler = (<any>window).StripeCheckout.configure({
      key: this.envservice.settings.payment_mode == 1 ? this.envservice.settings.public_key_live : this.envservice.settings.public_key_test,
      locale: "auto",
      currency: "eur",
      closed: () => {
        setTimeout(() => {
          this.global.loader = false;
          this.ngxService.stop();
        }, 3000);
      },
      panelLabel: "Update",
      token: (token: any) => {
        this.editCardInfo(token);
      },
    });
    handler.open({
      image: "https://stripe.com/img/documentation/checkout/marketplace.png",
      name: "Birlingo",
      // amount: 100 * price,
      allowRememberMe: false,
      description: `${this.global.termsArray["lbl_update_card"]}`,
      email: this.global.user.email
    });
  }
  /** Edit card info <<<<<< */


  getCountries() {
    this.global.get('/countries', (data: any) => {
      this.countries = data.data;
    }, (err: any) => {
      this.global.showDangerToast("", err.message);
    })
  }
  selectGender(gender) {
    this.formData.controls["gender"].value = gender;
    this.formData.value.gender = gender;
  }

  getProfileData() {
    this.global.get(
      "getManageAccount/" + this.global.user._id,
      (data) => {
        if (data.success) {
          let user = data.data.user_info;
          this.formData.get("email").setValue(user.email);
          this.formData.get("username").setValue(user.username ? user.username : user.name + " " + user.surname);
          this.formData.get("selectCountry").setValue(this.userCountry);

          // this.formData.get("name").setValue(user.name);
          // this.formData.get("surname").setValue(user.surname);
          //this.formData.get("gender").setValue(user.gender);
        } else {
          this.global.showDangerToast("", this.global.termsArray[data.message]);
        }
      },
      (err) => {
        this.global.showDangerToast("", err.message);
      },
      true
    );
  }

  onClickSubmit(value) {
    // this.formData.get("name").setValue(this.removeEmojis(value.name));
    // this.formData.get("surname").setValue(this.removeEmojis(value.surname));
    this.formSubmitAttempt = true;
    if (!this.formData.valid) {
      return;
    } else {
      this.callRegisterApi(value);
    }
  }

  callRegisterApi(value: any) {
    let body = {};
    // body["surname"] = value.surname;
    // body["name"] = this.capitalize(value.name);
    body["username"] = this.capitalize(value.username);
    body["user_id"] = this.global.user._id;
    body["country"] = value.selectCountry;
    body["email"] = value.email.toLowerCase();
    body["password"] = value.password;
    //body["gender"] = value.gender;
    this.global.post(
      "updateManageAccount",
      body,
      (data) => {
        if (data.success) {
          this.global.user = data.data.user;
          localStorage.setItem(
            btoa("user"),
            btoa(JSON.stringify(data.data.user))
          );
          if (data.data && data.data.type && data.data.type == "new_email_verification") {
            this.route.navigate(["verify"], {
              queryParams: {
                verify: true,
                slug: data.data.type,
                email: data.data.user.email,
                new_email: data.data.user.new_email,
              }
            });
          }
          this.global.showToast("", this.global.termsArray[data.message]);
        } else {
          this.global.showDangerToast("", this.global.termsArray[data.message]);
        }
      },
      (err) => {
        this.global.showDangerToast("", err.message);
      },
      true
    );
  }

  removeEmojis(string) {
    var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    return string.replace(regex, "");
  }

  goBack() {
    this.route.navigate(["setting"], { replaceUrl: true });
  }

  capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  deleteAccount() {
    if (this.subscribed) {
      (document.getElementById("showCancelSubsNotificationbutton") as any).click();
    } else {
      this.global.post("deleteUser", {}, (data) => {
        if (data.success) {
          this.global.showToast("", this.global.termsArray[data.message]);
          this.logout();
        } else {
          this.global.showDangerToast("", this.global.termsArray[data.message]);
        }
      },
        (err: any) => {
          this.global.showDangerToast("", err.message);
        }, true);
    }
  }

  logout() {
    localStorage.removeItem(btoa("AuthToken"));
    localStorage.removeItem(btoa("routeParams"));
    this.global.routeParams = null;
    this.global.isLogin = false;
    this.global.AuthToken = null;
    this.signOut();
  }

  signOut() {
    localStorage.removeItem(btoa("user"));
    this.global.lessonfamily_id = null;
    this.global.baselesson_id = null;
    this.global.lesson_id = null;
    this.global.lesson_title = null;
    this.global.max_read_slide = null;
    localStorage.removeItem(btoa("lessonfamily_id"));
    localStorage.removeItem(btoa("baselesson_id"));
    localStorage.removeItem(btoa("lesson_id"));
    localStorage.removeItem(btoa("lesson_title"));
    localStorage.removeItem(btoa("max_read_slide"));
    this.global.user = null;
    document.body.classList.remove("texture-bg-gry");
    this.route.navigate(["/login"], { replaceUrl: true });
  }



  selectCountry(country: any) {
    this.selectCountryTermFlag = this.global.termsArray[country.term];
    this.global.learningLanguage = country;
    this.formData.get("selectCountry").setValue(country);
  }


  getSubscriptionData() {
    let body = {};
    body["language_id"] = this.global.selectLanguage;
    body["user_id"] = this.global.user._id;
    this.global.post("subscriptions", JSON.stringify(body), (data) => {
      if (data.success) {
        this.subscriptionList = data.data;
        let unlimitedSubscription = this.subscriptionList.filter((subscription) => subscription.validity == 240)
        this.unlimitedSubscriptionInfo = unlimitedSubscription && unlimitedSubscription.length > 0 ? unlimitedSubscription[0] : null
        const subLength = this.subscriptionList.length;
        for (let i = 0; i < subLength; i++) {
          if (this.subscriptionList[i].status == "1" && this.subscriptionList[i].subs_source && this.subscriptionList[i].cancel_scheduled==false) {
            this.subscribed = true;
            this.subscribedFrom = this.subscriptionList[i].subs_source;
            break;
          }
        };
      } else {
        // this.global.showDangerToast("Error", this.global.languageArray[data.message]);
      }
    }, (err) => {
      // this.global.showDangerToast("Error", err.message);
    }, true);
  }


  openModal(template: TemplateRef<any>, data = "big-model-dialog") {
    const config = {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: true,
      class: data,
    };
    this.show = false;
    this.subData = this.unlimitedSubscriptionInfo;
    this.modalRef = this.modalService.show(template, config);
  }

  isActiveMethod(method: string) {
    return this.activePaymentOptions.indexOf(method) >= 0;
  }

  resetForm(form: any) {
    form.reset();
  }

  openModal2(template: TemplateRef<any>, data = "big-model-dialog", ignoreBackdropClick = false) {
    const config = {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: ignoreBackdropClick,
      class: data,
    };
    this.show = false;
    this.modalRef = this.modalService.show(template, config);
  }


  subscribeMyplan(){
    if(this.paymentOptionsForm.valid){
      this.paymentMethod = this.paymentOptionsForm.value.payment_method;
      this.modalRef.hide();
      this.openModal2(this.enterAmountModelRef,'big-model-dialog2',true);
      this.resetForm(this.paymentOptionsForm);
    }else{
      alert("Please select a payment option");
    }
  }


  validateInput(event: KeyboardEvent): void {
    const allowedChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Delete'];

    if (allowedChars.indexOf(event.key) === -1) {
      event.preventDefault();
    }
  }
  validateGiftCardAmount(){
    if (!this.giftCardAmount || isNaN(this.giftCardAmount)) {
      console.log("kfgjdfhgjdg")
      this.global.showDangerToast("Error",this.global.termsArray['err_enter_valid_gift_amount'] );
      return;
    }
    // if (this.giftCardAmount==0) {
    //   this.global.showDangerToast("Error",this.global.termsArray['err_gift_amount_greater_than_zero'] );
    //   return;
    // }
    if (this.giftCardAmount<50) {
      this.global.showDangerToast("Error",this.global.termsArray['err_gift_amount_greater_than_fifty'] );
      return;
    }
    if (this.giftCardAmount>this.unlimitedSubscriptionInfo.price) {
      this.global.showDangerToast("Error",this.global.termsArray['err_gift_card_amount_cnnot_be_greater_than_unlimited_sub_amount'] );
      return;
    }
    else{
      this.modalRef.hide();
      this.openModal2(this.couponcodeModelRef,'big-model-dialog2',true);
      if(this.paymentMethod == 'paypal'){
        setTimeout(() => {
          this.requestToPaypal(this.subData,this.couponCodeData);
        }, 100);
      }  
    }
  }

  applyCouponCode(coupon, template: TemplateRef<any>) {
    if (!coupon) {
      return;
    }
    let body = {};
    body["code"] = coupon;
    body["amount"] = this.giftCardAmount;
    this.global.post("apply-coupon-on-gift-card", JSON.stringify(body), (data) => {
      if (data.success) {
        this.couponCodeId = data.data;
        this.couponCode = "";
        this.modalRef.hide();
        const config = {
          animated: true,
          keyboard: true,
          backdrop: true,
          ignoreBackdropClick: false,
          class: 'big-model-dialog2 discountPOPUP',
        };
        this.modalRef = this.modalService.show(template, config);
        this.couponCodeData = data.data;
        this.global.showToast("", this.global.termsArray[data.message]);
        if(this.paymentMethod == "paypal"){
          this.requestToPaypal(this.couponCodeData, "paypal_button_container2");
        }
      } else {
        this.couponCode = "";
        this.global.showDangerToast("Error", this.global.termsArray[data.message]);
      }
    },
      (err) => {
        this.couponCode = "";
        this.modalRef.hide();
        this.global.showDangerToast("Error", err.message);
      },
      true
    );
  }


  manualPurchaseGiftCards() {
    const subs = this.subData;
    const subsId = subs && subs._id ? subs._id : subs;
    let stripeRequest = {basesubs_id: subsId,  payment_method: this.paymentMethod,amount:0};
    if(this.couponCodeData && this.couponCodeData.code){
      stripeRequest['coupon_code'] = this.couponCodeData.code;
      stripeRequest['amount'] = this.couponCodeData.discount_less;
      stripeRequest['purchasing_amount'] = this.giftCardAmount
    }
    this.global.post("manual-gift-code", stripeRequest, (response: any) => {
      if (response.success == true) {
        this.modalRef.hide();
        this.giftCardAmount=null
        this.route.navigate(['/gift-cards'])
      } else {
        this.global.showDangerToast("Error", response.message);
      }
    }, (err: any, httpErr: any) => {
      console.log(httpErr);
      this.global.showDangerToast("Error", httpErr.error.message);
    }, true);
  }

  purchaseGiftCards() {
    if (!this.giftCardAmount) {
      return;
    }
    const subs = this.subData;
    const subsId = subs && subs._id ? subs._id : subs;
    let stripeRequest = {basesubs_id: subsId,  payment_method: this.paymentMethod,amount:this.giftCardAmount ,gift_card_amount:this.giftCardAmount};
    if(this.couponCodeData && this.couponCodeData.code){
      stripeRequest['coupon_code'] = this.couponCodeData.code;
      stripeRequest['amount'] = this.couponCodeData.discount_less
    }
    this.global.post("purchase-gift-cards", stripeRequest, (response: any) => {
      if (response.success == true) {
        let url = response.data.payment_link;
        window.location.href = url;
      } else {
        this.global.showDangerToast("Error", response.message);
      }
    }, (err: any, httpErr: any) => {
      console.log(httpErr);
      this.global.showDangerToast("Error", httpErr.error.message);
    }, true);
  }

  
  /**PAYPAL INTEGRATION >>>> */
  requestToPaypal(subcription: any,couponInfo: any, tagId:string = "paypal_button_container") {
    console.log("subcription",subcription)
    let amount:any = parseFloat(this.giftCardAmount).toFixed(2)
    if (couponInfo) {
      amount = couponInfo.total - couponInfo.discount_amount;
      amount = amount >= 0 ? parseFloat(amount).toFixed(2) : 0;
    }
    this.initPaypal(amount,subcription,couponInfo, tagId,this.global.user._id);
  }

  initPaypal(amount: any,subcription: any, couponInfo: any, tagId:string,user_id:any) {
    const clsInstance = this;
    let custom_id = user_id+','+subcription._id;
    let org_amount= parseFloat(this.giftCardAmount).toFixed(2)
    custom_id = couponInfo && couponInfo._id ? custom_id+','+couponInfo._id : custom_id+',null';
    custom_id = custom_id + ',true';
    custom_id = org_amount ? custom_id+',null,'+org_amount : custom_id;
        document.querySelector("#"+tagId).innerHTML = "";
        paypal.Buttons({
          style: {
            layout:'horizontal',
            color:'white',
            height:55,
          },
          createOrder: function (data, actions) {
            return actions.order.create({
              purchase_units: [{
                amount: {
                  value: amount /** Set the payment amount */
                },
                custom_id:custom_id
              }]
            });
          },
          onApprove: async function (data, actions) {
            let userSub =await clsInstance.IntialisePaypalPayment(subcription,couponInfo,amount)
             return actions.order.capture().then(function (details) {
              // clsInstance.newSubscribeToPaypal(subcription, couponInfo, details);
              clsInstance.modalRef.hide();
              clsInstance.couponCodeData = null;
              clsInstance.route.navigate(['gift-cards']);
            });
          },
          onCancel: function (data, actions) {
            clsInstance.modalRef.hide();
            clsInstance.couponCodeData = null;
          }
        }).render("#"+tagId);
  }
    
    
  IntialisePaypalPayment(subs,amount,coupon) {
    const subsId = subs && subs._id ? subs._id : subs;
    let requestBody = {
      basesubs_id: subsId,
      amount: amount,
      coupon: coupon,
    };
    this.global.post("initGiftPurchasePaypalPayment", requestBody, async (response: any) => {
      if (response.success == true) {
        // this.global.showToast("", this.global.termsArray[response.message]);
      } else {
        this.global.showDangerToast("Error", response.message);
      }
    }, (err: any, httpErr: any) => {
      console.log(httpErr);
      this.global.showDangerToast("Error", httpErr.error.message);
    }, true);
  }


}
