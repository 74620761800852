import { Injectable } from '@angular/core';
import { GlobalService } from "../app/global.service"

@Injectable({
  providedIn: 'root'
})
export class EnvService {
  settings: any;
  facebook_app_id="448815219365716";  
  constructor(
    public global: GlobalService,
  ) {     
    this.application()
  }

  application() {
    this.global.get("getSettingData", data => {
      this.settings = data.data;
    },
    err => { }
    );
  }
 
}

