<div class="inner-wraper">
  <section class="abg-sect">
    <div class="container">
      <article>
        <h1>{{ aboutArray["title"] }}</h1>
        <div [innerHTML]="aboutArray['description']"></div>
      </article>
    </div>
  </section>
</div>
