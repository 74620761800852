<div class="inner-wraper">
  <section class="lesson-cover-sect">
    <div class="container">
      <div class="lektionen-cover-new">
        <div class="lekt-title" style="display: block !important;">
          <div class="d-flex">
            <a href="javascript:;" (click)="location.back()"><i class="ri-arrow-left-s-line"></i></a>
            <h2>{{ global.termsArray["lbl_notifications"] }}</h2>
          </div>
          <div class="list-sentens-box p-2" infiniteScroll [scrollWindow]="false" [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [immediateCheck]="true" (scrolled)="onScroll($event)" scrolledUp="onScroll($event)">
            <section class="msger">
              <main class="msger-chat">
                <div *ngFor="let dateGroup of myNotification" >
                  <div class="msg-date">
                    <p>{{ formatDateToGerman(dateGroup.created) }}</p>
                  </div>
                
                  <div class="msg left-msg" *ngFor="let notification of dateGroup.notification" [attr.id]="notification._id">
                    <div class="msg-bubble">
                      <div class="msg-info">
                        <div class="msg">
                          <span class="msg-img">
                            <img src="assets/images/bnotification.png" />
                          </span>
                          <p class="mb-0 me-2">Birlingo</p>
                        </div>
                      </div>
                      <div>
                        <p class="msg-text">{{ notification.message }}</p>
                      </div>
                    </div>
                  </div>
                </div>               
              </main>

              <div class="level-point" *ngIf="myNotification && myNotification?.length == 0">
                <div  class="level-point-in d-flex justify-content-center align-items-center">
                  <div class="text-black"> {{ global.termsArray["lbl_no_notification"]  ? global.termsArray["lbl_no_notification"] : "No Notification found."  }} </div>
                </div>
              </div>

            </section>

          </div>
          
        </div>
      </div>
      <app-app></app-app>
    </div>
  </section>
</div>