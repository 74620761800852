import { Location } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { GlobalService } from "../../global.service";
import { NavigationStart, Router } from "@angular/router";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { FormBuilder, Validators } from '@angular/forms';

declare var paypal: any;
@Component({
  selector: 'app-gift-cards',
  templateUrl: './gift-cards.component.html',
  styleUrls: ['./gift-cards.component.scss']
})
export class GiftCardsComponent implements OnInit {
  countries: any = [];
  giftCards: any;
  id: any;
  subscriptionList: any;
  unlimitedSubscription: any;
  show: boolean = false;
  modalRef: BsModalRef;
  public subData: any;
  paymentOptionsForm: any;
  paymentMethod: string = "";
  activePaymentOptions: any = [];
  couponCode;
  couponCodeId;
  couponCodeData;
  userCountry: any = this.global.user && this.global.user.country && this.global.user.country.term ? this.global.user.country : "";
  enableSofort: boolean = false;
  unlimitedSubscriptionInfo: any = {};
  giftCardAmount:any;
  sharingGiftCode:any='';
  recieverEmail:any='';
  @ViewChild('enterAmount', { static: false }) enterAmountModelRef: TemplateRef<HTMLElement>;
  @ViewChild('choosePaymetType', { static: false }) choosePaymentTypeRef: TemplateRef<HTMLElement>;
  @ViewChild('couponcode',{static: false}) couponcodeModelRef:TemplateRef<HTMLElement>;
  constructor(private route: Router, public global: GlobalService, public location: Location, private modalService: BsModalService,private formBuilder: FormBuilder,) {
    this.global.profileTab = 2    
    this.getGiftCards()
  }

  ngOnInit() {
    this.global.get("getSettingData", (response: any) => {
      this.activePaymentOptions = response && response.data && response.data.payment_options;
    }, (err) => { });
    this.userCountry = this.global && this.global.user && this.global.user.country && this.global.user.country.name ? this.global.user.country.name : "";
    this.enableSofort = this.userCountry == "Austria" || this.userCountry == "Germany" ? true : false;
    this.paymentOptionsForm = this.formBuilder.group({
      payment_method: ['', Validators.required]
    });
    this.getSubscriptionData();
    this.route.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // Navigation started, close modal if open
        if (this.modalRef) {
          this.modalRef.hide();
          this.modalRef = null;
        }
      }
    }); 
  }

  getCountries() {
    this.global.get('/countries', (data: any) => {
      this.countries = data.data;
    }, (err: any) => {
      this.global.showDangerToast("", err.message);
    })
  }

  getSubscriptionData() {
    let body = {};
    body["language_id"] = this.global.selectLanguage;
    body["user_id"] = this.global.user._id;
    this.global.post("subscriptions", JSON.stringify(body), (data) => {
      if (data.success) {
        this.subscriptionList = data.data;
        this.unlimitedSubscription= this.subscriptionList.filter((item)=>item.validity == 240)
        this.unlimitedSubscriptionInfo = this.unlimitedSubscription && this.unlimitedSubscription.length > 0 ? this.unlimitedSubscription[0] : null
        console.log("unlimitedSubscription",this.unlimitedSubscription)
        
      } else {
        this.global.showDangerToast("Error", this.global.languageArray[data.message]);
      }
    }, (err) => {
      this.global.showDangerToast("Error", err.message);
    }, true);
  }


  getGiftCards() {
    this.global.get(
      `getGiftCards/${this.global.user._id}`,
      data => {
        if (data.success) {
          this.giftCards = data && data.data ? data.data : []
        }
      },
      err => {
        this.global.showDangerToast("Error", err.message);
      },
      true
    );
  }

  goBack(){
    this.route.navigate(['setting'], { replaceUrl: true })
  }

  copyText(text) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text)
        .then(() => {
          this.global.showToast("", this.global.termsArray['msg_text_copy']);
          // this.id=null
        })
        .catch(err => {
          console.error('Could not copy text: ', err);
          // Handle error, show a message, etc.
        });
    }
  }


  openModal(template: TemplateRef<any>, data = "big-model-dialog") {
    const config = {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: true,
      class: data,
    };
    this.show = false;
    this.subData = this.unlimitedSubscriptionInfo;
    this.modalRef = this.modalService.show(template, config);
  }


  isActiveMethod(method: string) {
    return this.activePaymentOptions.indexOf(method) >= 0;
  }

  resetForm(form: any) {
    form.reset();
  }

  openModal2(template: TemplateRef<any>, data = "big-model-dialog", ignoreBackdropClick = false) {
    const config = {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: ignoreBackdropClick,
      class: data,
    };
    this.show = false;
    this.modalRef = this.modalService.show(template, config);
  }


  subscribeMyplan(){
    if(this.paymentOptionsForm.valid){
      this.paymentMethod = this.paymentOptionsForm.value.payment_method;
      this.modalRef.hide();
      this.openModal2(this.enterAmountModelRef,'big-model-dialog2',true);
      this.resetForm(this.paymentOptionsForm);
    }else{
      alert("Please select a payment option");
    }
  }

  validateInput(event: KeyboardEvent): void {
    const allowedChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Delete'];

    if (allowedChars.indexOf(event.key) === -1) {
      event.preventDefault();
    }
  }


  validateGiftCardAmount(){
    if (!this.giftCardAmount || isNaN(this.giftCardAmount)) {
      this.global.showDangerToast("Error",this.global.termsArray['err_enter_valid_gift_amount'] );
      return;
    }
    // if (this.giftCardAmount==0) {
    //   this.global.showDangerToast("Error",this.global.termsArray['err_gift_amount_greater_than_zero'] );
    //   return;
    // }
    if (this.giftCardAmount<50) {
      this.global.showDangerToast("Error",this.global.termsArray['err_gift_amount_greater_than_fifty'] );
      return;
    }
    if (this.giftCardAmount>this.unlimitedSubscriptionInfo.price) {
      this.global.showDangerToast("Error",this.global.termsArray['err_gift_card_amount_cnnot_be_greater_than_unlimited_sub_amount'] );
      return;
    }
    else{
      this.modalRef.hide();
      this.openModal2(this.couponcodeModelRef,'big-model-dialog2',true);
      if(this.paymentMethod == 'paypal'){
        setTimeout(() => {
          this.requestToPaypal(this.subData,this.couponCodeData);
        }, 100);
      }  
    }
  }

  applyCouponCode(coupon, template: TemplateRef<any>) {
    if (!coupon) {
      return;
    }
    let body = {};
    body["code"] = coupon;
    body["amount"] = this.giftCardAmount;
    this.global.post("apply-coupon-on-gift-card", JSON.stringify(body), (data) => {
      if (data.success) {
        this.couponCodeId = data.data;
        this.couponCode = "";
        this.modalRef.hide();
        const config = {
          animated: true,
          keyboard: true,
          backdrop: true,
          ignoreBackdropClick: false,
          class: 'big-model-dialog2 discountPOPUP',
        };
        this.modalRef = this.modalService.show(template, config);
        this.couponCodeData = data.data;
        this.global.showToast("", this.global.termsArray[data.message]);
        if(this.paymentMethod == "paypal"){
          setTimeout(() => {
            this.requestToPaypal(this.subData,this.couponCodeData, "paypal_button_container2");
          }, 100);
        }
      } else {
        this.couponCode = "";
        this.global.showDangerToast("Error", this.global.termsArray[data.message]);
      }
    },
      (err) => {
        this.couponCode = "";
        this.modalRef.hide();
        this.global.showDangerToast("Error", err.message);
      },
      true
    );
  }

  manualPurchaseGiftCards() {
    const subs = this.subData;
    const subsId = subs && subs._id ? subs._id : subs;
    let stripeRequest = {basesubs_id: subsId,  payment_method: this.paymentMethod,amount:0};
    if(this.couponCodeData && this.couponCodeData.code){
      stripeRequest['coupon_code'] = this.couponCodeData.code;
      stripeRequest['amount'] = this.couponCodeData.discount_less;
      stripeRequest['purchasing_amount'] = this.giftCardAmount
    }
    this.global.post("manual-gift-code", stripeRequest, (response: any) => {
      if (response.success == true) {
        this.giftCardAmount=null;
        this.modalRef.hide();
        this.getGiftCards();
        this.route.navigate(['/gift-cards'])
      } else {
        this.global.showDangerToast("Error", response.message);
      }
    }, (err: any, httpErr: any) => {
      console.log(httpErr);
      this.global.showDangerToast("Error", httpErr.error.message);
    }, true);
  }


  purchaseGiftCards() {
    if (!this.giftCardAmount) {
      return;
    }
    const subs = this.subData;
    const subsId = subs && subs._id ? subs._id : subs;
    let stripeRequest = {basesubs_id: subsId,  payment_method: this.paymentMethod,amount:this.giftCardAmount,gift_card_amount:this.giftCardAmount };
    if(this.couponCodeData && this.couponCodeData.code){
      stripeRequest['coupon_code'] = this.couponCodeData.code;
      stripeRequest['amount'] = this.couponCodeData.discount_less
    }
    this.global.post("purchase-gift-cards", stripeRequest, (response: any) => {
      if (response.success == true) {
        let url = response.data.payment_link;
        window.location.href = url;
      } else {
        this.global.showDangerToast("Error", response.message);
      }
    }, (err: any, httpErr: any) => {
      console.log(httpErr);
      this.global.showDangerToast("Error", httpErr.error.message);
    }, true);
  }

  /**PAYPAL INTEGRATION >>>> */
  requestToPaypal(subcription: any,couponInfo: any, tagId:string = "paypal_button_container") {
    let amount:any = parseFloat(this.giftCardAmount).toFixed(2)
    if (couponInfo) {
      amount = couponInfo.total - couponInfo.discount_amount;
      amount = amount >= 0 ? parseFloat(amount).toFixed(2) : 0;
    }
    this.initPaypal(amount,subcription,couponInfo, tagId,this.global.user._id);
  }

  initPaypal(amount: any,subcription: any, couponInfo: any, tagId:string,user_id:any) {
    const clsInstance = this;
    let org_amount= parseFloat(this.giftCardAmount).toFixed(2)
    // user_id, sub_id, coupon_id, gift_purchase,giftcode,giftCardOrgAmount
    let custom_id = user_id+','+subcription._id;
    custom_id = couponInfo && couponInfo._id ? custom_id+','+couponInfo._id : custom_id+',null';
    custom_id = custom_id + ',true';
    custom_id = org_amount ? custom_id+',null,'+org_amount : custom_id;
        document.querySelector("#"+tagId).innerHTML = "";
        paypal.Buttons({
          style: {
            layout:'horizontal',
            color:'white',
            height:55,
          },
          createOrder: function (data, actions) {
            return actions.order.create({
              purchase_units: [{
                amount: {
                  value: amount /** Set the payment amount */
                },
                custom_id:custom_id
              }]
            });
          },
          onApprove: async function (data, actions) {
            let userSub =await clsInstance.IntialisePaypalPayment(subcription,couponInfo,amount)
             return actions.order.capture().then(function (details) {
              // clsInstance.newSubscribeToPaypal(subcription, couponInfo, details);
              clsInstance.modalRef.hide();
              clsInstance.couponCodeData = null;
              clsInstance.route.navigate(['gift-cards']);
            });
          },
          onCancel: function (data, actions) {
            clsInstance.modalRef.hide();
            clsInstance.couponCodeData = null;
          }
        }).render("#"+tagId);
     
  }
    
  IntialisePaypalPayment(subs,amount,coupon) {
    const subsId = subs && subs._id ? subs._id : subs;
    let requestBody = {
      basesubs_id: subsId,
      amount: amount,
      coupon: coupon,
    };
    this.global.post("initGiftPurchasePaypalPayment", requestBody, async (response: any) => {
      if (response.success == true) {
        // this.global.showToast("", this.global.termsArray[response.message]);
      } else {
        this.global.showDangerToast("Error", response.message);
      }
    }, (err: any, httpErr: any) => {
      console.log(httpErr);
      this.global.showDangerToast("Error", httpErr.error.message);
    }, true);
  }

  isValidEmail(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }


  shareGiftCode() {
    if (!this.recieverEmail && !this.sharingGiftCode) {
      return;
    }
    if (!this.recieverEmail || !this.isValidEmail(this.recieverEmail)) {
      this.global.showDangerToast("Error",  this.global.termsArray['err_valid_email']);
      return;
    }
    if (!this.sharingGiftCode) {
      this.global.showDangerToast("Error",this.global.termsArray['err_valid_gift_code']);
      return;
    }
    let body = {};
    body["reciever_email"] = this.recieverEmail;
    body["gift_code"] = this.sharingGiftCode;
    this.global.post("share-gift-code", JSON.stringify(body), (data) => {
      if (data.success) {
        this.recieverEmail='';
        this.sharingGiftCode='';
        this.global.showToast("Success", this.global.termsArray[data.message]);
        this.modalRef.hide();
      } else {
        console.log("data",data.message)
        this.global.showDangerToast("Error", this.global.termsArray[data.message]);
      }
    },
      (err) => {
        this.modalRef.hide();
        this.global.showDangerToast("Error", err.message);
      },
      true
    );
  }
}
