import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Route
} from "@angular/router";
import { Observable } from "rxjs";
import { GlobalService } from "./global.service";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";

@Injectable()
export class AuthService implements CanActivate {
  isLogin = false;
  constructor(
    private _router: Router,
    public global: GlobalService,
    private route: ActivatedRoute,
    private location:Location
  ) {

    }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let user = localStorage.getItem(btoa("user")) ? atob(localStorage.getItem(btoa("user"))) : null;
    user = JSON.parse(user);
    if (user) {
      this.global.isLogin = true;
      return true;
    } else {
      sessionStorage.setItem('landingPage',state.url.split("?")[0]);
      sessionStorage.setItem('landingPageQuery',JSON.stringify(next.queryParams));
    }
    this._router.navigate(['login']);
    return false;
  }

  canActivate2(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let user = localStorage.getItem(btoa("user")) ? atob(localStorage.getItem(btoa("user"))) : null;
    user = JSON.parse(user);
    if (user) {
      this.global.isLogin = true;
      return true;
    } else {
      //this._router.navigate(["home"]);
      this._router.navigate(['account']);
    }
    this._router.navigate(['register'], { queryParams: { returnUrl: state.url }});
    return false;
  }
}
