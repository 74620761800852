import { Component, OnInit, Inject } from "@angular/core";
import { GlobalService } from "../global.service";
import { Router } from "@angular/router";
import { Title, Meta } from "@angular/platform-browser";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { resolve } from "url";
declare var $: any;
import { environment } from "src/environments/environment";
import { DOCUMENT } from '@angular/common';

@Component({
  selector: "app-method",
  templateUrl: "./method.component.html",
  styleUrls: ["./method.component.scss"],
})
export class MethodComponent implements OnInit {
  content: any;
  testimonials: any;
  video_url: any;
  video_image: any;
  deviceInfo = null;
  max_767_width;
  isPhone: any = false;
  carosalHide = true;
  loadMoreFlag:number = 0;
  public globalSettingValues: any;
  public homeContent: any = {};

  constructor(
    private metaTagService: Meta,
    public global: GlobalService,
    public route: Router,
    public breakpointObserver: BreakpointObserver,
    private titleService: Title,
    @Inject(DOCUMENT) private doc
  ) {
    let user = localStorage.getItem(btoa("user"))
      ? atob(localStorage.getItem(btoa("user")))
      : null;
    user = JSON.parse(user);
    // if (user) {
    //   this.route.navigate(["user"], { replaceUrl: true });
    // }
    if (this.global.isPhone) {
      setTimeout(() => {
        this.carosalHide = false;
      }, 2000);
    } else {
      this.carosalHide = false;
    }
    this.getLearningLanguage();
    this.getSettingData();
    // this.getTestimonials();
    this.getMethodPageContent().then((promise) => {
      $(document).ready(() => {
        setTimeout(() => {
          $(".carousel").swipe({
            swipe: (
              event,
              direction,
              distance,
              duration,
              fingerCount,
              fingerData
            ) => {
              //console.log("swipe");
              if (direction == "left") {
                $("#box-next").trigger("click");
              }
              if (direction == "right") {
                $("#box-pre").trigger("click");
              }
            },
            allowPageScroll: "vertical",
          });
        }, 1000);
      });

    });
  }

  ngOnInit() {
    // this.getHomeContent();
    this.loadScripts();
    localStorage.removeItem(btoa("routeParams"));
    this.global.routeParams = null;

    this.breakpointObserver.observe(["(max-width: 767px)"]).subscribe((result) => {
      if (result.matches) {
        this.max_767_width = true;
      } else {
        this.max_767_width = false;
      }
    });
    this.hideLoadmore();
  }

  getSettingData() {
    this.global.get("getSettingData", data => {
      console.log("getSettingData response: ", data);
      this.video_url = data.data.method_video_url
      this.video_image = data.data.home_video_url_image
      this.open();
    }, err => {
        console.log("error");
      }
    );
  }


  getId(str) {
    var res = str.split("=");
    // console.log("this is res", res);
    var embeddedUrl = "https://www.youtube.com/embed/" + res[1] + "?autoplay=0&rel=0";
    return embeddedUrl;
  }

  open(): void {
    let urls = this.getId(this.video_url);
    $("#test").attr("src", urls)
  }

  getMethodPageContent() {
    return new Promise((resolve, reject) => {
      let body = {};
      body["language_id"] = this.global.selectLanguage;
      this.global.post("methodContent", JSON.stringify(body), (data) => {
        if (data.success) {
          this.content = data.data;
          console.log("content: ", this.content);
          this.titleService.setTitle(data.data["meta_title"]);

          this.metaTagService.updateTag({ property: "og:site_name", content: "Method" });
          this.metaTagService.updateTag({ property: "og:type", content: "website" });
          this.metaTagService.updateTag({ property: "og:url", content: environment.wesiteUrl + "method" });
          this.metaTagService.updateTag({ property: "og:title", content: this.content['meta_title'] })
          this.metaTagService.updateTag({ property: "og:description", content: this.content["meta_description"] });
          // this.metaTagService.updateTag({ property: "og:languages_human_speak_text", content: this.content["languages_human_speak_text"] });
          this.metaTagService.updateTag({ property: "og:image", content: this.content.imageUrl + this.content.images.image_one });
          this.createLinkForCanonicalURL();

          document.getElementById("right_learning_guarantee_texts").innerHTML = this.content["right_learning_guarantee_texts"];
          document.getElementById("left_learning_guarantee_texts").innerHTML = this.content["left_learning_guarantee_texts"];
          document.getElementById("right_steps_to_new_lang_text").innerHTML = this.content["right_steps_to_new_lang_text"];
          document.getElementById("left_steps_to_new_lang_text").innerHTML = this.content["left_steps_to_new_lang_text"];
          document.getElementById("hear_actively_texts").innerHTML = this.content["hear_actively_texts"];
          document.getElementById("step_3_speak_texts").innerHTML = this.content["step_3_speak_texts"];
          document.getElementById("step_4_hear_passively_texts").innerHTML = this.content["step_4_hear_passively_texts"];
          document.getElementById("step_2_listen_actively_title").innerHTML = this.content["step_2_listen_actively_title"];
          document.getElementById("step_3_have_a_say_title").innerHTML = this.content["step_3_have_a_say_title"];
          document.getElementById("step_4_listen_passively_title").innerHTML = this.content["step_4_listen_passively_title"];
          document.getElementById("learn_these_lan_with_birlingo").innerHTML = this.content["learn_these_lan_with_birlingo"];
          
          // ------------------- New Content Related Tags 03-09-2021 ------------------
          document.getElementById("languages_human_speak_text").innerHTML = this.content["languages_human_speak_text"];
          // ------------------- End New Content Related Tags 03-09-2021 ------------------

          // ------------------------------------------ End New Content Related Tags ------------------------------------------
          document.getElementById("tabName2").style.backgroundSize = "cover";
          document.getElementById("tabName2").style.backgroundRepeat = "no-repeat";
          resolve(1);
        } else {
          this.global.showDangerToast(this.global.termsArray[data.message]);
          reject(0);
        }
      }, (err) => {
        this.global.showDangerToast(err.message);
        reject(0);
      }, true);
    });
  }

  getLearningLanguage() {
    this.global.get("learningLanguages/" + this.global.selectLanguage, (data) => {
      this.global.learningLanguages = data.data.filter((ele) => {
        return ele._id != this.global.selectLanguage;
      });
    }, (err) => {});
  }

  generateFaq(id, answer) {
    document.getElementById(id).innerHTML = answer;
  }

  closeModal(){
    $('.modal iframe').attr('src', '');
  }

  createLinkForCanonicalURL() {
    const link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    link.setAttribute('href', environment.wesiteUrl + "method");
  }

  loadScripts() {
    // This array contains all the files/CDNs
    const dynamicScripts = [
      'https://apps.elfsight.com/p/platform.js'
    ];

    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      const node2 = document.createElement('div');
      node2.className = "elfsight-app-0e639d93-32da-4b92-b597-d369f32c11cb";
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      document.getElementsByName('google-widget-sec')[0].appendChild(node);
      document.getElementsByName('google-widget-sec')[0].appendChild(node2);
    }
  }

  hideLoadmore(){
    this.loadMoreFlag++;
    let button = document.getElementsByClassName("ButtonBase__ButtonContainer-sc-p43e7i-3")[0];
    if(button){
      // console.log('button=======>>>>> found')
      let contentFlag = 0;
      let findContent = ()=>{
        contentFlag++;
        let content = document.getElementsByClassName("ReviewContainer__Inner-sc-yv0v3c-0");
          if(content){
            // console.log('ifBlock*****')
            setTimeout(() => {
              content = document.getElementsByClassName("ReviewContainer__Inner-sc-yv0v3c-0");
              // console.log("content===",content)
              // console.log("content",content.length)
                if(content.length>8){
                  (button as HTMLElement).style.display="block";
                }else{
                  (button as HTMLElement).style.display="none";
                }
            }, 1000);
          }else{
            setTimeout(() => {
              if(contentFlag<100){
                findContent();
              } 
            }, 100);
          }
        }
        findContent();
      }
    else{
      setTimeout(() => {
        if(this.loadMoreFlag<50){
          this.hideLoadmore();
        }
      }, 100);
    }
  }
}
