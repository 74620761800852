<div class="inner-wraper">
  <section class="lesson-cover-sect">
    <div class="container">
      <div class="lektionen-cover-new">
        <div class="lekt-title" style="display: block !important;">
          <div class="d-flex">
            <a href="javascript:;" (click)="location.back()"><i class="ri-arrow-left-s-line"></i></a>
            <h2>{{ this.global.termsArray["lbl_awards"]}}</h2>
            <!-- <div class="chat-notification">
              <span _ngcontent-serverapp-c1="" class="notification-badge">2</span>
              <div class="chat-notification-ic">
                <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.75 0C2.57852 0 0 2.57852 0 5.75V31.625C0 34.7965 2.57852 37.375 5.75 37.375H14.375V44.5625C14.375 45.1105 14.6805 45.6047 15.1656 45.8473C15.6508 46.0898 16.2348 46.0359 16.675 45.7125L27.7887 37.375H40.25C43.4215 37.375 46 34.7965 46 31.625V5.75C46 2.57852 43.4215 0 40.25 0H5.75Z" fill="white"/>
                  </svg>                    
              </div>
            </div> -->
          </div>

        </div>
        <div class="list-sentens-box p-2" infiniteScroll [scrollWindow]="false" [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="50" [immediateCheck]="true">
          <div class="body-wrap">
            <div class="heading-main">
              <p>{{ this.global.termsArray["lbl_award_description"]}}</p>
            </div>
            <div class="body-wrap-container" *ngFor="let year of myLearning;">
              <div class="date-badge mb-3 text-center">
                <span>{{year.created}}</span>
              </div>
              <div class="accordion award-accordion" id="accordionExample">
                <div *ngFor="let week of year.awards; let i = index" class="card">
                  <div class="card-header" [id]="'heading' + i">
                    <h2 class="mb-0">
                      <button class="btn-block text-left" type="button" [attr.data-toggle]="'collapse'"
                        [attr.data-target]="'#collapse' + i" aria-expanded="true" [attr.aria-controls]="'collapse' + i">
                        {{ formatDate(week.week_start_date) }} – {{formatDate(week.week_end_date) }} <sup>{{
                          getMinutes(week.learningTime) }}’</sup>
                      </button>
                    </h2>
                  </div>
                  <div [id]="'collapse' + i" class="collapse" [ngClass]="{'show': i==0 }" [attr.aria-labelledby]="'heading' + i">
                    <div class="card-body">
                        <div class="award-level-bar">
                            <div *ngFor="let award of awards" class="award-level-box cp"
                                [ngClass]="getMinutes(week.learningTime) >= award.minutes ? 'active' : ''"
                                (click)="selectedAward = award; selecteMinutes = award.minutes; getMsg(award.minutes)"
                                data-toggle="modal" data-target="#award-modal">
                                <div class="award-level-box-in">
                                    <figure>
                                        <img [src]="award.image" alt="{{ award.name }}">
                                    </figure>
                                    <figcaption>
                                        {{ award.minutes }}’
                                    </figcaption>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="body-wrap">
              <div class="heading-main">
                <p>Jede Woche haben Sie die Möglichkeit, 5 Preise zu verdienen. Diese werden nach jeweils 15 Minuten Lernen freigeschaltet.</p>
              </div>
              <div class="body-wrap-container">
                <div class="date-badge mb-3 text-center">
                  <span>2024</span>
                </div>
                <div class="accordion award-accordion" id="accordionExample">
                  <div class="card">
                    <div class="card-header" id="headingOne">
                      <h2 class="mb-0">
                        <button class="btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          12.2 – 18.2. <sup>46’</sup>
                        </button>
                      </h2>
                    </div>
                
                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                      <div class="card-body">
                        <div class="award-level-bar">
                          <div class="award-level-box active">
                            <div class="award-level-box-in">
                              <figure>
                                <img src="../../../assets/images/awards-icon-1.png">
                              </figure>
                              <figcaption>
                                15’
                              </figcaption>
                            </div>
                          </div>
                          <div class="award-level-box active">
                            <div class="award-level-box-in">
                              <figure>
                                <img src="../../../assets/images/awards-icon-2.png">
                              </figure>
                              <figcaption>
                                15’
                              </figcaption>
                            </div>
                          </div>
                          <div class="award-level-box active">
                            <div class="award-level-box-in">
                              <figure>
                                <img src="../../../assets/images/awards-icon-3.png">
                              </figure>
                              <figcaption>
                                15’
                              </figcaption>
                            </div>
                          </div>
                          <div class="award-level-box">
                            <div class="award-level-box-in">
                              <figure>
                                <img src="../../../assets/images/awards-icon-4.png">
                              </figure>
                              <figcaption>
                                15’
                              </figcaption>
                            </div>
                          </div>
                          <div class="award-level-box">
                            <div class="award-level-box-in">
                              <figure>
                                <img src="../../../assets/images/awards-icon-5.png">
                              </figure>
                              <figcaption>
                                15’
                              </figcaption>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingTwo">
                      <h2 class="mb-0">
                        <button class="btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          5.2. – 11.2. <sup>83’</sup>
                        </button>
                      </h2>
                    </div>
                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                      <div class="card-body">
                        Some placeholder content for the second accordion panel. This panel is hidden by default.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <h2 class="mb-0">
                        <button class="btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          29.1. – 4.2. <sup>12’</sup>
                        </button>
                      </h2>
                    </div>
                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                      <div class="card-body">
                        And lastly, the placeholder content for the third and final accordion panel. This panel is hidden by default.
                      </div>
                    </div>
                  </div>
                </div>
                
                
                
                


                <div class="modal-3">
                  <button data-toggle="modal" data-target="#award-modal">Modal 1</button>
                </div>
              </div>
            </div> -->
        </div>
      </div>
      <app-app></app-app>
    </div>
  </section>
</div>



<!-- Award 1 -->
<div class="modal modal-radius fade" id="award-modal" tabindex="-1" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body" *ngIf="selectedAward">
        <div class="modal-heading text-center mb-4">
          <img [src]="selectedAward.image" alt="{{ selectedAward.name }}" class="d-block m-auto mb-3 award-modal-img">
          <h4>{{selectedAward.name}}</h4>
          <p>{{message}}</p>
        </div>
        <div class="text-center">
          <button type="button" class="close close-btn-border" data-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i> {{global.termsArray["lbl_close"]}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>