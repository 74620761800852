
<div class="inner-wraper">
  <div
      class="blog-banner-in myclass"
      [ngStyle]="{
        background: 'url(' + blogData?.image + ')',
        height: blogData && blogData?.image ? '685px' : '0px',
        width: '100%'
      }"
      [style.background-size]="'cover'"
      id="tabName"
      style="background-repeat: no-repeat; margin: auto">
  </div>

  <section class="blog-detail-sect" [hidden]="!blogData">
    <div class="container-full">
      <div class="setting-arrow">
        <a
          href="javascript:;"
          style="width: fit-content; word-break: initial"
        >
          <i class="fas fa-long-arrow-alt-left" (click)="goBack()"></i>
          <h6 (click)="goBack()">{{ global.termsArray["lbl_go_back"] }}</h6>
        </a>
      </div>
    </div>
    <div class="container">
      <article style="display: grid">
        <div
          class="blog-dtl"
          style="
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: self-start;
          "
        >
          <h6 style="align-self: flex-start">
            {{ this.date(blogData?.release_date) }}
          </h6>
          <h1 style="display: inline-block; word-break: break-all; overflow: hidden; text-overflow: ellipsis; align-self: flex-start; max-width: 100% !important;">
            <a style="word-break: break-word">{{ blogData?.title }}</a>
          </h1>
          <!-- <figure style="max-width: 640px;"><img [src]="blogData && blogData.image?blogData.image:'assets/images/blog.jpg'" onError="assets/images/blog.jpg" style="max-height: 600px;"></figure> -->
          <div id="cmspages_id2" class="cmspages_id blog-des-bg" style="font-size: 18px !important; color: #888888 !important; font-family: 'Akkurat-Reguler' !important; word-break: break-all;"></div>
          <!-- [innerHTML]="blogData['description']" -->
        </div>
      </article>      
    </div>
  </section> 
</div>
<!-- <section class="Methode-sec-box pt-2 pb-2">
  <div class="container">
    <div class="Jetzt-box">
      <div class="jet-title">
        <h6 style="margin-right: 20px">
          {{ global.termsArray["lbl_method_try_free"] }}
        </h6>
      </div>
      <div class="jet-list">
        <ul>
          <li
            [ngClass]="
              language._id == global.learningLanguage ? ' active2' : ''
            "
            *ngFor="let language of global.learningLanguages"
            (click)="selectLanguage(language._id)"
          >
            <a style="cursor: pointer">{{
              global.termsArray[language?.term]
            }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section> -->
