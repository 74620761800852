
<div *ngIf="global.isLoading$ | async; else loadingContent" class="loading-overlay">
  <div class="skeleton-header">
    <div class="logo-skeleton skeleton-box"></div>
    <div class="logo-text-skeleton skeleton-box"></div>
    <div class="buttons-container">
      <div class="button-skeleton skeleton-box"></div>
      <div class="button-skeleton skeleton-box"></div>
      <div class="button-skeleton skeleton-box"></div>
    </div>
  </div>
  <div>
    <div class="mt-4 line-skeleton skeleton-box1"></div>
  </div> 
</div>

<ng-template #loadingContent>  

<div [className]="!display ? 'd-none' : ''">
  <div class="wrapper" [ngClass]="global.isLogin ? 'inner-ab texture-bg-gry' : ''">
    <app-header *ngIf="!hideHeaderFooter"></app-header>
    <ngx-ui-loader></ngx-ui-loader>
    <router-outlet *ngIf="termsLoaded"></router-outlet>
    <app-footer *ngIf="!hideHeaderFooter"></app-footer>
  </div>
</div>
</ng-template> 
