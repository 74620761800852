<div class="inner-wraper login-bg-cover login-method">
  <section class="abg-form-sect">
    <div class="container">
      <div class="login-in-color">
        <div class="setting-arrow">
          <!-- <a routerLink="/setting" href="javascript:;" style="width: fit-content; word-break: initial"> -->
          <a (click)="goToBack()" href="javascript:void(0);" style="width: fit-content; word-break: initial">
            <i class="fas fa-long-arrow-alt-left"></i>
            <h6>Zurück</h6>
          </a>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="log-wd-max">
              <h1 style="
                    color: #fff;
                    margin-bottom: 20px;
                    font-family: 'ACaslonPro-Bold';
                    ">
                {{ global.termsArray["lbl_video_title"] }}
              </h1>
              <div *ngIf="lastScreen != 'settings'">
                <p style="
                        font-family: Akkurat-Bold;
                        color: #fff;
                        font-size: 22px;
                        margin-right: 5px;
                    ">

                  {{ global.termsArray["lbl_video_text"] }}
              </p>
              </div>
            </div>

            <section class="video-sec-bg">
              <div class="video-md-5">
                <figure>
                  <iframe id="test" width="100%" height="450px" allowfullscreen></iframe>
                </figure>
              </div>
              <a *ngIf="lastScreen != 'settings'" class="btn" (click)="navigate()" href="javascript:;">Los geht’s</a>
            </section>

            <!-- <ng-container >
              <div class="try-learn-lm">
                <a class="btn" (click)="navigate()">{{ global.termsArray["lbl_here_we_go"] }}</a>
              </div>
            </ng-container> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</div>