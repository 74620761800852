import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GlobalService } from "../global.service";
import { FormBuilder, FormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-terms-condition",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.sass"]
})
export class ContactComponent implements OnInit {
  languageId: any;
  contactArray: any = null;
  contentLoader:any=true;
  formData: any;
  formSubmitAttempt: boolean=false;
  constructor(private route: ActivatedRoute, public global: GlobalService, public router: Router,private formBuilder: FormBuilder,) {
    let user = localStorage.getItem(btoa("user")) ? atob(localStorage.getItem(btoa("user"))) : null;
    user = JSON.parse(user);
    if (user) {
      this.router.navigate(["user"],{replaceUrl:true});
    }
    this.route.paramMap.subscribe(params => {
      this.languageId = params.get("id")
        ? params.get("id")
        : this.global.selectLanguage
          ? this.global.selectLanguage
          : "5dea0938ec155c3df111d13c";
      //console.log(params.get("id"));
    });
    this.formData = this.formBuilder.group({
      email: new FormControl("", Validators.compose([Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,5}$")])),
      name: new FormControl("", Validators.required),
      news: new FormControl("", Validators.required),
    });
  }
  ngOnInit() {
    this.getList();
  }

  getList() {
    let body = {};
    body["slug"] = "contact";
    body["language_id"] = this.languageId;
    body["device"] = "web";
    this.global.post(
      "getCmsPageData",
      JSON.stringify(body),
      data => {
        this.contactArray = data.data[0] ? data.data[0] : null;
        if(!this.contactArray || this.contactArray==null){
          this.contentLoader=false
        }
        //console.log(data);
        // document.getElementById("cmspages_id").innerHTML = this.contactArray['description'] ? this.contactArray['description'] : null;
      },
      err => { },
      true
    );
  }



  capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  }
  onClickSubmit(value) {
    this.formSubmitAttempt = true;
    if (!this.formData.valid) {
      return;
    } else {
      this.callSubmitApi(value);
    }
  }

  callSubmitApi(value:any) {
    let body = {};
    body["name"] = this.capitalize(value.name);
    body["email"] = value.email;
    body["news"] = value.news;
    this.global.post(
      "sendnews",
      body,
      (data) => {
        if (data.success) {
          this.formSubmitAttempt = false;
          this.formData.reset()
          this.global.showToast("", this.global.termsArray[data.message]);
        } else {
          this.global.showDangerToast("", this.global.termsArray[data.message]);
        }
      },
      (err) => {
        this.global.showDangerToast("", err.message);
      },
      true
    );
  }

}
