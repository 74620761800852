<div class="inner-wraper">
    <section class="lesson-cover-sect" >
      <div class="container">
        <div class="lektionen-cover-new">
          <div class="lekt-title" style="display: block !important;">
            <div class="d-flex">
              <a href="javascript:;" (click)="location.back()"><i class="ri-arrow-left-s-line"></i></a>
              <h2>{{ global.termsArray['lbl_in_progress_lesson'] }}</h2>
            </div>
          </div>
          <div class="list-sentens-box p-2" infiniteScroll [scrollWindow]="false" [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [immediateCheck]="true">
  
            <div class="leisure-lp-lists">
              <div class="level-point" *ngFor="let list of lastPlayedLessons; let i = index">
                <div class="level-point-in">
                  <div class="mb-2">
                    <div class="fav-css">
                      <a *ngIf="(list.is_free && list.is_demo!=1) || (list.is_demo==2 && (isSubscribed || isWithinFreeTrial()))" (click)="list.is_favorite==0 ? addFavorites(list._id) : openModal(favorites,'big-model-dialog2');favLessonId=list._id">
                        <figure><i [ngClass]="list.is_favorite == 1 ? 'fav-lesson ri-heart-line' : 'ri-heart-line'"></i></figure>
                      </a>
                    </div>
                    <div class="lock-css">
                      <a class="h6-css newCss">{{ global.termsArray[returnLevel(list.level_id)] ? global.termsArray[returnLevel(list.level_id)] : "name not inserted" }}</a>
                      <img class="unlock-icon" *ngIf="!list?.is_free && (!isSubscribed  && !isWithinFreeTrial())" src="assets/images/unlock@3x.png" alt="unlock icon image" />
                    </div>
                  </div>
                  <!-- <div class="lock-css">
                    <a class="h6-css newCss">{{ global.termsArray[returnLevel(list.level_id)] ? global.termsArray[returnLevel(list.level_id)] : "name not inserted" }}</a>
                    <img class="unlock-icon" *ngIf="!list?.is_free && !isSubscribed" src="assets/images/unlock@3x.png" alt="unlock icon image" />
                  </div> -->
                  <h4>{{ list?.lessonTitle ? list?.lessonTitle : "Name not insert" }}</h4>
  
                  <div class="progress-bar-sec">
                    <div class="lesson-hints">
                      <span class="w-100 lesson-label" [ngClass]="list.active_progress == 0 ? 'lesson-label-red' : 'lesson-label-green' ">
                        <h3 class="text-center">
                          {{list.active_progress == 0 ? global.termsArray['lbl_not_started_yet'] : global.termsArray['lbl_your_progress']}}
                        </h3>
                      </span>
                    </div>
  
                    <div class="down-navs">
                      <ul>
                        <li>
                          <div class="progress">
                            <span class="progress-bar" role="progressbar" [style.width]="list?.active_progress + '%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></span>
                          </div>
                          <a href="javascript:;" class="#">{{ global.termsArray["lbl_hearActively"] ? global.termsArray["lbl_hearActively"] : "Hear actively" }}</a>
                        </li>
  
                        <li>
                          <div class="progress">
                            <span class="progress-bar" role="progressbar" [style.width]="list?.speak_progress + '%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </span>
                          </div>
                          <a href="javascript:;" class="#">{{ global.termsArray["lbl_method_speak"] ? global.termsArray["lbl_method_speak"] : "Speak" }}</a>
                        </li>
  
                        <li>
                          <div class="progress">
                            <span class="progress-bar" role="progressbar" [style.width]="list?.repeat_progress + '%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </span>
                          </div>
                          <a href="javascript:;" class="#">{{ global.termsArray["lbl_repetition"] ? global.termsArray["lbl_repetition"] : "Repeat" }}</a>
                        </li>
  
                        <li>
                          <div class="progress">
                            <span class="progress-bar" role="progressbar" [style.width]="list?.passive_progress + '%'"
                              aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </span>
                          </div>
                          <a href="javascript:;" class="active">{{ global.termsArray["lbl_listen_passively"] ? global.termsArray["lbl_listen_passively"] : "listen passively" }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
  
                  <!-- <div class="bttn-blue" [ngClass]="list.active_progress == 0 ? 'Beginnen' : 'Fortfahren' ">
                    <a class="btn" href="javascript:;" (click)="setLessonTitle(list); (i==0) ?navigate(list, template, 0):navigate(list, template, i)">
                      {{ list.active_progress == 0 ? global.termsArray["lbl_start"] ? global.termsArray["lbl_begin"] : "Beginnen" : global.termsArray["lbl_continue"] ? global.termsArray["lbl_continue"] : "Fortfahren" }}
                    </a>
                  </div> -->

                  <div class="bttn-blue" [ngClass]="list.active_progress == 0 ? 'Beginnen' : 'Fortfahren'" >
                    <a class="btn" [ngClass]="(list.active_progress == 100 && list.passive_progress == 100 && list.repeat_progress == 100 && list.speak_progress == 100 ) ?'repeatation' :  ''" href="javascript:;" (click)="setLessonTitle(list); navigateLastPlayed(list?.is_demo==1 ? 'demo' : 'sentences', list,template);">
                      {{ (list.active_progress == 100 && list.passive_progress == 100 && list.repeat_progress == 100 && list.speak_progress == 100 ) ? global.termsArray["label_new_repeatation"] :  list.active_progress == 0 ? global.termsArray["lbl_start"] ? global.termsArray["lbl_begin"] : "Beginnen" : global.termsArray["lbl_continue"] ? global.termsArray["lbl_continue"] : "Fortfahren" }}
                    </a>
                  </div>

                </div>
              </div>
              <div class="level-point" *ngIf="listArray && listArray?.length == 0">
                <div class="level-point-in d-flex justify-content-center align-items-center">
                  <div>
                      {{ global.termsArray['lesson_not_found'] ? global.termsArray['lesson_not_found'] : "Lesson not found." }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <app-app></app-app>
      </div>
    </section>
  </div>
  
  <ng-template #template>
    <div class="modal-body like-sec" style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%; border-color: black;">
      <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()" style="align-self: flex-start">
        <span aria-hidden="true" style="color: black">&times;</span>
      </button>
      <h6 class="modal-title">{{ global.termsArray["msg_first_14_days_free"] }}</h6>
      <div class="d-flex">
        <button type="submit" class="btn btn-info modal-button" (click)="navigateToSubscription(); modalRef.hide()">{{ global.termsArray["lbl_see_pricing"] }}</button>
      </div>
    </div>
  </ng-template>
  



<ng-template #favorites>
  <div class="modal-header" style="border-bottom-width: 0px">
    <h4 class="modal-title" style="font-size: 25px; font-family: Akkurat-Bold; margin: 0; color: white;">{{ global.termsArray["lbl_rmv_fav"] }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modalRef.hide();favLessonId=null" style="font-family: Akkurat-Bold;  color: white;">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="font-family: Akkurat-Bold;  color: white;">
    <p>{{ global.termsArray["lbl_confirm_rmv_fav"] }}</p>
  </div>
  
  <div class="modal-footer coupon-modal-footer">
    <button type="button" class="btn btn-basic" style="font-size: 15px; font-family: Akkurat-Bold; color: #3d76ce" (click)="modalRef.hide();favLessonId=null">
      {{ global.termsArray["lbl_no"] }}
    </button>
    <button type="button" class="btn btn-primary" style="font-size: 15px;font-family: Akkurat-Bold;background-color: rgb(83, 179, 214);color: white;" (click)="removeFavorites();modalRef.hide();">
      {{ global.termsArray["lbl_yes"] }}
    </button>
  </div>
</ng-template>