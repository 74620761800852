import { Location } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { Router, NavigationEnd,ActivatedRoute } from "@angular/router";

import { GlobalService } from "../global.service";
declare var $: any;

@Component({
  selector: 'app-method-video',
  templateUrl: './method-video.component.html',
  styleUrls: ['./method-video.component.sass']
})
export class MethodVideoComponent implements OnInit {

  video_url: any;
  lastScreen = null;
  navigatefrom = null;

  constructor(
    private route: Router,
    private activatedRoute:ActivatedRoute,
    public global: GlobalService,
    private location: Location,

  ) { 
    this.getSettingData();
    this.lastScreen = this.global.routeParams && this.global.routeParams.lastRoute ? this.global.routeParams.lastRoute : "";
    this.navigatefrom = this.global.routeParams && this.global.routeParams.lastRoute ? this.global.routeParams.lastRoute : "";

    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.lastScreen = event.url;
      }
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.navigatefrom = params['navigatefrom'] || '';
    });
  }

  ngOnInit() {
  }


  getSettingData() {
    this.global.get("getSettingData", data => {
      console.log("getSettingData response: ", data);
      this.video_url = data.data.method_video_url
      // this.video_image = data.data.home_video_url_image
      this.open();
    }, err => {
        console.log("error");
      }
    );
    }

    open(): void {
      let urls = this.getId(this.video_url);
      $("#test").attr("src", urls);
    }


  getId(str) {
    var res = str.split("=");
    // console.log("this is res", res);
    var embeddedUrl = "https://www.youtube.com/embed/" + res[1] + "?autoplay=0&rel=0";
    return embeddedUrl;
  }

  

  
  navigate() {
    
    // if(this.navigatefrom == "login"){
    //   this.route.navigate(["lesson/" + "demo"]);
    // } else{
      this.route.navigate(["welcome"], { replaceUrl: true });
    // }
  }
  goToBack(){
    this.location.back();
  }
}
