import { Component, OnDestroy, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { GlobalService } from "./global.service";
import { EnvService } from './env.service';
import { Router, ActivatedRoute, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from "@angular/router";
import { DeviceDetectorService } from "ngx-device-detector";
import { SPINNER, POSITION, PB_DIRECTION } from "ngx-ui-loader";
// import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { filter, map } from "rxjs/operators";
import { fileContent } from "src/assets/html/html";


declare var dataLayer:any;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})

export class AppComponent {
  fileContent=fileContent;
  title = "website";
  SPINNER = SPINNER;
  location: any;
  previousUrl;
  currentUrl;
  browserName = '';
  isSafari : Boolean =false;
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;
  public globalSettings: any;
  public href: string = "";
  public hideHeaderFooter: boolean = false;
  public display = true;
  termsLoaded:boolean = false;
  pageLoaded: boolean = false;
  // isLoading$ = this.global.isLoading$;
  constructor(
    public global: GlobalService,
    // private ccService: NgcCookieConsentService,
    private deviceService: DeviceDetectorService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    // private gtmService: GoogleTagManagerService,
    private envService: EnvService,
    private ccService: NgcCookieConsentService,
    private translateService: TranslateService,
    private titleService: Title,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.browserName = this.detectBrowserName();
    console.log('this.browserName',this.browserName);
    if(this.browserName == "safari"){
      this.isSafari = true
    }else{
      this.isSafari = false
    }
    
    if (!isPlatformBrowser(this.platformId)) {
      this.display = false;
    }

    this.global.deviceInfo = this.deviceService.getDeviceInfo();
    this.global.isPhone = this.global.deviceInfo.os == "iOS" && this.global.deviceInfo.device == "device" ? true : false;
    var now = new Date().getTime();
    var setupTime = localStorage.getItem("setupTime");
    if (setupTime == null) {
      localStorage.setItem("setupTime", this.global.setupTime + "");
    } else {
      if (now - parseInt(setupTime) > this.global.setupHours) {
        localStorage.clear();
        localStorage.setItem("setupTime", this.global.setupTime + "");
      }
    }

    this.global.selectLanguage = localStorage.getItem(btoa("selectLanguage")) ? atob(localStorage.getItem(btoa("selectLanguage"))) : "5dea0938ec155c3df111d13c";

    let user: any = localStorage.getItem(btoa("user")) ? atob(localStorage.getItem(btoa("user"))) : null;
    user = JSON.parse(user);

    let AuthToken = localStorage.getItem(btoa("AuthToken")) ? atob(localStorage.getItem(btoa("AuthToken"))) : null;
    if (user) {
      this.global.user = user;
      this.global.AuthToken = AuthToken;
      this.global.isLogin = true;
      this.global.selectLanguage = user.language_id;
      localStorage.setItem(btoa("selectLanguage"), btoa(user.language_id));
      this.global.learningLanguage = user.learning_language_id;
      this.global.setCurrentUserInterval();
      this.global.loginTime = new Date();
    }
    router.events.subscribe(this.navigationInterceptor);
    let routeParam = localStorage.getItem(btoa("routeParams")) ? JSON.parse(atob(localStorage.getItem(btoa("routeParams")))) : null;
    this.global.routeParams = routeParam;
    router.events.subscribe((url: any) => {
      let currentUrl = router.routerState.snapshot.url;
      // console.log("this is currenturl",currentUrl);
      if (currentUrl == '/login' || currentUrl == '/forgot' || currentUrl == '/register' || currentUrl == '/register?type=true' || currentUrl == "/reset-password" ) {
        this.hideHeaderFooter = true;
      } else {
        this.hideHeaderFooter = false;
      }
    });

    router.events
    .pipe(filter((event:any) => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      sessionStorage.setItem('prevRoute',event.url.replace('/',''));
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data && child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          } else {
            return null;
          }
        }
        return null;
      })
    ).subscribe((data: any) => {
    setTimeout(() => {
      let title = this.titleService.getTitle()
      if (!title && data) {
        title = 'Birlingo-' + data
        this.titleService.setTitle(title);
      } 
      // Get current URL after title update
      const currentUrl = window.location.href;
      console.log("title",title)
      console.log("currentUrl",currentUrl)
      dataLayer.push({
        'event': 'virtualPageview',
        'pageUrl': currentUrl,
        'pageTitle': title ? title :'Birlingo'
        });
        }, 1000); 
      });
  }

  detectBrowserName() { 
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  navigationInterceptor = (event: RouterEvent) => {
    if (event instanceof NavigationStart) {
      this.global.loader = true;
    }

    if (event instanceof NavigationEnd) {
      this.global.loader = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.global.loader = false;
    }

    if (event instanceof NavigationError) {
      this.global.loader = false;
    }

    var element = document.getElementById("navbarSupportedContent");
    if (element) {
      element.classList.remove("show");
    }

  };

  getLanguageList() {
    this.global.showLoader()
    this.global.get(
      "languages",
      (data) => {
        localStorage.setItem(
          btoa("termArray"),
          btoa(JSON.stringify(data.data))
        );
        this.global.languageArray = data.data;
        this.global.getTermsData();
        //console.log(this.global.selectLanguage);
        this.global.languageArray.filter((ele) => {
          if (String(ele._id) == String(this.global.selectLanguage)) {
            this.global.selectedLabel = this.global.termsArray[ele.term];
            //console.log(this.global.selectedLabel);
          }
        });

      this.global.hideLoader()
      },
      (err) => { }
    );
  }

  async ngOnInit() {
    this.global.showLoader()
    this.global.get("getSettingData", (data) => {
      if (data.success) {
        this.globalSettings = data.data ? data.data : {};

        // this.setCookieConsent();
        if(!this.isSafari){
          this.translateService.addLangs(['en', 'fr']);
          this.translateService.setDefaultLang('en');
          const browserLang = this.translateService.getBrowserLang();
          this.translateService.use(browserLang.match(/en|fr/) ? browserLang : 'en');
          this.translateService.get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy']).subscribe(data => {
            this.ccService.getConfig().content = this.ccService.getConfig().content || {};
            // Override default messages with the translated ones
            this.ccService.getConfig().content.header = data['cookie.header'];
            this.ccService.getConfig().content.message = this.globalSettings.cookieconsent_message;
            this.ccService.getConfig().content.dismiss = this.globalSettings.cookieconsent_button;
            this.ccService.getConfig().content.allow = data['cookie.allow'];
            this.ccService.getConfig().content.deny = data['cookie.deny'];
            this.ccService.getConfig().content.link = this.globalSettings.cookieconsent_link_name;
            this.ccService.getConfig().content.href = this.globalSettings.cookieconsent_link_target;
            this.ccService.getConfig().content.policy = data['cookie.policy'];
  
            this.ccService.destroy(); // remove previous cookie bar (with default messages)
            this.ccService.init(this.ccService.getConfig()); // update config with translated messages
          });
        }
      this.global.hideLoader()
      }
    }, (err) => { });

    window.addEventListener("beforeunload", (e) => {
      if (this.global.isLogin) {
        this.global.submitLoginHours();
      }
    });

    await this.global.getTermsData();
    this.termsLoaded = true;
    this.getLanguageList();
    // this.router.events.forEach(item => {
    //   if (item instanceof NavigationEnd) {
    //     const gtmTag = {
    //       event: 'page',
    //       pageName: item.url
    //     };
    //     this.gtmService.pushTag(gtmTag);
    //   }
    // });
    // dataLayer.push({'event':'submit-registration-form'});

  }

  setCookieConsent() { }

  ngOnDestroy() {
    // this.popupOpenSubscription.unsubscribe();
    // this.popupCloseSubscription.unsubscribe();
    // this.initializeSubscription.unsubscribe();
    // this.statusChangeSubscription.unsubscribe();
    // this.revokeChoiceSubscription.unsubscribe();
    // this.noCookieLawSubscription.unsubscribe();
  }

}
