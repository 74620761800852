import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { GlobalService } from "../../global.service";
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { EmailValidation } from "src/app/validator.service";

@Component({
  selector: "app-support",
  templateUrl: "./support.component.html",
  styleUrls: ["./support.component.scss"]
})
export class SupportComponent implements OnInit {
  languageId: any;
  supportArray: any = null;
  contentLoader:any=true;
  formData: any;
  formSubmitAttempt: boolean=false;
  constructor(private route: ActivatedRoute, public global: GlobalService,
    private formBuilder: FormBuilder,
    public location: Location,public router:Router,) {
    this.global.profileTab = 2
    this.route.paramMap.subscribe(params => {
      this.languageId = params.get("id")
        ? params.get("id")
        : this.global.selectLanguage
          ? this.global.selectLanguage
          : "5bd9ae3c9e254aecf7f031a9";
      console.log(params.get("id"));
    });

    this.setFormValue()
    
  }


  setFormValue(){
    this.formData = this.formBuilder.group({
      email: new FormControl(this.global.user.email, Validators.compose([Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,5}$")])),
      name: new FormControl("", Validators.required),
      news: new FormControl("", Validators.required),
    });
  }
  ngOnInit() {
    this.getList();
  }

  getList() {
    let body = {};
    body["slug"] = "support";
    body["language_id"] =  this.languageId?this.languageId:this.global.selectLanguage;
    body["device"] = "web";
    this.global.post(
      "getCmsPageData",
      JSON.stringify(body),
      data => {
        this.supportArray = data.data[0] ? data.data[0] : null;
        if(!this.supportArray || this.supportArray==null || this.supportArray.length==0){
          this.contentLoader=false
        }
        console.log(data);
        if( document.getElementById("cmspages_id")){
          document.getElementById("cmspages_id").innerHTML = this.supportArray['description'] ? this.supportArray['description'] : null;
        }
      },
      err => { },
      true
    );
  }

  goBack(){
    this.router.navigate(['setting'], { replaceUrl: true })
  }


  capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  }
  onClickSubmit(value) {
    this.formSubmitAttempt = true;
    if (!this.formData.valid) {
      return;
    } else {
      this.callSubmitApi(value);
    }
  }

  callSubmitApi(value:any) {
    let body = {};
    body["name"] = this.capitalize(value.name);
    body["email"] = value.email;
    body["news"] = value.news;
    body["user_id"] = this.global.user._id ? this.global.user._id :'';
    this.global.post(
      "sendnews",
      body,
      (data) => {
        if (data.success) {
          this.formSubmitAttempt = false;  
          this.formData.reset()  
          this.setFormValue();
          this.global.showToast("", this.global.termsArray[data.message]);
        } else {
          this.global.showDangerToast("", this.global.termsArray[data.message]);
        }
      },
      (err) => {
        this.global.showDangerToast("", err.message);
      },
      true
    );
  }

}
