import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router, NavigationEnd,ActivatedRoute } from "@angular/router";
import { GlobalService } from "../global.service";
import { NgxNavigationWithDataComponent } from "ngx-navigation-with-data";
import { Location } from "@angular/common";
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
// import { GoogleTagManagerService } from "angular-google-tag-manager";
declare var $: any;
declare var dataLayer:any;

@Component({
  selector: "app-otp-verify",
  templateUrl: "./otp-verify.component.html",
  styleUrls: ["./otp-verify.component.scss"],
})

export class OtpVerifyComponent implements OnInit {
  @ViewChild("NgOtpInputElement", { static: false}) ngOtpInput:any;
  id: any;
  // formData;
  otpError: any = false;
  input;
  otp:any="";
  email = null;
  new_email = null;
  lastScreen = null;
  slug = null;
  formSubmitAttempt = false;
  config = { allowNumbersOnly: true, length: 4, isPasswordInput: false, disableAutoFocus: false, placeholder: "", inputClass: "form-control", containerClass: "container-style", inputStyles: {} };
  public isBoolean: any = 3;
  public product_id: any;

  constructor(
    private route: Router,
    public global: GlobalService,
    private formBuilder: FormBuilder,
    public location: Location,
    // private gtmService: GoogleTagManagerService,
    private activateRoute: ActivatedRoute,
  ) {
    this.activateRoute.queryParams.subscribe((queryParams)=>{
      if(queryParams.verify && queryParams.verify == "true"){
        this.email = queryParams.email;
        this.new_email = queryParams.new_email;
        this.slug = queryParams.slug;
      }else{
        let user = localStorage.getItem(btoa("user")) ? atob(localStorage.getItem(btoa("user"))) : null;
        user = JSON.parse(user);
        if (user) {
          this.route.navigate(["user"], { replaceUrl: true });
        }
        if (this.global.routeParams && this.global.routeParams["email"] && this.global.routeParams["lastScreen"]) {
          this.email = this.global.routeParams["email"] ? this.global.routeParams["email"] : "";
          this.lastScreen = this.global.routeParams["lastScreen"] ? this.global.routeParams["lastScreen"] : "";
          this.isBoolean = this.global.routeParams['type'] && this.global.routeParams['type'] == 1 ? 1 : this.global.routeParams['type'] == 2  ? 2 : 3;
          this.product_id = this.global.routeParams['product_id'] ? this.global.routeParams['product_id'] : "";
        } else {
          this.route.navigate([""], { replaceUrl: true });
        }
      }
    })

    this.input = new Array(4);
  }

  ngOnInit() {

  }

  verifyOtp() {
    this.formSubmitAttempt = true;
    if (this.otp.length != 4) {
      if(this.otp.length == 0){
        this.otpError = "required";
      }else{
        this.otpError = "invalid";
      }
      return;
    } else {
      this.otpError = false;
      let body = {};
      body["otp"] = +this.otp;
      body["email"] = this.email;
      if(this.slug){
        body["slug"] = this.slug;
      }
      this.global.post("verifyOtp", body, (data) => {
        if (data.statuscode === 400) {
          this.formSubmitAttempt = false;
          this.ngOtpInput.setValue("");
          this.global.showDangerToast("", this.global.termsArray[data.message]);
        } else {
          if (data.success) {
            if(this.slug && this.slug=="new_email_verification"){
              this.global.showToast("", this.global.termsArray[data.message]);
              this.global.user = data.data;
              localStorage.setItem(
                btoa("user"),
                btoa(JSON.stringify(data.data))
              );
              this.route.navigate(['/profile']);
              return;
            }else{
              setTimeout(() => {
                dataLayer.push({'event':'registration-completed'});
                console.log("Event fired (registration-completed)");
              }, 3000);
            }
            // this.route.events.forEach(item => {
            //   if (item instanceof NavigationEnd) {
            //     const gtmTag = {
            //       event: 'registration-completed'
            //     };
            //     this.gtmService.pushTag(gtmTag);
            //   }
            // });
            this.global.selectLanguage = data.data.language_id;
            this.global.setCurrentUserInterval();
            localStorage.setItem(btoa("selectLanguage"), btoa(data.data.language_id));
            this.global.learningLanguage = data.data.learning_language_id;
            this.global.getTermsData();
            if (this.lastScreen === "registration") {
              this.global.loginTime = new Date();
              localStorage.setItem(btoa("user"), btoa(JSON.stringify(data.data)));
              localStorage.setItem("setupTime", this.global.setupTime + "");
              this.global.user = data.data;
              this.global.isLogin = true;
              this.global.AuthToken = data.data.token;
              localStorage.setItem(btoa("AuthToken"), btoa(data.data.token));
              this.global.showToast("", this.global.termsArray[data.message]);
              if(this.isBoolean == 1) {
                this.global.navigate(["account"], { product_id:this.product_id });
                return;
              }
              else if(this.isBoolean == 2){
                this.route.navigate(["choose-lesson"]);
                return;
              }
              if (data.data.first_time) {
                // for navigate to demo when user move to app first time
                localStorage.setItem(btoa("lesson_id"), btoa(data.data.lesson_id));
                this.global.max_read_slide = 0;
                this.global.lesson_id = data.data.lesson_id;
                this.global.navigate(["method-video"], { replaceUrl: true, lastRoute : "verifyOtp" });
              } else {
                this.route.navigate(["user"], { replaceUrl: true });
              }
            } else if (this.lastScreen === "forgot") {
              this.global.showToast("", this.global.termsArray[data.message]);
              this.global.navigate(["reset-password"], {
                email: data.data.email,
              });
            } else if (this.lastScreen === "fb") {
              this.global.loginTime = new Date();
              localStorage.setItem(
                btoa("user"),
                btoa(JSON.stringify(data.data))
              );
              localStorage.setItem("setupTime", this.global.setupTime + "");
              this.global.user = data.data;
              this.global.isLogin = true;
              localStorage.setItem(btoa("AuthToken"), btoa(data.data.token));
              this.global.AuthToken = data.data.token;
              if (data.data.first_time) {
                // for navigate to demo when user move to app first time

                localStorage.setItem(
                  btoa("lesson_id"),
                  btoa(data.data.lesson_id)
                );
                this.global.max_read_slide = 0;
                this.global.lesson_id = data.data.lesson_id;
                this.route.navigate(["lesson/" + "demo"]);
              } else {
                this.route.navigate(["user"]);
              }
            } else if (this.lastScreen === "google") {
              this.global.loginTime = new Date();
              localStorage.setItem(
                btoa("user"),
                btoa(JSON.stringify(data.data))
              );
              localStorage.setItem("setupTime", this.global.setupTime + "");
              this.global.user = data.data;
              this.global.isLogin = true;
              localStorage.setItem(btoa("AuthToken"), btoa(data.data.token));
              this.global.AuthToken = data.data.token;
              if (data.data.first_time) {
                // for navigate to demo when user move to app first time
                localStorage.setItem(
                  btoa("lesson_id"),
                  btoa(data.data.lesson_id)
                );
                this.global.max_read_slide = 0;
                this.global.lesson_id = data.data.lesson_id;
                this.route.navigate(["lesson/" + "demo"]);
              } else {
                this.route.navigate(["user"]);
              }
            } else if (this.lastScreen === "login") {
              this.global.loginTime = new Date();
              localStorage.setItem(
                btoa("user"),
                btoa(JSON.stringify(data.data))
              );
              localStorage.setItem("setupTime", this.global.setupTime + "");
              this.global.user = data.data;
              this.global.isLogin = true;
              localStorage.setItem(btoa("AuthToken"), btoa(data.data.token));
              this.global.AuthToken = data.data.token;
              if (data.data.first_time) {
                // for navigate to demo when user move to app first time
                localStorage.setItem(
                  btoa("lesson_id"),
                  btoa(data.data.lesson_id)
                );
                this.global.max_read_slide = 0;
                this.global.lesson_id = data.data.lesson_id;
                this.global.navigate(["method-video"], { replaceUrl: true, lastRoute : "verifyOtp", navigatefrom: "login" });
              } else {
                this.route.navigate(["user"]);
              }
            }
          } else {
            this.formSubmitAttempt = false;
            this.input = [];
            this.input = new Array(4);
            this.global.showDangerToast(
              "",
              this.global.termsArray[data.message]
            );
          }
        }
      }, (err) => {
        this.ngOtpInput.setValue("");
        this.global.showDangerToast("", err.message);
      },
      true );
    }
  }

  resendOtp() {
    this.formSubmitAttempt = false;
    let body = {};
    if(this.slug && this.slug == "new_email_verification"){
      body["slug"] = this.slug;
      body["email"] = this.new_email;
    }else{
      body["email"] = this.email;
    }
    this.global.post(
      "resendotp",
      body,
      (data) => {
        if (data.statuscode === 400) {
          this.global.showDangerToast(this.global.termsArray[data.message]);
        } else {
          if (data.success) {
            this.global.showToast("", this.global.termsArray[data.message]);
            this.input = [];
            this.input = new Array(4);
          } else {
            this.global.showDangerToast(
              "",
              this.global.termsArray[data.message]
            );
          }
        }
      },
      (err) => {
        this.global.showDangerToast("", err.message);
      },
      true
    );
  }

  onOtpChange(otp) {
    this.otp = otp;
    if(this.formSubmitAttempt){
      if(otp.length == 4){
        this.otpError = false;
      }else{
        if(otp.length == 0){
          this.otpError = "required";
        }else if(otp.length > 0 && otp.length < 4){
          this.otpError = "invalid";
        }
      }
    }
  }
  logout() {
    localStorage.removeItem(btoa("AuthToken"));
    localStorage.removeItem(btoa("routeParams"));
    this.global.routeParams = null;
    this.global.isLogin = false;
    this.global.AuthToken = null;
    this.signOut();
  }

  signOut() { 
    localStorage.removeItem(btoa("user"));
    this.global.lessonfamily_id = null;
    this.global.baselesson_id = null;
    this.global.lesson_id = null;
    this.global.lesson_title = null;
    this.global.max_read_slide = null;
    localStorage.removeItem(btoa("lessonfamily_id"));
    localStorage.removeItem(btoa("baselesson_id"));
    localStorage.removeItem(btoa("lesson_id"));
    localStorage.removeItem(btoa("lesson_title"));
    localStorage.removeItem(btoa("max_read_slide"));
    this.global.user = null;
    document.body.classList.remove("texture-bg-gry");
    this.route.navigate(["/login"], { replaceUrl: true });
  }
}
