<!-- <div class="inner-wraper no-padding-top">
    <section class="lesson-cover-sect">
      <div class="container">
        <div class="lektionen-cover-new thank-you-container account-title">
          <h4>{{ global.termsArray["send_concern"] }}</h4>
          <div class="account-title" style="padding-bottom: 0px" >
            <p >{{ global.termsArray["send_concern_description"] }}</p>
          </div>
      </div>
      </div>
    </section>
</div>
   -->
<div class="inner-wraper">
  <section class="lesson-cont-sect wrap-space">
    <div class="container">
      <div class="lektionen-cover-new">
        <article>
          <div class="setting-arrow inner-arrow">
            <a href="javascript:;" (click)="goBack()">
              <i class="ri-arrow-left-s-line"></i>
              <h6>{{ global.termsArray["lbl_go_back"] }}</h6>
            </a>
          </div>
          <div class="list-sentens text-center">
            <div class="in-list-setting">
              <div class="account-title">
                <h4>{{ this.global.termsArray["send_concern"] }}</h4>
              </div>
              <div class="send_concern_des text-white" >
                <p id="description" [innerHTML]="concernDescription"></p>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
</div>
