<div class="inner-wraper">
  <section class="abg-sect">
    <div class="container">
      <!-- <app-no-data-found *ngIf="!contentLoader"></app-no-data-found> -->
      <article>
        <h1>{{ contactArray?.title ? contactArray?.title : 'Kontakt und Support' }}</h1>
        <div class="profile-line-box login-input pr-md-3">
          <form class="form-horizontal" role="form" [formGroup]="formData"
            (ngSubmit)="onClickSubmit(formData.value)">
            <div class="form-group pb-4">
              <div class="icon_inner_bx">
                <label for="name">{{ global.termsArray['lbl_support_name'] ? global.termsArray['lbl_support_name'] : "Name" }}</label>
                <input type="text" class="form-control" id="name" [placeholder]="global.termsArray['lbl_support_name']" formControlName="name" style="text-transform: capitalize;" maxlength="55" autofocus />
               </div>
              <div *ngIf="formData.controls['name'].invalid && (formData.controls['name'].dirty || formData.controls['name'].touched || this.formSubmitAttempt) ">
                <p *ngIf="formData.controls['name'].errors.required" class="validation_err validation_err_color text-left">{{ global.termsArray["err_support_enter_name"] }}</p>
              </div>
            </div>


            <div class="form-group pb-4">
              <div class="icon_inner_bx">
                  <label for="email">E-Mail-Adresse</label>
                 <input type="email" class="form-control" id="email" [placeholder]="global.termsArray['lbl_email_address']"
                  formControlName="email" />
              </div>
              <div *ngIf="formData.controls['email'].invalid && (formData.controls['email'].dirty || formData.controls['email'].touched || this.formSubmitAttempt) ">
                <p *ngIf="formData.controls['email'].errors.required" class="validation_err validation_err_color text-left">{{ global.termsArray["msg_enter_email"] }}</p>
                <p *ngIf="formData.controls['email'].errors.pattern" class="validation_err validation_err_color text-left">{{ global.termsArray["err_valid_email"] }}</p>
              </div>
            </div>

            

            <div class="form-group pb-4">
              <div class="icon_inner_bx">
                <label for="news">{{ global.termsArray['lbl_support_news'] ? global.termsArray['lbl_support_news'] : "Nachricht" }}</label>
                <textarea class="form-control" id="news" [placeholder]="global.termsArray['lbl_support_news']" formControlName="news" autofocus rows="5"  ></textarea> 
              </div>
              <div *ngIf="formData.controls['news'].invalid && (formData.controls['news'].dirty || formData.controls['news'].touched || this.formSubmitAttempt) ">
                <p *ngIf="formData.controls['news'].errors.required" class="validation_err validation_err_color text-left">{{ global.termsArray["err_support_enter_news"] }}</p>
              </div>
            </div>

            
          </form>
        </div>
        <div class="bttn-blue">
          <button class="btn btn-blue mt-1" (click)="onClickSubmit(formData.value)">
            {{ global.termsArray["lbl_support_send"] }}
          </button>
          <br>
          </div>

          <p  style="padding-top: 10px;">{{ global.termsArray["support_text"] }}</p>
        <!-- <div id="cmspages_id" style="padding-top: 10px;"></div> -->
      </article>
    </div>
  </section>
</div>




<!-- <div class="inner-wraper">
  <section class="lesson-cont-sect wrap-space">
    <div class="container">
      <div class="lektionen-cover-new">
          <article>
            <div class="setting-arrow inner-arrow">
              <a href="javascript:;" (click)="goBack()">
                <i class="ri-arrow-left-s-line"></i>
                <h6>{{ global.termsArray["lbl_Settings"] }}</h6>
              </a>
            </div>
            
            <div class="list-sentens text-center">
              <div class="in-list-setting">
                <div class="account-title">
                  <h4>{{ this.global.termsArray["lbl_Support_new"] }}</h4>
                </div>

                <div class="profile-line-box login-input pr-md-3">
                  <form class="form-horizontal" role="form" [formGroup]="formData"
                    (ngSubmit)="onClickSubmit(formData.value)">
                    <div class="form-group pb-4">
                      <div class="icon_inner_bx">
                        <label for="name">{{ global.termsArray['lbl_support_name'] ? global.termsArray['lbl_support_name'] : "Name" }}</label>
                        <input type="text" class="form-control" id="name" [placeholder]="global.termsArray['lbl_support_name']" formControlName="name" style="text-transform: capitalize;" maxlength="55" autofocus />
                       </div>
                      <div *ngIf="formData.controls['name'].invalid && (formData.controls['name'].dirty || formData.controls['name'].touched || this.formSubmitAttempt) ">
                        <p *ngIf="formData.controls['name'].errors.required" class="validation_err validation_err_color text-left">{{ global.termsArray["err_support_enter_name"] }}</p>
                      </div>
                    </div>


                    <div class="form-group pb-4">
                      <div class="icon_inner_bx">
                          <label for="email">E-Mail-Adresse</label>
                         <input type="email" class="form-control" id="email" [placeholder]="global.termsArray['lbl_email_address']"
                          formControlName="email" />
                      </div>
                      <div *ngIf="formData.controls['email'].invalid && (formData.controls['email'].dirty || formData.controls['email'].touched || this.formSubmitAttempt) ">
                        <p *ngIf="formData.controls['email'].errors.required" class="validation_err validation_err_color text-left">{{ global.termsArray["msg_enter_email"] }}</p>
                        <p *ngIf="formData.controls['email'].errors.pattern" class="validation_err validation_err_color text-left">{{ global.termsArray["err_valid_email"] }}</p>
                      </div>
                    </div>
  
                    
  
                    <div class="form-group pb-4">
                      <div class="icon_inner_bx">
                        <label for="news">{{ global.termsArray['lbl_support_news'] ? global.termsArray['lbl_support_news'] : "Nachricht" }}</label>
                        <textarea class="form-control" id="news" [placeholder]="global.termsArray['lbl_support_news']" formControlName="news" autofocus rows="5"  ></textarea> 
                      </div>
                      <div *ngIf="formData.controls['news'].invalid && (formData.controls['news'].dirty || formData.controls['news'].touched || this.formSubmitAttempt) ">
                        <p *ngIf="formData.controls['news'].errors.required" class="validation_err validation_err_color text-left">{{ global.termsArray["err_support_enter_news"] }}</p>
                      </div>
                    </div>
  
                    
                  </form>
                </div>
                <div class="bttn-blue">
                  <button class="btn btn-blue" (click)="onClickSubmit(formData.value)">
                    {{ global.termsArray["lbl_support_send"] }}
                  </button>
                  <br>
                  </div>
                  <p class="text-white">{{ global.termsArray["support_text"] }}</p>
              </div>
            </div>
          </article>
      </div>
    </div>
  </section>
</div> -->