
<div class="inner-wraper">
    <section class="lesson-cover-sect">
      <div class="container">
        <div class="lektionen-cover-new">
          <div class="lekt-title" style="display: block !important;">
            <div class="d-flex">
              <a href="javascript:;" (click)="location.back()"><i class="ri-arrow-left-s-line"></i></a>
              <h2>{{ global.termsArray["lbl_learning_goals"] }}</h2>
            </div>
          </div>
          <div class="list-sentens-box p-2" infiniteScroll [scrollWindow]="false" [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="50" [immediateCheck]="true">
              <!-- Setting up Learning Goals 1 -->
              <ng-container *ngIf="!myGoals || editGoals ;else mygoals">
                <div class="body-wrap" *ngIf="!showSetGoals && !editGoals;else goals">
                  <div class="heading-main">
                    <h4>{{ global.termsArray["lbl_weekly_learning_goals"] }}</h4>
                    <p>{{ global.termsArray["lbl_learning_goals_description"] }}</p>
                  </div>
                  <div class="body-wrap-container">
                    <button _ngcontent-serverapp-c6="" class="btn btn-sm w-100 btn-with-icon" (click)="showSetGoals=true">{{ global.termsArray["lbl_set_weekly_goals"] }}<svg width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M43.974 1.3207C40.9688 0.458203 37.7883 0 34.5 0C15.4441 0 0 15.4441 0 34.5C0 53.5559 15.4441 69 34.5 69C53.5559 69 69 53.5559 69 34.5C69 31.2117 68.5418 28.0312 67.6793 25.026L64.6066 28.449C64.4584 28.6107 64.3236 28.759 64.1619 28.9072C64.4988 30.7266 64.674 32.5863 64.674 34.5C64.674 51.1705 51.157 64.6875 34.4865 64.6875C17.816 64.6875 4.3125 51.1705 4.3125 34.5C4.3125 17.8295 17.8295 4.3125 34.5 4.3125C36.4137 4.3125 38.2869 4.4877 40.0928 4.82461C40.241 4.67637 40.3893 4.52812 40.551 4.37988L43.974 1.3207ZM37.0066 13.0857C36.1846 12.9914 35.349 12.9375 34.5 12.9375C22.5867 12.9375 12.9375 22.5867 12.9375 34.5C12.9375 46.4133 22.5867 56.0625 34.5 56.0625C46.4133 56.0625 56.0625 46.4133 56.0625 34.5C56.0625 33.651 56.0086 32.8154 55.9143 31.9934C55.5504 31.9664 55.1865 31.926 54.8227 31.8721L51.4535 31.3061C51.6422 32.3438 51.75 33.4084 51.75 34.5C51.75 44.0279 44.0279 51.75 34.5 51.75C24.9721 51.75 17.25 44.0279 17.25 34.5C17.25 24.9721 24.9721 17.25 34.5 17.25C35.5916 17.25 36.6562 17.3578 37.6939 17.5465L37.1279 14.1773C37.0605 13.8135 37.0201 13.4496 37.0066 13.0857ZM46.0225 26.0367L55.51 27.6135C57.7066 27.9773 59.9303 27.1957 61.4127 25.5246L67.3289 18.8672C68.9057 17.1018 68.1105 14.2986 65.8465 13.6248L57.801 11.2125L55.3752 3.15352C54.7014 0.889453 51.8982 0.0943359 50.1328 1.67109L43.4754 7.5873C41.8178 9.06973 41.0227 11.2934 41.3865 13.49L42.9633 22.9775L32.9637 32.9771C32.1281 33.8127 32.1281 35.1873 32.9637 36.0229C33.7992 36.8584 35.1738 36.8584 36.0094 36.0229L46.009 26.0232L46.0225 26.0367ZM49.7689 22.2902L56.6824 15.3768L62.9895 17.2635L58.1918 22.6541C57.6932 23.2066 56.952 23.4762 56.2242 23.3549L49.7689 22.2768V22.2902ZM53.6232 12.3311L46.7098 19.2445L45.6316 12.7893C45.5104 12.0615 45.7664 11.3203 46.3324 10.8217L51.723 6.02402L53.6098 12.3311H53.6232Z" fill="white"/>
                      </svg>
                      </button>
                  </div>
                </div>

                <!-- Setting up Learning Goals 2 -->
                <ng-template #goals>
                  <div class="body-wrap">
                    <div class="heading-main">
                      <h4>{{ global.termsArray["lbl_set_days"] }}</h4>
                      
                    </div>
                    <div class="body-wrap-container">
                      <div class="day-list mb-5">
                        <ul>
                          <li *ngFor="let day of daysOfWeek; let i = index" (click)="toggleDay(i + 1)" [ngClass]="{'selected': isDaySelected(i + 1)}">
                            <div class="day-list-icon">
                              <i class="ri-checkbox-circle-fill" *ngIf="isDaySelected(i + 1)"></i>
                              <i class="ri-checkbox-blank-circle-line" *ngIf="!isDaySelected(i + 1)"></i>
                            </div>
                            <span>{{ getDayLabel(i + 1) }}</span>
                          </li>
                        </ul>
                      </div>
                      <div class="heading-main">
                        <h4>{{ global.termsArray["lbl_set_time"] }}</h4>
                        <p>{{ global.termsArray["lbl_recommended_min"] }}</p>
                      </div>
                      <div class="date-plus-minus text-center mb-5">
                        <div class="d-flex justify-content-center align-items-center">
                          <button (click)="decreaseDuration()"><i class="ti ti-circle-minus"></i></button>
                          <span>{{ durationMinutes }}</span>
                          <button (click)="increaseDuration()"><i class="ti ti-circle-plus"></i></button>
                        </div>
                        <p>Min./{{global.termsArray["lbl_learnability"]}}</p>
                      </div>
                      <button (click)="saveGoals()" class="btn btn-sm w-100">{{ global.termsArray["lbl_save_goal"] }} </button>
                    </div>
                  </div>
                </ng-template>
              </ng-container>

              <ng-template #mygoals>
                <div class="body-wrap">
                  <div class="heading-main">
                    <h4>{{ global.termsArray["lbl_weekly_learning_goals"] }} </h4>
                    <p>{{ global.termsArray["lbl_current_goals"] }} </p>
                  </div>
                  <div class="body-wrap-container">
                    <div class="day-list mb-5">
                      <ul>
                        <li *ngFor="let day of daysOfWeek; let i = index" [ngClass]="{'selected': isDaySelected(i + 1)}">
                          <div class="day-list-icon">
                            <i class="ri-checkbox-circle-fill" *ngIf="isDaySelected(i + 1)"></i>
                            <i class="ri-checkbox-blank-circle-line" *ngIf="!isDaySelected(i + 1)"></i>
                          </div>
                          <span>{{ getDayLabel(i + 1) }}</span>
                        </li>
                      </ul>
                    </div>
                    <div class="heading-main mb-4 text-center">
                      <p class="mb-0">Dauer</p>
                      <h4>{{ durationMinutes }} Min./Tag</h4>
                    </div>
                    <div class="two-links d-flex justify-content-center align-items-center my-4">
                      <a href="#" data-toggle="modal" data-target="#exampleModal1"><img src="../../../assets/images/chart.png"> {{global.termsArray["lbl_my_preformance"]}}</a>
                      <a href="#" data-toggle="modal" data-target="#deleteGoalModal"> {{global.termsArray["lbl_delete_learning_objectives"]}}</a>
                    </div>
                    
                    <button (click)="editGoals=true;" class="btn btn-sm w-100 btn-with-icon cp">{{global.termsArray["lbl_edit_learning_objectives"]}}<svg width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M43.974 1.3207C40.9688 0.458203 37.7883 0 34.5 0C15.4441 0 0 15.4441 0 34.5C0 53.5559 15.4441 69 34.5 69C53.5559 69 69 53.5559 69 34.5C69 31.2117 68.5418 28.0312 67.6793 25.026L64.6066 28.449C64.4584 28.6107 64.3236 28.759 64.1619 28.9072C64.4988 30.7266 64.674 32.5863 64.674 34.5C64.674 51.1705 51.157 64.6875 34.4865 64.6875C17.816 64.6875 4.3125 51.1705 4.3125 34.5C4.3125 17.8295 17.8295 4.3125 34.5 4.3125C36.4137 4.3125 38.2869 4.4877 40.0928 4.82461C40.241 4.67637 40.3893 4.52812 40.551 4.37988L43.974 1.3207ZM37.0066 13.0857C36.1846 12.9914 35.349 12.9375 34.5 12.9375C22.5867 12.9375 12.9375 22.5867 12.9375 34.5C12.9375 46.4133 22.5867 56.0625 34.5 56.0625C46.4133 56.0625 56.0625 46.4133 56.0625 34.5C56.0625 33.651 56.0086 32.8154 55.9143 31.9934C55.5504 31.9664 55.1865 31.926 54.8227 31.8721L51.4535 31.3061C51.6422 32.3438 51.75 33.4084 51.75 34.5C51.75 44.0279 44.0279 51.75 34.5 51.75C24.9721 51.75 17.25 44.0279 17.25 34.5C17.25 24.9721 24.9721 17.25 34.5 17.25C35.5916 17.25 36.6562 17.3578 37.6939 17.5465L37.1279 14.1773C37.0605 13.8135 37.0201 13.4496 37.0066 13.0857ZM46.0225 26.0367L55.51 27.6135C57.7066 27.9773 59.9303 27.1957 61.4127 25.5246L67.3289 18.8672C68.9057 17.1018 68.1105 14.2986 65.8465 13.6248L57.801 11.2125L55.3752 3.15352C54.7014 0.889453 51.8982 0.0943359 50.1328 1.67109L43.4754 7.5873C41.8178 9.06973 41.0227 11.2934 41.3865 13.49L42.9633 22.9775L32.9637 32.9771C32.1281 33.8127 32.1281 35.1873 32.9637 36.0229C33.7992 36.8584 35.1738 36.8584 36.0094 36.0229L46.009 26.0232L46.0225 26.0367ZM49.7689 22.2902L56.6824 15.3768L62.9895 17.2635L58.1918 22.6541C57.6932 23.2066 56.952 23.4762 56.2242 23.3549L49.7689 22.2768V22.2902ZM53.6232 12.3311L46.7098 19.2445L45.6316 12.7893C45.5104 12.0615 45.7664 11.3203 46.3324 10.8217L51.723 6.02402L53.6098 12.3311H53.6232Z" fill="white"/>
                      </svg>
                    </button>


                    <!-- <div class="modal-3">
                      <button data-toggle="modal" data-target="#modal1">Modal 1</button>
                      <button data-toggle="modal" data-target="#modal2">Modal 2</button>
                      <button data-toggle="modal" data-target="#modal3">Modal 3</button>
                    </div> -->
                  </div>
                </div>
              </ng-template>
            </div>
        </div>
        <app-app></app-app>
      </div>
    </section>
  </div>

<!-- Modal -->
<div class="modal modal-radius fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="modal-heading text-center">
          <img src="../../../assets/images/char-line.png" class="d-block m-auto mb-3">
          <h4> {{global.termsArray["lbl_my_preformance"]}}</h4>
          <p>{{global.termsArray["lbl_performance_description"]}}</p>
        </div>

        <div class="lesson-time-set text-center">
          <div class="lesson-time-row mb-4">
            <span>{{global.termsArray["lbl_lessons_learned"]}}</span>
            <div class="number-abs " [ngClass]="achievedDays>=selectedDays.length ? 'text-green' :'' ">
              {{myGoals ? achievedDays :'N/A'}}<div class="number-abs-text">/{{myGoals ?selectedDays.length:'N/A'}}</div>
            </div>
          </div>
          <div class="lesson-time-row mb-4" >
            <span>{{global.termsArray["lbl_minutes"]}}</span>
            <div class="number-abs"[ngClass]="achievedMinutes>=(selectedDays.length*durationMinutes) ? 'text-green' :'' ">
              {{myGoals ? achievedMinutes :'N/A'}}<div class="number-abs-text">/{{myGoals ? (selectedDays.length*durationMinutes) : 'N/A' }}</div>
            </div>
            <p class="mt-4">{{(achievedDays>=selectedDays.length && achievedMinutes>=(selectedDays.length*durationMinutes)) ? global.termsArray["lbl_did_it"] : global.termsArray["lbl_keep_up"]}}</p>
            <!-- lbl_did_it -->
          </div>
        </div>

        <div class="text-center">
          <button type="button" class="close close-btn-border" data-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i> {{global.termsArray["lbl_close"]}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="deleteGoalModal" role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog big-model-dialog-lesson">
    <div class="modal-content" style="background-color: white">
      <div class="modal-body" style="font-size: 20px; font-family: Akkurat-Bold; margin: 0; color: #4782c5; ">
        {{ this.global.termsArray["lbl_delete_confirm_goal_msg"] }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" style="font-size: 15px; font-family: Akkurat-Bold" data-dismiss="modal" data-toggle="modal" data-target="#deleteGoalModal">
          {{ global.termsArray["lbl_no"]}}
        </button>
        <button type="button" class="btn btn-primary" style="font-size: 15px; font-family: Akkurat-Bold" data-dismiss="modal" (click)="deleteGoal()" data-target="#deleteGoalModal">
          {{ global.termsArray["lbl_yes"]}}
        </button>
      </div>
    </div>
  </div>
</div>