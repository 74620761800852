<div class="inner-wraper price-sec">

  <section class="sub-pricing-sec">
    <div class="container">
      <div class="row lektionen–ohne">
        <div class="col-md-7">
          <h3>{{global.termsArray["lbl_subscription_pricing"]}}</h3>
          <p id="subscription_pricing_texts"></p>
        </div>
        <div id="subscription_pricing_points_text" class="col-md-5"></div>
      </div>
    </div>
    <div class="container-full abonnement-kasten-list">
      <div class="row">
        <div class="col-md-3" *ngIf="isShowPriceBox">
          <div class="abonnement-kasten">
            <div class="font-weight-bold">
              <h5>Gratis-Zugang</h5>
            </div>
            <figcaption>
              <div class="ht-figcapt">
                <h6>Gratis</h6>
                <!-- <p>Zugang zu einer Auswahl an Gratislektionen in unterschiedlichen Schwierigkeitsgraden</p> -->
                <p><i class="fas fa-check-circle"></i> Zugang zu Testlektionen</p>
                <p><i class="fas fa-check-circle"></i> Kostenlos für immer</p>
                <p><i class="fas fa-check-circle"></i> Alle 8 Sprachen inklusive</p>
              </div>
              <div class="btn-dwn01">
                  <div class="custom-control custom-checkbox price-check">
                    <input type="checkbox" class="custom-control-input" id="customCheck1" (click)="cardClick(null, 1)"
                      required>
                    <label class="custom-control-label" for="customCheck1">Ich akzeptiere die <a
                        routerLink="/term-condition" target="_blank" href="javascript:;">AGBs</a></label>
                  </div>
                  <span class="text-danger" id="error-free-card"></span>
              
                  <a class="btn" href="javascript:void(0);" (click)="choosePlan(null, 1)">Auswählen</a>
              </div>
            </figcaption>
          </div>
        </div>
        <div class="col-md-3" *ngFor="let item of subscriptionList; let i = index">
          <div class="abonnement-kasten">
            <!-- <div class="you-save" *ngIf="item.validity == '6'">
              <h6>{{ global.termsArray['lbl_safe'] ? global.termsArray['lbl_safe'] : 'Sie sparen' }}<br>
                € {{ item.subscription_saving }}.</h6>
            </div> -->
            <div class="you-save" *ngIf="item.validity == '6'">
              <ng-container *ngIf="item.subscription_saving_text; else subscription6template">
                <h6>{{ item.subscription_saving_text }}</h6>
              </ng-container>
              <ng-template #subscription6template>
                <h6>{{ global.termsArray['lbl_safe'] ? global.termsArray['lbl_safe'] : 'Sie sparen' }}<br>
                  € {{ item.subscription_saving }}.</h6>
              </ng-template>
            </div>
            <div class="you-save" *ngIf="item.validity == '12'">
              <ng-container *ngIf="item.subscription_saving_text; else subscription6template">
                <h6>{{ item.subscription_saving_text }}</h6>
              </ng-container>
              <ng-template #subscription6template>
                <h6>{{ global.termsArray['lbl_safe'] ? global.termsArray['lbl_safe'] : 'Sie sparen' }}<br>
                  € {{ item.subscription_saving }}.</h6>
              </ng-template>
            </div>

            <div class="you-save" *ngIf="item.validity == '240'">
              <ng-container *ngIf="item.subscription_saving_text; else subscription6template">
                <h6>{{ item.subscription_saving_text }}</h6>
              </ng-container>
              <ng-template #subscription6template>
                <h6>{{ global.termsArray['lbl_access_forever'] ? global.termsArray['lbl_access_forever'] : 'Zugriff für immer' }}<br></h6>
              </ng-template>
            </div>
            <!-- <div class="you-save" *ngIf="item.validity == '240'">
              <h6>{{ global.termsArray['lbl_access_forever'] ? global.termsArray['lbl_access_forever'] : 'Zugriff für immer' }}<br></h6>
            </div> -->
            <div class="subscrip-left">
              <ng-container *ngIf="item.name != 'Unbegrenzt'">
                <h5>{{ item.validity ? item.validity : "N/A" }}-Monats-Abo</h5>
              </ng-container>
              <ng-container *ngIf="item.name == 'Unbegrenzt'">
                <h5>Lifetime-Zugang</h5>
              </ng-container>
            </div>
            <figcaption>
              <div class="ht-figcapt">
                <!-- <h6>€ {{ item.price * 1 }} {{ item.name != 'Unbegrenzt' ? "pro Monat" : "einmalig"}}</h6> -->
                <!-- <p><i class="fas fa-check-circle"></i> Bezahlung: Kreditkarte</p> -->
                <h6 *ngIf="item?.validity!='0'" [ngStyle]="item?.name == 'Unbegrenzt' ? {'margin-bottom': '20px'} : {'margin-bottom': '0px'}" >
                  {{ item?.total | currency: "EUR":"symbol":"1.2-2" }}
                  <ng-container *ngIf="item?.name != 'Unbegrenzt'; else unlimitedCaseHeading">
                    {{ global.termsArray["lbl_all"]+' '+item?.validity+' '+global.termsArray["lbl_subs_month"] }}
                  </ng-container>
                  <ng-template #unlimitedCaseHeading>
                    {{global.termsArray["lbl_unique"]}}
                  </ng-template>
                </h6>
                <ng-container *ngIf="item?.name != 'Unbegrenzt'">
                  <p class="mt-2" style="font-size: 13px;">
                    {{ item?.price | currency: "EUR":"symbol":"1.2-2" }}
                    {{ global.termsArray["lbl_per_month"] }}
                  </p>
                </ng-container>
                <div class="price_description" [innerHtml]="item?.description"></div>
                <!-- <p *ngIf="item.validity <= 12">Preis total € {{ item.total }}</p> -->
              </div>
              <div class="btn-dwn01">
                  <div class="custom-control custom-checkbox price-check">
                    <input type="checkbox" class="custom-control-input" id="customCheck2-{{item._id}}" (click)="cardClick(item._id)">
                    <label class="custom-control-label" for="customCheck2-{{item._id}}">{{global.termsArray['msg_privacy_policy_web']}} 
                      <a routerLink="/term-condition" target="_blank" href="javascript:;">{{global.termsArray['msg_privacy_policy_link']}}</a>
                    </label>
                  </div>
                  <span class="text-danger" id="error_{{item._id}}"></span>
              
                  <a class="btn " href="javascript:;" (click)="choosePlan(item._id) ">{{global.termsArray['lbl_choose']}}</a>
              </div>
            </figcaption>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row lernen-sie-text">
        <div class="col-md-6">
          <h4 id="below_price_card_left_text"></h4>
        </div>
        <div class="col-md-6">
          <p id="below_price_card_right_text"></p>
        </div>
      </div>
    </div>
  </section>

  <section class="learning-guarantee-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="guarantee-texts">
            <div class="main-title">
              <h2>{{ global.termsArray["lbl_method_learning_ guarantee"]}} *</h2>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="guarantee-texts">
            <div id="left_learning_guarantee_texts" class="guarantee-dote-here"></div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="graf-cover">
            <img src="assets/images/graf-method.png">
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="wieviele-sec">
    <div class="container">
      <div class="sprachen-texts" id="languages_human_speak_text"></div>
    </div>
  </section>

  <section class="inner_faq_sec">
    <div class="faq_list-box">
      <div class="container">
        <h2>FAQ</h2>
        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="false">
          <div class="panel panel-default" *ngFor="let faq of priceContent?.faqs; let i = index">
            <div class="panel-heading panel-heading2" role="tab" id="heading{{ i }}"
              (click)="generateFaq('collapses' + i, faq.answer)">
              <h4 class="panel-title">
                <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapse{{ i }}"
                  aria-expanded="false" attr.aria-controls="collapse{{ i }}">
                  {{ faq.question }}
                </a>
              </h4>
            </div>
            <div id="collapse{{ i }}" class="panel-collapse collapse in" role="tabpanel"
              attr.aria-labelledby="heading{{ i }}" data-parent="#accordion">
              <div class="panel-body">
                <div id="collapses{{ i }}"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</div>

<a [hidden]="true" id="box-pre" class="carousel-control-prev" href="#carouselExampleIndicators2" role="button"
  data-slide="prev">
  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
  <span class="sr-only">Previous</span>
</a>
<a [hidden]="true" id="box-next" class="carousel-control-next" href="#carouselExampleIndicators2" role="button"
  data-slide="next">
  <span class="carousel-control-next-icon" aria-hidden="true"></span>
  <span class="sr-only">Next</span>
</a>
<div class="video">
  <div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog bd-example-modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <button (click)="closeModal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body" style>
          <iframe id="test" width="100%" height="400px">
          </iframe>
        </div>
      </div>
    </div>
  </div>
</div>