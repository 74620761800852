<div class="inner-wraper login-ppd verify-bg">
  <section class="abg-form-sect">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1
            style="
              color: #fff;
              margin-bottom: 20px;
              font-family: 'ACaslonPro-Bold';
            "
          >
            {{ global.termsArray["lbl_otp"] }}
          </h1>
          <div>
            <p
              style="
                font-family: Akkurat-Bold;
                color: #fff;
                font-size: 22px;
                margin-right: 5px;
              "
            >

              {{ global.termsArray["lbl_otp_subtitle"] }}
              <span
                style="
                  font-family: Akkurat-Bold;
                  color: #fff;
                  text-decoration: underline;
                  font-size: 16px;
                "
              >
                {{ new_email ? new_email : email }}</span
              >
            </p>
          </div>
          <div class="row">
            <div class="col-md-5">
              <form (ngSubmit)="verifyOtp()">
                <div class="form-group pb-3">
                  <ng-otp-input  (onInputChange)="onOtpChange($event)" #NgOtpInputElement [config]="{length:4,allowNumbersOnly:true}"></ng-otp-input>
                  <!-- <input
                    type="email"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    minlength="4"
                    maxlength="4"
                    formControlName="otp"
                    [placeholder]="global.termsArray['lbl_enter_otp']"
                  /> -->
                  <div *ngIf="otpError">
                    <div *ngIf="otpError == 'required'" class="validation_err validation_err_color">
                      {{ global.termsArray["msg_enter_otp"] }}
                    </div>
                    <div *ngIf="otpError == 'invalid'" class="validation_err validation_err_color">
                      {{ global.termsArray["msg_enter_valid_otp"] }}
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn mb-3" type="submit">
                  {{ global.termsArray["lbl_Submit"] }}
                </button>
              </form>
              <a style="color: white; margin: 30px;" (click)="resendOtp()"
                ><u style="cursor: pointer;">{{
                  global.termsArray["lbl_resend"]
                }}</u></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
