
<div class="inner-wraper lesson-pdd-sect">
    <section class="lesson-cover-sect lesson-cover-sect-100 new_chgs02">
      <div class="container">
        <div class="row">
            <div class="col-12 pt-5">
                <h1>Why you cancelled your subscription?</h1>
            </div>
            <div class="col-12 mt-5">
                <form [formGroup]="feedbackForm" (ngSubmit)="submitFeedback()">
                    <div class="form-group">
                        <label for="exampleFormControlTextarea1">Reason<span class="required">*</span></label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" formControlName="reason"></textarea>
                        <span *ngIf="(feedbackForm.controls.reason.touched || submitted) && feedbackForm.controls.reason.invalid" class="validation_err validation_err_color">
                            {{global.termsArray['msg_field_required']}}</span>
                    </div>
                    <div class="form-group">
                        <label for="files">Attach screenshots if possible (Optional)</label>
                        <div class="input-group mb-1">
                            <div class="custom-file">
                                <input type="file" class="custom-file-input" id="files" multiple="true" formControlName="screenshots" (change)="showFiles($event)">
                                <label class="custom-file-label" for="files">Choose file</label>
                            </div>
                        </div>
                        <span *ngIf="invalidImageType" class="validation_err validation_err_color">Invalid file type.</span>
                        <div class="imgPreview mt-2" id="preview">
                            <!-- <img *ngFor="let file of preview; let i=index;" [id]="'previewfile'+i" [src]="readFile(file,'previewfile'+i)" alt=""> -->
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary mt-5">Submit</button>
                </form>
            </div>
        </div>
      </div>
    </section>
  </div>
  
