export const fileContent = `<div  class="inner-wraper home-sec">
  <div class="slider-banner-in"id="tabName2" style="background: url('/assets/images/banner-image.webp');  margin: auto !important;">
    <div class="banner-container-full">
      <div class="banner-title">
        <span><h1>Your language stay at home – in just 10 minutes a day</h1></span>
      </div>
    </div>
  </div>
  <section class="select-language-sec">
    <div class="container">
      <div class="language-list">
        <div class="list-bg">
          <div class="list-bg-title">
            <h2>Try it for free</h2>
            <h5> No credit card required</h5>
          </div>
        </div>
        <ul>
          <li>
            <a href="javascript:;"  class="dropdown-item active" >English</a>
          </li>
          <li>
            <a href="javascript:;"class="dropdown-item">French</a>
          </li>
          <li>
            <a href="javascript:;"class="dropdown-item">Italian</a>
          </li>
          <li>
            <a href="javascript:;"class="dropdown-item">Spanish</a>
          </li>
          <li>
            <a href="javascript:;"class="dropdown-item">Russian</a>
          </li>
          <li>
            <a href="javascript:;"class="dropdown-item">Chinese</a>
          </li>
          <li>
            <a href="javascript:;"class="dropdown-item">Portuguese</a>
          </li>
          <li>
            <a href="javascript:;"class="dropdown-item">Dutch</a>
          </li>
          <li>
            <a href="javascript:;"class="dropdown-item">Hausa</a>
          </li>
        </ul>
      </div>
    </div>
  </section>
  </div>
  `

