import { GlobalService } from './../../global.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as moment from "moment";
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { EnvService } from 'src/app/env.service';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.sass']
})
export class TransactionHistoryComponent implements OnInit {
  transactionsData:any = [];
  pagination:any = [];

  constructor(
    private route: Router,
    public global: GlobalService,
    private ngxService: NgxUiLoaderService,
    public envservice: EnvService,
  ) { }

  ngOnInit() {
    this.getTransactionHistory(1);
    this.global.loader = false;
  }

  /** This function is used to get transaction history */
  getTransactionHistory(currentPage:number){
    this.global.get("getTransactionHistory?current_page="+currentPage, (response:any) => {
      if (response.success) {
        this.pagination=[];
        this.transactionsData = response.data;
        for (let i = 0; i < this.transactionsData.totalPages; i++) {
          this.pagination.push(i+1);
        }
      } else {
        this.global.showDangerToast("Error", this.global.languageArray[response.message]);
      }
    }, (err:any) => {
      this.global.loader = false;
      this.global.showDangerToast("Error", err.message);
    }, true);
  }

  /** This function is used to go back history */
  goBack() {
    this.route.navigate(["setting"], { replaceUrl: true });
  }

  /** This function is used to paginate transaction history */
  paginate(elem:any,type:string) {
    if(type == 'prev'){
      if(!elem.classList.contains('disabled')){
        this.getTransactionHistory(this.transactionsData.currentPage-1);
      }
    }
    if(type == 'next'){
      if(!elem.classList.contains('disabled')){
        this.getTransactionHistory(this.transactionsData.currentPage+1);
      }
    }
    if(type == 'page'){
      if(!elem.classList.contains('disabled')){
        this.getTransactionHistory(Number(elem.innerText));
      }
    }
  }
  /** This function is used to format date */
  transform(date: any, args?: any): any {
    return moment(moment(date)).format("DD.MM.YYYY");
  }

  getAmount(price:any){
		let newPrice:any = Number(price);
		if(String(price).indexOf('.') < 0){
			newPrice = (Number(price)/100).toFixed(2);
		}
		return newPrice;
	}
  getDate(dateString:string, showTime:boolean=true){
    let newStrDate = "";
    let separator = ".";
    const date = new Date(dateString);
    /**setting date */
    if(String(date.getDate()).length > 1){
        newStrDate += `${date.getDate()}${separator}`;
    }else{
        newStrDate += `0${date.getDate()}${separator}`;
    }
    /**setting month */
    if(String(date.getMonth()).length > 1){
        newStrDate += `${date.getMonth()+1}${separator}`;
    }else{
        newStrDate += `0${date.getMonth()+1}${separator}`;
    }
    /**setting year */
    newStrDate += `${date.getFullYear()}`;

    /**Configuring time view */
    if(showTime){
        const hour = date.getHours() == 0 ? 12 : (date.getHours() > 12 ? date.getHours() - 12 : date.getHours());
        const min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
        const secs = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        const ampm = date.getHours() < 12 ? 'AM' : 'PM';
        newStrDate += ` ${hour}:${min}:${secs} ${ampm}`;
    }
		return newStrDate;
	}

  getInvoiceUrl(chargeId){
    this.global.get("getInvoiceUrl?charge_id="+chargeId, (response:any) => {
      if (response.success) {
        window.open(response.data.invoice_url, '_blank')
      } else {
        this.global.showDangerToast("Error", this.global.languageArray[response.message]);
      }
    }
    , (err:any) => {
      this.global.loader = false;
      this.global.showDangerToast("Error", err.message);
    }, true);
  }
}
