<div class="inner-wraper">
  <section class="lesson-cont-sect">
    <div class="container">
      <article>
        <!-- <span><h6>Lesson 5.3</h6></span> -->
        <div class="list-sentens text-center">
          <div class="in-list-leson">
            <h4>{{ global.termsArray["lbl_choose_lesson"] }}</h4>

            <div class="list-lesson-rrow">
              <div class="point-box">
                <a>
                  <h6>{{ global.termsArray["lbl_free_demo"] }}</h6>
                  <img src="assets/images/check-white.svg" alt="check white image" />
                </a>
              </div>
              <div class="point-box">
                <a>
                  <h6>Lesson 1</h6>
                  <img src="assets/images/check-white.svg" alt="check white image" />
                </a>
              </div>
              <div class="point-box">
                <a>
                  <h6>Lesson 2</h6>
                  <img src="assets/images/check-white.svg" alt="check white image" />
                </a>
              </div>
              <div class="read-lesson">
                <a>
                  <h6>Lesson 3</h6>
                  <div class="progress">
                    <div class="progress-bar" role="progressbar" style="width: 45%;" aria-valuenow="25"
                      aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </a>
              </div>
              <div class="read-lesson">
                <a href="javascript:;">
                  <h6>Lesson 4</h6>
                  <div class="progress">
                    <div class="progress-bar" role="progressbar" style="width: 25%;" aria-valuenow="25"
                      aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </a>
              </div>
              <div class="read-lesson">
                <a href="javascript:;">
                  <h6>Lesson 5</h6>
                  <div class="progress">
                    <div class="progress-bar" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0"
                      aria-valuemax="100"></div>
                  </div>
                </a>
              </div>
              <div class="read-lesson">
                <a href="javascript:;">
                  <h6>Lesson 6</h6>
                  <div class="progress">
                    <div class="progress-bar" role="progressbar" style="width: 45%;" aria-valuenow="25"
                      aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </article>
      <app-app></app-app>
    </div>
  </section>
</div>