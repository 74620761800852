// export const environment = {
//   production: false,
//   apiUrl: "http://172.16.11.183:8081/api/webservice/",
//   wesiteUrl: "http://172.16.11.183:4200/",
//   cookieDomain: "172.16.11.183",
//   gtm_id:"GTM-M9KX7XTX",
//   version: "0.0.0",
//   setupTime: new Date().getTime(),
// };
export const environment = {
  production: true,
  apiUrl: "https://birlingo.de:8081/api/webservice/",
  cookieDomain: "birlingo.de",
  wesiteUrl: "https://birlingo.de:8081/", 
  gtm_id:"GTM-M9KX7XTX",
  version: "0.0.0",
  setupTime: new Date().getTime()
};