import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/global.service';
import { Location } from "@angular/common";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-set-learning-goal',
  templateUrl: './set-learning-goal.component.html',
  styleUrls: ['./set-learning-goal.component.scss']
})
export class SetLearningGoalComponent implements OnInit {

  alreadyHasGoals: Boolean = false;
  daysOfWeek: Number[] = [1, 2, 3, 4, 5, 6, 7];
  selectedDays: Number[] = [];
  durationMinutes: number = 10;
  loader = false;
  myGoals: any = null;
  showSetGoals: boolean = false;
  editGoals: boolean = false;
  achievedDays: Number = 0;
  achievedMinutes: number = 0;
  constructor(public location: Location, private fb: FormBuilder, public global: GlobalService) { }

  ngOnInit() {
    this.global.loader = true;
    this.getGoals();
  }

  getDayLabel(day: number): string {
    switch (day) {
      case 1: return 'Mo.';
      case 2: return 'Di.';
      case 3: return 'Mi.';
      case 4: return 'Do.';
      case 5: return 'Fr.';
      case 6: return 'Sa.';
      case 7: return 'So.';
      default: return '';
    }
  }
  setShowGoals() {
    this.alreadyHasGoals = true;
  }

  getGoals() {
    this.global.get("goals", (data) => {
      if (data.success) {
        this.myGoals = data.data;
        this.selectedDays = this.myGoals.daysOfWeek;
        this.durationMinutes = this.myGoals.durationMinutes;
        this.alreadyHasGoals = true
        this.achievedMinutes = this.myGoals.totalWeekTime ? Math.round(this.myGoals.totalWeekTime/60) : 0
        this.achievedDays = this.myGoals.day_completed ? this.myGoals.day_completed : 0
      } else {
        this.myGoals =null;
        this.selectedDays = [];
        this.durationMinutes =10;
        this.alreadyHasGoals = false
        // this.global.showDangerToast(this.global.termsArray[data.message]);
      }
    }, (err) => {
      this.global.showDangerToast(err.message);
    }, true);
  }

  toggleDay(day: number): void {
    const index = this.selectedDays.indexOf(day);
    if (index === -1) {
      this.selectedDays.push(day);
    } else {
      this.selectedDays.splice(index, 1);
    }
  }

  isDaySelected(day: number): boolean {
    return this.selectedDays.includes(day);
  }

  increaseDuration(): void {
    this.durationMinutes += 1;
  }

  decreaseDuration(): void {
    if (this.durationMinutes > 5) {
      this.durationMinutes -= 1;
    }
  }

  saveGoals(): void {
    console.log("this.selectedDays",this.selectedDays,this.global.termsArray['err_select_week_day'])
    if(this.selectedDays && this.selectedDays.length==0){
      return this.global.showDangerToast("",this.global.termsArray['err_select_week_day']);
    }
    const goalsData = {
      daysOfWeek: this.selectedDays,
      durationMinutes: this.durationMinutes
    };
    this.global.post("add-update-goal", JSON.stringify(goalsData), (data) => {
      if (data.success) {
        this.global.showToast("", this.global.termsArray[data.message]);
        this.editGoals = false;
        this.getGoals();
      } else {
        this.global.showDangerToast("",this.global.termsArray[data.message]);
        this.loader = true;
      }
    }, (err) => {
      this.global.showDangerToast("",err.message);
    }, true);
  }


  deleteGoal() {
    this.global.get("delete-goal", (data) => {
      if (data.success) {
        this.global.showToast("", this.global.termsArray[data.message]);
        this.getGoals();
        this.showSetGoals=false;
        this.editGoals=false;
      } else {
        this.global.showDangerToast("", this.global.termsArray[data.message]);
      }
    },
      (err: any) => {
        this.global.showDangerToast("", err.message);
      }, true);
  }

}
