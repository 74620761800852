import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { GlobalService } from "../../global.service";
import { Router, ActivatedRoute} from "@angular/router";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { TemplateRef } from "@angular/core";
import { config } from 'rxjs';
// import { GoogleTagManagerService } from "angular-google-tag-manager";
declare var dataLayer: any;
declare var PostAffTracker: any;
@Component({
  selector: "app-choose-lesson",
  templateUrl: "./choose-lesson.component.html",
  styleUrls: ["./choose-lesson.component.scss"],
})

export class ChooseLessonComponent implements OnInit {

  LessonFamily: any;
  isSubscribed: any;
  modalRef: BsModalRef;
  completeCount: any = 0;
  totalCount: any = 0;

  constructor(public global: GlobalService, 
    public router: Router, 
    private modalService: BsModalService,
    public actRoute: ActivatedRoute,
    // private gtmService: GoogleTagManagerService,
    ) {
    global.setCurrentUserInterval();
    this.global.profileTab = 1;
    localStorage.removeItem(btoa("routeParams"));
    this.global.routeParams = null;
    // this.getSubsriptionStatus();
  }

  ngOnInit() {
    this.actRoute.queryParams.subscribe(async (data:any)=>{
      if(data && data.subscribedbystripe && data.subscribedbystripe == "true"){
        console.log("subscribedbystripe",data.subscribedbystripe);
        /** PostAffTracker >>>>>*/
        await this.getAffiliateInfo();
        /** PostAffTracker <<<<<*/
        setTimeout(() => {
          dataLayer.push({'event':'buying-process-completed'});
          console.log("Event fired (buying-process-completed)");
          this.router.navigate(['/choose-lesson']);
        }, 3000);
      }
    });
    // this.actRoute.queryParams.subscribe((data:any)=>{
    //   if(data && data.subscription && data.subscription == "true" && (data.subscription_id || data.paymentId)){
    //     const subscriptionId = data.paymentId?data.paymentId:data.subscription_id;
    //     this.activateSubscription(subscriptionId);
    //   }
    // });

    this.global.loader = true;
    setTimeout(() => { this.getLessonFamily();});
  }

  // refreshPage() {
  //   window.location.reload();
  // }
  
  navigate(type, data, progress = null) {
    sessionStorage.setItem("lesson_family_id",data._id);
    // return;
    if (type == "sentences") {
      this.router.navigate(["lesson-description/" + data._id]);
      localStorage.setItem(btoa("Lfamily"), data.Lfamily);
    } else {
      if (data.lesson_id) {
        localStorage.setItem(btoa("lesson_id"), btoa(data.lesson_id));
        this.global.lesson_id = data.lesson_id;
      }

      localStorage.setItem(btoa("max_read_slide"), btoa(data.levels[0].progres));
      localStorage.setItem(btoa("time_loop"), btoa(data.levels[0].time_loop));
      this.global.max_read_slide = data.levels[0].progres;
      this.router.navigate(["lesson/" + type]);
    }
  }

  getLessonFamily() {
    let body = {};
    body["language_id"] = this.global.selectLanguage;
    body["user_id"] = this.global.user._id;
    this.global.post("lesson-family-v2", JSON.stringify(body), (data) => {
      if (data.success) {
        this.LessonFamily = data.data;

        this.totalCount = data.data.family.length > 0 ? data.data.family.length : 1;
        data.data.family.map((ele) => {
          if (ele.progress >= 100) {
            this.completeCount++;
          }
        });
      } else {
        this.global.showDangerToast(this.global.termsArray[data.message]);
      }
    },
    (err) => {
      this.global.showDangerToast(err.message);
    }, true, 1000 );
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  // getSubsriptionStatus() {
  //   this.global.get("getSubsriptionStatus", (data) => {
  //     if (data.success) {
  //       this.isSubscribed = data.data.is_subsribed;
  //     }
  //   }, (err) => {}, true, 1000);
  // }

  lessonDes(level_id,family){
    localStorage.setItem('level_id',level_id)
    localStorage.setItem(btoa("Lfamily"), family.Lfamily);
    this.router.navigate(["lesson-description/" +family._id]);
  }
  // activateSubscription(subscriptionId:string){
  //   const data:any = {
  //     paypal_subscription_id : subscriptionId,
  //   };
  //   this.global.post("activate-subscription",data,(response:any)=>{
  //     dataLayer.push({'event':'buying-process-completed'});
  //     if(response.success){
  //       this.router.navigate(['/choose-lesson'])
  //     }else{
  //       this.global.showDangerToast("",response.message);
  //     }
  //   },(err:any)=>{
  //     console.log("Error",err);
  //     this.global.showDangerToast("",err.message);
  //   })
  // }

  setFamilyTitle(family:any){
    sessionStorage.setItem('familyTitle',this.global.termsArray[family.term]);
  }
  getAffiliateInfo(){
    return new Promise((resolve, reject)=>{
      this.global.post("get-affiliate-info", JSON.stringify({}), (response:any) => {
        if (response.success && response.data) {
          let data:any = response.data;
          PostAffTracker.setAccountId('default1');
          let sale = PostAffTracker.createSale();
          sale.setTotalCost(data.baseSubsData.price);
          sale.setOrderID(data.subscription_name);
          sale.setProductID(data.baseSubsData._id);
          PostAffTracker.register();
          console.log("Affiliate sale marked");
          resolve(response.data);
        } else {
         reject();
        }
      }, (err) => {
        reject();
        this.global.showDangerToast("Error", err.message);
      }, true);
    })
  }
}
