import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { GlobalService } from "../global.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { url } from "inspector";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { WeeklyGoalService } from "../weekly-goal.service";
declare var Socket: any;
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  languageArray: any;
  login_with_social: any;
  gapi: any;
  public auth2: any;
  public showBtn: boolean = false;
  public showCross: boolean = false;
  max_767_width: any = false;
  hideHeader: boolean = false;
  public currentRoute: any = "";
  storeUrls: any = {};
  unreadNotifications: number = 0;
  repeatedLessonCount: any;
  notifications: any;
  modalRef: BsModalRef;
  goalAchieved: boolean = false;
  selectedAward: any = null;
  msgTerm = 'msg_award_description';
  message: any;
  awards = this.global.awards;
  dont_show_awards: boolean = false;
  myGoals: any;
  neverShowUpdateModal:boolean = localStorage.getItem('neverShowUpdateModal') && localStorage.getItem('neverShowUpdateModal')== 'true'?  true : false;
  constructor(
    private titleService: Title,
    public global: GlobalService,
    public router: Router,
    public location: Location,
    public breakpointObserver: BreakpointObserver,
    private modalService: BsModalService, private goalService: WeeklyGoalService
  ) {
    this.getLanguageList();
    router.events.subscribe((url: any) => {
      // console.log(router.routerState.snapshot.url)
      if (router.routerState.snapshot.url == "/") this.showBtn = true; else this.showBtn = false;
      this.currentRoute = this.router.routerState.snapshot.url;
      if (this.currentRoute == '/choose-lesson') {
        this.hideHeader = false;
      } else {
        this.hideHeader = true;
      }
    });

    if (this.global.isLogin) {
      this.global.currentUser();
    }

    
  }

  ngOnInit() {
    console.log("this.global.user", this.global.user)
    this.dont_show_awards = this.global.user && this.global.user.dont_show_awards ? this.global.user.dont_show_awards : false
    this.fbLibrary();
    this.breakpointObserver.observe(["(max-width: 767px)"]).subscribe((result) => {
      if (result.matches) {
        this.max_767_width = false;
      } else {
        this.max_767_width = true;

      }
    });
    if (this.global.user && this.global.user.status != 0) {
      if (this.global.user._id) {
        let joinRequest = { room: this.global.user._id };
        Socket.emit('notificationJoin', joinRequest, (error) => {
          if (error) {
            console.log("*****Socket connection error on notificationJoin", error);
          }
          else {
            console.log("conncdfbgdkfg")
          }
        });
      }
      this.getLearningTime()
      this.getRepeatingLessonCount();
      this.getrepeatedLessonValue()
      this.getNotification();
      Socket.on('notificationReceive', (data) => {
        console.log("notificationReceive", data)
        this.getNotification()
      });

      Socket.on('termsUpdated', (data) => {
        console.log("termsUpdated", data)
      });
      this.getUnreadNotificationsValue()

      this.getGoals();
      this.goalService.getGoalAchieved().subscribe(achieved => {
        this.getGoals();

        if (this.myGoals) {
          if (achieved) {
            let element = document.getElementById('openModal2')
            if (element) {
              document.getElementById('openModal2').click();
              this.updateWeeklyGoal('achieved');
              this.goalService.setPauseInterval(true);
            }
          }
        }
      });


      this.goalService.getAwardAchieved().subscribe(awardachieved => {
        if (awardachieved && Object.keys(awardachieved).length > 0) {
          this.selectedAward = awardachieved
          let newmsg = this.global.termsArray[this.msgTerm]
          this.message = newmsg.replace(/{minutes}/, this.selectedAward.minutes);
          let element = document.getElementById('award-Modal3')
          if (element) {
            document.getElementById('award-Modal3').click();
            this.goalService.setPauseInterval(true);
          }
        }
      });
    }

    this.global.storeUrls.subscribe((value) => {
      this.storeUrls = value;
    })

    // if(!this.neverShowUpdateModal){
    //   document.getElementById('updatesAvailableModalButton').click();
    // }

  }

  getUnreadNotificationsValue() {
    this.global.getNotificationValue().subscribe((val) => {
      console.log("data", val)
      this.unreadNotifications = val
      console.log("data", this.unreadNotifications)
      if (document.getElementById('unreadNotificationsElem') && val > 0) {
        document.getElementById('unreadNotificationsElem').innerHTML = val.toString()
      }
    })
  }

  getrepeatedLessonValue() {
    this.global.getrepeatingLessonValue().subscribe((val) => {
      console.log("this.repeatedLessonCount", this.repeatedLessonCount)
      this.repeatedLessonCount = val
    })
  }
  setPageTitle(title: string) {
    this.titleService.setTitle(title);
    var element = document.getElementById("navbarSupportedContent");
    element.classList.remove("show");
  }

  logout() {
    sessionStorage.setItem(btoa("backToHome"), btoa("true"));
    localStorage.removeItem(btoa("blogData"));
    if (window.confirm(this.global.termsArray["msg_logout_confirm"])) {
      this.global.submitLoginHours();
      localStorage.removeItem(btoa("AuthToken"));
      localStorage.removeItem(btoa("routeParams"));
      this.global.routeParams = null;
      this.global.isLogin = false;
      this.global.AuthToken = null;
      this.signOut();
      this.getLanguageList();
    }
  }

  fbLibrary() {
    (window as any).fbAsyncInit = function () {
      window["FB"].init({
        appId: "434838987192361",
        cookie: true,
        xfbml: true,
        version: "v3.1",
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }

  signOut() {
    if (this.global.user.login_with_social == 1) {
      window["gapi"].load("auth2", () => {
        this.auth2 = window["gapi"].auth2.getAuthInstance();
        this.auth2.signOut().then(() => { });
        //this.attachSignout(document.getElementById('googleBtn2'));
      });
      window["FB"].logout(function (response) {
        console.log("User signed out.", response);
      });
    }
    localStorage.removeItem(btoa("user"));
    this.global.lessonfamily_id = null;
    this.global.baselesson_id = null;
    this.global.lesson_id = null;
    this.global.max_read_slide = null;
    this.global.lesson_title = null;
    localStorage.removeItem(btoa("lessonfamily_id"));
    localStorage.removeItem(btoa("baselesson_id"));
    localStorage.removeItem(btoa("lesson_id"));
    localStorage.removeItem(btoa("max_read_slide"));
    localStorage.removeItem(btoa("lesson_title"));
    this.global.user = null;
    document.body.classList.remove("texture-bg-gry");
    this.router.navigate(["/login"]);
  }

  getLanguageList() {
    this.global.get("languages", (data) => {
      localStorage.setItem(btoa("termArray"), btoa(JSON.stringify(data.data)));
      this.languageArray = data.data;
      this.languageArray.map((ele) => {
        if (ele._id == this.global.selectLanguage) {
          this.global.selectedLabel = this.global.termsArray[ele.term];
        }
      });
      if (data.data.lenght == 1) {
        this.selectLanguage(data.data[0]._id, data.data[0].label);
      }
      this.global.languageArray = data.data;
    },
      (err) => { },
      true);
  }

  selectLanguage(id, label) {
    console.log(id);
    this.global.selectedLabel = label;
    this.global.selectLanguage = id;
    localStorage.setItem(btoa("selectLanguage"), btoa(id));
    this.global.getTermsData();
    this.getLearningLanguage();
    location.reload();
  }

  getLearningLanguage() {
    this.global.get(
      "learningLanguages/" + this.global.selectLanguage,
      (data) => {
        this.global.learningLanguages = data.data.filter((ele) => {
          return ele._id != this.global.selectLanguage;
        });
        console.log("learning_language", data);
      },
      (err) => { }
    );
  }

  openUrl(type) {
    const _this = this;
    let obj = {
      1: _this.storeUrls.play_url,
      2: _this.storeUrls.apple_url
    };
    window.open(obj[type], 'blank');
  }

  crossBtnClicked(route = "") {
    // console.log("route: ", route);
    // console.log("currentRoute: ", this.currentRoute);
    if (route == this.currentRoute && route != "" && this.currentRoute != "") return;
    this.showCross = !this.showCross;
  }


  getRepeatingLessonCount() {
    let body = {
      learning_language_id: this.global && this.global.user && this.global.user.learning_language_id ? this.global.user.learning_language_id : ""
    };
    this.global.post("repeating-lesson-count", JSON.stringify(body), (data) => {
      if (data.success) {
        this.repeatedLessonCount = data.data && data.data.count ? data.data.count : 0;
        this.global.setrepeatingLessonValue(this.repeatedLessonCount)
      } else {
        this.global.showDangerToast(this.global.termsArray[data.message]);
      }
    }, (err) => {
      this.global.showDangerToast(err.message);
    }, true);
  }


  getNotification() {
    this.global.get("unread-notifications-count", (response) => {
      if (response.success) {
        this.unreadNotifications = response && response.data ? response.data : 0;
        this.global.setNotificationValue(this.unreadNotifications)
      } else {
        this.global.showDangerToast(this.global.termsArray[response.message]);
      }
    }, (err) => {
      this.global.showDangerToast(err.message);
    }, true);
  }

  getGoals() {
    this.global.get("goals", (data) => {
      console.log("goal data", data)
      if (data.success) {
        this.myGoals = data.data;
        if (this.myGoals.prevWeekCompletionStatus == false && this.myGoals.prevWeekSeenByUser == false) {
          let element = document.getElementById('openModal3')
          if (element) {
            document.getElementById('openModal3').click();
            this.updateWeeklyGoal('not-achived');
            this.goalService.setPauseInterval(true);
          }
        }
      } else {
        this.myGoals = null;
      }
    }, (err) => {
      this.global.showDangerToast(err.message);
    }, true);
  }

  updateWeeklyGoal(type) {
    let body = type == 'achieved' ? { seenByUser: true } : { prevWeekSeenByUser: true }
    this.global.post(
      "update-daily-goal",
      body,
      (response) => {
        if (response.success) {
          console.log("sdjhfjfsdf")
        }
      },
      (err) => {
        this.global.showDangerToast("", err.message);
      },
      false
    );
  }

  resumeInterval() {
    this.goalService.setPauseInterval(false);
    this.goalService.setGoalAchieved(false);
  }


  getLearningTime() {
    this.global.get(
      "check-new-learning-time",
      (response) => {
        if (response.success) {
          console.log("sdjhfjfsdf")
        }
      },
      (err) => {
        this.global.showDangerToast("", err.message);
      },
      false
    );
  }

  onCheckboxChange(event: any) {
    this.dont_show_awards = event.target.checked
    let data = { dont_show_awards: event.target.checked }
    this.global.post("update-ask-award-status", data, (res) => {
      if (res.success) {
        this.global.user = res.data;
        console.log("userere", this.global.user)
      }
    }, (err) => {
      this.global.showDangerToast(err.message);
    }, false);
  }

  // Method to hide the cookies-statement div
  onHideUpdateModal(e): void {
    this.neverShowUpdateModal = e.target.checked;
    localStorage.setItem('neverShowUpdateModal', this.neverShowUpdateModal.toString());
  }




}
