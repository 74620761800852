import { Component, OnInit } from '@angular/core';
import { Location } from "@angular/common";
import { GlobalService } from 'src/app/global.service';

@Component({
  selector: 'app-awards',
  templateUrl: './awards.component.html',
  styleUrls: ['./awards.component.scss']
})
export class AwardsComponent implements OnInit {
  myLearning: any;
  awards = this.global.awards;
  selectedAward: any = null;
  selecteMinutes: any
  msgTerm = 'msg_award_description';
  message: any;
  constructor(public location: Location,
    public global: GlobalService) { }

  ngOnInit() {
    this.getLearningTime();
    this.getLearningMinutes()
  }


  getLearningMinutes() {
    this.global.get("myAwards", (data) => {
      if (data.success) {
        this.myLearning= data.data;
        // Convert to a format suitable for the template
        // const formattedData = learning.map(item => ({
        //   startDate: this.formatDate(item.week_start_date),
        //   endDate: this.formatDate(item.week_end_date),
        //   minutes: item.learningMinutes
        // }));
        // this.myLearning = formattedData
        console.log("mylearnign", this.myLearning)
      } else {
        this.global.showDangerToast(this.global.termsArray[data.message]);
      }
    }, (err) => {
      this.global.showDangerToast(err.message);
    }, false);
  }


  formatDate(date) {
    const day = new Date(date).getDate();
    const month = new Date(date).getMonth() + 1;
    const formattedDate = `${day}.${month}`;
    return formattedDate;
  }
  getMsg(minutes) {
    let newmsg = this.global.termsArray[this.msgTerm]
    console.log(newmsg)
    this.message = newmsg.replace(/{minutes}/, minutes);
  }

  getMinutes(seconds){
  const minutes = Math.round(seconds / 60);
  const remainingSeconds = seconds % 60;
  return minutes;
  }


  getLearningTime() {
    this.global.get(
      "check-new-learning-time",
      (response) => {
        if (response.success) {
          console.log("sdjhfjfsdf")
        }
      },
      (err) => {
        this.global.showDangerToast("", err.message);
      },
      true
    );
  }
}
