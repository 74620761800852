<div class="inner-wraper login-bg-cover">
  <section class="abg-form-sect">

    <div class="container">
      <div class="login-in-color">
        <div class="setting-arrow">
          <!-- <a routerLink="/" href="javascript:;" style="width: fit-content; word-break: initial"> -->
          <a (click)="goToBack()" href="javascript:void(0);" style="width: fit-content; word-break: initial">
            <i class="fas fa-long-arrow-alt-left"></i>
            <h6>Zurück</h6>
          </a>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="log-wd-max">
              <form [formGroup]="formData" (ngSubmit)="login(formData.value)">
                <h1 class="heading-log">{{ global.termsArray["lbl_login"] }}</h1>
                <div class="form-group pb-3">
                  <input type="email" class="form-control autofill" id="exampleInputEmail1" aria-describedby="emailHelp"
                    formControlName="email" [placeholder]="global.termsArray['lbl_email_address']" [(ngModel)]="defaultEmail" />
                  <div
                    *ngIf="formData.controls['email'].invalid && (formData.controls['email'].dirty || formData.controls['email'].touched || this.formSubmitAttempt) ">
                    <div *ngIf="formData.controls['email'].errors.required" class="validation_err validation_err_color" style="font-size: 14px;">
                      {{ global.termsArray["msg_enter_email"] }}
                    </div>
                  </div>
                </div>
                <div class="form-group pb-3">
                  <div class="password-container">
                    <input type="password" class="form-control password" id="exampleInputPassword1" formControlName="password" #passwordField
                      [placeholder]="global.termsArray['lbl_password_only']" [(ngModel)]="defaultPass" />
                      <div class="password-view-wrapper tooltip-trigger" #toggleButton (click)="togglePassword(passwordField,toggleButton,toolTip)">
                        <span class="custom-tooltip" [attr.data-title]="global.termsArray['msg_show_password'] ? global.termsArray['msg_show_password']:'Passwort anzeigen'" #toolTip></span>
                        <i class="ri-eye-off-fill"></i>
                        <i class="ri-eye-fill"></i>
                      </div>
                  </div>
                  <div
                    *ngIf=" formData.controls['password'].invalid && (formData.controls['password'].dirty || formData.controls['password'].touched || this.formSubmitAttempt)">
                    <div *ngIf="formData.controls['password'].errors.required" class="validation_err validation_err_color" style="font-size: 14px;">
                      {{ global.termsArray["err_enter_password"] }}
                    </div>
                  </div>
                </div>
                <!-- <div class="guidelines-sec">
                  <a class="dch-pb" href="javascript:;" [routerLink]="['/privacy-policy']">Datenschutzrichlinien</a>
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheck1" formControlName="acceptTerms">
                    <label class="custom-control-label" for="customCheck1">Ich akzeptiere die <a routerLink="/term-condition" target="_blank" href="javascript:;">AGBs</a></label>
                  </div>
                  <div
                    *ngIf="(this.formSubmitAttempt || formData.controls['acceptTerms'].touched) && formData.controls['acceptTerms'].invalid && formData.controls['acceptTerms'].errors.pattern "
                    style="color: red; margin-bottom: 10px;">{{ global.termsArray["err_terms_condition"] }}</div>

                </div> -->
                <button type="submit" class="btn">
                  {{ global.termsArray["lbl_login"] }}
                </button>
              </form>
              <div class="already-have-sec">
                <!-- <a [routerLink]="['/register']" routerLinkActive="active">{{ global.termsArray["lbl_donot_have_account"] }}</a> -->
                <h6 [routerLink]="['/register']"> {{ global.termsArray["lbl_donot_have_account"] }} <a
                    href="javascript:;"> Anmelden </a></h6>
                <a [routerLink]="['/forgot']"
                  routerLinkActive="active">{{ global.termsArray["lbl_forgot_password"] }}</a>
              </div>

              <!-- <div class="sing-bttn">
                        <h6>{{ global.termsArray["lbl_sign_with_social_networks"] }}</h6>
                        <span>
                          <a class="btn" (click)="socialSignIn()" ><i class="fab fa-google"></i >{{ global.termsArray["lbl_signup_with_google"] }}</a>
                          <a class="btn" (click)="facebookLogin()" ><i class="fab fa-facebook"></i>{{ global.termsArray["lbl_signup_with_facebook"] }}</a>
                        </span>
                      </div>-->
            </div>
          </div>
          <div class="col-md-6 top-space-log">
            <div class="log-item-right">
              <!-- <h4>Danke, dass Sie sich für Birlingo entscheiden.</h4> -->
              <!-- <h6>Sie werden staunen, wie schnell Sie sich in einer neuen Sprache verständigen können.</h6> -->
              <h4>{{global.termsArray['lbl_login_heading_text']}}</h4>
              <h6>{{global.termsArray['lbl_login_desc_text']}}</h6>
            </div>
          </div>
        </div>

      </div>
    </div>

  </section>
</div>
