import { Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { GlobalService } from "../../global.service";
import { TemplateRef } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Router, ActivatedRoute } from "@angular/router";
import { InfiniteScrollDirective } from "ngx-infinite-scroll";
import { Location } from "@angular/common";
declare var Socket: any;


@Component({
  selector: 'app-notifiations',
  templateUrl: './notifiations.component.html',
  styleUrls: ['./notifiations.component.scss']
})
export class NotifiationsComponent implements OnInit {

  @ViewChild(InfiniteScrollDirective, { static: true })
  infiniteScroll: InfiniteScrollDirective;

  set appScroll(directive: InfiniteScrollDirective) {
    this.infiniteScroll = directive;
  }

  @ViewChild('scrollContainer', { static: true }) scrollContainer: ElementRef;
  prevNotifications: any = []
  notifications: any = [];
  public authUser: any;
  messages: any = [];
  page: number = 1;
  currentPage: number = 1;
  totalPage: number = 1;
  myNotification: any =[]
  constructor(
    public global: GlobalService,
    public router: Router,
    private route: ActivatedRoute,
    public location: Location,
    private modalService: BsModalService
  ) {

  }

  ngOnInit() {
    this.getNotification();
    Socket.on('notificationReceive', (data) => {
      this.getNotification();
    });
    this.markvisibleNotificationsAsRead();
  }


  getNotification(page = 1) {
    this.global.get(`notifications?page=${page}`, (response) => {
      if (response.success) {
        let newNotifications = response.data.notifications;
        if (!this.myNotification || page==1) {
          this.myNotification = []
        }

        newNotifications.forEach(element => {
          const dateKey = element.created;
          const notificationsArray = element.notifications || [];
          const keyToCheck = 'created';
          const valueToFind = dateKey;
          const index = this.myNotification.findIndex(obj => obj[keyToCheck] === valueToFind);
          if (index!=-1) {
            let prev = this.myNotification[index]['notification']
            let newVal = prev.concat(notificationsArray)
            this.myNotification[index]['notification']=newVal;
          } else {
            this.myNotification.push({created:dateKey,notification:notificationsArray})
          }
        });

        console.log("myNotification", this.myNotification)

        this.totalPage = response.data.totalPages;
        this.currentPage = response.data.currentPage;

        this.getUnreadNotification();
        // this.markvisibleNotificationsAsRead();

      } else {
        this.global.showDangerToast(this.global.termsArray[response.message]);
      }
    }, (err) => {
      this.global.showDangerToast(err.message);
    }, true);
  }



  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    this.markvisibleNotificationsAsRead()
    this.loadMore();
  }

  loadMore(): void {
    if (this.currentPage < this.totalPage) {
      this.currentPage++;
      this.getNotification(this.currentPage);
    }
  }

  getUnreadNotification() {
    this.global.get("unread-notifications-count", (response) => {
      if (response.success) {
        let unreadNotifications = response && response.data ? response.data : 0;
        this.global.setNotificationValue(unreadNotifications)
        // let element = document.getElementById('unreadNotifications');
        // if (element) {
        //   if (unreadNotifications > 0) {
        //     element.innerHTML = `<i class="ri-chat-4-line"></i> <span class="notification-badge">${unreadNotifications}</span>`
        //   }
        //   else {
        //     element.remove()
        //   }
        // }
      } else {
        this.global.showDangerToast(this.global.termsArray[response.message]);
      }
    }, (err) => {
      this.global.showDangerToast(err.message);
    }, false);
  }

  markvisibleNotificationsAsRead() {
    const visibleNotifications = [];
    for (const date of this.myNotification) {
      for(let notification of date.notification){
        const notificationElement = document.getElementById(notification._id);
        if (notificationElement) {
          if (notification.is_read === 0) {
            visibleNotifications.push(notification._id);
          }
        }
      }
    }

    if (visibleNotifications.length > 0) {
      this.markAsRead(visibleNotifications);
    }
  }


  markAsRead(notificationIds): void {
    this.global.post("mark-as-read", ({ ids: JSON.stringify(notificationIds) }), (response) => {
      if (response.success) {
        this.getUnreadNotification();
      }
    },
      (err) => {
        this.global.showDangerToast(err.message);
      }, false);
  }

 formatDateToGerman(date) {
    let updatedDate = new Date(date)
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    };

    return new Intl.DateTimeFormat('de-DE', options).format(updatedDate);

}
}
