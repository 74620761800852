<div class="inner-wraper profile-sec transaction_history_container">
    <section class="lesson-cont-sect wrap-space">
        <div class="container">
            <div class="lektionen-cover-new transaction_history_container">
                <article>
                    <div class="setting-arrow inner-arrow">
                        <a href="javascript:;" (click)="goBack()" class="d-inline-block">
                            <i class="ri-arrow-left-s-line"></i>
                            <!-- <h6>{{ global.termsArray["lbl_transaction_history"] }}</h6> -->
                        </a>
                    </div>
                    <div class="list-sentens text-center transaction_history pb-5">
                        <div class="in-list-setting">
                            <div class="account-title">
                                <h4>{{ global.termsArray["lbl_transaction_history"] }}</h4>
                            </div>
                            <div class="row mt-lg-5 mt-4">
                                <div class="col-lg-12" *ngFor="let transaction of transactionsData.transactions; let i = index; let count = count;" [ngClass]="{'mb-4':i<count-1}">
                                    <div class="card w-100">
                                        <div class="card-body position-relative bg-default">
                                            <span class="offset_count bg-default">{{transactionsData.skip+(i+1)}}</span>
                                            <div class="row text-left mb-3">
                                                <div class="col-md-6 col-5">
                                                    {{ global.termsArray["lbl_subscription"] }}
                                                </div>
                                                <div class="col-md-6 col-7">
                                                    :&nbsp; {{transaction?.subscriptionDetails?.title ? transaction?.subscriptionDetails?.title:"N/A"}}
                                                </div>
                                            </div>
                                            <div class="row text-left mb-3">
                                                <div class="col-md-6 col-5">
                                                    {{ global.termsArray["lbl_transaction_id"] }}
                                                </div>
                                                <div class="col-md-6 col-7">
                                                    <div class="d-flex align-items-center">
                                                        <span>
                                                            :&nbsp;
                                                        </span> 
                                                        <p class="m-0">{{transaction?.transaction_id?transaction?.transaction_id:"N/A"}}</p>
                                                       </div>
                                                </div>
                                            </div>
                                            
                                            <div class="row text-left mb-3">
                                                <div class="col-md-6 col-5">
                                                    {{ global.termsArray["lbl_coupon"] }}
                                                </div>
                                                <div class="col-md-6 col-7">
                                                    :&nbsp; 
                                                    <span class="badge badge-dark" *ngIf="transaction?.coupon_applied==false || !transaction?.coupon_applied">
                                                        Not Applied
                                                    </span>
                                                    <span class="badge badge-success badge-applied" *ngIf="transaction?.coupon_applied==true">Applied</span>
                                                </div>
                                            </div>
                                            <div class="row text-left mb-3">
                                                <div class="col-md-6 col-4">
                                                    {{ global.termsArray["lbl_amount"] }}
                                                </div>
                                                <div class="col-md-6 col-8">
                                                   <div class="d-flex align-items-center">
                                                    <span>
                                                        :&nbsp;
                                                    </span> 
                                                    <div class="d-flex align-items-center">
                                                        <img src="assets/images/money-euro-icon.png" alt="">
                                                        {{transaction?.amount ? transaction.amount : "N/A"}}
                                                    </div>
                                                   </div>
                                                </div>
                                            </div>
                                            <div class="row text-left mb-3">
                                                <div class="col-md-6 col-4">
                                                    {{ global.termsArray["lbl_status"] }}
                                                </div>
                                                <div class="col-md-6 col-8">
                                                    :&nbsp; 
                                                    <ng-container *ngIf="transaction?.payment_status != 'refunded'; else showRefunded">
                                                        <span class="badge badge-dark" *ngIf="transaction?.status==0 || !transaction?.status">Failed</span>
                                                        <span class="badge badge-success" *ngIf="transaction?.status==1">Success</span>
                                                    </ng-container>
                                                    <ng-template #showRefunded>
                                                        <span class="badge badge-secondary">Refunded</span>
                                                    </ng-template>
                                                </div>
                                            </div>
                                            <div class="row text-left mb-3">
                                                <div class="col-md-6 col-4">
                                                    {{ global.termsArray["lbl_invoice"] }}
                                                </div>
                                                <div class="col-md-6 col-8">
                                                   <div class="d-flex align-items-center">
                                                    <span>
                                                        :&nbsp;
                                                    </span> 
                                                    <div *ngIf="transaction && transaction.transaction_id && transaction.transaction_id!='offline' && (transaction.paid_by!='paypal' && transaction.paid_by!='android' && transaction.paid_by!='ios' ); else invoiceElseBlock" class="bttn-blue p-0">
                                                        <a class="btn text-white" target="_blank" (click)="getInvoiceUrl(transaction.transaction_id)">{{ global.termsArray["lbl_invoice"] }}</a>
                                                    </div>
                                                    <ng-template #invoiceElseBlock>
                                                        <!-- [ngStyle]="(transaction.paid_by === 'android' || transaction.paid_by === 'ios') ? {'color': 'red'} : {}" -->
                                                        <span>
                                                          {{ (transaction.paid_by === 'android' || transaction.paid_by === 'ios') ? global.termsArray["msg_reciept_in_app_store"] : 'N/A' }}
                                                        </span>
                                                      </ng-template>
                                                   </div>
                                                </div>
                                            </div>
                                            <div class="row text-left">
                                                <div class="col-md-6 col-4">
                                                    {{ global.termsArray["lbl_transaction_date"] }}
                                                </div>
                                                <div class="col-md-6 col-8">
                                                    :&nbsp;{{getDate(transaction?.created)}}
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                </div>
                            </div>
                            
                            <div *ngIf="transactionsData && transactionsData.transactions && transactionsData.transactions.length==0" class="mt-4 text-white">
                                <h3>{{ global.termsArray["lbl_record_not_found"] }}</h3>
                            </div>
                            <!-- pagination -->
                            <div class="d-flex justify-content-end my-4" *ngIf="transactionsData.totalPages > 1">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination">
                                        <li class="page-item" [ngClass]="{'disabled':transactionsData.currentPage==1}" #prev (click)="paginate(prev,'prev')">
                                            <button class="page-link" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                                <span class="sr-only">Previous</span>
                                            </button>
                                        </li>
                                        <li class="page-item" *ngFor="let page of pagination;" #pageItem (click)="paginate(pageItem,'page')"
                                        [ngClass]="{'active':transactionsData.currentPage==page}">
                                            <button class="page-link">{{page}}</button>
                                        </li>
                                        <li class="page-item" [ngClass]="{'disabled':transactionsData.currentPage==transactionsData.totalPages}"
                                        #next (click)="paginate(next,'next')">
                                            <button class="page-link" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                                <span class="sr-only">Next</span>
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </article>
                <app-app></app-app>
            </div>
        </div>
    </section>
</div>