import { Component, OnInit, Inject } from "@angular/core";
import { GlobalService } from "../global.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Title, Meta, MetaDefinition } from "@angular/platform-browser";
import { BreakpointObserver } from "@angular/cdk/layout";
import { environment } from "src/environments/environment";
import { filter, map, mergeMap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { commonConstants } from "../common-const";
declare var $: any;
declare var PostAffTracker: any;

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.sass"],
})

export class HomeComponent implements OnInit {

  [x: string]: any;
  languageArray = ["English"];
  testimonials: any;
  setting: any;
  video_url: any;
  video_image: any;
  homeContent: any = {};
  public homeContentImages: any = {};
  content: any;
  max_767_width;
  public slider: any = [];
  public methodImages: any = [];

  constructor(
    public global: GlobalService,
    private metaTagService: Meta,
    public route: Router,
    private titleService: Title,
    public breakpointObserver: BreakpointObserver,
    public commonconstant : commonConstants,
    @Inject(DOCUMENT) private doc
  ) {
    let user = localStorage.getItem(btoa("user")) ? atob(localStorage.getItem(btoa("user"))) : null;
    user = JSON.parse(user);

    // this.getHomeContent();
    // this.getLearningLanguage();
    // this.getTestimonials();
    // this.getSettingData();
    // this.getMethodPageContent().then((promise) => {
    //   $(document).ready(() => {
    //     // setTimeout(() => {
    //       $(".carousel").swipe({
    //         swipe: (
    //           event,
    //           direction,
    //           distance,
    //           duration,
    //           fingerCount,
    //           fingerData
    //         ) => {
    //           //console.log("swipe");
    //           if (direction == "left") {
    //             $("#box-next").trigger("click");
    //           }
    //           if (direction == "right") {
    //             $("#box-pre").trigger("click");
    //           }
    //         },
    //         allowPageScroll: "vertical",
    //       });
    //     // },1000);

    //     $('#recipeCarousel').carousel({
    //       // interval: 10000
    //     })

    //     $('.carousel.threeslider .carousel-item').each(function () {
    //       var minPerSlide = 3;
    //       var next = $(this).next();
    //       if (!next.length) {
    //         next = $(this).siblings(':first');
    //       }
    //       next.children(':first-child').clone().appendTo($(this));

    //       for (var i = 0; i < minPerSlide; i++) {
    //         next = next.next();
    //         if (!next.length) {
    //           GlobalService
    //           next = $(this).siblings(':first');
    //         }
    //         next.children(':first-child').clone().appendTo($(this));
    //       }
    //     });

    //   });

    // });
    this.getHomeImages();
  }

  ngOnInit() {
    localStorage.removeItem(btoa("routeParams"));
    this.global.routeParams = null;
    this.breakpointObserver.observe(["(max-width: 767px)"]).subscribe((result) => {
      if (result.matches) {
        this.max_767_width = true;
      } else {
        this.max_767_width = false;
      }
    });
  }

  open(): void {
    let urls = this.getId(this.video_url);
    $("#test").attr("src", urls)
  }

  getLearningLanguage() {
    this.global.get("learningLanguages/" + this.global.selectLanguage, (data) => {
      this.languageArray = data.data.filter((ele) => {
        return ele._id != this.global.selectLanguage;
      });
      this.global.learningLanguages = data.data.filter((ele) => {
        return ele._id != this.global.selectLanguage;
      });
    }, (err) => { });
  }

  getHomeImages() {
    this.global.showLoader('home');
    this.global.get("homeImage", (data) => {
      // console.log("homeImage", data);
      this.homeContentImages = data.data;
      this.global.hideLoader()
    }, (err) => {
      this.global.showDangerToast[err.message];
    }, true);
  }

  getHomeContent() {
    this.global.showLoader('home');
    this.global.post("home", JSON.stringify({ language_id: this.global.selectLanguage }), (data) => {
      // console.log("data", data);
      this.homeContent = data.data;
      this.titleService.setTitle(this.commonconstant.homeTitle);
      this.metaTagService.updateTag({ name: this.commonconstant.description , content: this.commonconstant.homeDescription});
      this.metaTagService.updateTag({ property: "og:site_name", content: "Home" });
      this.metaTagService.updateTag({ property: "og:type", content: "website" });
      this.metaTagService.updateTag({ property: "og:url", content: environment.wesiteUrl });
      this.metaTagService.updateTag({ property: "og:title", content: data.data["meta_title"] });
      this.metaTagService.updateTag({ property: "og:description", content: data.data["meta_description"] });
      this.metaTagService.updateTag({ property: "og:learning_language_texts", content: this.homeContent.learning_language_texts["learning_language_texts"] });
      this.metaTagService.updateTag({ property: "og:image", content: this.homeContent.imageUrl + this.homeContent.images && this.homeContent.images.banner_image ?  this.homeContent.images.banner_image : '' });
      this.createLinkForCanonicalURL();

      if( document.getElementById("learning_language_texts")){
         document.getElementById("learning_language_texts").innerHTML = data.data["learning_language_texts"];
      }
      if( document.getElementById("hear_actively_texts")){
        document.getElementById("hear_actively_texts").innerHTML = data.data["hear_actively_texts"];
      }
      if(document.getElementById('tabName2')){
        document.getElementById('tabName2').style.backgroundSize = 'cover';
        document.getElementById('tabName2').style.backgroundRepeat = "no-repeat";
      }
    
      this.global.hideLoader()
    }, (err) => {
      this.global.showDangerToast[err.message];
    }, true);
  }

  getSettingData() {
    this.global.get("getSettingData", data => {
      // console.log("getSettingData response: ", data);
      this.video_url = data.data.home_video_url
      this.video_image = data.data.home_video_url_image
      this.open();
    }, err => {
        console.log("error");
      }
    );
  }

  getTestimonials() {
    this.global.post('testimonials', JSON.stringify({ language_id: this.global.selectLanguage }), (data) => {
      this.testimonials = data.data;
    }, (err) => {
      this.global.showDangerToast[err.message];
    }, true );
  }

  openUrl(type) {
    let obj = {
      1: localStorage.getItem('play_url'),
      2: localStorage.getItem('apple_url'),
    };
    window.open(obj[type], 'blank');
  }

  selectLanguage(id) {
    this.global.learningLanguage = id;
    this.route.navigate(['register']);
  }

  getId(str) {
    var res = str.split("=");
    // console.log("this is res", res);
    var embeddedUrl = "https://www.youtube.com/embed/" + res[1] + "?autoplay=0&rel=0";
    return embeddedUrl;
  }

  closeModal() {
    $('.modal iframe').attr('src', '');
  }

  getMethodPageContent() {
    return new Promise((resolve, reject) => {
      let body = {};
      body["language_id"] = this.global.selectLanguage;
      this.global.post("methodContent", JSON.stringify(body), (data) => {
        if (data.success) {
          this.content = data.data;
          this.methodImages.push(this.content.images && this.content.images.image_three ? this.content.images.image_three : "")
          this.methodImages.push(this.content.images && this.content.images.image_four ? this.content.images.image_four : "")
          this.methodImages.push(this.content.images && this.content.images.image_five ? this.content.images.image_five : "")
          resolve(1);
        } else {
          this.global.showDangerToast(this.global.termsArray[data.message]);
          reject(0);
        }
      }, (err) => {
        this.global.showDangerToast(err.message);
        reject(0);
      }, true);
    });
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  createLinkForCanonicalURL() {
    const link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    link.setAttribute('href', environment.wesiteUrl);
  }

  // createSlider(arr = []) {
  //   let slideTotal = Math.ceil(arr.length / 3);
  //   let index = 0;
  //   for (let i = 0; i < slideTotal; i++) {
  //     let arr2 = [];
  //     for (let j = 0; j < 3; j++) {
  //       if (arr[index])
  //         arr2.push(arr[index]);
  //       index = index + 1;
  //     }
  //     this.slider.push(arr2);
  //     console.log("slider: ", this.slider);
  //   }
  // }

  ngAfterViewInit() {
    this.getHomeContent();
    this.getLearningLanguage();
    this.getTestimonials();
    this.getSettingData();
    this.getMethodPageContent().then((promise) => {
      $(document).ready(() => {
          $(".carousel").swipe({
            swipe: (event, direction, distance, duration, fingerCount, fingerData) => {
              if (direction == "left") {
                $("#box-next").trigger("click");
              }
              if (direction == "right") {
                $("#box-pre").trigger("click");
              }
            },
            allowPageScroll: "vertical",
          });

        $('#recipeCarousel').carousel({
          // interval: 10000
        })

        $('.carousel.threeslider .carousel-item').each(function () {
          var minPerSlide = 3;
          var next = $(this).next();
          if (!next.length) {
            next = $(this).siblings(':first');
          }
          next.children(':first-child').clone().appendTo($(this));

          for (var i = 0; i < minPerSlide; i++) {
            next = next.next();
            if (!next.length) {
              GlobalService
              next = $(this).siblings(':first');
            }
            next.children(':first-child').clone().appendTo($(this));
          }
        });

      });

    });
    this.global.hideLoader()
  }
}
