<div class="inner-wraper no-padding-top">
  <section class="lesson-cover-sect">
    <div class="container">
      <div class="lektionen-cover-new thank-you-container account-title">
        <img src="assets/images/hand.png" class="centered-image mb-lg-5 mb-4" />
        <h4>{{ global.termsArray["access_conf_page_title"] }}</h4>
        <div class="account-title" style="padding-bottom: 0px" >
          <p >{{ global.termsArray["access_conf_page_des"] }}</p>
        </div>
        <div class="centered-buttons">
          <!-- <button class="btn" >
            {{ global.termsArray["access_conf_browser_btn"] }}
          </button> -->
          <a class="btn" href="/choose-lesson">{{ global.termsArray["access_conf_browser_btn"] }}</a>
          
          <ng-container *ngIf="deviceType==null || deviceType==''; else mobileButton;">
            <button class="btn outline-btn bg-grad" (click)="openUrl(1)">
              {{ global.termsArray["open_android_app"] }}
            </button>
            <button class="btn outline-btn bg-grad" (click)="openUrl(2)">
              {{ global.termsArray["open_ios_app"] }}
            </button>
          </ng-container>
          <ng-template #mobileButton>
            <button class="btn outline-btn bg-grad" (click)="openUrl(1)" *ngIf="deviceType=='android'">
              {{ global.termsArray["open_android_app"] }}
            </button>
            <button class="btn outline-btn bg-grad" (click)="openUrl(2)" *ngIf="deviceType=='ios'">
              {{ global.termsArray["open_ios_app"] }}
            </button>
          </ng-template>

          <!-- <button class="btn outline-btn bg-grad" (click)="openInApp()" *ngIf="deviceType=='' || deviceType=='android'">
            {{ global.termsArray["open_android_app"] }}
          </button>
          <button class="btn outline-btn bg-grad" (click)="openInApp()" *ngIf="deviceType=='ios'">
            {{ global.termsArray["open_ios_app"] }}
          </button> -->

          <!-- <button class="btn outline-btn bg-grad" (click)="openInApp()">
            {{ global.termsArray["open_android_app"] }}
          </button>
          <button class="btn outline-btn bg-grad" (click)="openInApp()">
            {{ global.termsArray["open_ios_app"] }}
          </button> -->
        </div>
      </div>
    </div>
  </section>
</div>
