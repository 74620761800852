import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { GlobalService } from "../global.service";
import { Router, ActivatedRoute } from "@angular/router";
import { EnvService } from "../env.service";
import { NgxNavigationWithDataComponent } from "ngx-navigation-with-data";
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
// import { AuthService, FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";
import { Location } from "@angular/common";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.sass"]
})

export class LoginComponent implements OnInit {

  //  @ViewChild("google", { static: true }) googleElement: ElementRef;
  previousUrl;
  auth2: any;
  formSubmitAttempt = false;
  formData;
  disabledBtn = 0;
  appId: any;
  public isBoolean: any = 3;
  public product_id: any;
  defaultEmail:any;
  defaultPass:any;
  constructor(
    private route: Router,
    private router: ActivatedRoute,
    public global: GlobalService,
    private formBuilder: FormBuilder,
    // private socialAuthService: AuthService,
    public envS: EnvService,
    private location: Location,
  ) {
    this.formData = this.formBuilder.group({
      email: new FormControl("", Validators.required),
      password: new FormControl("", Validators.required),
      // acceptTerms: new FormControl(false, Validators.pattern("true")),
    });
    let user = localStorage.getItem(btoa("user")) ? atob(localStorage.getItem(btoa("user"))) : null;
    user = JSON.parse(user);
    if (user) {
      this.route.navigate(["user"], { replaceUrl: true });
    }
    this.previousUrl = this.router.snapshot.queryParams['returnUrl'] || 'user';
    this.isBoolean = this.global.routeParams && this.global.routeParams['type'] && this.global.routeParams['type'] == 1 ? 1 : this.global.routeParams && this.global.routeParams['type'] == 2  ? 2 : 3;
    this.product_id = this.global.routeParams && this.global.routeParams['product_id'] ? this.global.routeParams['product_id'] : "";
  }

  ngOnInit() {
    //this.fbLibrary();
    let self = this;
    // console.log("this is self",self);
    // self.appId = this.envS.facebook_app_id;
    // self.fbLibrary();
    localStorage.removeItem(btoa("routeParams"));
    this.global.routeParams = null
    //this.googleSDK();
    this.router.queryParams.subscribe((params)=>{
      if(params.authenticated && params.authenticated == "true" && params.data && Object.keys(JSON.parse(params.data)).length > 0){
        const data = {
          data:JSON.parse(params.data),
          message:"msg_login_successfully"
        };
        setTimeout(() => {
          this.defaultEmail="********************";
          this.defaultPass="********************";
        }, 1000);
        setTimeout(() => {
          this.setLogin(data);
        }, 3000);
      }
    })
  }

  login(value) {
    if (this.disabledBtn) {
      return;
    }
    this.disabledBtn = 1;
    this.formSubmitAttempt = true;
    if (!this.formData.valid) {
      // console.log(this.formData);
      this.disabledBtn = 0;
      return;
    } else {
      let body = {};
      body["email"] = value.email.toLowerCase();
      body["password"] = value.password;
      this.global.post("login", body, data => {
        this.disabledBtn = 0;
        if (data.success) {
          this.setLogin(data);
        } else {
          this.global.showDangerToast("", this.global.termsArray[data.message],5000);
        }
      }, err => {
        this.disabledBtn = 0;
        this.global.showDangerToast("", err.message);
      }, true );
    }
  }

  setLogin(data: any) {
    console.log("############",data)
    this.global.setCurrentUserInterval()
    this.global.selectLanguage = data.data.language_id?data.data.language_id:"5dea0938ec155c3df111d13c";
    localStorage.setItem(btoa("selectLanguage"), btoa(data.data.language_id));
    this.global.learningLanguage = data.data.learning_language_id
    this.global.getTermsData();
    if (data.data.status !== 0) {
      this.global.loginTime = new Date()
      localStorage.setItem(btoa("user"), btoa(JSON.stringify(data.data)));
      localStorage.setItem("setupTime", this.global.setupTime + "")
      this.global.user = data.data;
      this.global.isLogin = true;
      localStorage.setItem(btoa("AuthToken"), btoa(data.data.token));
      this.global.AuthToken = data.data.token;
      this.global.showToast("", this.global.termsArray[data.message]);
      if (this.product_id != "") {
        this.global.navigate(["account"], { product_id: this.product_id });
        return;
      }
      if (data.data.hasOwnProperty('first_time')) {
        console.log("userdata===", data.data)
        if (data.data.first_time == 1) {
          // this.route.navigate(['welcome']);
          this.global.navigate(["method-video"], { replaceUrl: true, lastRoute: "verifyOtp", navigatefrom: "login" });
          return;
        } else {
          let landingPage = sessionStorage.getItem('landingPage');
          let landingPageQuery = JSON.parse(sessionStorage.getItem('landingPageQuery')) || {};
          console.log(landingPage);
          if (landingPage && landingPage != "") {
            this.route.navigate([landingPage], {
              queryParams: landingPageQuery
            });
            sessionStorage.removeItem('landingPage');
            sessionStorage.removeItem('landingPageQuery');
          } else {
            this.global.navigate(["choose-lesson"], { replaceUrl: true, lastRoute: "verifyOtp", navigatefrom: "login" });
          }
          return;
        }
      }
      if (data.data.first_time) {
        // for navigate to demo when user move to app first time
        localStorage.setItem(btoa("lesson_id"), btoa(data.data.lesson_id))
        this.global.max_read_slide = 0
        this.global.lesson_id = data.data.lesson_id
        this.route.navigate(["lesson/" + 'demo'], { replaceUrl: true });
      } else {
        // this.route.navigateByUrl(this.previousUrl);
        // this.route.navigate([this.previousUrl], { replaceUrl: true });
        // this.route.navigate(["user"], { replaceUrl: true });
      }
    } else {
      this.global.user = data.data;
      this.global.showToast("", this.global.termsArray[data.message]);
      this.global.navigate(["verify"], { email: data.data.email, lastScreen: "login" });
    }
  }
  // googleSDK() {
  //   window["googleSDKLoaded"] = () => {
  //     window["gapi"].load("auth2", () => {
  //       this.auth2 = window["gapi"].auth2.init({
  //         client_id:
  //           "105221082816-0ai54u4jpst6bln9hd7uqjpntejr1ba6.apps.googleusercontent.com",
  //         cookiepolicy: "single_host_origin",
  //         scope: "profile email"
  //       });
  //       this.googleLogin();
  //     });
  //   };

  //   (function (d, s, id) {
  //     var js,
  //       fjs = d.getElementsByTagName(s)[0];
  //     if (d.getElementById(id)) {
  //       return;
  //     }
  //     js = d.createElement(s);
  //     js.id = id;
  //     js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
  //     fjs.parentNode.insertBefore(js, fjs);
  //   })(document, "script", "google-jssdk");
  // }

  // public socialSignIn() {
  //   let socialPlatformProvider;
  //   socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
  //   let user = {};
  //   this.socialAuthService.signIn(socialPlatformProvider).then(userData => {
  //     console.log(" sign in data : ", userData);
  //     var names = userData["name"].split(" ");
  //     user["id"] = userData["id"];
  //     user["first_name"] = names[0];
  //     user["image"] = userData["image"];
  //     user["email"] = userData["email"];
  //     user["last_name"] = names.splice(1, names.length - 1).join(" ");
  //     this.loginWithGoogle(user);
  //   });
  // }

  // fbLibrary() {
  //   let self = this;
  //   (window as any).fbAsyncInit = function () {
  //     window["FB"].init({
  //       appId: "448815219365716",
  //       cookie: true,
  //       xfbml: true,
  //       version: "v3.1"
  //     });
  //     window["FB"].AppEvents.logPageView();
  //   };

  //   (function (d, s, id) {
  //     var js,
  //       fjs = d.getElementsByTagName(s)[0];
  //     if (d.getElementById(id)) {
  //       return;
  //     }
  //     js = d.createElement(s);
  //     js.id = id;
  //     js.src = "https://connect.facebook.net/en_US/sdk.js";
  //     fjs.parentNode.insertBefore(js, fjs);
  //   })(document, "script", "facebook-jssdk");
  // }

  // googleLogin() {
  //   // this.auth2.attachClickHandler(
  //   //   this.googleElement.nativeElement,
  //   //   {},
  //   //   googleUser => {
  //   //     let user = {};
  //   //     let profile = googleUser.getBasicProfile();
  //   //     console.log("Token || " + googleUser.getAuthResponse().id_token);
  //   //     user["id"] = profile.getId();
  //   //     user["first_name"] = profile.getName();
  //   //     user["image"] = profile.getImageUrl();
  //   //     user["email"] = profile.getEmail();
  //   //     user["last_name"] = "";
  //   //     this.loginWithGoogle(user);
  //   //   },
  //   //   error => {
  //   //     alert(JSON.stringify(error, undefined, 2));
  //   //   }
  //   // );
  // }

  // facebookLogin() {
  //   window["FB"].login(
  //     response => {
  //       console.log("login response", response);
  //       if (response.authResponse) {
  //         window["FB"].api(
  //           "/me",
  //           {
  //             fields: "last_name, first_name, email"
  //           },
  //           userInfo => {
  //             console.log("user information => ", userInfo);
  //             this.loginWithFacebook(userInfo);
  //           }
  //         );
  //       } else {
  //         console.log("User login failed");
  //         this.global.showDangerToast("", "User login failed, please try again")
  //       }
  //     },
  //     { scope: "email" }
  //   );
  // }

  // // after completion of facebook login  store data in backend
  // loginWithFacebook(user) {
  //   let body = {};
  //   body["facebook_id"] = user.id ? user.id : "";
  //   body["language_id"] = this.global.selectLanguage ? this.global.selectLanguage : "";
  //   body["email"] = user.email ? user.email : "";
  //   body["surname"] = user.last_name ? user.last_name : "";
  //   body["name"] = user.first_name ? user.first_name : "";
  //   body["learning_language_id"] = this.global.learningLanguage ? this.global.learningLanguage : "";

  //   this.global.post(
  //     "loginwithfacebook",
  //     JSON.stringify(body),
  //     data => {
  //       if (data.success) {
  //         this.global.showToast("", this.global.termsArray[data.message]);
  //         if (data.data.isCompleted === 0) {
  //           this.global.navigate(["register"], { user: user, type: 'fb', fb: 'fb' });
  //         } else {
  //           this.global.selectLanguage = data.data.language_id;
  //           localStorage.setItem(btoa("selectLanguage"), btoa(data.data.language_id));
  //           this.global.learningLanguage = data.data.learning_language_id
  //           this.global.getTermsData();
  //           if (data.data.status === 0) {
  //             this.global.navigate(["verify"], {
  //               email: data.data.email,
  //               lastScreen: "fb"
  //             });
  //           } else {
  //             this.global.loginTime = new Date()
  //             localStorage.setItem(btoa("user"), btoa(JSON.stringify(data.data)));
  //             localStorage.setItem("setupTime", this.global.setupTime + "")
  //             this.global.isLogin = true;
  //             localStorage.setItem(btoa("AuthToken"), btoa(data.data.token));
  //             this.global.AuthToken = data.data.token;
  //             this.global.user = data.data;

  //             if (data.data.first_time) {
  //               // for navigate to demo when user move to app first time

  //               localStorage.setItem(btoa("lesson_id"), btoa(data.data.lesson_id))
  //               this.global.max_read_slide = 0
  //               this.global.lesson_id = data.data.lesson_id
  //               this.route.navigate(["lesson/" + 'demo']);
  //             } else {
  //               this.route.navigate(["user"]);
  //             }
  //           }

  //         }
  //       } else {
  //         this.global.showDangerToast("", this.global.termsArray[data.message]);
  //       }
  //     },
  //     err => {
  //       this.global.showDangerToast("", err.message);
  //     },
  //     true
  //   );
  // }

  // // after completion of facebook login  store data in backend
  // loginWithGoogle(user) {
  //   let body = {};
  //   body["google_id"] = user.id;
  //   body["language_id"] = this.global.selectLanguage;
  //   body["email"] = user.email ? user.email : "";
  //   body["surname"] = user.last_name ? user.last_name : "";
  //   body["name"] = user.first_name ? user.first_name : "";
  //   body["learning_language_id"] = this.global.learningLanguage ? this.global.learningLanguage : "";

  //   this.global.post(
  //     "loginwithgoogle",
  //     JSON.stringify(body),
  //     data => {
  //       if (data.success) {
  //         this.global.showToast("", this.global.termsArray[data.message]);
  //         if (data.data.isCompleted === 0) {
  //           this.global.navigate(["register"], { user: user, type: 'google' });
  //         } else {

  //           this.global.selectLanguage = data.data.language_id;
  //           localStorage.setItem(btoa("selectLanguage"), btoa(data.data.language_id));
  //           this.global.learningLanguage = data.data.learning_language_id
  //           this.global.getTermsData();

  //           if (data.data.status === 0) {
  //             this.global.navigate(["verify"], {
  //               email: data.data.email,
  //               lastScreen: "google"
  //             });
  //           } else {
  //             this.global.loginTime = new Date()
  //             localStorage.setItem(btoa("user"), btoa(JSON.stringify(data.data)));
  //             localStorage.setItem("setupTime", this.global.setupTime + "")
  //             localStorage.setItem(btoa("AuthToken"), btoa(data.data.token));
  //             localStorage.setItem(btoa("selectLanguage"), btoa(data.data.language_id));
  //             this.global.AuthToken = data.data.token;
  //             this.global.user = data.data;
  //             this.global.isLogin = true;
  //             if (data.data.first_time) {
  //               // for navigate to demo when user move to app first time

  //               localStorage.setItem(btoa("lesson_id"), btoa(data.data.lesson_id))
  //               this.global.max_read_slide = 0
  //               this.global.lesson_id = data.data.lesson_id
  //               this.route.navigate(["lesson/" + 'demo']);
  //             } else {
  //               this.route.navigate(["user"]);
  //             }
  //           }
  //         }
  //       } else {
  //         this.global.showDangerToast("", this.global.termsArray[data.message]);
  //       }
  //     },
  //     err => {
  //       this.global.showDangerToast("", err.message);
  //     },
  //     true
  //   );
  // }
  goToBack(){
    let slug = sessionStorage.getItem(btoa("backToHome")) ? atob(sessionStorage.getItem(btoa("backToHome"))) : null;
    if(slug){
      sessionStorage.removeItem(btoa("backToHome"));
      this.route.navigate(['/']);
    }else{
      const prev = sessionStorage.getItem('prevRoute');
      const pathArr = window.location.pathname.split('/');
      const currentPage = pathArr[pathArr.length-1];
      if(prev && prev != currentPage){
        this.location.back();
      }else{
        this.route.navigate(['/']);
      }
    }
  }
  togglePassword(passwordField:any, toggleButton:any, toolTip:any){
    if(passwordField.type == "password"){
      passwordField.type = "text";
      toggleButton.classList.add('active');
      toolTip.setAttribute('data-title',this.global.termsArray["msg_hide_password"] || 'Passwort verbergen');
    }else{
      passwordField.type = "password";
      toggleButton.classList.remove('active');
      toolTip.setAttribute('data-title',this.global.termsArray["msg_show_password"] || 'Passwort anzeigen');
    }
  }
}
