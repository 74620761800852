
<div class="inner-wraper">
  <section class="lesson-cont-sect lesson-cover-sect">
    <div class="container">
      <div class="lektionen-cover-new">
        <article>
          <div class="list-sentens text-center">
            <div class="setting-sec setting-sec-arrow">
              <div class="panel-heading" role="tab" id="headingOne">
                <h4 class="panel-title">
                  <a role="button" class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne"
                    aria-expanded="false" aria-controls="collapseOne">
                    {{ this.global.termsArray["lbl_profile"] }}
                  </a>
                  <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordion">
                    <div class="accordion-body">
                      <ul class="setting-sec-in">
                        <li>
                          <a (click)="navigate('profile')" class="cp"> {{ this.global.termsArray["lbl_user_profile"]
                            }}</a>
                        </li>
                        <li>
                          <a (click)="navigate('change-password')" class="cp">{{
                            this.global.termsArray["lbl_change_password"] }}</a>
                        </li>
                        <li>
                          <a (click)="navigate('account')" class="cp"> {{ this.global.termsArray["lbl_account"] }}</a>
                        </li>
                        <li>
                          <a (click)="navigate('gift-cards')" class="cp"> {{ this.global.termsArray["lbl_gift_cards"] }}</a>
                        </li>
                        <!-- <li>
                                <a (click)="navigate('transaction-history')"> {{ this.global.termsArray["lbl_transaction_history"] }}</a>
                              </li> -->
                      </ul>
                    </div>
                  </div>
                </h4>
              </div>

              <div class="panel-heading" role="tab" id="headingOne">
                <h4 class="panel-title">
                  <a role="button" class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse3"
                    aria-expanded="false" aria-controls="collapse3" (click)="navigate('choose-learning-language')">
                    {{ this.global.termsArray["lbl_change_learning_title"] }}
                  </a>
                </h4>
              </div>

              <div class="panel-heading" role="tab" id="headingOne" *ngIf="languageData && languageData.length > 1">
                <h4 class="panel-title">
                  <a role="button" class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse4"
                    aria-expanded="false" aria-controls="collapse4" (click)="navigate('choose-app-language')">
                    {{ this.global.termsArray["lbl_change_web_language"] }}
                  </a>
                </h4>
              </div>
              <div class="panel-heading" role="tab" id="headingOne">
                <h4 class="panel-title">
                  <a role="button" class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse3"
                    aria-expanded="false" aria-controls="collapse3" (click)="navigate('learning-goals')">
                    <!-- learning-goals -->
                    {{ this.global.termsArray["lbl_learning_goals"] }}
                  </a>
                </h4>
              </div>

              <div class="panel-heading" role="tab" id="headingOne">
                <h4 class="panel-title">
                  <a role="button" class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse3"
                    aria-expanded="false" aria-controls="collapse3" (click)="navigate('awards')">
                    {{ this.global.termsArray["lbl_awards"] }}
                  </a>
                </h4>
              </div>

              <div class="panel-heading" role="tab" id="headingOne">
                <h4 class="panel-title">
                  <a role="button" class="collapsed" data-toggle="collapse" data-parent="#accordion"
                    href="#collapseAboutBirlingo" aria-expanded="false" aria-controls="collapseAboutBirlingo">
                    {{ this.global.termsArray["lbl_about_birlingo"] }}
                  </a>
                  <div id="collapseAboutBirlingo" class="accordion-collapse collapse" data-bs-parent="#accordion">
                    <div class="accordion-body">
                      <ul class="setting-sec-in">
                        <li>
                          <a (click)="navigate1()" class="cp"> {{ this.global.termsArray["lbl_method"] }} </a>
                        </li>
                        <!-- <li>
                                <a  (click)="navigate('rating')" class="cp"> {{ this.global.termsArray["lbl_rate_birlingo"] }} </a>
                              </li> -->

                        <li>
                          <a data-toggle="modal" data-target="#ratingModal" class="cp"> {{
                            this.global.termsArray["lbl_rate_birlingo"] }} </a>
                        </li>
                        <li>
                          <a (click)="navigate('terms-use')" class="cp"> {{ this.global.termsArray["lbl_terms_use"] }}
                          </a>
                        </li>
                        <li>
                          <a (click)="navigate('privacy')" class="cp"> {{ this.global.termsArray["lbl_privacy_policy"]
                            }} </a>
                        </li>

                      </ul>
                    </div>
                  </div>
                </h4>
              </div>

              <div class="panel-heading" role="tab" id="headingOne">
                <h4 class="panel-title">
                  <a role="button" class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse9"
                    aria-expanded="false" aria-controls="collapse9" (click)="navigate('support')">
                    {{ this.global.termsArray["lbl_Support_new"] }}
                  </a>
                </h4>
              </div>
              <div class="panel-heading" role="tab" id="headingOne">
                <h4 class="panel-title">
                  <a role="button" class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse15"
                    aria-expanded="false" aria-controls="collapse15" (click)="logout()">
                    {{ this.global.termsArray["lbl_Logout"] }}
                  </a>
                </h4>
              </div>
            </div>
          </div>
        </article>
        <button type="button" class="btn btn-danger d-none" id="rating1" data-dismiss="modal" data-toggle="modal"
          data-target="#ratingModal"></button>
        <button type="button" class="btn btn-danger d-none" id="rating2a" data-dismiss="modal" data-toggle="modal"
          data-target="#ratingModal2a"></button>
        <button type="button" class="btn btn-danger d-none" id="rating2b" data-dismiss="modal" data-toggle="modal"
          data-target="#ratingModal2b"></button>
        <button type="button" class="btn btn-danger d-none" id="rating3a" data-dismiss="modal" data-toggle="modal"
          data-target="#ratingModal3a"></button>
        <button type="button" class="btn btn-danger d-none" id="rating3b" data-dismiss="modal" data-toggle="modal"
          data-target="#ratingModal3b"></button>
        <app-app></app-app>
      </div>
    </div>
  </section>
</div>




<div class="modal modal-radius fade" id="rate-birlingo-step1-modal" tabindex="-1"
  aria-labelledby="rateBirlingoStep1Modal" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="modal-heading text-center mb-4">
          <h4>{{ this.global.termsArray["lbl_ask_for_review"] }}</h4>
        </div>
        <div class="text-center">
          <button type="button" class="btn btn-info" data-dismiss="modal" aria-label="Close">
            {{ this.global.termsArray["lbl_ok_rating"] }}
          </button>
        </div>
        <div class="text-center mt-4">
          <button type="button" class=" btn btn-info" data-dismiss="modal" aria-label="Close">
            {{ this.global.termsArray["lbl_not_now"] }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="modal fade" id="ratingModal" role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog bd-example-modal-lg modal-dialog-centered">
    <div class="modal-content d-flex rating-modal modal-bg">
      <div class="modal-heading">
        <button type="button" class="close close-modal" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" class="bi bi-x close-svg" viewBox="0 0 16 16">
            <defs>
              <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" style="stop-color:#c75aa3; stop-opacity:1" />
                <stop offset="100%" style="stop-color:#70b0da; stop-opacity:1" />
              </linearGradient>
            </defs>

            <path
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"
              fill="url(#grad1)" />
          </svg>
        </button>
      </div>
      <div class="modal-body">
        <div class="rate-texts-c">
          <h6 class="rating-modal-heading text-color-purple mt-3 mb-4">{{ global.termsArray["lbl_like_birlingo_1"] }}
          </h6>
        </div>
        <div class="example-result-card">
          <mat-slider max="10" min="1" step="1" [value]="rated" class="rating-slider w-100" showTickMarks="false"
            (input)="onSliderChange($event)">
            <input matSliderThumb [(ngModel)]="rated" #slider value="rated">
          </mat-slider>
          <div class="d-flex mb-5 w-100 justify-content-between">
            <div class="d-flex flex-column align-items-center">
              <span class="rating-suggetion-values">1</span>
              <span class="rating-suggetion-text text-color-purple">{{ global.termsArray["lbl_bad_rating"] }}</span>
            </div>
            <div class="d-flex flex-column align-items-center">
              <span class="rating-suggetion-values">10</span>
              <span class="rating-suggetion-text text-color-purple">{{ global.termsArray["lbl_super_rating"] }}</span>
            </div>
          </div>
        </div>
        <div class="d-flex mb-5">
          <button type="submit" class="btn btn-sm btn-info" (click)="goToStep2();" data-toggle="modal"
            data-dismiss="modal">
            {{ global.termsArray["lbl_next"] }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="ratingModal2a" role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog bd-example-modal-lg modal-dialog-centered">
    <div class="modal-content d-flex rating-modal modal-bg">
      <div class="modal-heading">
        <button type="button" class="close close-modal" data-dismiss="modal" aria-label="Close" id="close2a"  (click)="closeModal()">
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" class="bi bi-x " viewBox="0 0 16 16">
            <defs>
              <linearGradient id="grad2a" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" style="stop-color:#c75aa3; stop-opacity:1" />
                <stop offset="100%" style="stop-color:#70b0da; stop-opacity:1" />
              </linearGradient>
            </defs>

            <path
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"
              fill="url(#grad2a)" />
          </svg>
        </button>
      </div>
      <div class="modal-body">
        <div class="rate-texts-c">
          <h6 class="rating-modal-heading text-color-purple mb-4">{{ global.termsArray["lbl_improve_rating"] }} </h6>
        </div>
        <textarea (input)="checkSpace()" class="form-control feedback-input feedback-textarea" [(ngModel)]="feedback"
          style="color: #4882c2" [placeholder]="global.termsArray['lbl_write_message']"></textarea>
        <!-- <ng-container *ngIf="isError">
                      <span class="text-danger mb-2">{{error}}</span>
                  </ng-container><br /> -->

          <div class="d-flex mb-5 mt-4">
            <button type="submit" class="btn btn-sm btn-info" (click)="submitRating();" >
              {{ global.termsArray["absenden_btn"] }}
            </button>
          </div>

      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="ratingModal2b" role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog bd-example-modal-lg modal-dialog-centered">
    <div class="modal-content d-flex rating-modal modal-bg">
      <div class="modal-heading">
        <button type="button" class="close close-modal" data-dismiss="modal" aria-label="Close" id="close2b"  (click)="closeModal()">
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" class="bi bi-x " viewBox="0 0 16 16">
            <defs>
              <linearGradient id="grad2b" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" style="stop-color:#c75aa3; stop-opacity:1" />
                <stop offset="100%" style="stop-color:#70b0da; stop-opacity:1" />
              </linearGradient>
            </defs>

            <path
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"
              fill="url(#grad2b)" />
          </svg>
        </button>
      </div>
      <div class="modal-body">
        <div class="rate-texts-c">
        <h6 class="rating-modal-heading text-color-purple mb-4">{{ global.termsArray["lbl_rate_birlingo_1"] }} </h6>
        </div>
        <div class="d-flex mb-5 w-100 flex-column">
          <button class="btn btn-sm btn-info d-flex flex-column align-items-center mb-3 h-auto app-button" type="submit"
            (click)="goToStep3('app')">
            {{ global.termsArray["lbl_in_app_store"] }}
            <span>{{ global.termsArray["lbl_in_app_store_sub"] }}</span>
          </button>
          <div class="d-flex flex-column align-items-center purple-color cp fw-bold" (click)="goToStep3('web')"> {{
            global.termsArray["lbl_direct_feedback"] }}</div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="ratingModal3a" role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog bd-example-modal-lg modal-dialog-centered">
    <div class="modal-content d-flex rating-modal modal-bg">
      <div class="modal-heading">
        <button type="button" class="close close-modal" data-dismiss="modal" aria-label="Close" id="close3a" (click)="closeModal()">
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" class="bi bi-x " viewBox="0 0 16 16">
            <defs>
              <linearGradient id="grad3a" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" style="stop-color:#c75aa3; stop-opacity:1" />
                <stop offset="100%" style="stop-color:#70b0da; stop-opacity:1" />
              </linearGradient>
            </defs>

            <path
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"
              fill="url(#grad3a)" />
          </svg>
        </button>
      </div>
      <div class="modal-body">
        <div class="rate-texts-c">
        <h6 class="rating-modal-heading text-color-purple mb-4">{{ global.termsArray["lbl_direct_feedback_1"] }} </h6>
        </div>
        <textarea (input)="checkSpace()" class="form-control feedback-input feedback-textarea" [(ngModel)]="feedback"
          [placeholder]="global.termsArray['lbl_write_message']" style="color: #4882c2"></textarea>
        <ng-container *ngIf="isError">
          <span class="text-danger">{{error}}</span>
        </ng-container><br />
        
      <div class="d-flex mb-5">
        <button type="submit" class="btn btn-sm btn-info" (click)="submitRating();" >
          {{ global.termsArray["absenden_btn"] }}
        </button>
      </div>

    </div>
  </div>
</div>

<div class="modal fade" id="ratingModal3b" role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog bd-example-modal-lg modal-dialog-centered">
    <div class="modal-content d-flex rating-modal modal-bg">
      <div class="modal-heading">
        <button type="button" class="close close-modal" data-dismiss="modal" aria-label="Close" id="close3b"  (click)="closeModal()">
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" class="bi bi-x " viewBox="0 0 16 16">
            <defs>
              <linearGradient id="grad3b" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" style="stop-color:#c75aa3; stop-opacity:1" />
                <stop offset="100%" style="stop-color:#70b0da; stop-opacity:1" />
              </linearGradient>
            </defs>

            <path
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"
              fill="url(#grad3b)" />
          </svg>
        </button>
      </div>
      <div class="modal-body">
        <div class="rate-texts-c">
          <h6 class="rating-modal-heading text-color-purple mb-4">{{global.termsArray["ask_for_rating_apps"] ?
            global.termsArray["ask_for_rating_apps"] : 'Would you like to give us a feedback on our app store'}} </h6>
        </div>
        <div class="d-flex mb-3 w-100 flex-column">
          <button class="btn btn-sm btn-info d-flex flex-column align-items-center mb-3 h-auto" type="submit"
            (click)="openUrl(1)">{{ global.termsArray["open_android_app"] }}
          </button>
          <button class="btn btn-sm btn-info d-flex flex-column align-items-center h-auto" type="submit"
            (click)="openUrl(2)">{{ global.termsArray["open_ios_app"] }}
          </button>
        </div>

      </div>
    </div>
  </div>
</div>
