import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { GlobalService } from "../../global.service";
import { Router, ActivatedRoute} from "@angular/router";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { TemplateRef } from "@angular/core";
import { config } from 'rxjs';
// import { GoogleTagManagerService } from "angular-google-tag-manager";
declare var dataLayer: any;
declare var PostAffTracker: any;
@Component({
  selector: "app-choose-lesson",
  templateUrl: "./choose-lesson.component.html",
  styleUrls: ["./choose-lesson.component.scss"],
})

export class ChooseLessonComponent implements OnInit {

  LessonFamily: any;
  isSubscribed: any;
  modalRef: BsModalRef;
  completeCount: any = 0;
  totalCount: any = 0;
  lastPlayedLessons: any;
  loader = false;
  
  constructor(public global: GlobalService, 
    public router: Router, 
    private modalService: BsModalService,
    public actRoute: ActivatedRoute,
    // private gtmService: GoogleTagManagerService,
    ) {
    if (this.global.user.isFreeAccess && this.global.user.isFreeAccess == 1) this.isSubscribed = 1; else this.getSubsriptionStatus();
    global.setCurrentUserInterval();
    this.global.profileTab = 1;
    localStorage.removeItem(btoa("routeParams"));
    this.global.routeParams = null;
    // this.getSubsriptionStatus();
  }

  ngOnInit() {
    this.actRoute.queryParams.subscribe(async (data:any)=>{
      if(data && data.subscribedbystripe && data.subscribedbystripe == "true"){
        console.log("subscribedbystripe",data.subscribedbystripe);
        /** PostAffTracker >>>>>*/
        await this.getAffiliateInfo();
        /** PostAffTracker <<<<<*/
        setTimeout(() => {
          dataLayer.push({'event':'buying-process-completed'});
          console.log("Event fired (buying-process-completed)");
          this.router.navigate(['/choose-lesson']);
        }, 3000);
      }
    });
    // this.actRoute.queryParams.subscribe((data:any)=>{
    //   if(data && data.subscription && data.subscription == "true" && (data.subscription_id || data.paymentId)){
    //     const subscriptionId = data.paymentId?data.paymentId:data.subscription_id;
    //     this.activateSubscription(subscriptionId);
    //   }
    // });

    this.global.loader = true;
    setTimeout(() => { this.getLessonFamily();});
    this.getLastPlayedLessons();
  }

  getSubsriptionStatus() {
    this.global.get("getSubsriptionStatus", (data) => {
      if (data.success) {
        this.isSubscribed = data.data.is_subsribed;
        // console.log("isSubscribed: ", this.isSubscribed);
      }
    }, (err) => { }, true);
  }

  // refreshPage() {
  //   window.location.reload();
  // }
  
  navigate(type, data, progress = null) {
    sessionStorage.setItem("lesson_family_id",data._id);
    // return;
    if (type == "sentences") {
      this.router.navigate(["lesson-description/" + data._id]);
      localStorage.setItem(btoa("Lfamily"), data.Lfamily);
    } else {
      if (data.lesson_id) {
        localStorage.setItem(btoa("lesson_id"), btoa(data.lesson_id));
        this.global.lesson_id = data.lesson_id;
      }

      localStorage.setItem(btoa("max_read_slide"), btoa(data.levels[0].progres));
      localStorage.setItem(btoa("time_loop"), btoa(data.levels[0].time_loop));
      this.global.max_read_slide = data.levels[0].progres;
      this.router.navigate(["lesson/" + type]);
    }
    
  }

  getLessonFamily() {
    let body = {};
    body["language_id"] = this.global.selectLanguage;
    body["user_id"] = this.global.user._id;
    this.global.post("lesson-family-v2", JSON.stringify(body), (data) => {
      if (data.success) {
        this.LessonFamily = data.data;

        this.totalCount = data.data.family.length > 0 ? data.data.family.length : 1;
        data.data.family.map((ele) => {
          if (ele.progress >= 100) {
            this.completeCount++;
          }
        });
      } else {
        this.global.showDangerToast(this.global.termsArray[data.message]);
      }
    },
    (err) => {
      this.global.showDangerToast(err.message);
    }, true, 1000 );
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  lessonDes(level_id,family){
    localStorage.setItem('level_id',level_id)
    localStorage.setItem(btoa("Lfamily"), family.Lfamily);
    this.router.navigate(["lesson-description/" +family._id]);
  }

  setFamilyTitle(family:any){
    sessionStorage.setItem('familyTitle',this.global.termsArray[family]);
  }
  
  getAffiliateInfo(){
    return new Promise((resolve, reject)=>{
      this.global.post("get-affiliate-info", JSON.stringify({}), (response:any) => {
        if (response.success && response.data) {
          let data:any = response.data;
          PostAffTracker.setAccountId('default1');
          let sale = PostAffTracker.createSale();
          sale.setTotalCost(data.baseSubsData.price);
          sale.setOrderID(data.subscription_name);
          sale.setProductID(data.baseSubsData._id);
          PostAffTracker.register();
          console.log("Affiliate sale marked");
          resolve(response.data);
        } else {
         reject();
        }
      }, (err) => {
        reject();
        this.global.showDangerToast("Error", err.message);
      }, true);
    })
  }

  
  getLastPlayedLessons(page = 1) {
    let body = {
    learning_language_id :this.global.user.learning_language_id
    };
    this.global.post("last-played-lessons", JSON.stringify(body), (data) => {
      if (data.success) {
        this.lastPlayedLessons = data.data && data.data[0]  ? data.data[0] : null;
      } else {
        this.global.showDangerToast(this.global.termsArray[data.message]);
        this.loader = true;
      }
    }, (err) => {
      this.global.showDangerToast("",err.message);
    }, true);
  }


  returnLevel(level_id) {
    let level = "";
    if (level_id == 1) {
      level = "lbl_level_one";
    } else if (level_id == 2) {
      level = "lbl_level_two";
    } else if (level_id == 3) {
      level = "lbl_level_three";
    } else if (level_id == 4) {
      level = "lel_level_four";
    }
    return level;
  }


  setLessonTitle(lesson:any){
    sessionStorage.setItem('lessonTitle',lesson.title);
    sessionStorage.setItem('lessonLevel',this.global.termsArray[this.returnLevel(lesson.level_id)]);
  }

  navigateToSubscription() {
    this.router.navigate(["account"]);
  }

  navigateLastPlayed(type, lesson, template?) {
    console.log("this.isSubscribed",this.isSubscribed)
    sessionStorage.setItem("lessonTitle",lesson.lessonTitle|| "");
    sessionStorage.setItem("familyTitle" ,this.global.termsArray[lesson.family_name]|| "")
    sessionStorage.setItem("lesson_family_id",lesson.lessonfamily_id);
    localStorage.setItem(btoa("Lfamily"), lesson.family_name);
    // return;
    if (type=='demo' && lesson.is_free && lesson.is_demo == 1) {
      if (lesson.lesson_id) {
        localStorage.setItem(btoa("lesson_id"), btoa(lesson.lesson_id));
        this.global.lesson_id = lesson.lesson_id;
      }
      localStorage.setItem(btoa("max_read_slide"), btoa(lesson.progres));
      localStorage.setItem(btoa("time_loop"), btoa(lesson.time_loop));
      this.global.max_read_slide = lesson.progres;
      (lesson.active_progress == 100 && lesson.passive_progress == 100 && lesson.repeat_progress == 100 && lesson.speak_progress == 100 ) ? this.router.navigate(["repeated-lesson/demo"]):this.router.navigate(["lesson/demo"]);
    } 
    else if (this.isSubscribed || lesson.is_free|| this.isWithinFreeTrial()) {
      localStorage.setItem(btoa("baselesson_id"), btoa(lesson.baselesson_id));
      localStorage.setItem(btoa("lessonfamily_id"), btoa(lesson.lessonfamily_id));
      localStorage.setItem(btoa("lesson_id"), btoa(lesson._id));
      localStorage.setItem(btoa("lesson_title"), btoa(lesson.title + " "));
      localStorage.setItem(btoa("max_read_slide"), btoa(lesson.progress));
      localStorage.setItem(btoa("time_loop"), btoa(lesson.time_loop));
      this.global.baselesson_id = lesson.baselesson_id;
      this.global.lessonfamily_id = lesson.lessonfamily_id;
      this.global.lesson_id = lesson._id;
      this.global.lesson_title = lesson.title + " ";
      this.global.max_read_slide = lesson.progress;
      (lesson.active_progress == 100 && lesson.passive_progress == 100 && lesson.repeat_progress == 100 && lesson.speak_progress == 100 ) ? this.router.navigate(["repeated-lesson/sentences"]) : this.router.navigate(["lesson/" + "sentences"]);
    }
    else {
      this.openModal(template);
    }
    
  }
  

  isWithinFreeTrial(): boolean {
    let result = false
    if (!this.global.user.freeTrialEndDate) {
      return result;
    }
    const currentDate = new Date();
    const freeTrialEndDate = new Date(this.global.user.freeTrialEndDate);
    result = currentDate < freeTrialEndDate;
    return result;
  }

}
