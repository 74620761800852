import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/global.service';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.sass']
})
export class DownloadsComponent implements OnInit {

  constructor(public global: GlobalService) {
    this.global.profileTab = 3
  }

  ngOnInit() {
  }
  openUrl(type) {
    let obj = { 
      1: localStorage.getItem('play_url'),
      2: localStorage.getItem('apple_url') }
    window.open(obj[type], "_blank");
  }
}
