import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from 'src/app/global.service';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
 
@Component({
  selector: 'app-cancellation-feedback',
  templateUrl: './cancellation-feedback.component.html',
  styleUrls: ['./cancellation-feedback.component.sass']
})
export class CancellationFeedbackComponent implements OnInit {
  imgFiles: any;
  apiUrl = environment.apiUrl;
  invalidImageType: boolean = false;
  submitted: boolean = false;
  feedbackForm:FormGroup;
  constructor(
    private fb:FormBuilder,
    public global: GlobalService,
    private ngxService: NgxUiLoaderService,
    public http: HttpClient,
  ) { }

  ngOnInit() {
    this.createFeedbackForm();
    console.log("**",btoa('AuthToken'))
    console.log("**",localStorage.getItem(btoa('AuthToken')))
  }
  createFeedbackForm(){
    this.feedbackForm = this.fb.group({
      reason: new FormControl('',Validators.required),
      screenshots: new FormControl('')
    });
  }
  showFiles(event: any) {
    const preview = document.querySelector('#preview');
    preview.innerHTML = "";

    const files = [];
    Object.keys(event.target.files).forEach(element => {
      let file = event.target.files[element];
      if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
        files.push(file);
      } else {
        this.invalidImageType = true;
        return;
      }
    });

    function readAndPreview(file: any) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const image = new Image() as any;
        image.height = 100;
        image.title = file.name;
        image.src = reader.result;
        preview.appendChild(image);
      }, false);

      reader.readAsDataURL(file);
    }
    this.imgFiles = event.target.files;
    if (files) {
      Array.prototype.forEach.call(files, readAndPreview);
    }
  }


  submitFeedback(){
    this.submitted = true;
    if(this.feedbackForm.valid && !this.invalidImageType){
      this.ngxService.start();
      const formData = new FormData();
      formData.append('reason',this.feedbackForm.value.reason);
      if(this.imgFiles && this.imgFiles.length>0){
        for (let i = 0; i < this.imgFiles.length; i++) {
          formData.append("files", this.imgFiles[i]);
        }
      }
      let headers = new HttpHeaders({
        Authorization:"Bearer " + atob(localStorage.getItem(btoa('AuthToken')))
      });
      this.http.post(this.apiUrl+'save-cancel-feedback',formData,{headers:headers}).subscribe((response:any)=>{
        setTimeout(() => {
          this.ngxService.stop();
        }, 1000);
        if(response.status == 1){
          this.global.showToast("", this.global.termsArray[response.message]);
          this.submitted = false;
          const preview = document.querySelector('#preview');
          preview.innerHTML = "";
          this.feedbackForm.reset();
        }else{
          this.global.showDangerToast("", this.global.termsArray[response.message]);
        }
      },(err)=>{
        this.global.showDangerToast("", err.message);
      });
    }else{
      this.global.showDangerToast("", this.global.termsArray['err_fill_required_fields']);
    }
  }
}
