export class commonConstants {
    blogDetailTitle = "Birlingo Sprachlern-Blog";
    blogDetailDescription = "Finden Sie Beiträge zum Thema Sprachenlernen und der Sprachlernmethode nach Vera F. Birkenbihl.";
    description = "description";
    homeTitle = '"Sprachen lernen nach Vera F. Birkenbihl"';
    homeDescription = 'Dein Sprachaufenthalt für zuhause – in nur 10 Minuten am Tag. Ohne Vokabeln und Grammatik pauken.';
    methodTitle = "Die Birkenbihl Sprachlernmethode";
    methodDescription = 'In drei einfachen Schritten gehirngerecht Sprachen lernen, fast so, wie ein Kind seine Muttersprache lernt.' ;
    priceTitle = "Birlingo Preise";
    priceDescription = 'Erfahren Sie, was die Birlingo Sprachlernkurse kosten, als Livetime-Zugang oder als Abo.' ;
}