<ng-container *ngIf="!global.mobileCms">

  <!-- header will be render before login -->
  <header *ngIf="!global.isLogin" class="outer-head">
    <div class="container-fluid">
      <nav class="navbar navbar-expand-lg navbar-light">
        <div class="navbar-brand">
          <a class="navbar-brand-in" (click)="crossBtnClicked('/')" [routerLink]="['']">
            <img src="assets/images/logo.svg" alt="home logo image" />
          </a>
        </div>

        <button class="navbar-toggler" [ngClass]="showCross == true ? 'cross-btn' : '' " type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="Toggle navigation" (click)="crossBtnClicked()">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="crossBtnClicked('/')" >Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['method']" routerLinkActive="active" (click)="crossBtnClicked('/method')">{{ this.global.termsArray["lbl_method"] ? this.global.termsArray["lbl_method"] : "Method" }}</a>
            </li>
            <!-- <li class="nav-item">
              <a [routerLink]="['blog']" [ngClass]="global.isBlogActive ? 'nav-link active' : 'nav-link'" >{{ this.global.termsArray["lbl_blog"] ? this.global.termsArray["lbl_blog"] : "Blog" }}</a>
            </li> }}-->
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['blog']"
                [ngClass]="global.isBlogActive ? 'nav-link active' : 'nav-link'" (click)="crossBtnClicked('/blog')">{{ this.global.termsArray["lbl_blog"] ? this.global.termsArray["lbl_blog"] : "Blog" }}</a>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link" [routerLink]="['reviews']" routerLinkActive="active" (click)="setPageTitle('Reviews')" >{{ this.global.termsArray["lbl_reviews_list"] ? this.global.termsArray["lbl_reviews_list"] : "Reviews" }}</a >
            </li> -->
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['price']" (click)="crossBtnClicked('/price')">{{ this.global.termsArray["lbl_price"] ? this.global.termsArray["lbl_price"] : "Price" }}</a>
            </li>
            <!-- lbl_customer_voices -->
          </ul>
          <div class="dropdown" style="margin-left: 30px;" *ngIf="languageArray && languageArray.length > 1">
            <button class="btn btn-info dropdown-toggle hover-btn" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="font-size: 14px; font-family: 'Akkurat-Bold'; background: none; backface-visibility: hidden; box-shadow: none; ">
              {{ global.selectedLabel ? global.selectedLabel : global.termsArray["lbl_choose_app_language"] }}<img src="assets/images/down-arrow.png" style="margin-left: 5px; margin-top: -10px;" alt="down arrow image" />
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenu2" style="margin-top: 40px; height: auto;">
              <button [ngClass]="language._id == global.selectLanguage ? 'dropdown-item active' : 'dropdown-item'" type="button" style="font-size: 14px; font-family: 'Akkurat-Bold';" *ngFor="let language of languageArray" (click)="selectLanguage(language._id, language.label)">
                {{ global.termsArray[language?.term] }}
              </button>
            </div>
          </div>
        </div>

        <div class="try-butt-left" *ngIf="global.isLogin">
          <figcaption class="nav-link btn btn-black" (click)="router.navigate(['user'])">
            {{ global.user.name ? global.user.name.length > 10 ? global.user.name.slice(0, 10) + ".." : global.user.name : "" }}
          </figcaption>

          <div>
            <a class="nav-link" (click)="logout()">{{
              this.global.termsArray["lbl_Logout"]
              }}</a>
          </div>
        </div>
        <div class="try-butt-left" *ngIf="!global.isLogin">
          <!-- <a class="nav-link btn btn-black m_header_btn" style=" display: flex; justify-content: center; align-items: center; text-align: center; " [routerLink]="['register']" routerLinkActive="active" (click)="setPageTitle('Sign up')">{{ this.global.termsArray["lbl_try_free"] ? this.global.termsArray["lbl_try_free"] : "Try free" }}</a> -->
          <div class="dropdown gratis-drop">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              gratis testen
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

              <a class="dropdown-item cp" (click)="openUrl(2)"><i
                  class="fab fa-apple"></i>{{global.termsArray['lbl_App_Store_download']}}</a>
              <a class="dropdown-item cp" (click)="openUrl(1)"><i class="fab fa-google-play"></i>
                {{global.termsArray['lbl_Google_Play_Store']}}</a>
              <a class="dropdown-item" [routerLink]="['/register']"><i
                  class="fas fa-laptop"></i>{{global.termsArray['lbl_At_the_computer']}}</a>
            </div>
          </div>

          <a class="nav-link btnn-links disp-none" [routerLink]="['login']" routerLinkActive="active"
            (click)="setPageTitle('Login')">{{ this.global.termsArray["lbl_login"] ? this.global.termsArray["lbl_login"]
            : "Login" }}</a>
        </div>
      </nav>
    </div>
  </header>

  <!-- header will be render after login -->
  <ng-container>
    <header class="inner-headder new_chgs01" *ngIf="global.isLogin">
      <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-light">
          <div class="navbar-brand">
            <a class="navbar-brand-in" href="javascript:;" [routerLink]="['choose-lesson']"
              (click)="setPageTitle('Lesson overview')">
              <img src="assets/images/logo.svg" alt="home logo image" />
            </a>
          </div>

          <button class="navbar-toggler" [ngClass]="showCross == true ? 'cross-btn' : '' " type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="Toggle navigation" (click)="crossBtnClicked()">
            <span class="navbar-toggler-icon"><i class="ri-more-2-fill"></i></span>
          </button>


          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <div class="navbar-nav inner-btn-nav">

              <div class="footer-opp-sect">
                <ul>
                  <li [routerLink]="['/choose-lesson']">
                    <a href="javascript:;" (click)="crossBtnClicked('/choose-lesson')">
                      <figure><i class="ri-function-line"></i></figure>
                      <h6>{{ this.global.termsArray["lbl_lesson_overview"] }}</h6>
                    </a>
                  </li>
                  <li [routerLink]="['/setting']">
                    <a href="javascript:;" (click)="crossBtnClicked('/setting')">
                      <figure><i class="ri-settings-5-line"></i></figure>
                      <h6>{{ this.global.termsArray["lbl_Settings"] }}</h6>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" (click)="logout()" class="btn-black-rilogout" (click)="crossBtnClicked()">
                      <figure><i class="ri-logout-box-r-line"></i></figure>
                      <h6>{{ this.global.termsArray["lbl_Logout"] }}</h6>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>

      </div>
    </header>
  </ng-container>
</ng-container>