import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  HostListener,
  AfterViewInit,
  ElementRef,
  OnDestroy,
} from "@angular/core";
import { AudioService } from "src/app/audio.service";
import { of } from "rxjs";
import { GlobalService } from "src/app/global.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { type } from "jquery";
import { DeviceDetectorService } from "ngx-device-detector";
import is from "is_js";
import { stringify } from "querystring";
import * as e from "express";
import { NewAudioService } from "src/app/new-audio.service";
import { Track } from "ngx-audio-player";
import { Howl } from 'howler';
import { isSame } from "ngx-bootstrap/chronos";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { WeeklyGoalService } from "src/app/weekly-goal.service";


export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
}

declare var $: any;

@Component({
  selector: 'app-repeated-lessons',
  templateUrl: './repeated-lessons.component.html',
  styleUrls: ['./repeated-lessons.component.scss']
})
export class RepeatedLessonsComponent implements OnInit, AfterViewInit, OnDestroy {
  speedOptions = [
    { value: 0.5, selected: false },
    { value: 0.6, selected: false },
    { value: 0.7, selected: false },
    { value: 0.8, selected: false },
    { value: 0.9, selected: false },
    { value: 1.0, selected: false },
  ];
  selectedSleepTimer: number = 0; // Default selected speed
  sleepTimerOptions: number[] = this.generateSleepTimerOption(); // Generate speed options
  indexes = [1, 3, 5, 7, 10];
  screenHeight: any;
  screenWidth: any;
  gradientClass: any;
  carosalHide = true;
  modalConfirmation: any = false;
  loader: any = false;
  max_767_width: any = false;
  goToSentenceFlag: any = false;
  goToSentenceArray: any = null;
  lessonType: any;
  lessonProgress = 0;
  songLoopIndex: any;
  repeatIndex: any;
  speakIndex: any;
  hearStatus = 1;
  btnDisabled = false;
  value: any;
  calculateTimeCall = 0;
  timeInterval: any = 0;
  btnVal: any;
  loopStopWatchTime: any = localStorage.getItem(btoa("time_loop")) ? atob(localStorage.getItem(btoa("time_loop"))) : 0;
  files: Array<any> = [];
  audioLoopIndex = 0;
  isSubscribed: any;
  lessonTitle: string;
  breadCrumb: string;
  circleImage = {
    pause: "assets/images/playIcon.svg",
    play: "assets/images/pauseIcon.svg",
    refresh: "assets/images/refreshIcon.svg",
  };

  defaultSpeed: number = 0.8;
  isPlaying: boolean = false;
  isMobileDevice = false;
  public currentFile: any = {};
  public currentIndex = 0;
  public lessons: Array<any> = [];
  public lessonData: any = {};
  public Interval: any;
  public saveProgressInterval: any;
  // save history  after new changes object and variable
  currentState = {
    active: 1,
    passive: 2,
    speak: 3,
    repeat: 4,
  };

  saveHistory = {
    current: 0,
    level_id: 0,
    active: { indexArray: [0], total: 0, max: 1 },
    passive: { indexArray: [], total: 0, max: 0 },
    speak: { indexArray: [0], total: 0, max: 1 },
    repeat: { indexArray: [0], total: 0, max: 0 },
  };

  public demoFamily: any;
  // public removeWhiteBackground: boolean = false;
  public repeatScreenSentences: any = [];
  public repeatSelectedIndex: any;
  public repeatPlayedIndex: any;
  public isMenuIconsShow: boolean = false;
  public isShowfakeBtn: boolean = false;
  public fakeBtnData: any;
  public backgroundClass: any = "";
  browserName = "";
  isSafari: Boolean = false;
  isSafariMobile: Boolean = false;
  currentPlayingIndex: any = null;
  audio: HTMLAudioElement = new Audio();
  playingSentenceIndex: number = 0;
  alarmTimer: any;
  showTimerPopup: boolean = false;
  alarmTime: number = 15; /**Default time for alarm */
  alarmRealSeconds: number = 0;
  alarmStart: boolean = false;
  showAlarmClock: boolean = false;
  maxAlarmTime: number = 60; /**Max time for alarm */
  mssapPlaylist: Track[];
  repeatAudioType: string = "one";
  activeTab: Number = 1;
  private sound: Howl;
  playingPassiveSentence: any = 0
  playingRepeatSentence: any = 0
  is_already_lesson_completed: boolean = false
  is_lesson_completed: boolean = false
  selectLanguageTermFlag: any = this.global.termsArray["lbl_choose_language"];
  modalRef: BsModalRef;
  changedSentenceId: any = null;
  learningLanguages: any;
  changedLangugae: any = null;
  changedLessonId: any = null;
  selectedSentence: any = null
  learningSeconds: number = 0
  learningInterval: any;
  repeatation_cycle: number = 1;
  awards = this.global.awards;
  myGoals: any = null;
  todaylearningTime: number = 0;
  weeklyGoalCompleted: boolean = false;
  isIntervalPaused: boolean = false;
  totalGoalTime: number = 0;
  passiveListeningTime: number = 0;
  selectedSleepTimerValue: boolean = false;
  overallTime: any = '00:00';
  elapsedTime: any = 0;
  dont_show_awards: boolean = false;
  timerValue: any = 0;

  constructor(
    private audioService: AudioService,
    public global: GlobalService,
    private route: ActivatedRoute,
    public router: Router,
    private ngxService: NgxUiLoaderService,
    public breakpointObserver: BreakpointObserver,
    public location: Location,
    private deviceService: DeviceDetectorService,
    private elem: ElementRef,
    public newaudioService: NewAudioService,
    private modalService: BsModalService,
    public goalService: WeeklyGoalService
  ) {
    this.global.loader = true;
    this.global.profileTab = 1;
    // listen to stream state
    this.route.paramMap.subscribe((params) => {
      this.lessonType = params.get("id") ? params.get("id") : null;
      if (this.lessonType != "sentences" && this.lessonType != "demo") {
        location.back();
      }
    });

    this.getSubsriptionStatus();
    if (this.global.isPhone) {
      setTimeout(() => {
        this.carosalHide = false;
      }, 2000);
    } else {
      this.carosalHide = false;
    }
    this.browserName = this.detectBrowserName();
    if (this.browserName == "safari") {
      this.isSafari = true;
    } else {
      this.isSafari = false;
    }
  }

  ngOnInit() {
    this.dont_show_awards = this.global.user && this.global.user.dont_show_awards ? this.global.user.dont_show_awards : false
    this.lessonTitle = sessionStorage.getItem("lessonTitle") || "";
    this.breadCrumb = (sessionStorage.getItem("familyTitle") || "") + " > " + (sessionStorage.getItem("lessonLevel") || "");
    this.breakpointObserver.observe(["(max-width: 767px)"])
      .subscribe((result) => {
        if (result.matches) {
          this.max_767_width = false;
        } else {
          this.max_767_width = true;
        }
      });
    this.getLessons().then((promise) => {
      $(document).ready(() => {
        setTimeout(() => {
          $(".carousel").bind("slide.bs.carousel", (e) => {
            this.onSlide(e);
          });
        }, 3000);

        $(".carousel").swipe({
          swipe: (event, direction, distance, duration, fingerCount, fingerData) => {
            if (this.audio.src || this.sound) {
              this.pause();
              this.audio.src = null;
              this.isPlaying = false
              this.pauseTrack();
            }
            let currentIndex = $("div.active").index();
            if (direction == "left" && this.currentIndex < this.lessons.length - 1) {
              this.currentIndex = this.currentIndex + 1;
              // $(".lists_" + this.currentIndex).trigger("click");
              $(".carousel-control-next-icon").trigger("click");
              this.decideToShowPlayBtnOrNot();
            }

            if (direction == "right" && this.currentIndex != 0) {
              this.currentIndex = this.currentIndex - 1;
              if (this.currentIndex == 0)
                $("#video-carousel-example2").carousel(this.currentIndex);
              $(".carousel-control-prev-icon").trigger("click");
              this.decideToShowPlayBtnOrNot();
            }
          },
          allowPageScroll: "vertical",
        });

        $(".touch-event").on("touchend", function (e) {
          $(this).trigger("click");
          return;
        });

        if (this.global.max_repeated_read_slide && this.global.max_repeated_read_slide != null && this.global.max_repeated_read_slide !== 0) {
          setTimeout(() => {
            $(".lists_" + +this.global.max_repeated_read_slide).trigger("click");
            this.decideToShowPlayBtnOrNot();
            this.switchSlide(this.global.max_repeated_read_slide);
            return;
          }, 1000);
          return;
        }
      });
    });
    this.initSpeedView();
    this.speedOptions.forEach((item) => {
      if (item.value == this.defaultSpeed) {
        item.selected = true;
      }
    });
    this.isMobileDevice = this.deviceService.isMobile();

    this.getLearningMinutes();
    this.getGoals();
    sessionStorage.setItem(btoa("alarmFlag"), btoa("true"));
    sessionStorage.setItem(btoa("alarmCurrentTime"), this.selectedSleepTimer!=0 ? btoa(String(this.selectedSleepTimer * 60)) : btoa(String(900)));
    /** Manage timer for learning */

    this.goalService.getPauseInterval().subscribe(value => {
      this.isIntervalPaused = value
      console.log("this.isIntervalPaused", this.isIntervalPaused)
    });
  }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf("edge") > -1:
        return "edge";
      case agent.indexOf("opr") > -1 && !!(<any>window).opr:
        return "opera";
      case agent.indexOf("chrome") > -1 && !!(<any>window).chrome:
        return "chrome";
      case agent.indexOf("trident") > -1:
        return "ie";
      case agent.indexOf("firefox") > -1:
        return "firefox";
      case agent.indexOf("safari") > -1:
        return "safari";
      default:
        return "other";
    }
  }

  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode == KEY_CODE.RIGHT_ARROW) {
      if (this.audio.src || this.sound) {
        this.pause();
        this.audio.src = null;
        this.isPlaying = false
        this.pauseTrack();
      }
      $(".carousel-control-next-icon").trigger("click");
    } else if (event.keyCode == KEY_CODE.LEFT_ARROW) {
      if (this.audio.src || this.sound) {
        this.pause();
        this.audio.src = null;
        this.isPlaying = false
        this.pauseTrack();
      }
      $(".carousel-control-prev-icon").trigger("click");
    }
  }

  //clear the current sound on navigation
  ngOnDestroy() {
    this.isPlaying = false
    this.pauseTrack();
    this.updateLessonProgress(
      this.global.lesson_id,
      this.global.lessonfamily_id,
      this.global.baselesson_id,
      this.lessons.length,
      this.lessonProgress,
      this.lessonType,
      true
    );

    this.updateUserLearningMinutes();
    this.clearUpdateLearningInterval();
    // this.resetProgressBar();

    localStorage.removeItem('time_loop');
    sessionStorage.removeItem(btoa("alarmFlag"));
    sessionStorage.removeItem(btoa("alarmCurrentTime"));
    sessionStorage.removeItem(btoa("lessonTitle"));
    sessionStorage.removeItem(btoa("familyTitle"));
    sessionStorage.removeItem(btoa("lessonLevel"));
    sessionStorage.removeItem(btoa("sleepTimerCurrentTime"));

  }

  pause() {
    this.isPlaying = false;
    this.audio.pause();
  }


  calculateTime(data) {
    this.timeInterval = 0;
    data.reduce(async (promise, file) => {
      await promise;
      let data = await this.getDuration(file.sound);
      this.timeInterval = this.timeInterval + data;
    }, Promise.resolve());
    this.timeInterval = this.timeInterval;
    return this.timeInterval;
  }

  getDuration(src) {
    return new Promise((resolve, reject) => {
      var audio = new Audio();
      audio.src = src;
      audio.onloadedmetadata = () => {
        resolve(Math.round(audio.duration));
      };
    });
  }

  updateIndex(boolean) {
    if (this.btnDisabled) {
      return;
    }
    this.btnDisabled = true;
    setTimeout(() => {
      this.btnDisabled = false;
    }, 600);

    clearInterval(this.Interval);
  }

  // this function maintain the selection of a particular slide from Drop down
  switchSlide(index) {
    this.goToSentenceFlag = false;
    clearInterval(this.Interval);
    if (this.currentPlayingIndex != null) {
      this.lessons[this.currentPlayingIndex]["toggle"] = "pause";
    }
    this.isPlaying = false
    this.pauseTrack();
    this.currentFile = {};
    this.audioLoopIndex = 0;
    this.lessonProgress = this.currentIndex;
  }


  getLessons() {
    return new Promise((resolve, reject) => {
      this.global.loader = true;
      let slug = "app-repeated-demo";
      let body = {};
      if (this.lessonType == "sentences") {
        // if (this.isSubscribed === 0) {
        //   this.global.showWarningToast(
        //     this.global.termsArray["msg_please_subscribe"]
        //   );
        //   this.router.navigate(["account"]);
        //   this.global.loader = false;
        //   return;
        // }
        slug = "sentencesByRepeatedessonId";
        body["lesson_id"] = this.global.lesson_id;
        body["baselesson_id"] = this.global.baselesson_id;
      }
      body["language_id"] = this.global.selectLanguage;
      body["learning_language_id"] = this.global.user.learning_language_id;
      body["user_id"] = this.global.user._id;
      this.global.post(slug, body, (data) => {
        if (data.success) {
          this.demoFamily = data.data.demoFamily ? data.data.demoFamily : "";
          if (this.lessonType == "sentences") {
            let pop = data.data.sentenceslist.pop();
            this.goToSentenceArray = pop;
            data.data.sentenceslist.push(pop);
          }

          this.lessons = data.data[this.lessonType == "sentences" ? "sentenceslist" : "demo"];
          this.lessonData["progress"] = data.data.progress;
          this.lessonData["stateCount"] = data.data[this.lessonType == "sentences" ? "test" : "stateCount"];
          this.global.max_repeated_read_slide = this.lessonData["progress"].current;
          this.repeatation_cycle = this.lessonData["progress"].repeating_cycle
          this.fillSaveHostroy(this.lessonData["progress"], this.lessonData["stateCount"]);
          if (this.lessons.length > 0) {
            this.setIntervalToStoreProgress();
          } else {
            this.loader = true;
          }
          // console.log(this.lessons,"this.lessons")
          this.lessons.map((ele, index) => {
            if (ele.sentence1 && ele.sentence2) {
              ele["sentenceArray"] = this.sentenceToArray(ele);
              console.log(ele["sentenceArray"], "this.lessons");
            }
            if (ele.type == "slow_song_with_sentence" || ele.type == "fast_song_with_sentence") {
              ele["data"] = ele.data.map((ele2, index) => {
                if (ele2.sentence1) {
                  ele2["sentenceArray"] = this.sentenceToArray(ele2);
                  return ele2;
                }
              });
            }
            if (ele.type == "songLoop") {
              this.songLoopIndex = index;
              this.calculateTime(ele.data);
            } else if (ele.type == "slow_song_with_sentence") {
              this.speakIndex = index;
            } else if (ele.type == "sentenceList") {
              this.repeatIndex = index - 1;
            }
          });

          if (this.deviceService.isMobile() && !is.safari()) {
            $("#mobile-app-dwn-popup").trigger("click");
          }

          if (this.deviceService.isMobile() && is.safari()) {
            $("#safari-mobile-app-dwn-popup").trigger("click");
          }

          if (!this.deviceService.isMobile() && is.safari()) {
            $("#safari-desktop-app-dwn-popup").trigger("click");
          }

          let lessonProgress = data.data.progress;
          if (lessonProgress && lessonProgress.active_progress == 100 && lessonProgress.repeat_progress == 100 && lessonProgress.speak_progress == 100 && lessonProgress.passive_progress == 100) {
            this.is_already_lesson_completed = true
          }

          this.passiveListeningTime = this.lessonData["progress"].time_loop ? this.lessonData["progress"].time_loop : 0
          this.updateProgressBar(this.passiveListeningTime);
          this.updateTimerValue(this.passiveListeningTime);
          resolve(true);
        } else {
          this.loader = true;
          reject(false);
          this.global.showToast("", this.global.termsArray[data.message]);
        }
      },
        (err) => {
          this.ngxService.stop();
          reject(false);
          this.global.showDangerToast(err.message);
        },
        true,
        2000
      );
    });
  }

  updateLessonProgress(lesson_id, family_id, base_id, total, lessonProgress, lessonType,isCycleChange=false) {
    let refreshPage = false
    if (this.global.user && this.global.user.learning_language_id) {
      let body = {};
      body["lesson_id"] = lesson_id;
      body["lessonfamily_id"] = sessionStorage.getItem("lesson_family_id");
      body["total"] = total;
      // here we check if reading index is equal to full array length then we remove one step before
      body["current"] = this.saveHistory.current;
      body["level_id"] = this.saveHistory.level_id;
      body["is_demo"] = lessonType == "sentences" ? 2 : 1;
      body["active"] = this.saveHistory.active;
      body["speak"] = this.saveHistory.speak;
      body["repeat"] = this.saveHistory.repeat;
      body["passive"] = this.saveHistory.passive;
      body["learning_language_id"] = this.global.user.learning_language_id;
      body["repetation_cycle"] = this.repeatation_cycle;
      body['changeCycle']=isCycleChange
      body["time_loop"] = this.passiveListeningTime

      // if (this.value.count) {
      //   body["time_loop"] = +this.value.count;
      // }
      if (this.lessons.length > 0) {
        this.global.post(
          "saveRepeatedLessonHistory",
          JSON.stringify(body),
          (data) => {
            if (data.data.active_progress == 100 && data.data.repeat_progress == 100 && data.data.speak_progress == 100 && data.data.passive_progress >= 100 && this.repeatation_cycle==2) {
              this.getRepeatingLessonCount();
            }
            // if (this.repeatation_cycle != data.data.repeating_cycle) {
              // this.global.showToast("", this.global.termsArray['first_repeatation_completed']);
              // refreshPage = true
              // this.value.count = 0
              // this.repeatation_cycle = data.data.repeating_cycle;
              // this.getLessons();
              

            // this.passiveListeningTime = data.data.time_loop
            // this.updateProgressBar(this.passiveListeningTime);
              // window.location.reload();
            // }

          },
          (err) => {
          },
          refreshPage
        );
      }
    }
  }

  setIntervalToStoreProgress() {
    let lastProgress: any = null;
    let lastLoopTime: any = null;
    this.saveProgressInterval = setInterval(() => {
      let currentIndex = $("div.active").index();
      if (currentIndex != this.currentIndex && !this.goToSentenceFlag) {
        this.switchSlide(this.currentIndex);
        return;
      }
      // if (lastProgress != this.lessonProgress || lastLoopTime != this.value.count) {
      //   lastProgress = this.lessonProgress;
      //   lastLoopTime = this.value.count;
      //   this.updateLessonProgress(
      //     this.global.lesson_id,
      //     this.global.lessonfamily_id,
      //     this.global.baselesson_id,
      //     this.lessons.length,
      //     this.lessonProgress,
      //     this.lessonType,
      //   );
      // }
      if (lastProgress != this.lessonProgress || (this.passiveListeningTime  && lastLoopTime != this.passiveListeningTime)) {
        lastProgress = this.lessonProgress;
        lastLoopTime = this.passiveListeningTime ? this.passiveListeningTime : 0;
        this.updateLessonProgress(
          this.global.lesson_id,
          this.global.lessonfamily_id,
          this.global.baselesson_id,
          this.lessons.length,
          this.lessonProgress,
          this.lessonType
        );
      }
      else {
      }
    }, 5000);
  }

  getSubsriptionStatus() {
    this.global.get(
      "getSubsriptionStatus",
      (data) => {
        if (data.success) {
          this.isSubscribed = data.data.is_subsribed;
        }
      },
      (err) => { },
      true
    );
  }

  navigate(slug = null) {
    clearInterval(this.Interval);
    clearInterval(this.saveProgressInterval);
    this.global.profileTab = 1;
    if (slug == "lesson-description") {
      this.router.navigate([slug + "/" + this.demoFamily], {
        replaceUrl: true,
      });
    } else if (slug) {
      this.router.navigate([slug], { replaceUrl: true });
    } else {
      // this.router.navigate(["choose-lesson"], { replaceUrl: true });
      history.back();
    }
  }

  //covert the sentence into word to word translation
  sentenceToArray(data) {
    let sentence1 = data.sentence1.trim().split(" ");
    let sentence2 = data.sentence2 && data.sentence2.trim() ? data.sentence2.trim().split(" ") : "";
    let sentence3 = data.latin_sentence && data.latin_sentence.trim() ? data.latin_sentence.trim().split(" ") : "";
    let newArray = [];
    sentence1.forEach((element, k) => {
      let obj = {};
      obj["sentence1"] = element.split("*").join(" ");
      obj["sentence2"] = sentence2[k] ? sentence2[k].split("*").join(" ") : "";
      obj["sentence3"] = sentence3[k] ? sentence3[k].split("*").join(" ") : "";
      if (sentence3.length > 0) {
        if (k == 0) {
          obj["sentence3"] = "[" + obj["sentence3"];
        }
        if (k == sentence1.length - 1) {
          obj["sentence3"] = obj["sentence3"] + "]";
        }
      }
      newArray.push(obj);
    });
    return newArray;
  }


  // add the slide in array
  addSlideInArray(item, state, index) {
    this.saveHistory.current = index;
    console.log(this.saveHistory)
    if (
      this.saveHistory[state] &&
      this.saveHistory[state].indexArray.indexOf(item) == -1
    ) {
      this.saveHistory[state].indexArray.push(item);
      this.saveHistory[state].max = this.saveHistory[state].max + 1;
    } else {
    }
    console.log(this.saveHistory)
  }

  onSlide(e) {
    let speed = 0.9;
    if (this.lessons[e.to].state == "active") {
      this.backgroundClass = "outs-box-cover1";
      speed = localStorage.getItem("activeSpeed") ? parseFloat(localStorage.getItem("activeSpeed")) : speed;
      if (speed == 0.9) {
        this.btnVal = "Normal";
      } else {
        this.btnVal = "Langsam";
      }
    }
    else if (this.lessons[e.to].state == "speak") {
      this.backgroundClass = "outs-box-cover2";
      speed = localStorage.getItem("speakSpeed") ? parseFloat(localStorage.getItem("speakSpeed")) : speed;
      if (speed == 0.9) {
        this.btnVal = "Normal";
      } else {
        this.btnVal = "Langsam";
      }
    }
    else if (this.lessons[e.to].state == "passive") {
      this.backgroundClass = "outs-box-cover4";
      speed = localStorage.getItem("passiveSpeed") ? parseFloat(localStorage.getItem("passiveSpeed")) : speed;
      if (speed == 0.9) {
        this.btnVal = "Normal";
      } else {
        this.btnVal = "Langsam";
      }
    } else if (this.lessons[e.to].state == "repeat") {
      this.backgroundClass = "outs-box-cover3";
    }

    clearInterval(this.Interval);
    this.pauseTrack();
    this.isPlaying = false
    this.currentFile = {};
    this.audioLoopIndex = 0;

    // get current index from slide event
    this.currentIndex = e.to;
    this.lessonProgress = this.currentIndex;
    this.decideToShowPlayBtnOrNot();

    if (this.lessons[this.currentIndex].type == "title") {
      this.gradientClass = "gradient-css";
    } else {
      this.gradientClass = "";
    }

    // open modal
    if (this.lessonType != "demo" && this.lessons[this.currentIndex].state == "passive" && this.lessons[this.currentIndex].type == "title") {
      $("#open-modal").trigger("click");
    }
    else if (this.lessonType == "demo" && this.lessons[this.currentIndex].fordemo != "undefined" && this.lessons[this.currentIndex].fordemo == "titles") {
      $("#open-modal").trigger("click");
    }

    this.hearStatus = this.currentState[this.lessons[this.currentIndex].state] ? this.currentState[this.lessons[this.currentIndex].state] : 4;
    // save hostory data
    this.addSlideInArray(e.to, this.lessons[this.currentIndex].state, e.to);
  }

  fillSaveHostroy(object, total) {
    this.loopStopWatchTime = object.time_loop ? object.time_loop : 0;
    this.saveHistory.current = object.current;
    this.saveHistory.level_id = object.level_id;
    this.currentIndex = object.current >= this.lessons.length ? 0 : object.current;
    this.switchSlide(this.currentIndex);
    this.hearStatus = this.currentState[this.lessons[this.currentIndex].state]
      ? this.currentState[this.lessons[this.currentIndex].state] : 4;
    // this.saveHistory.active.indexArray =
    //   typeof object.active_indexArray == "object" &&object.active_indexArray.length >= 1 ? object.active_indexArray : [0];
    // this.saveHistory.active.max =object.active_read && object.active_read >= 1 ? object.active_read : 1;
    // this.saveHistory.active.total = total.active;
    this.saveHistory.passive.indexArray = typeof object.passive_indexArray == "object" ? object.passive_indexArray : [];
    this.saveHistory.passive.max = object.passive_read;
    this.saveHistory.passive.total = total.passive;
    this.saveHistory.speak.indexArray = typeof object.speak_indexArray == "object" ? object.speak_indexArray && object.speak_indexArray.length > 0 ? object.speak_indexArray : [total.active] : [];
    this.saveHistory.speak.max = object.speak_read ? object.speak_read : 1;
    this.saveHistory.speak.total = total.speak ? total.speak : 1;
    this.saveHistory.repeat.indexArray = typeof object.repeat_indexArray == "object" ? object.repeat_indexArray : [];
    // this.modalConfirmation = typeof object.repeat_indexArray == "object" && object.repeat_indexArray.length == total.repeat ? true : false;
    this.saveHistory.repeat.max = object.repeat_read;
    this.saveHistory.repeat.total = total.repeat;
    this.fakeBtnData = this.lessons[this.currentIndex];
    this.mssapPlaylist = [
      {
        title: this.fakeBtnData.lessonID,
        link: this.fakeBtnData.sound,
      },
    ];
    let speed = 0.9;
    if (this.lessons[this.currentIndex].state == "active") {
      this.backgroundClass = "outs-box-cover1";
      speed = localStorage.getItem("activeSpeed") ? parseFloat(localStorage.getItem("activeSpeed")) : speed;
      if (speed == 0.9) {
        this.btnVal = "Normal";
      } else {
        this.btnVal = "Langsam";
      }
    } else if (this.lessons[this.currentIndex].state == "speak") {
      this.backgroundClass = "outs-box-cover2";
      speed = localStorage.getItem("speakSpeed") ? parseFloat(localStorage.getItem("speakSpeed")) : speed;
      if (speed == 0.9) {
        this.btnVal = "Normal";
      } else {
        this.btnVal = "Langsam";
      }
    } else if (this.lessons[this.currentIndex].state == "passive") {
      this.backgroundClass = "outs-box-cover4";
      speed = localStorage.getItem("passiveSpeed") ? parseFloat(localStorage.getItem("passiveSpeed")) : speed;
      if (speed == 0.9) {
        this.btnVal = "Normal";
      } else {
        this.btnVal = "Langsam";
      }
    } else {
      this.backgroundClass = "outs-box-cover3";
    }
  }


  percent() {
    // let percent = this.value && this.value.count ? (this.value.count / 60) * 100 : 0;
    // return percent

    // return this.value && this.value.count ? (this.value.count / 900) * 100 : 0;
    return this.passiveListeningTime && this.passiveListeningTime ? (this.passiveListeningTime / 900) * 100 : 0;
  }

  modalConfirmationFill() {
    this.modalConfirmation = true;
  }

  panEvent() {
    $("#collapsebarsetting3").trigger("click");
    this.showMenuIcons();
  }

  openUrl(type) {
    let obj = {
      1: localStorage.getItem("play_url"),
      2: localStorage.getItem("apple_url"),
    };
    window.open(obj[type], "blank");
  }

  showMenuIcons() {
    this.isMenuIconsShow = !this.isMenuIconsShow;
  }

  async fakeCircleBtn() {
    this.fakeBtnData = this.lessons[this.currentIndex];
    console.log(this.fakeBtnData)
    let speed = localStorage.getItem("speed") ? Number(localStorage.getItem("speed")) : this.defaultSpeed;
    if (this.fakeBtnData.type == "song") {
      this.playActiveTabAudios();
    }

    else if (this.fakeBtnData.type == "fast_song_with_sentence" || this.fakeBtnData.type == "slow_song_with_sentence") {
      this.playSpeakTabAudios()
    }
    else if (this.fakeBtnData.type == "songLoop") {
      let alarmCurrentTime = atob(sessionStorage.getItem(btoa("alarmCurrentTime"))) as any;
      alarmCurrentTime = isNaN(alarmCurrentTime) ? 0 : Number(alarmCurrentTime);
      this.alarmRealSeconds = alarmCurrentTime;
      if (this.fakeBtnData.toggle == "pause") {
        if (this.alarmRealSeconds > 0) {
          this.initAlarm();
        }
        else {
          // this.resetProgressBar()
          setTimeout(() => {
            sessionStorage.setItem(btoa("alarmFlag"), btoa("true"));
            sessionStorage.setItem(btoa("alarmCurrentTime"), btoa(String(this.selectedSleepTimer!=0 ? (this.selectedSleepTimer * 60) : 900)));
            // this.playPassiveTabAudio();
            this.initAlarm();
          }, 500);
        }
      }
      else {
        this.pausePassiveTabAudio();
        clearInterval(this.alarmTimer);
      }
    }

  }

  splitMyWord(word) {
    return word.split(" ");
  }

  setLimit(event: any) {
    this.isPlaying = false
    this.sound.stop();
    this.sound = null
    if (this.lessons[this.currentIndex]["toggle"]) {
      this.lessons[this.currentIndex]["toggle"] = "pause";
    }
    this.currentFile = {};
    let speed = event;
    localStorage.setItem("speed", speed.toString());
    this.initSpeedView();
  }

  slidesFooter(state = "") {
    if (state == "active") {
      this.backgroundClass = "outs-box-cover1";
    } else if (state == "speak") {
      this.backgroundClass = "outs-box-cover2";
    } else if (state == "repeat") {
      this.backgroundClass = "outs-box-cover3";
    } else if (state == "passive") {
      this.backgroundClass = "outs-box-cover4";
    }

    this.decideToShowPlayBtnOrNot();
    this.slideChanged();
  }

  goBack() {
    this.location.back();
  }

  initSpeedView() {
    if (
      localStorage.getItem("speed") &&
      Number(localStorage.getItem("speed")) > 0
    ) {
      this.defaultSpeed = Number(localStorage.getItem("speed"));
    } else {
      this.defaultSpeed = 0.8;
    }
  }

  decideToShowPlayBtnOrNot() {
    this.initSpeedView();
    if (
      this.lessons[this.currentIndex].type == "songLoop" ||
      this.lessons[this.currentIndex].type == "fast_song_with_sentence" ||
      this.lessons[this.currentIndex].type == "song" ||
      this.lessons[this.currentIndex].type == "slow_song_with_sentence"
    ) {
      this.isShowfakeBtn = true;
    } else {
      this.isShowfakeBtn = false;
    }
  }

  slideChanged() {
    this.repeatPlayedIndex = null;
    this.repeatSelectedIndex = null;
    this.playingSentenceIndex = 0;
    this.playingPassiveSentence = 0;
    this.playingRepeatSentence = 0;
    this.isPlaying = false;
    this.decideToShowPlayBtnOrNot();
    if (this.sound) {
      this.sound.unload()
    }
    this.updateProgressBar(this.passiveListeningTime);
    // this.resetSelectedSleepTimer();
    this.pausePassiveTabAudio();
    clearInterval(this.alarmTimer);
  }

  selectOption(event: any) {
    this.speedOptions.forEach((item) => {
      item.selected = false;
    });
    event.selected = true;
    this.setLimit(event.value);
  }

  scrollToElement(index: number, i: number) {
    const elementId = "scroll-" + index + "" + i;
    let el = document.getElementById(elementId);
    if (el) {
      el.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      var element = document.querySelector(".playing");
      if (element) {
        element.classList.remove("playing");
        el.classList.add("not-playing");
      }
      el.classList.remove("not-playing");
      el.classList.add("playing");
    }
    return el;
  }


  setTimerPopup(action: boolean, event: any = null): void {
    if (event && !action) {
      if (event.target.classList.contains("closePopup")) {
        this.showTimerPopup = false;
      }
    } else {
      this.showTimerPopup = action;
    }
  }

  adjustAlarmTime(action: any = "-"): void {
    if (action == "+") {

      if (this.alarmTime < this.maxAlarmTime) {
        this.alarmTime += 1;
      }
    } else {
      if (this.alarmTime > 1) {
        this.alarmTime -= 1;
      }
    }
  }

  checkAlarmTime(event: any): void {
    if (Number(event.target.value) >= this.maxAlarmTime) {
      this.alarmTime = this.maxAlarmTime;
      event.target.value = this.alarmTime;
    }
    if (this.alarmTime <= 1) {
      this.alarmTime = 1;
    }
  }

  setAlarm() {
    sessionStorage.setItem(btoa("alarmFlag"), btoa("true"));
    sessionStorage.setItem(
      btoa("alarmCurrentTime"),
      btoa(String(this.alarmTime * 60))
    );
    this.getAlarmTimeString();
  }

  resetAlarm() {
    this.isPlaying = false
    this.pauseTrack();
    sessionStorage.removeItem(btoa("alarmFlag"));
    sessionStorage.removeItem(btoa("alarmCurrentTime"));
    clearInterval(this.alarmTimer);
    this.getAlarmTimeString();
  }

  ngAfterViewInit(): void {
    let alarmFlag = atob(sessionStorage.getItem(btoa("alarmFlag")));
    if (alarmFlag && alarmFlag == "true") {
      const alarmInitInterval = setInterval(() => {
        const currentTab = document.querySelector(
          "#passive_listening_tab.active"
        );
        if (
          currentTab &&
          document.getElementById("playButtonProgress") &&
          document.getElementById("playButtonProgress").classList
        ) {
          clearInterval(alarmInitInterval);
          // this.initAlarm();
        }
      }, 1000);
      setTimeout(() => {
        clearInterval(alarmInitInterval);
      }, 10000);
    }
  }

  getAlarmTimeString() {
    let time = `00:00`;
    let alarmCurrentTime = atob(sessionStorage.getItem(btoa("alarmCurrentTime"))) as any;
    alarmCurrentTime = isNaN(alarmCurrentTime) ? 0 : Number(alarmCurrentTime);
    this.alarmRealSeconds = alarmCurrentTime;
    if (this.alarmRealSeconds > 0) {
      const minutes = Math.floor(this.alarmRealSeconds / 60);
      const seconds = this.alarmRealSeconds % 60;
      time = String(`${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`);
    }
    document.getElementById('alarmTime').innerText = time
    return time;
  }


  initAlarm() {
    this.alarmStart = true;
    let alarmCurrentTime = atob(sessionStorage.getItem(btoa("alarmCurrentTime"))) as any;
    alarmCurrentTime = isNaN(alarmCurrentTime) ? 0 : Number(alarmCurrentTime);
    this.alarmRealSeconds = alarmCurrentTime;
    let isTimerSelected=this.selectedSleepTimer && this.selectedSleepTimer!==0 ? true : false;
    this.elapsedTime= sessionStorage.getItem(btoa("sleepTimerCurrentTime")) ?atob(sessionStorage.getItem(btoa("sleepTimerCurrentTime"))) : this.elapsedTime;
    if (this.alarmTimer) {
      clearInterval(this.alarmTimer);
    }
    if (this.alarmRealSeconds > 0 && !this.isPlaying) {
      try {
        this.playPassiveTabAudio();
      } catch (err) {
        console.log("Dom exception", err);
      }
    }
    
    this.alarmTimer = setInterval(() => {
      console.log(isTimerSelected,this.alarmRealSeconds)
      if(isTimerSelected && this.alarmRealSeconds > 0 && this.isPlaying){
        this.alarmRealSeconds -= 1;
        this.elapsedTime = this.selectedSleepTimer && this.selectedSleepTimer != 0 ? this.elapsedTime - 1 : 0;
        this.passiveListeningTime += 1;
        this.updateProgressBar(this.passiveListeningTime);
        this.updateCountdown(this.elapsedTime)
        this.updateTimerValue(this.passiveListeningTime);
        sessionStorage.setItem(btoa("sleepTimerCurrentTime"), btoa(this.elapsedTime));
        sessionStorage.setItem(btoa("alarmCurrentTime"), btoa(String(this.alarmRealSeconds)));
      }
      else if(isTimerSelected && this.alarmRealSeconds <= 0){
          this.playingPassiveSentence = 0
          this.pausePassiveTabAudio();
          sessionStorage.setItem(btoa("alarmCurrentTime"), btoa(String(0)));
          this.updateLessonProgress(
            this.global.lesson_id,
            this.global.lessonfamily_id,
            this.global.baselesson_id,
            this.lessons.length,
            this.lessonProgress,
            this.lessonType
          );
          sessionStorage.removeItem(btoa("alarmFlag"));
          sessionStorage.removeItem(btoa("alarmCurrentTime"));
          clearInterval(this.alarmTimer);
          sessionStorage.removeItem(btoa("sleepTimerCurrentTime"));
      }
      else if(this.isPlaying){
        sessionStorage.setItem(btoa("alarmCurrentTime"), btoa(String(this.alarmRealSeconds)));
        this.passiveListeningTime += 1;
        this.updateProgressBar(this.passiveListeningTime);
        this.updateTimerValue(this.passiveListeningTime);
        this.updateLessonProgress(
          this.global.lesson_id,
          this.global.lessonfamily_id,
          this.global.baselesson_id,
          this.lessons.length,
          this.lessonProgress,
          this.lessonType
        );
      }
      else{
        clearInterval(this.alarmTimer);
        this.updateLessonProgress(
          this.global.lesson_id,
          this.global.lessonfamily_id,
          this.global.baselesson_id,
          this.lessons.length,
          this.lessonProgress,
          this.lessonType
        );
      }
      
    }, 1000);
  }
  playActiveTabAudios() {
    this.isPlaying = !this.isPlaying;
    this.fakeBtnData = this.lessons[this.currentIndex];
    let speed = localStorage.getItem("speed") ? Number(localStorage.getItem("speed")) : this.defaultSpeed;
    if (this.isPlaying) {
      this.sound = new Howl({
        src: [this.fakeBtnData.sound],
        html5: true,
        autoplay: false,
        loop: true,
        volume: 1,
        rate: speed,
      });

      this.sound.once('load', () => {
        this.fakeBtnData['toggle'] = "play";
        this.sound.play();
      });

      this.sound.on('play', () => {
        this.fakeBtnData['toggle'] = "play";
      });
      this.sound.on('end', () => {
        this.fakeBtnData['toggle'] = "refresh";
      });
    } else {
      this.sound.stop();
      this.sound = null
      this.fakeBtnData.toggle = "pause";
    }
  }

  playSpeakTabAudios(sentenceIndex?) {
    if (this.sound && this.playingSentenceIndex != sentenceIndex) {
      this.sound.unload()
    }
    if (this.playingSentenceIndex == sentenceIndex) {
      if (this.sound) {
        this.sound.unload()
      }
    }
    this.isPlaying = sentenceIndex || sentenceIndex == 0 ? true : !this.isPlaying;
    console.log(this.isPlaying)
    this.fakeBtnData = this.lessons[this.currentIndex];
    let sounds = this.fakeBtnData.data.map((item) => item.sound);
    let speed = localStorage.getItem("speed") ? Number(localStorage.getItem("speed")) : this.defaultSpeed;
    this.playingSentenceIndex = sentenceIndex || sentenceIndex == 0 ? sentenceIndex : this.playingSentenceIndex
    console.log(this.playingSentenceIndex, this.isPlaying)
    const playNextSound = () => {
      this.sound = new Howl({
        src: [sounds[this.playingSentenceIndex]],
        html5: true,
        autoplay: false,
        loop: false,
        volume: 1,
        rate: speed,
      });

      this.sound.once('load', () => {
        this.fakeBtnData['toggle'] = "play";
        this.sound.play();
      });

      this.sound.on('play', (e) => {
        let ele = this.scrollToElement(this.currentIndex, this.playingSentenceIndex);
        ele.classList.remove("not-playing");
        ele.classList.add("playing");
        this.fakeBtnData['toggle'] = "play";
      });

      this.sound.on('end', (e) => {
        console.log(this.playingSentenceIndex, sounds.length, this.playingSentenceIndex < sounds.length - 1);
        // this.fakeBtnData['toggle'] = "refresh";
        if (this.playingSentenceIndex < sounds.length - 1) {
          this.playingSentenceIndex++;
          this.sound.unload()
          playNextSound();
        } else {
          this.isPlaying = false
          this.playingSentenceIndex = 0
          let ele = this.scrollToElement(this.currentIndex, 0);
          ele.classList.remove("not-playing");
          ele.classList.add("playing");
          this.fakeBtnData['toggle'] = "refresh";
        }
      });
    };

    if (this.isPlaying) {
      playNextSound();
    } else {
      if (this.sound) {
        this.sound.stop();
        this.sound = null;
      }
      this.fakeBtnData.toggle = "pause";
    }
  }

  playRepeatTabAudio(index, sentenceIndex) {
    if (this.repeatPlayedIndex == sentenceIndex || this.repeatSelectedIndex == sentenceIndex) {
      this.isPlaying = !this.isPlaying
    }
    else {
      this.repeatPlayedIndex = null;
      this.repeatSelectedIndex = null;
      this.isPlaying = true
      if (this.sound) {
        this.sound.unload();
      }
    }
    console.log(this.repeatPlayedIndex, sentenceIndex, this.isPlaying)
    if (this.lessonType == "demo") {
      if (index == 6) {
        this.fakeBtnData = this.lessons[index];
      }
      else {
        index = index + 1
        this.fakeBtnData = this.lessons[index];
      }
    } else {
      this.fakeBtnData = this.lessons[index];
    }
    let speed = localStorage.getItem("speed") ? Number(localStorage.getItem("speed")) : this.defaultSpeed;
    if (this.isPlaying) {
      this.sound = new Howl({
        src: [this.fakeBtnData.sound],
        html5: true,
        autoplay: false,
        loop: false,
        volume: 1,
        rate: speed,
      });
      this.sound.once('load', () => {
        this.repeatSelectedIndex = sentenceIndex;
        this.repeatPlayedIndex = null;
        this.sound.play();
      });
      this.sound.on('play', () => {
        this.repeatSelectedIndex = sentenceIndex;
        this.repeatPlayedIndex = null;
      });
      this.sound.on('end', () => {
        this.isPlaying = false
        let childIndex = this.repeatSelectedIndex;
        this.repeatPlayedIndex = childIndex;
        this.repeatSelectedIndex = null;
        this.sound.unload();
      });
    } else {
      this.repeatPlayedIndex = null;
      this.repeatSelectedIndex = null;
      this.sound.unload();
    }
  }

  playPassiveTabAudio() {
    this.isPlaying = !this.isPlaying;
    this.fakeBtnData = this.lessons[this.currentIndex];
    let sounds = this.fakeBtnData.data.map((item) => item.sound);
    let speed = localStorage.getItem("speed") ? Number(localStorage.getItem("speed")) : this.defaultSpeed;

    const playNextSound = () => {
      this.sound = new Howl({
        src: [sounds[this.playingPassiveSentence]],
        html5: true,
        autoplay: false,
        loop: false,
        volume: 1,
        rate: speed,
      });
      this.sound.once('load', () => {
        if (this.sound) {
          this.fakeBtnData['toggle'] = "play";
          this.sound.play();
        }
        else {
          this.pausePassiveTabAudio();
        }
      });

      this.sound.on('play', (e) => {
        this.fakeBtnData['toggle'] = "play";
      });

      this.sound.on('end', (e) => {
        console.log(this.playingPassiveSentence, sounds.length, this.playingPassiveSentence < sounds.length - 1);
        if (this.playingPassiveSentence < sounds.length - 1) {
          this.playingPassiveSentence++;
        } else {
          this.playingPassiveSentence = 0; // Reset to the first sound when all sounds are played
        }
        this.sound.unload()
        playNextSound();
      });
    };

    if (this.isPlaying) {
      playNextSound();
    } else {
      this.isPlaying = false;
      this.sound.unload()
      this.fakeBtnData.toggle = "pause";
    }
  }

  pausePassiveTabAudio() {
    this.isPlaying = false;
    if (this.sound) {
      this.sound.unload()
    }
    this.fakeBtnData.toggle = "pause";
  }

  pauseTrack() {
    if (this.fakeBtnData) {
      this.fakeBtnData.toggle = 'pause'
    }
    if (this.sound) {
      this.isPlaying = false;
      this.sound.unload()
    }
    this.repeatPlayedIndex = null;
    this.repeatSelectedIndex = null;
    this.playingSentenceIndex = 0;
    this.playingPassiveSentence = 0
    this.playingRepeatSentence = 0
  }

  // Function to generate speed options from 5 to 60 minutes with an interval of 5
  generateSleepTimerOption(): number[] {
    const options = [0];
    for (let i = 5; i <= 60; i += 5) {
      options.push(i);
    }
    return options;
  }


  updateRepeatedLessonProgress(lesson_id, family_id, base_id, total, lessonProgress, lessonType, createnew) {
    if (this.global.user && this.global.user.learning_language_id) {
      let body = {};
      body["lesson_id"] = lesson_id;
      body["lessonfamily_id"] = sessionStorage.getItem("lesson_family_id");
      body["total"] = total;
      // here we check if reading index is equal to full array length then we remove one step before
      body["current"] = this.saveHistory.current;
      body["level_id"] = this.saveHistory.level_id;
      body["is_demo"] = lessonType == "sentences" ? 2 : 1;
      body["active"] = this.saveHistory.active;
      body["speak"] = this.saveHistory.speak;
      body["repeat"] = this.saveHistory.repeat;
      body["passive"] = this.saveHistory.passive;
      body["learning_language_id"] = this.global.user.learning_language_id;
      body["createnew"] = true
      if (this.value.count) {
        body["time_loop"] = +this.value.count;
      }
      if (this.lessons.length > 0) {
        this.global.post(
          "saveRepeatedLessonHistory",
          JSON.stringify(body),
          (data) => {
            // this.getRepeatingLessonCount()
            // let lessonRepeatCount =
            // this.global.setrepeatingLessonValue();
          },
          (err) => {
          },
          false
        );
      }
    }
  }

  // Function to select a speed option
  selectsleepTimerOption(option: number) {
    this.selectedSleepTimer = option;
    sessionStorage.setItem(btoa("alarmFlag"), btoa("true"));
    sessionStorage.setItem(btoa("alarmCurrentTime"), btoa(String(option * 60)) );
    this.elapsedTime=option*60;
    this.updateCountdown(this.elapsedTime);
    sessionStorage.setItem(btoa("sleepTimerCurrentTime"), btoa(this.elapsedTime));
  }

  getProgressWidth() {
    let alarmCurrentTime = atob(sessionStorage.getItem(btoa("alarmCurrentTime"))) as any;
    alarmCurrentTime = isNaN(alarmCurrentTime) ? 0 : Number(alarmCurrentTime);
    this.alarmRealSeconds = alarmCurrentTime;
    const time = Math.floor(this.alarmRealSeconds / 60);
    const maxTime = this.selectedSleepTimer; // Max time in minutes
    const progressPercentage = (maxTime - time / maxTime) * 100;
    console.log(time, maxTime, progressPercentage)
    return `${progressPercentage}%`;
  }

  updateProgressBar(passiveListeningTime) {
    const maxTime = 900;
    const progressPercentage = ((passiveListeningTime) / maxTime) * 100;
    setTimeout(() => {
      if (document.querySelector('#timerProgress')) {
        document.querySelector('#timerProgress').setAttribute('style', `width: ${progressPercentage}%`);
      }
    }, 500);
    return progressPercentage > 100 ? 100 : progressPercentage;
  }

  resetProgressBar() {
    // this.value=null
    this.isPlaying = false
    this.pauseTrack();
    sessionStorage.removeItem(btoa("alarmFlag"));
    sessionStorage.removeItem(btoa("alarmCurrentTime"));
    clearInterval(this.alarmTimer);
    if (document.querySelector('#timerProgress')) {
      document.querySelector('#timerProgress').setAttribute('style', `width:0%`);
    }
  }




  goToActiveTab(index) {
    console.log(index)
    this.currentIndex = index;
    $("#video-carousel-example2").carousel(this.currentIndex);
    this.switchSlide(index)
  }


  selectLanguage(language) {
    this.changedLangugae = language
  }

  openModal(template: TemplateRef<any>, cssClass, sentence = null) {
    this.selectedSentence = sentence;
    console.log("ser", sentence, this.lessons[this.selectedSentence])
    const config = {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: false,
      class: cssClass
    };
    this.modalRef = this.modalService.show(template, config);
    this.getLearningLanguage()
  }


  getLearningLanguage() {
    this.global.get(
      "learningLanguages/" + this.global.selectLanguage,
      (data) => {
        this.learningLanguages = data.data
        console.log("learning_language", data);
      },
      (err) => { }
    );
  }


  getRepeatingLessonCount() {
    let body = {
      learning_language_id: this.global && this.global.user && this.global.user.learning_language_id ? this.global.user.learning_language_id : ""
    };
    this.global.post("repeating-lesson-count", JSON.stringify(body), (data) => {
      if (data.success) {
        let repeatedLessonCount = data.data && data.data.count ? data.data.count : 0;
        this.global.setrepeatingLessonValue(repeatedLessonCount)
      } else {
        this.global.showDangerToast(this.global.termsArray[data.message]);
      }
    }, (err) => {
      this.global.showDangerToast(err.message);
    }, false);
  }

  getLearningMinutes() {
    this.global.get("mylearningTime", (data) => {
      if (data.success) {
        this.learningSeconds = data.data && data.data.learningTime ? data.data.learningTime : 0;
        this.todaylearningTime = this.learningSeconds;
        this.totalGoalTime = data.data && data.data.totalTime ? data.data.totalTime : 0

        this.learningInterval = setInterval(() => {
          if (!this.isIntervalPaused) {
            this.learningSeconds += 10;
            this.todaylearningTime += 10;
            this.totalGoalTime += 10;
            this.checkForAwards();
            this.updateWeeklyGoal();
            this.updateUserLearningMinutes();
          }
        }, 10000);
      } else {
      }
    }, (err) => {
      this.global.showDangerToast(err.message);
    }, false);
  }


  clearUpdateLearningInterval() {
    clearInterval(this.learningInterval)
  }

  checkForAwards() {
    const matchedAwards = this.awards.filter(award => (award.minutes * 60) === this.learningSeconds);
    console.log("Matched awards for learning:", this.learningSeconds, "Awards:", matchedAwards);
    // Process matched awards
    if (matchedAwards.length > 0 && !this.dont_show_awards) {
      this.goalService.setAwardAchieved(matchedAwards[0]);
      this.updateUserLearningMinutes();
    }
  }


  updateUserLearningMinutes() {
    let body = { 'learningTime': this.learningSeconds };
    this.global.post(
      "updateLearningTime",
      body,
      (data) => {
        if (data.success) {
          console.log("done")
        }
      },
      (err) => {
        console.log("err", err.message)
      },
      false
    );
  }


  /** To manage goals */

  getGoals() {
    this.global.get("goals", (data) => {
      if (data.success) {
        this.myGoals = data.data;
        this.todaylearningTime = data.data.todayTime;
        this.totalGoalTime = data.data.totalWeekTime;
      } else {
        this.myGoals = null;
        this.todaylearningTime = 0
      }
    }, (err) => {
      this.global.showDangerToast(err.message);
    }, true);
  }

  updateWeeklyGoal() {
    if (this.myGoals) {
      let body = { 'todayTime': this.todaylearningTime, 'totalWeekTime': this.totalGoalTime };
      this.global.post(
        "update-daily-goal",
        body,
        (response) => {
          if (response.success) {
            let data = response.data
            if (data.seenByUser == false && data.achieved == true && data.currentWeekCompletionStatus == true) {
              this.goalService.setGoalAchieved(true)
            }
          }
        },
        (err) => {
          this.global.showDangerToast("", err.message);
        },
        false
      );
    }
    else return;
  }


  removeSpecialCharacters(str) {
    return str.replace(/\*/g, ' ');
  }

  updateCountdown(time) {
    if (this.selectedSleepTimer && this.selectedSleepTimer != 0) {
      const hours = Math.floor((time /3600));
      const minutes = Math.floor((time % 3600) / 60);
      const seconds = time % 60;
      if(time===3600){
        this.overallTime = '60:00';
      }
      else{
        this.overallTime = `${minutes < 10 ? `0${minutes}` : minutes
        }:${seconds < 10 ? `0${seconds}` : seconds}`;
      }
      if(minutes==0 && seconds==0 && hours!=1){
        this.resetSelectedSleepTimer();
        this.pausePassiveTabAudio();
      }
    }
  }




  updateTimerValue(time) {
    const hours = Math.floor(time/3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    this.timerValue = `${hours < 10 ? `0${hours}` : hours }:${minutes < 10 ? `0${minutes}` : minutes }:${seconds < 10 ? `0${seconds}` : seconds}`;
  }

  resetSelectedSleepTimer(){
    this.selectedSleepTimer = 0;
  }


}