import { Component, OnInit } from "@angular/core";
import { GlobalService } from "src/app/global.service";
import { Location } from "@angular/common";
import { Router } from "@angular/router";

@Component({
  selector: 'app-new-rating',
  templateUrl: './new-rating.component.html',
  styleUrls: ['./new-rating.component.sass']
})
export class NewRatingComponent implements OnInit {
  showAppLinks = false;
  feedback: any = "";
  isError : boolean = false;
  error: string = "";
  step1=true;
  step2=false;
  step3=false;
  rated:number;
  constructor(
    public global: GlobalService,
    public location: Location,
    public router: Router
  ) {
    this.global.profileTab = 2;
  }

  ngOnInit() { }

  ngOnDestroy() { }

  goBack() {
    this.router.navigate(["setting"], { replaceUrl: true });
  }

  checkSpace() {
    this.feedback = this.feedback.trim();
  }

  openUrl(type) {
    let obj = {
      1: localStorage.getItem('play_url'),
      2: localStorage.getItem('apple_url'),
    };
    window.open(obj[type], 'blank');
  }

  onSliderChange(event: any) {
    this.rated = event.value;
  }
  goToStep2(){
    this.step1= false
    this.step2 = true
  }
  goToStep3(type='web'){
    this.step2= false
    this.step3 = true
    if(type!='web'){
      this.showAppLinks=true
    }
  }



  submitRating() {
    if (!this.rated) {
      this.global.showDangerToast("", this.global.termsArray["err_please_rate"]);
      return;
    } 
    else if (!this.feedback) {
      this.isError = true;
      this.error = "Field is required!";
      this.global.showDangerToast("", this.global.termsArray["err_please_give_feedback"]);
      return;
    }
    else{
      this.isError = false;
      this.error = "";
      let body = {};
      body["rating"] = this.rated;
      body["feedback"] = this.feedback;
      body["public"] = 1;
      this.global.post(
        "rating",
        JSON.stringify(body),
        (data) => {
          if (data.success) {
              this.global.showToast("", this.global.termsArray[data.message]);
              this.location.back();
          } else {
            this.global.showDangerToast("", this.global.termsArray[data.message]);
          }
        },
        (err) => { },
        true
      );
    }
    
  }
}

