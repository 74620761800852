<div class="inner-wraper profile-sec">
  <section class="lesson-cont-sect wrap-space">
    <div class="container">
      <div class="lektionen-cover-new">
        <article>
          <div class="setting-arrow inner-arrow">
            <a href="javascript:;" (click)="goBack()">
              <i class="ri-arrow-left-s-line"></i>
              <h6>{{ global.termsArray["lbl_Settings"] }}</h6>
            </a>
          </div>
          <div class="list-sentens text-center">
            <div class="in-list-setting">
              <div class="account-title">
                <h4>{{ global.termsArray["lbl_profile"] }}</h4>
                <p>
                  {{ global.termsArray["lbl_profile_subTitle"] }}
                </p>
              </div>
              <div class="profile-line-box login-input pr-md-3">
                <form class="form-horizontal" role="form" [formGroup]="formData"
                  (ngSubmit)="onClickSubmit(formData.value)">
                  <div class="d-flex">
                    <!-- <div class="custom-control custom-radio">
                      <input type="radio" class="custom-control-input" id="defaultUnchecked" name="gender"
                        [checked]="formData.controls['gender'].value === 0" formControlName="gender" />
                      <label class="custom-control-label" for="defaultUnchecked"
                        (click)="selectGender(0)">{{ global.termsArray["lbl_mr"] }}</label>
                    </div> -->
                    <!-- <div class="custom-control custom-radio">
                      <input
                        type="radio"
                        class="custom-control-input"
                        id="defaultUnchecked1"
                        name="gender"
                        [checked]="formData.controls['gender'].value === 1"
                        formControlName="gender"
                      />
                      <label
                        class="custom-control-label"
                        for="defaultUnchecked1"
                        (click)="selectGender(1)"
                        >{{ global.termsArray["lbl_ms"] }}</label
                      >
                    </div> -->
                  </div>
                  <!-- <div
                    style="margin-top: -25px; margin-bottom: 10px;"
                    *ngIf="
                      formData.controls['gender'].value === '' &&
                      this.formSubmitAttempt
                    "
                  >
                    <div
                      *ngIf="formData.controls['gender'].value === ''"
                      style="color: red;"
                    >
                      {{ global.termsArray["msg_field_required"] }}
                    </div>
                  </div> -->
                  <div class="form-group pb-4">
                    <div class="icon_inner_bx">
                        <label for="email">E-Mail-Adresse</label>
                       <input type="email" class="form-control" id="email" [placeholder]="global.termsArray['lbl_email_address']"
                        formControlName="email" />
                      <span class="icon_inner"><i class="ri-pencil-fill white-color"></i></span>
                    </div>
                    <div *ngIf="
                      formData.controls['email'].invalid &&
                      (formData.controls['email'].dirty ||
                      formData.controls['email'].touched ||
                      this.formSubmitAttempt)
                      ">
                      <div *ngIf="formData.controls['email'].errors.required" class="validation_err validation_err_color text-left" style="font-size: 14px;">
                        {{ global.termsArray["msg_enter_email"] }}
                      </div>
                      <div *ngIf="formData.controls['email'].errors.pattern" class="validation_err validation_err_color text-left" style="font-size: 14px;">
                        {{ global.termsArray["err_valid_email"] }}
                      </div>
                    </div>
                  </div>
                  <!-- ask for password if user changes email >>>>> -->
                  <div class="form-group pb-4" *ngIf="userOldEmail != formData.controls['email'].value">
                    <div class="icon_inner_bx">
                      <label for="password">Passwort</label>
                      <input type="password" class="form-control" id="password" [placeholder]="global.termsArray['lbl_enter_password']"
                      formControlName="password" />
                      <span class="icon_inner"><i class="ri-pencil-fill white-color"></i></span>
                    </div>
                    <div *ngIf="formData.controls['password'].touched && !formData.controls['password'].value.length"
                    class="validation_err validation_err_color text-left" style="font-size: 14px;">
                      {{ global.termsArray["err_enter_password"] }}
                    </div>
                  </div>
                  <!-- ask for password if user changes email <<<<< -->

                  <div class="form-group pb-4">
                    <div class="icon_inner_bx">
                      <label for="username">{{ global.termsArray['lbl_name'] ? global.termsArray['lbl_name'] : "Benutzername" }}</label>
                      <input type="name" class="form-control" id="username" [placeholder]="global.termsArray['lbl_name']" formControlName="username" style="text-transform: capitalize;" maxlength="55" autofocus />
                      <a href="javascript:void(0);" class="icon_inner"> <i class="ri-pencil-fill white-color"></i> </a>
                    </div>
                    <div *ngIf="formData.controls['username'].invalid && (formData.controls['username'].dirty || formData.controls['username'].touched || this.formSubmitAttempt) ">
                      <p *ngIf="formData.controls['username'].errors.required" class="validation_err validation_err_color text-left">{{ global.termsArray["err_enter_name"] }}</p>
                    </div>
                  </div>

                  <!-- Select country for sofort -->
                  <div class="dropdown inner-drop icon_inner_bx">
                    <label>{{ global.termsArray['lbl_country'] ? global.termsArray['lbl_country'] : "Land" }}</label>
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <!-- {{ formData.value["selectLanguage"] != "" ? formData.controls["selectLanguageTerm"].value : global.termsArray["lbl_choose_language"] }} -->
                      {{ selectCountryTermFlag }}
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item" *ngFor="let country of countries" 
                      (click)="selectCountry(country)">{{ global.termsArray[country.term] }}</a>
                    </div>
                    <span class="glyphicon glyphicon-envelope form-control-feedback"></span>
                    <div *ngIf="formData.controls['selectCountry'].value == '' && this.formSubmitAttempt">
                      <span class="error text-white" *ngIf="formData.controls['selectCountry'].value == ''">
                        {{ global.termsArray["err_select_country"]?global.termsArray["err_select_country"]:"Please select country" }}
                      </span>
                    </div>
                  </div>

                  <!-- First name and surname fields are not replaced with user name -->
                  <!-- <div class="form-group pb-4">
                    <input type="name" class="form-control" id="exampleInputEmail1"
                      [placeholder]="global.termsArray['lbl_name']" formControlName="name"
                      style="text-transform: capitalize;" maxlength="55" autofocus />
                    <div *ngIf="
                        formData.controls['name'].invalid &&
                        (formData.controls['name'].dirty ||
                          formData.controls['name'].touched ||
                          this.formSubmitAttempt)
                      ">
                      <p *ngIf="formData.controls['name'].errors.required" style="color: red;">
                        {{ global.termsArray["err_enter_name"] }}
                      </p>
                    </div>
                  </div>

                  <div class="form-group pb-4">
                    <input type="name" class="form-control" id="exampleInputEmail1"
                      [placeholder]="global.termsArray['lbl_surname']" autofocus formControlName="surname"
                      style="text-transform: capitalize;" maxlength="55" />
                    <div *ngIf="
                        formData.controls['surname'].invalid &&
                        (formData.controls['surname'].dirty ||
                          formData.controls['surname'].touched ||
                          this.formSubmitAttempt)
                      ">
                      <span *ngIf="formData.controls['surname'].errors.required" style="color: red;">
                        {{ global.termsArray["err_select_surname"] }}
                      </span>
                    </div>
                  </div> -->

                  
                </form>
              </div>
              <div class="bttn-blue">
                <button class="btn btn-blue" (click)="onClickSubmit(formData.value)">
                  {{ global.termsArray["lbl_update"] }}
                </button>
                <br>
                <br>

                <p class="delete-act-txt"></p>

                <div *ngIf="haveStripeSource && cardInfo && cardInfo.brand"
                  [className]="cardInfo?.brand ? 'd-block text-left text-white pb-5' : 'd-none text-left text-white pb-5'">
                  <div class="account-title">
                    <h4 class="mb-4">{{ global.termsArray["lbl_card_info"] }}</h4>
                  </div>
                  <div class="card card-detail-box">
                    <div class="card-body bttn-blue pb-4 text-right">
                      <div class="card-top">
                        <div class="card-top-L">
                          <h4 class="card-title text-dark text-left">{{cardInfo?.brand}}</h4>
                          <h6 class="card-subtitle mb-2 text-muted text-left">**** **** **** {{cardInfo?.last4}}</h6>
                        </div>
                        <div class="card-top-R"><button class="edit-btn" (click)="openEditCard()">Edit</button></div>
                      </div>
                      <div class="date-card"><b>Expire on : </b>
                        <p>{{cardInfo?.exp_month+" / "+cardInfo?.exp_year}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <button routerLink="/transaction-history" class="btn btn-blue mb-4"> {{ this.global.termsArray["lbl_transaction_history"] }} </button>
                <p class="delete-act-txt"></p>
                <button class="btn btn-blue mb-4" (click)="openModal(choosePaymentType, 'big-model-dialog2 modal-dialog-centered') ">  {{this.global.termsArray["lbl_purchase_gift_card"]? this.global.termsArray["lbl_purchase_gift_card"]:'Purchase Gift Card' }}  </button>
                <p class="delete-act-txt"></p>
                <p class="text-white">{{ global.termsArray["lbl_delete_profile" ]}}</p>
                <button class="btn btn-blue" data-toggle="modal" data-target="#myModal1">{{ global.termsArray["lbl_delete"] }}</button>
                <!-- <button class="btn btn-blue"  (click)="deleteAccount()">{{ global.termsArray["lbl_delete"] }}</button> -->
              </div>
              
            </div>
          </div>
        </article>
        <app-app></app-app>
      </div>
    </div>
  </section>
</div>


<div class="modal fade" id="myModal1" role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog big-model-dialog-lesson">
    <div class="modal-content" style="background-color: white">
      <div class="modal-body" style="font-size: 20px; font-family: Akkurat-Bold; margin: 0; color: #4782c5; ">
        {{ this.global.termsArray["lbl_delete_confirm_msg"] }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" style="font-size: 15px; font-family: Akkurat-Bold" data-dismiss="modal" data-toggle="modal" data-target="#myModal1">
          {{ global.termsArray["lbl_no"]}}
        </button>
        <button type="button" class="btn btn-primary" style="font-size: 15px; font-family: Akkurat-Bold" data-dismiss="modal" (click)="deleteAccount()" data-target="#myModal1">
          {{ global.termsArray["lbl_yes"]}}
        </button>
      </div>
    </div>
  </div>
</div>
<button class="btn btn-blue d-none" data-toggle="modal" id="showCancelSubsNotificationbutton" data-target="#showCancelSubsNotification"></button>
<div class="modal fade" id="showCancelSubsNotification" role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog big-model-dialog-lesson">
    <div class="modal-content" style="background-color: white">
      <div class="modal-body" style="font-size: 20px; font-family: Akkurat-Bold; margin: 0; color: #4782c5; ">
        <ng-container *ngIf="subscribedFrom =='website'">
          {{ global.termsArray["lbl_cancel_subs_from_website"]?global.termsArray["lbl_cancel_subs_from_website"]:'Please cancel your subscription first' }}
        </ng-container>
        <ng-container *ngIf="subscribedFrom =='ios'">
          {{ global.termsArray["lbl_cancel_subs_from_ios"]?global.termsArray["lbl_cancel_subs_from_ios"]:'Please cancel your subscription from your app store first' }}
        </ng-container>
        <ng-container *ngIf="subscribedFrom =='android'">
          {{ global.termsArray["lbl_cancel_subs_from_android"]?global.termsArray["lbl_cancel_subs_from_android"]:'Please cancel your subscription from your play store first' }}
        </ng-container>
        <ng-container *ngIf="subscribedFrom =='offline'">
          {{ global.termsArray["lbl_cancel_subs_from_offline"]?global.termsArray["lbl_cancel_subs_from_offline"]:'Please cancel your subscription first' }}
        </ng-container>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" style="font-size: 15px; font-family: Akkurat-Bold" data-dismiss="modal" data-target="#showCancelSubsNotification">
          OK
        </button>
      </div>
    </div>
  </div>
</div>


<ng-template #choosePaymentType>
  <div class="modal-header payment-options-modal-header" style="border-bottom-width: 0px">
    <h4 class="modal-title" style="font-size: 25px; font-family: Akkurat-Bold; margin: 0; color: white;">{{ global.termsArray["lbl_payment_method"] }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modalRef.hide(); resetForm(paymentOptionsForm)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-footer coupon-modal-footer bg-white payment-options-modal-body">
    <form class="w-100 text-center" [formGroup]="paymentOptionsForm" (ngSubmit)="subscribeMyplan()">
      <ul class="w-100 payment_options">
        <li *ngIf="isActiveMethod('card')">
          <div class="pay_option_container">
            <img src="assets/images/credit-card.png" alt="card.png" class="credit-card">
            {{ global.termsArray["lbl_credit_card"]?global.termsArray["lbl_credit_card"]:"Credit Card" }}
            <input type="radio" formControlName="payment_method" id="radio_card" value="card" />
            <label for="radio_card"></label>
          </div>
        </li>
        <li *ngIf="enableSofort && isActiveMethod('sepa')">
          <div class="pay_option_container">
            <img src="assets/images/sepa.png" alt="sepa.png">
            {{ global.termsArray["lbl_sepa_debit"]?global.termsArray["lbl_sepa_debit"]:"SEPA" }}
            <input type="radio" formControlName="payment_method" id="radio_sepa_debit" value="sepa_debit" />
            <label for="radio_sepa_debit"></label>
          </div>
        </li>
        <li *ngIf="enableSofort && isActiveMethod('sofort')">
          <div class="pay_option_container">
            <img src="assets/images/sofort.png" alt="sofort.png">
            {{ global.termsArray["lbl_sofort"]?global.termsArray["lbl_sofort"]:"Sofort" }}
            <input type="radio" formControlName="payment_method" id="radio_sofort" value="sofort" />
            <label for="radio_sofort"></label>
          </div>
        </li>
        <li *ngIf="userCountry == 'Germany' && isActiveMethod('giropay')">
            <div class="pay_option_container">
              <img src="assets/images/giropay.png" alt="giropay.png">
              {{ global.termsArray["lbl_giropay"]?global.termsArray["lbl_giropay"]:"Giropay" }}
              <input type="radio" formControlName="payment_method" id="radio_giropay" value="giropay" />
              <label for="radio_giropay"></label>
            </div>
        </li>
        <li *ngIf="userCountry == 'Austria' && isActiveMethod('eps')">
            <div class="pay_option_container">
              <img src="assets/images/eps.png" alt="eps.png">
              {{ global.termsArray["lbl_eps"]?global.termsArray["lbl_eps"]:"EPS" }}
              <input type="radio" formControlName="payment_method" id="radio_eps" value="eps" />
              <label for="radio_eps"></label>
            </div>
          </li>
          <li *ngIf="isActiveMethod('paypal')">
            <div class="pay_option_container">
              <img src="assets/images/paypal.png" alt="paypal.png">
              {{ global.termsArray["lbl_paypal"]?global.termsArray["lbl_paypal"]:"Paypal" }}
              <input type="radio" formControlName="payment_method" id="radio_paypal" value="paypal" />
              <label for="radio_paypal"></label>
            </div>
          </li>
      </ul>
      <button class="btn sky-btn mt-4 mb-3" type="submit" [attr.disabled]="paymentOptionsForm.invalid?true:null">{{ global.termsArray["lbl_proceed"] }}</button>
    </form>
  </div>
</ng-template>

<ng-template #enterAmount>
  <div class="modal-header" style="border-bottom-width: 0px">
    <h4 class="modal-title" style="font-size: 25px; font-family: Akkurat-Bold; margin: 0; color: white;">{{ global.termsArray["lbl_gift_card_amount"] ? global.termsArray["lbl_gift_card_amount"] :'Gift Card Amount' }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modalRef.hide();giftCardAmount=null;">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="form-group">
      <div class="icon_inner_bx" >
        <input type="text" class="form-control" [(ngModel)]="giftCardAmount" [placeholder]="global.termsArray['lbl_enter_gift_card_amount']" (keydown)="validateInput($event)" [max]="unlimitedSubscriptionInfo?.price" autofocus style="font-size: 14px;height: 46px;background: #8c5182;color:#fff;border:none" />
      </div>
    </div>
    <div class="info-section" style="font-size: 16px;padding-left: 25px;">
      <ul style="list-style: disc;">
        <li>{{global.termsArray["amount_description_1"]}}</li>
        <li>{{global.termsArray["amount_description_2"]}}</li>
        <li>{{global.termsArray["amount_description_3"]}}</li>
    </ul>
    </div>
  </div>
 
  <div class="modal-footer coupon-modal-footer">
    <button type="button" class="btn btn-primary paypal_config_none" (click)="validateGiftCardAmount()" style="font-size: 15px;font-family: Akkurat-Bold;background-color: rgb(83, 179, 214);color: white;">
      {{ global.termsArray["lbl_ok"] }}
    </button>
  </div>
</ng-template>


  
<ng-template #couponcode>
  <div class="modal-header" style="border-bottom-width: 0px">
    <h4 class="modal-title" style="font-size: 25px; font-family: Akkurat-Bold; margin: 0; color: white;">{{ global.termsArray["lbl_enter_promo_code"] }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modalRef.hide();giftCardAmount=null">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group pb-4">
      <div class="icon_inner_bx" >
        <!-- <label for="username">{{ global.termsArray['lbl_name'] ? global.termsArray['lbl_name'] : "Benutzername" }}</label> -->
        <input type="email"  class="form-control" id="exampleInputEmail1" [(ngModel)]="couponCode" [placeholder]="global.termsArray['lbl_promo_code']"  autofocus style="font-size: 14px;height: 46px;background: #8c5182;color:#fff;border:none" />
      </div>
    </div>
  </div>
  <div class="modal-footer coupon-modal-footer">
    <!-- Button for paypal -->
      <ng-container *ngIf="paymentMethod == 'paypal'; else showStripeOption;">
        <div class="paypal_config_box">
          <button type="button" class="btn btn-basic paypal_config_none"
            style="font-size: 15px; font-family: Akkurat-Bold; color: #3d76ce">
            {{ global.termsArray["lbl_skip"] }}
          </button>
          <div id="paypal_button_container" class="paypal_custom_button paypal_config_trigger"></div>
        </div>
      </ng-container>
      <!-- Button for paypal -->
      <ng-template #showStripeOption>
        <button type="button" class="btn btn-basic paypal_config_none" (click)="purchaseGiftCards()" style="font-size: 15px; font-family: Akkurat-Bold; color: #3d76ce">
          {{ global.termsArray["lbl_skip"] }}
        </button>
      </ng-template>
    <button type="button" class="btn btn-primary" style="font-size: 15px;font-family: Akkurat-Bold;background-color: rgb(83, 179, 214);color: white;" (click)="applyCouponCode(couponCode, couponDiscountResult)">
      {{ global.termsArray["lbl_Submit"] }}
    </button>
  </div>
</ng-template>



<ng-template #couponDiscountResult>
  <div class="modal-header" style="border-bottom-width: 0px">
    <h4 class="modal-title" style="font-size: 25px; font-family: Akkurat-Bold; margin: 0; color: white;">{{ global.termsArray["lbl_your_order"] }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modalRef.hide();giftCardAmount=null">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body body-parent">
    <div class="child-body">
      <h3>{{ this.global.termsArray["lbl_total_price"] }}</h3>
      <h3>{{ this.global.termsArray["lbl_subscription_amount"] }}</h3>
      <h3>{{ this.global.termsArray["lbl_discount"] }}</h3>
    </div>
    <div class="child-body" *ngIf="couponCodeData">
      <h3>: {{ couponCodeData["total"] | currency: "EUR":"symbol":"1.2-2" }}</h3>
      <h3>: {{ couponCodeData["discount_less"] | currency: "EUR":"symbol":"1.2-2" }}</h3>
      <h3>: {{ couponCodeData["discount_amount"] > couponCodeData["total"] ? (couponCodeData["total"] | currency: "EUR":"symbol":"1.2-2") :( couponCodeData["discount_amount"] | currency: "EUR":"symbol":"1.2-2") }}</h3>
    </div>

  </div>
  <div class="modal-footer coupon-modal-footer">

    <p *ngIf="couponCodeData && couponCodeData['discount_amount'] > couponCodeData['total']" style="margin-top: -50px; margin-bottom: 20px;" >{{ global.termsArray['lbl_rest_gift_amount']}}</p>
    <!-- Button for paypal -->
    <ng-container *ngIf="couponCodeData.discount_less>0;else manualiftCardPurchase;">
      <ng-container *ngIf="paymentMethod == 'paypal'; else showStripeOkOption;">
        <div class="paypal_config_box">
          <button type="button" class="btn btn-primary paypal_config_none" style="font-size: 15px;font-family: Akkurat-Bold;background-color: rgb(83, 179, 214);color: white;">
            {{ global.termsArray["lbl_ok"] }}
          </button>
          <div id="paypal_button_container2" class="paypal_custom_button paypal_config_trigger"></div>
        </div>
      </ng-container>
      <!-- Button for paypal -->
      <ng-template #showStripeOkOption>
        <button type="button" class="btn btn-primary paypal_config_none" (click)="purchaseGiftCards()" style="font-size: 15px;font-family: Akkurat-Bold;background-color: rgb(83, 179, 214);color: white;">
          {{ global.termsArray["lbl_ok"] }}
        </button>
      </ng-template>
    </ng-container>

    <ng-template #manualiftCardPurchase>
      <button type="button" class="btn btn-primary paypal_config_none" (click)="manualPurchaseGiftCards()" style="font-size: 15px;font-family: Akkurat-Bold;background-color: rgb(83, 179, 214);color: white;">
        {{ global.termsArray["lbl_ok"] }}
      </button>
    </ng-template>
  </div>
</ng-template>