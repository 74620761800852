import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import "rxjs/add/operator/map";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  mobileCms: any = false;
  isLive: any = false;
  // mobile cms page management
  loginTime: any = null;
  setupTime = environment.setupTime;
  setupHours = 24 * 60 * 60 * 1000;
  profileTab = 1;
  currentBlogPage: any = 1;
  termDate: any = localStorage.getItem(btoa("changeDate")) ? atob(localStorage.getItem(btoa("changeDate"))) : new Date().toISOString();
  baselesson_id: any = localStorage.getItem(btoa("baselesson_id"))
    ? atob(localStorage.getItem(btoa("baselesson_id")))
    : null;
  lessonfamily_id: any = localStorage.getItem(btoa("lessonfamily_id"))
    ? atob(localStorage.getItem(btoa("lessonfamily_id")))
    : null;
  lesson_id: any = localStorage.getItem(btoa("lesson_id"))
    ? atob(localStorage.getItem(btoa("lesson_id")))
    : null;
  lesson_title: any = localStorage.getItem(btoa("lesson_title"))
    ? atob(localStorage.getItem(btoa("lesson_title")))
    : null;
  max_read_slide: any = localStorage.getItem(btoa("max_read_slide"))
    ? atob(localStorage.getItem(btoa("max_read_slide")))
    : null;
  max_repeated_read_slide: any = localStorage.getItem(btoa("max_repeated_read_slide"))
    ? atob(localStorage.getItem(btoa("max_repeated_read_slide")))
    : null;
  apple_url: any = localStorage.getItem('apple_url')
    ? localStorage.getItem('apple_url')
    : 'javascript:;';
  play_url: any = localStorage.getItem('play_url')
    ? localStorage.getItem('play_url')
    : 'javascript:;';
  isBlogActive = false;
  isLogin = false;
  blogData;
  loader;
  apiUrl = environment.apiUrl;
  languageArray = [];
  learningLanguages = [];
  selectLanguage = localStorage.getItem(btoa("selectLanguage")) && String(localStorage.getItem(btoa("selectLanguage"))) !="undefined" ? atob(localStorage.getItem(btoa("selectLanguage"))) : "5dea0938ec155c3df111d13c";



  termsArray = localStorage.getItem("termsArray") ? JSON.parse(localStorage.getItem("termsArray")) : [];
  selectedLabel: any = this.termsArray["lbl_choose_app_language"];
  user: any = null;
  AuthToken = null;
  routeParams;
  blogArray: any;
  learningLanguage: any = this.user && this.user.learning_language_id ? this.user.learning_language_id : null;
  formData: any;
  currentUserInterval: any = null;
  deviceInfo = null;
  max_767_width;
  isPhone: any = false;
  storeUrls: Subject<any> = new Subject<any>(); 
  private notificationsSubject = new Subject<number>();
  private repeatingLessonSubject = new Subject<number>();
  awards = [
    {
      name: 'Guter-Anfang-Award',
      image: '../../../assets/images/Guter-Anfang-Award.png',
      minutes: 20 
    },
    {
      name: 'Aufbau-Award',
      image: '../../../assets/images/Aufbau-Award.png',
      minutes: 40 
    },
    {
      name: 'Fleiss-Award',
      image: '../../../assets/images/Fleiss-Award.png',
      minutes: 60 
    },
    {
      name: 'Hartnäckigkeits-Award',
      image: '../../../assets/images/Hartnäckigkeits-Award.png',
      minutes: 80 
    },
    {
      name: 'Sprachlern-Star-Award',
      image: '../../../assets/images/Sprachlern-Star-Award.png',
      minutes: 100 
    }
  ];
  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  // Observable to subscribe to loading state changes
  public isLoading$ = this.isLoadingSubject.asObservable();

  constructor(
    public http: HttpClient,
    public toastrService: ToastrService,
    private ngxService: NgxUiLoaderService,
    public router: Router,
  ) {
    toastrService["options"] = {
      preventDuplicates: true,
      preventOpenDuplicates: true,
    };

    // console.log("tempsArray: ", this.termsArray);
    // let linkHref = "https://birlingo.de/privacy-policy";
    // let linkText = this.termsArray['lbl_cookie_link'];
    // let link = "<a aria-label='learn more about cookies' role='button' tabindex='0' class='cc-link' href='"+linkHref+"' rel='noopener noreferrer nofollow' target='_blank'>"+linkText+"</a>"
    // document.getElementById("cookieconsent").innerHTML = this.termsArray['lbl_cookie_text'] + link;
  }

  public post(url, body, successCallback, failedCallback, loader = false, interval = 300, text = "Please wait...") {
    let headers = this.AuthToken != null || this.AuthToken != undefined ? new HttpHeaders({ "Content-Type": "application/json", Authorization: "Bearer " + this.AuthToken, }) : new HttpHeaders({ "Content-Type": "application/json" });

    let options = { headers: headers };

    if (loader) {
      this.loader = true;
      this.ngxService.start();
    }

    // console.log("Post api url: ", this.apiUrl + url);

    this.http.post(this.apiUrl + url, body, options).subscribe((data2) => {
      this.loader = false;
      setTimeout(() => {
        this.ngxService.stop();
      }, interval);
      let data;
      data = data2;
      if (data.success && data.last_updated_date && data.last_updated_date != "") {
        let difference = new Date(data.last_updated_date).getTime() - new Date(this.termDate).getTime();
        if (difference > 0) {
          this.getTermsData();
        }
      }
      successCallback(data);
    }, (err) => {
      console.log("***",err)
      setTimeout(() => {
        this.ngxService.stop();
      }, 300);
      let online = window.navigator.onLine;
      let error = {};
      if (!online) {
        // error["message"] = "Please check your internet";
      } else if (err.statusText) {
        error["message"] = err.statusText;
      } else {
        error["message"] = "Server is not responding. Please try again after some time";
      }
      this.loader = false;
      //console.log("err => ", err.error);
      if (err && err.error == "Unauthorized") {
        localStorage.removeItem(btoa("AuthToken"));
        localStorage.removeItem(btoa("user"))
        localStorage.removeItem(btoa("routeParams"));
        this.routeParams = null;
        this.isLogin = false;
        this.AuthToken = null;
        failedCallback(error);
        this.router.navigate(['login']);
      } else {
        failedCallback(error,err);
      }
    },
    );
  }

  get(url, successCallback, failedCallback, loader = false, interval = 300) {
    let headers = this.AuthToken != null || this.AuthToken != undefined ? new HttpHeaders({ "Content-Type": "application/json", Authorization: "Bearer " + this.AuthToken }) : new HttpHeaders({ "Content-Type": "application/json" });

    let options = { headers: headers };

    if (loader) {
      this.loader = true;
      this.ngxService.start();
    }
    // console.log("get api url: ", this.apiUrl + url);

    this.http.get(this.apiUrl + url, options).subscribe((data2) => {
      this.loader = false;
      setTimeout(() => {
        this.ngxService.stop();
      }, interval);
      let data;
      data = data2;
      if (data.success && data.last_updated_date && data.last_updated_date != "") {
        let difference = new Date(data.last_updated_date).getTime() - new Date(this.termDate).getTime();
        if (difference > 0) {
          this.getTermsData();
        }
      }
      successCallback(data);
    }, (err) => {
      setTimeout(() => {
        this.ngxService.stop();
      }, 300);
      this.loader = false;
      let online = window.navigator.onLine;
      let error = {};
      if (!online) {
        error["message"] = "PleasesuccessCallback check your internet";
      } else if (err.statusText) {
        error["message"] = err.statusText;
      } else {
        error["message"] =
          "Server is not responding. Please try again after some time";
      }
      this.loader = false;
      console.log("err => ", error);
      if (err && err.error == "Unauthorized") {
        localStorage.removeItem(btoa("AuthToken"));
        localStorage.removeItem(btoa("user"))
        localStorage.removeItem(btoa("routeParams"));
        this.routeParams = null;
        this.isLogin = false;
        this.AuthToken = null;
        failedCallback(error);
        this.router.navigate(['login']);
      } else {
        failedCallback(error);
      }

    } ,
    );
  }

  showToast(title = "", message = "") {
    this.toastrService.success("", message);
  }

  showDangerToast(title = "", message = "",time?: number) {
    // this.toastrService.error("", message);
    this.toastrService.error("", message, {
      timeOut: time || 3000,
    });
  }

  showWarningToast(title = "", message = "") {
    this.toastrService.warning("", message);
  }

  getTermsData(flag = false, message = "") {
    return new Promise((resove)=>{
      let language_id = this.selectLanguage && this.selectLanguage.trim() !== "" && this.selectLanguage != null && 
                         this.selectLanguage != undefined ? this.selectLanguage : "5dea0938ec155c3df111d13c"
      if(!language_id){
        resove(this.termsArray['err_select_language']);
        this.showToast("", this.termsArray['err_select_language']); 
      }
      this.post(this.isLogin ? "afterloginterms" : "terms", JSON.stringify({
        language_id:language_id
      }),
        (data) => {
          console.log(language_id);
          resove(data);
          if (data.success) {
            if (JSON.stringify(data.data) != JSON.stringify({}) && data.data) {
              this.termsArray = data.data;
              localStorage.setItem("termsArray", JSON.stringify(data.data));
            }
            localStorage.setItem(
              btoa("selectLanguage"),
              btoa(this.selectLanguage)
            );
            localStorage.setItem(
              btoa("changeDate"),
              btoa(new Date().toISOString())
            );
            this.termDate = new Date().toISOString();
            if (flag) {
              this.currentUser();
              this.showToast("", this.termsArray[message]);
            }
          } else {
            this.showDangerToast("", this.termsArray[data.message]);
          }
        },
        (err) => {
          resove(err);
          this.showDangerToast("Error", err.message);
        },
        true
      );
    })
  }

  currentUser() {
    this.get(
      "currentUser",
      (data) => {
        //console.log(data);
        if (data.success) {
          // console.log(data);
          this.selectLanguage = data.data.language_id;
          localStorage.setItem(
            btoa("selectLanguage"),
            btoa(data.data.language_id)
          );
          this.learningLanguage = data.data.learning_language_id;
          localStorage.setItem(btoa("user"), btoa(JSON.stringify(data.data)));
          this.user = data.data;
          this.isLogin = true;
          localStorage.setItem(btoa("AuthToken"), btoa(data.data.token));
          this.AuthToken = data.data.token;
        }
      },
      (err) => {
        this.showDangerToast("Error", err.message);
      }
    );
  }

  submitLoginHours() {
    let minutes = Math.abs(
      (new Date().getTime() - this.loginTime.getTime()) / 60000
    );
    //console.log(this.loginTime.getTime(), minutes);
    this.post(
      "updateLoginHrs",
      JSON.stringify({ totalmins: minutes }),
      (data) => {
        console.log(data);
        if (data.success) {
        }
      },
      (err) => {
        this.showDangerToast("Error", err.message);
      },
      true
    );
  }

  setCurrentUserInterval() {
    this.currentUserInterval = setInterval(() => {
      if (this.isLogin) {
        this.currentUser();
      } else {
        clearInterval(this.currentUserInterval);
      }
    }, 300000);
  }

  navigate(route, data) {
    localStorage.setItem(btoa("routeParams"), btoa(JSON.stringify(data)));
    this.routeParams = data;
    this.router.navigate(route);
    if (data.fb && data.fb == "fb") {
      setTimeout(() => {
        location.reload();
      }, 300);
    }
  }

  getSettingData() {
    this.get(
      "getSettingData",
      (data) => {
        // console.log(data);
        if (data.success) {
          // console.log('data ==>',data);
          this.isLive = data.data.payment_mode;
          return data;
        }
      },
      (err) => {
        this.showDangerToast("Error", err.message);
      }
    );
  }

  getStorage(name){
    return new Promise((resolve)=>{
      let data = JSON.parse(atob(localStorage.getItem(btoa(name))));
      resolve(data)
    })
  }


  setNotificationValue(value: number) {
    this.notificationsSubject.next(value);
  }

  getNotificationValue() {
    return this.notificationsSubject.asObservable();
  }

  setrepeatingLessonValue(value: number) {
    this.repeatingLessonSubject.next(value);
  }

  getrepeatingLessonValue() {
    return this.repeatingLessonSubject.asObservable();
  }



  showLoader(component?) {
    console.log("showing loader from component",component)
    this.isLoadingSubject.next(true);
  }

  hideLoader() {
    console.log("hiding loader")
    this.isLoadingSubject.next(false);
  }
}
