<div class="inner-wraper fav-inner-wrapper">
  <section class="lesson-cover-sect">
    <div class="container">
      <div class="lektionen-cover-new" [ngClass]="backgroundClass">
        <div class="lekt-title title" style="display: block !important;">
          <div class="d-flex">
            <a href="javascript:;" (click)="location.back()"><i class="ri-arrow-left-s-line"></i></a>
            <h2>{{ global.termsArray["lbl_listen_passively"] }}</h2>
          </div>
        </div>
        <div *ngIf="lessons && lessons?.length>0">
          <div class="view-icon fake-btns m-left">
            <span class="mr-3">
              <a>
                <circle-progress (click)="fakeCircleBtn()" [percent]="percent()" [startFromZero]="false"
                  [maxPercent]="100" [showZeroOuterStroke]="true" [radius]="30" [space]="-2" [outerStrokeWidth]="0"
                  [innerStrokeWidth]="0" [outerStrokeColor]="percent() > 0 ? '#3399FF' : '#c3d6eb'"
                  [innerStrokeColor]="'#c3d6eb'" [animation]="true" [animationDuration]="300"
                  [backgroundStrokeWidth]="0" [outerStrokeLinecap]="'round'" [space]="-7" [showUnits]="false"
                  [showTitle]="false" [showSubtitle]="false" [showImage]="true" [showInnerStroke]="true"
                  [showZeroOuterStroke]="true" [imageSrc]="circleImage[isPlaying==false?'pause':'play']"
                  [imageHeight]="25" [imageWidth]="25" [responsive]="false" [class]="'circle-progress'"
                  [backgroundColor]="'#fff'">
                </circle-progress>
              </a>
            </span>
            <div class="dropdown" *ngIf="!isMobileDevice">
              <button class="speed_dropdown" id="speedDrop" type="button" data-toggle="dropdown"
                aria-expanded="false">
                <span class="button_view">
                  <img height="10" width="10" src="assets/images/playIcon.svg" />
                </span>
                <span class="selected_text">{{defaultSpeed.toFixed(1)}}x</span>
                <i class="ri-arrow-down-s-line"></i>
              </button>
              <ul class="dropdown-menu speed_options">
                <li class="dropdown-item" [ngClass]="{active:option?.selected}"
                  *ngFor="let option of speedOptions;" (click)="selectOption(option)">
                  <i class="ri-check-fill"></i>
                  <span class="sl_value" [attr.data-value]="option?.value">{{option?.value.toFixed(1)}}x</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="view">
            <div class="listeng listting-desc-box">
              <h3 style="font-size: 20px">
                {{ global.termsArray['lbl_songloop'] }}
              </h3>
            </div>
            <div class="time-section-less">
              <div class="dropdown time-dropdown dropdown-height">
                <button class="speed_dropdown" id="speedDrop" type="button" data-toggle="dropdown"
                  aria-expanded="false">
                  <svg width="53" height="58" viewBox="0 0 53 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="53" height="58" fill="white" />
                    <path
                      d="M37.268 2C36.5259 2 35.9288 2.60605 35.9288 3.35938C35.9288 4.1127 36.5259 4.71875 37.268 4.71875H40.5656L36.258 9.71445C35.9121 10.1166 35.8284 10.683 36.046 11.1701C36.2636 11.6572 36.7435 11.9688 37.268 11.9688H43.5173C44.2594 11.9688 44.8564 11.3627 44.8564 10.6094C44.8564 9.85605 44.2594 9.25 43.5173 9.25H40.2197L44.5272 4.2543C44.8732 3.85215 44.9569 3.28574 44.7393 2.79863C44.5217 2.31152 44.0418 2 43.5173 2H37.268ZM44.4101 14.6875C43.668 14.6875 43.0709 15.2936 43.0709 16.0469C43.0709 16.8002 43.668 17.4062 44.4101 17.4062H47.9197L43.3555 23.3705C43.043 23.7783 42.9872 24.3334 43.2104 24.8035C43.4336 25.2736 43.9023 25.5682 44.4156 25.5682H50.6594C51.4015 25.5682 51.9985 24.9621 51.9985 24.2088C51.9985 23.4555 51.4015 22.8494 50.6594 22.8494H47.1497L51.7195 16.8852C52.032 16.4773 52.0878 15.9223 51.8646 15.4521C51.6414 14.982 51.1727 14.6875 50.6594 14.6875H44.4101ZM28.3404 14.6875C27.5983 14.6875 27.0012 15.2936 27.0012 16.0469C27.0012 16.8002 27.5983 17.4062 28.3404 17.4062H35.5159L27.2579 28.8363C26.9622 29.2498 26.9175 29.7992 27.1407 30.2523C27.3639 30.7055 27.8382 31 28.3404 31H38.1607C38.9029 31 39.4999 30.3939 39.4999 29.6406C39.4999 28.8873 38.9029 28.2812 38.1607 28.2812H30.9852L39.2432 16.8512C39.5389 16.4377 39.5836 15.8883 39.3604 15.4352C39.1372 14.982 38.6629 14.6875 38.1607 14.6875H28.3404Z"
                      fill="black" />
                    <path
                      d="M17.8377 15.9503C8.53365 19.7559 4.09189 30.3965 7.90512 39.7191C11.7184 49.0417 22.3439 53.5194 31.6479 49.7137C36.2133 47.8463 39.6033 44.3308 41.431 40.1346C41.6566 39.6113 41.5203 38.9984 41.0881 38.6298C40.6559 38.2611 40.0281 38.21 39.5453 38.513C38.8594 38.9431 38.1338 39.3191 37.3578 39.6365C30.0577 42.6225 21.7072 39.1058 18.7108 31.7803C16.6825 26.8213 17.6282 21.3936 20.7127 17.4751C21.0643 17.0234 21.0821 16.4003 20.7595 15.934C20.4369 15.4678 19.8427 15.2621 19.2968 15.4326C18.8068 15.5891 18.3229 15.7606 17.8408 15.9578L17.8377 15.9503Z"
                      fill="black" />
                  </svg>
                  <span class="selected_text text-black" >{{  selectedSleepTimer }} Min</span>
                  <i class="ri-arrow-down-s-line"></i>
                </button>
                <ul class="dropdown-menu speed_options">
                  <li class="dropdown-item" [ngClass]="{active: option === selectedSleepTimer }"
                    *ngFor="let option of sleepTimerOptions" (click)="selectsleepTimerOption(option)">
                    <i class="ri-check-fill"></i>
                    <p class="sl_value" [attr.data-value]="option">{{ option }} Min</p>
                  </li>
                </ul>
              </div>
            </div>

            <div class="time-section-less time-section-less-bottom mt-4">
              <span>
                <ng-container>
                  <h3>
                    {{overallTime}}
                  </h3>
                  <!-- <h6>Empfohlen</h6> -->
                  <!-- <div class="progress-outer d-flex progress-div">
                    <span>0`</span>
                    <div class="progress">
                      <span class="progress-bar" id="timerProgress" role="progressbar" aria-valuenow="25"
                        value="sleepTimerValue?.value" aria-valuemin="0" aria-valuemax="100"></span>
                    </div>
                    <span>{{selectedSleepTimer}}`</span>
                  </div> -->
                </ng-container>
              </span>
            </div>
          </div>
        </div>

        <div class="list-sentens-box p-2">
          <div class="leisure-lp-lists">
            <div class="level-point" *ngIf="lessons && lessons?.length == 0">
              <div class="level-point-in d-flex justify-content-center align-items-center">
                <div>
                  {{
                  global.termsArray["lesson_not_found_for_passive_listening"]
                  ? global.termsArray["lesson_not_found_for_passive_listening"]
                  : "Keine Lektion für passives Zuhören gefunden"
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>