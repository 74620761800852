<div class="inner-wraper">
  <section class="lesson-cont-sect wrap-space">
    <div class="container">
      <div class="lektionen-cover-new">
        <article>
          <div class="setting-arrow inner-arrow">
            <a href="javascript:;" (click)="goBack()">
              <i class="ri-arrow-left-s-line"></i>
              <h6>{{ global.termsArray["lbl_go_back"] }}</h6>
            </a>
          </div>
          <div class="list-lesson-mob">
            <div class="account-title">
              <h4>{{ global.termsArray["lbl_rate_birlingo"] }} </h4>
            </div>

            <!--Step 1  -->
            <div class="like-sec" *ngIf="step1">
              <h6>{{ global.termsArray["lbl_like_birlingo_1"] }} </h6>
              <div class="example-result-card">
                <div class="example-label-container">
                  <label id="example-name-label" class="example-name-label">{{ global.termsArray["lbl_bad_rating"]
                    }}</label>
                  <label class="example-value-label">{{ global.termsArray["lbl_super_rating"] }}</label>
                </div>
                <mat-slider class="example-margin" max="10" min="1" step="1" showTickMarks="false"
                  (input)="onSliderChange($event)">
                  <input matSliderThumb [(ngModel)]="rated" #slider>
                </mat-slider>
              </div>
              <div class="d-flex">
                <button type="submit" class="btn btn-info" (click)="goToStep2();">
                  {{ global.termsArray["lbl_next"] }}
                </button>
              </div>
            </div>

            <!--Step 2  -->
            <div class="like-sec" *ngIf="step2">
              <!--Step 2.a  -->
              <ng-container *ngIf="rated<7;else elseblock1">
                <div class="rate-texts-c">
                  <h6>{{ global.termsArray["lbl_improve_rating"] }}</h6>
                  <textarea (input)="checkSpace()" class="form-control feedback-input" [(ngModel)]="feedback"
                    style="color: #4882c2"></textarea>
                  <ng-container *ngIf="isError">
                    <span class="text-danger">{{error}}</span>
                  </ng-container><br />
                  <button _ngcontent-dfe-c3="" class="btn btn-info" type="submit" (click)="submitRating()">
                    {{ global.termsArray["lbl_Submit"] }}
                  </button>
                </div>
              </ng-container>
              <!--Step 2.b  -->
              <ng-template #elseblock1>
                <div class="like-sec">
                  <h6>{{ global.termsArray["lbl_rate_birlingo_1"] }}</h6>
                  <div class="d-flex">
                    <button class="btn btn-info" (click)="goToStep3('app')">
                      {{ global.termsArray["lbl_in_app_store"] }}
                      <span>{{ global.termsArray["lbl_in_app_store_sub"] }}</span>
                    </button>
                    <button class="btn btn-info" (click)="goToStep3('web')">
                      {{ global.termsArray["lbl_direct_feedback"] }}
                    </button>
                  </div>
                </div>
              </ng-template>

            </div>

            <!--Step 3  -->

            <div class="like-sec" *ngIf="step3">
              <!--Step 3.a  -->
              <ng-container *ngIf="!showAppLinks;else elseblock2">
                <div class="rate-texts-c">
                  <h6>{{ global.termsArray["lbl_direct_feedback_1"] }}</h6>
                  <textarea (input)="checkSpace()" class="form-control feedback-input" [(ngModel)]="feedback"
                    [placeholder]="global.termsArray['lbl_write_message']" style="color: #4882c2"></textarea>
                  <ng-container *ngIf="isError">
                    <span class="text-danger">{{error}}</span>
                  </ng-container><br />
                  <button _ngcontent-dfe-c3="" class="btn btn-info" type="submit" (click)="submitRating()">
                    {{ global.termsArray["lbl_Submit"] }}
                  </button>
                </div>
              </ng-container>
              <!--Step 3.b  -->
              <ng-template #elseblock2>
                <div class="like-sec">
                  <h6>{{global.termsArray["ask_for_rating_apps"] ? global.termsArray["ask_for_rating_apps"] : 'Would you
                    like to give us a feedback on our app store'}}</h6>
                  <div class="d-flex">
                    <button class="btn btn-info" (click)="openUrl(1)">
                      {{ global.termsArray["open_android_app"] }}
                    </button>
                    <button class="btn btn-info" (click)="openUrl(2)">
                      {{ global.termsArray["open_ios_app"] }}
                    </button>
                  </div>
                </div>
              </ng-template>

            </div>
          </div>
        </article>
        <app-app></app-app>
      </div>
    </div>
  </section>
</div>