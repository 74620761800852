import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { GlobalService } from "../../global.service";
import { TemplateRef } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Router, ActivatedRoute } from "@angular/router";
import { InfiniteScrollDirective } from "ngx-infinite-scroll";
import { Location } from "@angular/common";


@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit {

  @ViewChild(InfiniteScrollDirective, { static: true })
  infiniteScroll: InfiniteScrollDirective;

  set appScroll(directive: InfiniteScrollDirective) {
    this.infiniteScroll = directive;
  }

  Lfamily: any = localStorage.getItem(btoa("Lfamily")) ? localStorage.getItem(btoa("Lfamily")) : "";
  isSubscribed: any;
  page: any = 1;
  LessonList: any = {};
  listArray: any;
  status: any = "all";
  level: any = "";
  loader = false;
  lessonFamilyId: any;
  totalCount: any;
  modalRef: BsModalRef;
  completeCount: any = 0;
  favLessonId: any = null;
  public authUser: any;

  constructor(
    public global: GlobalService,
    public router: Router,
    private route: ActivatedRoute,
    public location: Location,
    private modalService: BsModalService
  ) {
    this.global.profileTab = 1;
    this.global.currentUser();
    if (this.global.user.isFreeAccess && this.global.user.isFreeAccess == 1) this.isSubscribed = 1; else this.getSubsriptionStatus();
    this.route.paramMap.subscribe((params) => {
      this.lessonFamilyId = params.get("id") ? params.get("id") : null;
    });

  }

  ngOnInit() {
    this.global.loader = true;
    this.authUser = this.global.user;
    const defaultLevel = localStorage.getItem(btoa('defaultLevel'));
    this.level = defaultLevel ? atob(defaultLevel): 'all';
    this.getFavLessonList();
  }

  onScroll() {
    if (!this.global.loader) {
      this.getFavLessonList();
      this.infiniteScroll.ngOnDestroy();
      this.infiniteScroll.setup();
    }
  }

  getFavLessonList() {
    let body = {
      learning_language_id :this.global.user.learning_language_id
    };
    this.global.post("get-favourite", JSON.stringify(body),(data) => {
      if (data.success) {
        this.LessonList = data.data;
        console.log(this.LessonList)
      } else {
        this.global.showDangerToast(this.global.termsArray[data.message]);
        this.loader = true;
      }
    }, (err) => {
      this.global.showDangerToast(err.message);
    }, true);
  }

  getSubsriptionStatus() {
    this.global.get("getSubsriptionStatus", (data) => {
      if (data.success) {
        this.isSubscribed = data.data.is_subsribed;
        // console.log("isSubscribed: ", this.isSubscribed);
      }
    }, (err) => { }, true);
  }

  navigate(type, lesson, template?) {
    console.log("this.isSubscribed",this.isSubscribed)
    sessionStorage.setItem("lessonTitle",lesson.lessonTitle|| "");
    sessionStorage.setItem("familyTitle" ,this.global.termsArray[lesson.family_name]|| "")
    sessionStorage.setItem("lesson_family_id",lesson.lessonfamily_id);
    localStorage.setItem(btoa("Lfamily"), lesson.family_name);
    // return;
    if (type=='demo' && lesson.is_free && lesson.is_demo == 1) {
      if (lesson.lesson_id) {
        localStorage.setItem(btoa("lesson_id"), btoa(lesson.lesson_id));
        this.global.lesson_id = lesson.lesson_id;
      }
      localStorage.setItem(btoa("max_read_slide"), btoa(lesson.progres));
      localStorage.setItem(btoa("time_loop"), btoa(lesson.time_loop));
      this.global.max_read_slide = lesson.progres;
      (lesson.active_progress == 100 && lesson.passive_progress == 100 && lesson.repeat_progress == 100 && lesson.speak_progress == 100 ) ? this.router.navigate(["repeated-lesson/demo"]):this.router.navigate(["lesson/demo"]);
    } 
    else if (this.isSubscribed || lesson.is_free|| this.isWithinFreeTrial()) {
      localStorage.setItem(btoa("baselesson_id"), btoa(lesson.baselesson_id));
      localStorage.setItem(btoa("lessonfamily_id"), btoa(lesson.lessonfamily_id));
      localStorage.setItem(btoa("lesson_id"), btoa(lesson._id));
      localStorage.setItem(btoa("lesson_title"), btoa(lesson.title + " "));
      localStorage.setItem(btoa("max_read_slide"), btoa(lesson.progress));
      localStorage.setItem(btoa("time_loop"), btoa(lesson.time_loop));
      this.global.baselesson_id = lesson.baselesson_id;
      this.global.lessonfamily_id = lesson.lessonfamily_id;
      this.global.lesson_id = lesson._id;
      this.global.lesson_title = lesson.title + " ";
      this.global.max_read_slide = lesson.progress;
      (lesson.active_progress == 100 && lesson.passive_progress == 100 && lesson.repeat_progress == 100 && lesson.speak_progress == 100 ) ? this.router.navigate(["repeated-lesson/sentences"]) : this.router.navigate(["lesson/" + "sentences"]);
    }
    else {
      this.openModal(template,'big-model-dialog');
    }
    
  }

  openModal(template: TemplateRef<any>,cssClass) {
    const config = {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: false,
      class: cssClass
    };
    this.modalRef = this.modalService.show(template, config);
  }

  navigateToSubscription() {
    // this.global.showWarningToast(this.global.termsArray['msg_please_subscribe'])
    this.router.navigate(["account"]);
  }

  returnLevel(level_id) {
    let level = "";
    if (level_id == 1) {
      level = "lbl_level_one";
    } else if (level_id == 2) {
      level = "lbl_level_two";
    } else if (level_id == 3) {
      level = "lbl_level_three";
    } else if (level_id == 4) {
      level = "lel_level_four";
    }
    return level;
  }

  filterLevel(level) {
    if (level != this.level) {
      localStorage.setItem(btoa('defaultLevel'),btoa(level));
      this.level = level;
      this.LessonList = {};
      // this.listArray = [];
      this.getFavLessonList();
    }
  }

  filterStatus(status) {
    if (status != this.status) {
      this.status = status;
      this.LessonList = {};
      this.listArray = [];
      this.getFavLessonList();
    }
  }

  getDemoLessonOnly() {
    this.global.get("demoLessonOnly", (data) => {
      // console.log("data: ", data);
    },
      (err) => { },
      true
    );
  }
  setLessonTitle(lesson:any){
    sessionStorage.setItem('lessonTitle',lesson.lessonTitle);
    sessionStorage.setItem('lessonLevel',this.global.termsArray[this.returnLevel(lesson.level_id)]);
  }


  toggleFavorites(){
    if(this.favLessonId){
      let body = {
        action:0,
        lesson_id:this.favLessonId,
        learning_language_id :this.global.user.learning_language_id
      };
      this.global.post("toggle-favourite", JSON.stringify(body), (data) => {
        if (data.success) {
          this.global.showToast("",this.global.termsArray[data.message]);
          this.getFavLessonList();
          this.favLessonId=null
        } else {
          this.global.showDangerToast(this.global.termsArray[data.message]);
          this.loader = true;
        }
      }, (err) => {
        this.global.showDangerToast(err.message);
      }, true);
    }
  }

  isWithinFreeTrial(): boolean {
    let result = false
    if (!this.global.user.freeTrialEndDate) {
      return result;
    }
    const currentDate = new Date();
    const freeTrialEndDate = new Date(this.global.user.freeTrialEndDate);
    result = currentDate < freeTrialEndDate;
    return result;
  }


}
