<div class="inner-wraper container welcome-page mt-0">
    <div class="demo-explain-sec mt-5">
        <div class="w-50-px-clicks">
            <div class="first-heading">
                <h2>{{ global.termsArray["lbl_here_we_go"] }}</h2>
                <p>{{ global.termsArray["lbl_want_to_start"] }}</p>
            </div>

            <div class="app-links-sec">
                <div class="app-link-head">
                    <h3>{{ global.termsArray["lbl_use_on_your_smartphone"] }}</h3>
                </div>
                <div class="button-gp-d">
                    <a href="javascript:;" class="btn btn-ios d-flex" (click)="openUrl(1)">
                      <figure>
                        <img src="assets/images/google-play.svg" alt="google play image" />
                      </figure>
                      <figcaption>
                        <span>{{ global.termsArray["lbl_home_get_it_on"] }}</span>
                        <strong>
                          {{ global.termsArray["lbl_home_google_play"] }}</strong>
                      </figcaption>
                    </a>&nbsp;
                    <a href="javascript:;" class="btn btn-ios d-flex" (click)="openUrl(2)">
                      <figure>
                        <i class="fab fa-apple"></i>
                      </figure>
                      <figcaption>
                        <span>
                          {{ global.termsArray["lbl_home_download_on_the"] }}</span>
                        <strong> {{ global.termsArray["lbl_home_app_store"] }}</strong>
                      </figcaption>
                    </a>
                  </div>
            </div>

            <ul class="explain-click-sec">
              <div class="web-link-head">
                <h3>{{ global.termsArray["lbl_use_on_your_browser"] }}</h3>
              </div>
              <li>
                <button type="button" (click)="updateWelcomeProcess('lesson/demo')" class="btn btn-blue-shine">{{ global.termsArray["lbl_demo_explain"] }}</button>
              </li>
              <li>
                <button type="button" (click)="updateWelcomeProcess('lesson-description')" class="btn btn-blue-shine">{{ global.termsArray["lf_kostenlose_lektionen"] }}</button>
              </li>
            </ul>
        </div>    
    </div>
</div>