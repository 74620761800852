<div class="inner-wraper no-padding-top">
  <section class="lesson-cover-sect">
    <div class="container">
      <div class="lektionen-cover-new">
        <div class="lekt-title">
          <form class="title-input-bx">
            <div class="form-group" class="view-icon d-none">
              <input type="text" class="form-control" placeholder="Lektion: Erinnerungen an eine Schöne Reise">
              <button type="button" class="btn btn-close"><i class="ri-close-fill"></i></button>
            </div>
          </form>
        </div>
        <ng-container *ngIf="LessonFamily?.family && LessonFamily?.family.length > 0">
          <div class="list-sentens">
            <div *ngFor="let family of LessonFamily.family; let i = index" 
              [ngClass]="i==0 ? 'thems-box hide' : 'thems-box'"
            >
             
              <div class="upper-lesson-family">
                <div class="lesson-img-cover">
                  <figure>
                    <img src="{{ LessonFamily?.imageUrl + family.image }}" alt="lesson family image" (click)="setFamilyTitle(family); navigate('sentences', family);" />
                  </figure>
                  <h6 class="demo-name-text" (click)="setFamilyTitle(family); navigate('sentences', family);">
                    <b>{{ family?.term ? global.termsArray[family?.term] : "Name not insert" }}</b>
                  </h6>
                </div>

              </div>
              <div class="thems-box-in">

                <div class="progress-bar-sec">
                  <div class="thems-box-in them-unline">
                    <ng-container *ngIf="family.started == 1">
                      <h6>{{ global.termsArray["lbl_completeLesson"] ? global.termsArray["lbl_completeLesson"] : "Completed lessons" }}</h6>
                    </ng-container>
                    <ng-container *ngIf="family.started == 0">
                      <h6 class="red-label">{{ global.termsArray["lbl_not_started_yet"] ? global.termsArray["lbl_not_started_yet"] : "Completed lessons" }}</h6>
                    </ng-container>
                  </div>


                  <div class="row inner-pr-br" *ngIf="family?.level1HasLessons">
                    <div class="col-md-4">
                      <a href="javascript:;" (click)="lessonDes(1,family)">
                        <h6>{{ global.termsArray['lbl_level_one'] }}</h6>
                      </a>
                    </div>
                    <div class="col-md-6">
                      <div class="progress-cover">
                        <div class="progress">
                          <div class="progress-bar" role="progressbar" [style.width]="(family?.level1progress) + '%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <span>{{ family?.level1progress }}%</span>
                    </div>
                  </div>


                  <div class="row inner-pr-br" *ngIf="family?.level2HasLessons">
                    <div class="col-md-4">
                      <a href="javascript:;" (click)="lessonDes(2,family)">
                        <h6>{{ global.termsArray['lbl_level_two'] }}</h6>
                      </a>
                    </div>
                    <div class="col-md-6">
                      <div class="progress-cover">
                        <div class="progress">
                          <div class="progress-bar" role="progressbar" [style.width]="(family?.level2progress) + '%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <span>{{ family?.level2progress }}%</span>
                    </div>
                  </div>

                  <div class="row inner-pr-br" *ngIf="family?.level3HasLessons">
                    <div class="col-md-4">
                      <a href="javascript:;" (click)="lessonDes(3,family)">
                        <h6>{{ global.termsArray['lbl_level_three'] }}</h6>
                      </a>
                    </div>
                    <div class="col-md-6">
                      <div class="progress-cover">
                        <div class="progress">
                          <div class="progress-bar" role="progressbar" [style.width]="(family?.level3progress) + '%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <span>{{ family?.level3progress }}%</span>
                    </div>
                  </div>

                </div>

                <div class="bttn-blue" (click)="setFamilyTitle(family); navigate(i == 0 ? 'demo' : 'sentences', family);">
                  <a class="btn" href="javascript:;">{{ family.started == 1 ? global.termsArray["lbl_choose"] ? global.termsArray["lbl_choose"] : "Choose" : global.termsArray["lbl_begin"] }}</a>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <app-app></app-app>
    </div>
  </section>
</div>