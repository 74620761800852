import { AfterViewInit, Component, DoCheck, OnInit } from "@angular/core";
import { GlobalService } from "src/app/global.service";
import { Router } from "@angular/router";

declare var dataLayer: any;
declare var PostAffTracker: any;

@Component({
  selector: "app-thank-you",
  templateUrl: "./thank-you.component.html",
  styleUrls: ["./thank-you.component.scss"],
})


export class ThankYouComponent implements OnInit,AfterViewInit {
  appUrl: any;
  deviceType:string = ''
  user: any = localStorage.getItem(btoa("user")) ? atob(localStorage.getItem(btoa("user"))) : null;
  constructor(public router: Router,
    public global: GlobalService) {}

  ngOnInit() {
    this.user = JSON.parse(this.user);
    this.getURLs()
    this.checkDevice()
  }
  ngAfterViewInit() {
    let classInstance = this;
    setTimeout(async () => {
     /** PostAffTracker >>>>>*/
     await classInstance.getAffiliateInfo();
     /** PostAffTracker <<<<<*/
      dataLayer.push({ 'event': 'buying-process-completed' });
      console.log("Event fired (buying-process-completed)");
    }, 3000);

  }

  getURLs(){
    this.global.get("getSettingData", data => {
      localStorage.setItem("android_deeplink",data.data.android_deeplink)
      localStorage.setItem("ios_deeplink",data.data.ios_deeplink)        
      this.global.storeUrls.next({
        android_deeplink:data.data.android_deeplink,
        ios_deeplink:data.data.ios_deeplink
      });
    }, err => { });
  }
  openInApp() {
    if (this.deviceType == 'android') {
      this.openUrl(1)
    } else if (this.deviceType == 'ios') {
      this.openUrl(2)
    } else {
      // this.router.navigate(["/choose-lesson"]);
      this.openUrl(1);
    }
  }

  checkDevice(){
    if (/Android/i.test(navigator.userAgent)) {
      this.deviceType = 'android'
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      this.deviceType = 'ios'
    } else {
      this.deviceType = ''
    }
  }

  getAffiliateInfo(){
    return new Promise((resolve, reject)=>{
      this.global.post("get-affiliate-info", JSON.stringify({}),(response:any) => {
        if (response.success && response.data) {
          let data:any = response.data;
          PostAffTracker.setAccountId('default1');
          let sale = PostAffTracker.createSale();
          sale.setTotalCost(data.baseSubsData.price);
          sale.setOrderID(data.subscription_name);
          sale.setProductID(data.baseSubsData._id);
          sale.setData1(this.user.email);
          PostAffTracker.register();
          console.log("Affiliate sale marked");
          resolve(response.data);
        } else {
         reject();
        }
      }, (err) => {
        reject();
        this.global.showDangerToast("Error", err.message);
      }, false);
    })
  }


  openUrl(type) {
    let obj = {
      1: localStorage.getItem('android_deeplink'),
      2: localStorage.getItem('ios_deeplink'),
    };
    window.open(obj[type], 'blank');
  //   let obj = {
  //     1: localStorage.getItem('play_url'),
  //     2: localStorage.getItem('apple_url'),
  //   };
  //   window.open(obj[type], 'blank');
  }
}
