<div class="inner-wraper">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="error-template">
          <h1>
            Oops!
          </h1>
          <h2>
            404 Not Found
          </h2>
          <div class="error-details">
            Sorry, No information available !
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
