<ng-container *ngIf="blogHeader">
  <div class="inner-wraper">

    <section class="amet-blog-sect blog-section">
      <div class="container">
        <h2 class="blog-title">Blog</h2>
        <div class="row">
          <div class="col-md-4" *ngFor="let blog of blogArray; let i = index">
            <div class="box-of-blog">
              <article>
                <figure><img [src]="blog.image?blog.image:'assets/images/blog.jpg'" onError="assets/images/blog.jpg" style="max-height: 400px;"></figure>
                <figcaption>
                  <h6>{{ this.date(blog?.release_date) }}</h6>
                  <h5>
                    <a (click)="setBlog(blog)" href="javascript:;">{{ blog?.title }}</a>
                  </h5>
                  <p style="word-break: break-word">
                    {{ blog["introductory_text"] ? blog["introductory_text"] : htmlParse(blog["description"]) }}
                    <!-- <a [routerLink]="['/blog-detail/' + blog.slug]">{{ global.termsArray["lbl_view_more"] }}</a> -->
                  </p>

                  <!-- <div class="authorDiv">
                    <span>{{ global.termsArray["lbl_author"] }}:</span>{{ blog?.author_name }}
                  </div> -->
                  <a class="link-class underlineText" [routerLink]="['/blog-detail/' + blog.slug]">{{ global.termsArray["lbl_view_more"] }}</a>
                </figcaption>
              </article>
            </div>
          </div>
        </div>
        <ng-container *ngIf="isTenRecord">
          <div class="try-learn-lm">
          <a  class="btn" (click)="loadMore()">{{ global.termsArray["lbl_load_more"] }}</a>
        </div>
        </ng-container>
      </div>
    </section>

    <section class="mission-language-sec">
      <div class="container">
        <div class="mission-outline">
          <div class="mission-outline-in">
            <div class="row">
              <div class="col-md-6">
                <div class="try-can-learn">
                  <h4>Diese Sprachen können Sie mit Birlingo lernen:</h4>
                  <p>{{ global.termsArray["learn_these_lan_with_birlingo"] }}</p>
                  <!-- <p>Englisch, Französich, Italienisch, Spanisch, Russisch, Chinesisch, Niederländisch, Protugiesisch</p> -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="try-can-learn">
                  <a class="btn black-btn" href="javascript:;" [routerLink]="['/privacy-policy']"
                    target="-blank">Kostenlos testen</a>
                  <h6>Keine Kreditkarte erforderlich.</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


  </div>
</ng-container>