import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from "src/app/global.service";

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.sass']
})
export class WelcomePageComponent implements OnInit {

  public demoLessonData: any;
  public authUser: any;

  constructor(
    private route: Router,
    private router: ActivatedRoute,
    public global: GlobalService,
  ) { }

  ngOnInit() {
    try{
      this.authUser = localStorage.getItem(btoa("user")) ? JSON.parse(atob(localStorage.getItem(btoa("user")))) : null;
      if (this.authUser.isWeclomeProceed == 1) { 
        this.route.navigate(['home']); 
      }
    }catch(err){
      console.log("err", err);
    }
    this.getDemoLessonOnly();
  }

  getDemoLessonOnly() {
    this.global.get("demoLessonOnly", (data) => {
      this.demoLessonData = data.data;
      // console.log("demoLessonData: ", this.demoLessonData);
    }, (err) => { },
      true
    );
  }

  updateWelcomeProcess(url) {
    console.log("this.demoLessonDatathis.demoLessonDatathis.demoLessonData",this.demoLessonData);
    this.global.get("welcomeProceeded", (data) => {
      // console.log("datAaaaa : ", data);
      localStorage.setItem(btoa("user"), btoa(JSON.stringify(data.data)));
      if (data.statuscode == 200 && data.data.isWeclomeProceed == 1) {
        if (url == "lesson-description") {
          this.route.navigate([url + "/" + this.demoLessonData.lessonfamily_id]);
          localStorage.setItem(btoa("Lfamily"), this.demoLessonData.term);
        } else {
          this.route.navigate([url]);
        }
      }
    }, error => { }, true)
  }

  openUrl(type) {
    let obj = {
      1: localStorage.getItem('play_url'),
      2: localStorage.getItem('apple_url'),
    };
    window.open(obj[type], 'blank');
  }

}
