import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { GlobalService } from "../../global.service";
import { TemplateRef } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Router, ActivatedRoute } from "@angular/router";
import { InfiniteScrollDirective } from "ngx-infinite-scroll";
import { Location } from "@angular/common";
import * as moment from "moment";

@Component({
  selector: 'app-repeated-lesson-description',
  templateUrl: './repeated-lesson-description.component.html',
  styleUrls: ['./repeated-lesson-description.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RepeatedLessonDescriptionComponent implements OnInit {

  @ViewChild(InfiniteScrollDirective, { static: true })
  infiniteScroll: InfiniteScrollDirective;
  set appScroll(directive: InfiniteScrollDirective) {
    this.infiniteScroll = directive;
  }

  Lfamily: any = localStorage.getItem(btoa("Lfamily")) ? localStorage.getItem(btoa("Lfamily")) : "";
  isSubscribed: any;
  page: any = 1;
  LessonList: any = [];
  deletedLessonList: any = {};
  lastPlayedLessons: any;
  listArray: any;
  status: any = "all";
  level: any = "";
  loader = false;
  lessonFamilyId: any;
  totalCount: any;
  modalRef: BsModalRef;
  completeCount: any = 0;
  public authUser: any;
  favLessonId: any = null;
  svgPath: string = "M0.656641 6.11797C0.224219 5.47734 0 4.70859 0 3.93984C0 1.76172 1.76172 0 3.93984 0H21.4449C23.2387 0 24.9203 0.944922 25.8332 2.48242L36.8039 20.7563C29.0844 21.7332 22.1816 25.3367 17.0406 30.6699L0.656641 6.11797ZM81.3273 6.11797L64.9594 30.6699C59.8184 25.3367 52.9156 21.7332 45.1961 20.7563L56.1668 2.48242C57.0957 0.944922 58.7613 0 60.5551 0H78.0602C80.2383 0 82 1.76172 82 3.93984C82 4.70859 81.7758 5.47734 81.3434 6.11797H81.3273ZM12.8125 53.8125C12.8125 46.3367 15.7822 39.1671 21.0684 33.8809C26.3546 28.5947 33.5242 25.625 41 25.625C48.4758 25.625 55.6454 28.5947 60.9316 33.8809C66.2178 39.1671 69.1875 46.3367 69.1875 53.8125C69.1875 61.2883 66.2178 68.4579 60.9316 73.7441C55.6454 79.0303 48.4758 82 41 82C33.5242 82 26.3546 79.0303 21.0684 73.7441C15.7822 68.4579 12.8125 61.2883 12.8125 53.8125ZM42.3453 38.6137C41.8008 37.4926 40.2152 37.4926 39.6547 38.6137L36.0672 45.8848C35.843 46.3332 35.4266 46.6375 34.9461 46.7016L26.9062 47.8707C25.673 48.0469 25.1926 49.5523 26.0734 50.4332L31.8871 56.1027C32.2395 56.4551 32.3996 56.9355 32.3195 57.432L30.9422 65.4238C30.734 66.641 32.0152 67.5859 33.1203 67.0094L40.2953 63.2297C40.7277 63.0055 41.2562 63.0055 41.6887 63.2297L48.8637 67.0094C49.9688 67.5859 51.25 66.657 51.0418 65.4238L49.6645 57.432C49.5844 56.9516 49.7445 56.4551 50.0969 56.1027L55.9105 50.4332C56.8074 49.5684 56.3109 48.0629 55.0777 47.8707L47.0539 46.7016C46.5734 46.6375 46.141 46.3172 45.9328 45.8848L42.3453 38.6137Z"; 
  fillColor: string = "#00FF00";
  backgroundColor: string = "#ECECEC"; 
  percentage: number = 50; 
  constructor(
    public global: GlobalService,
    public router: Router,
    private route: ActivatedRoute,
    public location: Location,
    private modalService: BsModalService
  ) {
    this.global.profileTab = 1;
    this.global.currentUser();
    if (this.global.user.isFreeAccess && this.global.user.isFreeAccess == 1) this.isSubscribed = 1; else this.getSubsriptionStatus();
    this.route.paramMap.subscribe((params) => {
      this.lessonFamilyId = params.get("id") ? params.get("id") : null;
    });

  }

  ngOnInit() {
    this.global.loader = true;
    this.authUser = this.global.user;
    const defaultLevel = localStorage.getItem(btoa('defaultLevel'));
    this.level = defaultLevel ? atob(defaultLevel) : 'all';
    this.getRepeatedLessonList();
  }

  get clipWidth(): string {
    return `${this.percentage}%`;
  }
  updatePercentage(newPercentage: number) {
    if (newPercentage >= 0 && newPercentage <= 100) {
      this.percentage = newPercentage;
    }
  }
  onScroll() {
    if (!this.global.loader) {
      // this.getLessonList(this.page + 1);
      this.infiniteScroll.ngOnDestroy();
      this.infiniteScroll.setup();
    }
  }

  getLessonList(page = 1) {
    if (this.page == page && page != 1) {
      return;
    }
    let body = {};
    body["lessonfamily_id"] = this.lessonFamilyId;
    body["status"] = this.status;
    body["level"] = this.level;
    body["page"] = page;
    body["learning_language_id"] = this.global.user.learning_language_id;/** language id */
    this.global.post("lessonList", JSON.stringify(body), (data) => {
      if (data.success) {
        if (data.data.list == 0) {
          this.loader = true;
          this.page = page - 1;
        } else {
          this.page = page;
        }
        this.LessonList = data && data.data ? data.data : [];

        this.listArray = data.data && data.data.list ? data.data.list : [];
        data.data && data.data.list ? this.listArray.concat(data.data.list) : this.listArray.concat([]);
        if (data.data && data.data.list && data.data.list.length > 0) {
          // console.log("this.listArray: ", this.listArray);
          if (this.listArray == undefined)
            this.listArray = data.data.list;
          else
            this.listArray.concat(data.data.list);
          // console.log("list array: ", this.listArray);
        } else if (this.listArray.length == 0 && data.data.list.length == 0) {
          // console.log("this is list array",this.listArray);
          this.listArray = []
        }
      } else {
        this.global.showDangerToast(this.global.termsArray[data.message]);
        this.loader = true;
      }
    }, (err) => {
      this.global.showDangerToast(err.message);
    }, true);
  }

  getSubsriptionStatus() {
    this.global.get("getSubsriptionStatus", (data) => {
      if (data.success) {
        this.isSubscribed = data.data.is_subsribed;
        // console.log("isSubscribed: ", this.isSubscribed);
      }
    }, (err) => { }, true);
  }

  navigate(lesson, template, i = 0) {
    sessionStorage.setItem("lessonTitle",lesson.lessonTitle|| "");
    sessionStorage.setItem("familyTitle" ,this.global.termsArray[lesson.family_name]|| "")
    sessionStorage.setItem("lesson_family_id",lesson.lessonfamily_id);
    localStorage.setItem(btoa("Lfamily"), lesson.family_name);
    localStorage.setItem(btoa("lesson_id"), btoa(lesson.lesson_id));
    localStorage.setItem(btoa("lessonfamily_id"), btoa(lesson.lessonfamily_id));
    localStorage.setItem(btoa("max_read_slide"), btoa(lesson.progres));
    localStorage.setItem(btoa("time_loop"), btoa(lesson.time_loop));
    localStorage.setItem(btoa("lesson_title"), btoa(lesson.title + " "));
    localStorage.setItem(btoa("baselesson_id"), btoa(lesson.baselesson_id));
    this.global.lesson_id = lesson.lesson_id;
    this.global.max_read_slide = lesson.progres;
    this.global.baselesson_id = lesson.baselesson_id;
    this.global.lessonfamily_id = lesson.lessonfamily_id;
    this.global.lesson_id = lesson._id;
    this.global.lesson_title = lesson.title + " ";
    this.global.max_read_slide = lesson.progress;
   
    if (lesson.is_free && lesson.is_demo == 1) {
      this.router.navigate(["repeated-lesson/demo"]);
    } else if (this.isSubscribed || lesson.is_free || this.isWithinFreeTrial()) {
      this.router.navigate(["repeated-lesson/" + "sentences"]);
    } else {
      this.openModal(template);
    }
  }

  openModal(template: TemplateRef<any>, cssClass = "big-model-dialog") {
    const config = {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: false,
      class: cssClass,
    };
    this.modalRef = this.modalService.show(template, config);
  }

  navigateToSubscription() {
    // this.global.showWarningToast(this.global.termsArray['msg_please_subscribe'])
    this.router.navigate(["account"]);
  }

  returnLevel(level_id) {
    let level = "";
    if (level_id == 1) {
      level = "lbl_level_one";
    } else if (level_id == 2) {
      level = "lbl_level_two";
    } else if (level_id == 3) {
      level = "lbl_level_three";
    } else if (level_id == 4) {
      level = "lel_level_four";
    }
    return level;
  }

  filterLevel(level) {
    if (level != this.level) {
      localStorage.setItem(btoa('defaultLevel'), btoa(level));
      this.level = level;
      this.LessonList = [];
      // this.listArray = [];
      // this.getLessonList();
      this.getRepeatedLessonList();
    }
  }

  filterStatus(status) {
    if (status != this.status) {
      this.status = status;
      this.LessonList = []
      this.listArray = [];
      this.getLessonList();
    }
  }

  getDemoLessonOnly() {
    this.global.get("demoLessonOnly", (data) => {
      // console.log("data: ", data);
    },
      (err) => { },
      true
    );
  }
  setLessonTitle(lesson: any) {
    sessionStorage.setItem('lessonTitle', lesson.lessonTitle);
    sessionStorage.setItem('lessonLevel', this.global.termsArray[this.returnLevel(lesson.level_id)]);
  }



  addFavorites(lesson_id) {
    let body = {
      action: 1,
      lesson_id: lesson_id,
      learning_language_id: this.global.user.learning_language_id
    };
    this.global.post("toggle-favourite", JSON.stringify(body), (data) => {
      console.log(data.message, this.global.termsArray[data.message])
      if (data.success) {
        this.global.showToast("", this.global.termsArray[data.message]);
        this.getRepeatedLessonList();
      } else {
        this.global.showDangerToast(this.global.termsArray[data.message]);
        this.loader = true;
      }
    }, (err) => {
      this.global.showDangerToast(err.message);
    }, true);
  }

  removeFavorites() {
    if (this.favLessonId) {
      let body = {
        action: 0,
        lesson_id: this.favLessonId,
        learning_language_id: this.global.user.learning_language_id
      };
      this.global.post("toggle-favourite", JSON.stringify(body), (data) => {
        console.log(data.message, this.global.termsArray[data.message])
        if (data.success) {
          this.global.showToast("", this.global.termsArray[data.message]);
          this.getRepeatedLessonList();
          this.favLessonId = null
        } else {
          this.global.showDangerToast(this.global.termsArray[data.message]);
          this.loader = true;
        }
      }, (err) => {
        this.global.showDangerToast(err.message);
      }, true);
    }
  }

  getRepeatedLessonList(deleted = false) {
    let body = {
      learning_language_id: this.global.user.learning_language_id,
      deleted: deleted
    };
    body["level"] = this.level;
    this.global.post("get-repeated-lessons", JSON.stringify(body), (data) => {
      if (data.success) {
        this.LessonList = data.data.lessons;
        this.deletedLessonList = data.data.deletedLessons
        console.log(this.LessonList, this.deletedLessonList)
      } else {
        this.global.showDangerToast(this.global.termsArray[data.message]);
        this.loader = true;
      }
    }, (err) => {
      this.global.showDangerToast(err.message);
    }, true);
  }


  toggleDelete(lesson_id, action) {
    let body = {
      action: action,
      lesson_id: lesson_id,
      learning_language_id: this.global.user.learning_language_id
    };
    this.global.post("toggle-delete-repeatation", JSON.stringify(body), (data) => {
      console.log(data.message, this.global.termsArray[data.message])
      if (data.success) {
        this.global.showToast("", this.global.termsArray[data.message]);
        this.getRepeatedLessonList();
      } else {
        this.global.showDangerToast(this.global.termsArray[data.message]);
        this.loader = true;
      }
    }, (err) => {
      this.global.showDangerToast(err.message);
    }, true);
  }

  formateDate(date) {
    console.log(moment(moment(date)).format("DD.MM.YYYY"))
    return moment(moment(date)).format("DD.MM.YYYY");
  }

  getDueDate(lesson) {
    let dueDate = null;

    // Helper function to reset time to the start of the day
    function resetToMidnight(date) {
        date.setHours(0, 0, 0, 0);
        return date;
    }

    if (lesson.repeating_cycle === 1) {
        dueDate = new Date(lesson.created);
        dueDate = resetToMidnight(dueDate);
        dueDate.setDate(dueDate.getDate() + 3); 
    } else if (lesson.first_repeatation_complete_day) {
        dueDate = new Date(lesson.first_repeatation_complete_day);
        dueDate = resetToMidnight(dueDate);
        dueDate.setDate(dueDate.getDate() + 10);
    }

    if (!dueDate) {
        throw new Error("Unable to determine due date.");
    }

    const currentDate = new Date();
    const currentDateMidnight = resetToMidnight(currentDate);

    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    const millisecondsUntilDue = dueDate.getTime() - currentDateMidnight.getTime();
    const daysDifference = Math.floor(millisecondsUntilDue / millisecondsPerDay);

    return daysDifference;
}


isWithinFreeTrial(): boolean {
  if (!this.global.user.freeTrialEndDate) {
    return false;
  }
  const currentDate = new Date();
  const freeTrialEndDate = new Date(this.global.user.freeTrialEndDate);
  return currentDate < freeTrialEndDate;
}



}

