<div class="inner-wraper login-bg-cover">
  <section class="abg-form-sect">
    <div class="container">
      <div class="login-in-color">
          <div class="setting-arrow">
            <a routerLink="/login" href="javascript:;" style="width: fit-content; word-break: initial">
              <i class="fas fa-long-arrow-alt-left"></i>
              <h6>Zurück</h6>
            </a>
          </div>
          <div class="row">
            <div class="col-md-5">
              <form [formGroup]="formData" (ngSubmit)="submit(formData.value)">
                <h1>{{ global.termsArray["lbl_Reset_Password"] }}</h1>
                <div class="form-group pb-4">
                  <div class="password-container">
                    <input
                      style="max-width: 100%;"
                      type="password"
                      class="form-control"
                      id="exampleInputPassword1"
                      [placeholder]="global.termsArray['lbl_password']"
                      formControlName="password" #passwordField
                    />
                    <div class="password-view-wrapper tooltip-trigger" #confirmToggleButton (click)="togglePassword(passwordField,confirmToggleButton,confirmToolTip)">
                      <span class="custom-confirmTooltip" [attr.data-title]="global.termsArray['msg_show_password'] ? global.termsArray['msg_show_password']:'Passwort anzeigen'" #confirmToolTip></span>
                      <i class="ri-eye-off-fill"></i>
                      <i class="ri-eye-fill"></i>
                    </div>
                  </div>
                  <div
                    *ngIf="
                      formData.controls['password'].invalid &&
                      (formData.controls['password'].dirty ||
                        formData.controls['password'].touched ||
                        this.formSubmitAttempt)
                    "
                  >
                    <div
                      *ngIf="formData.controls['password'].errors.required"
                      class="validation_err validation_err_color"
                    >
                      {{ global.termsArray["err_enter_password"] }}
                    </div>
                    <div
                      *ngIf="formData.controls['password'].errors.minlength"
                      class="validation_err validation_err_color"
                    >
                      {{ global.termsArray["err_pass_length"] }}
                    </div>
                    <div
                      *ngIf="formData.controls['password'].errors.maxlength"
                      class="validation_err validation_err_color"
                    >
                      {{ global.termsArray["err_pass_length"] }}
                    </div>
                  </div>
                </div>
                <div class="form-group pb-4">
                  <div class="password-container">
                    <input
                      style="max-width: 100%;"
                      type="password"
                      class="form-control"
                      id="exampleInputPassword1"
                      [placeholder]="global.termsArray['lbl_ConfirmPass']"
                      formControlName="copassword" #confirmPasswordField
                    />
                    <div class="password-view-wrapper tooltip-trigger" #toggleButton (click)="togglePassword(confirmPasswordField,toggleButton,toolTip)">
                      <span class="custom-tooltip" [attr.data-title]="global.termsArray['msg_show_password'] ? global.termsArray['msg_show_password']:'Passwort anzeigen'" #toolTip></span>
                      <i class="ri-eye-off-fill"></i>
                      <i class="ri-eye-fill"></i>
                    </div>
                  </div>
                  <div
                    *ngIf="
                      !formData.controls['password'].untouched ||
                      this.formSubmitAttempt ||
                      !formData.controls['copassword'].untouched
                    "
                  >
                    <div
                      *ngIf="
                        formData.controls['copassword'].errors &&
                        formData.controls['copassword'].errors.required
                      "
                      class="validation_err validation_err_color"
                    >
                      {{ global.termsArray["err_enter_confirmPassword"] }}
                    </div>
                    <!-- <div
                      *ngIf="formData.controls['copassword'].errors.minlength"
                      style="color: red;"
                    >
                      confirm password with less than 6 characters.
                    </div> -->
                    <!-- <div
                      *ngIf="formData.controls['copassword'].errors.maxlength"
                      style="color: red;"
                    >
                      confirm password with more than 24 characters.
                    </div> -->
                  </div>
                  <div
                    *ngIf="
                      !(
                        formData.controls['copassword'].errors &&
                        formData.controls['copassword'].errors.required
                      ) &&
                      formData.errors &&
                      formData.errors != null &&
                      formData.errors.passwordsNotEqual
                    "
                    class="validation_err validation_err_color"
                  >
                    {{ global.termsArray["err_password_not_match"] }}
                  </div>
                </div>
                <button type="submit" class="btn btn-primary mb-3">
                  {{ global.termsArray["lbl_Submit"] }}
                </button>
              </form>
            </div>
          </div>
      </div>
    </div>
  </section>
</div>
