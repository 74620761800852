<div class="inner-wraper no-padding-top">
  <section class="lesson-cover-sect">
    <div class="container">
      <div class="lektionen-cover-new">
        <div class="lekt-title">
          <form class="title-input-bx">
            <div class="form-group" class="view-icon d-none">
              <input type="text" class="form-control" placeholder="Lektion: Erinnerungen an eine Schöne Reise">
              <button type="button" class="btn btn-close"><i class="ri-close-fill"></i></button>
            </div>
          </form>
        </div>

        <ng-container *ngIf="LessonFamily?.family && LessonFamily?.family.length > 0">

          <div class="list-sentens">

            <ng-container *ngIf="lastPlayedLessons">
              <div class="head-title mb-2 d-flex">
                <h4>{{global.termsArray["term_last_lesson"] ? global.termsArray["term_last_lesson"] : 'Letzte Lektion'}}</h4>
                <a [routerLink]="['/last-played-lessons']" class="ml-auto">{{global.termsArray["lbl_view_all"] ? global.termsArray["lbl_view_all"] : 'Alle anzeigen'}}</a>
              </div>
              <div class="level-top-border">
              <div class="level-top-box level-point-in">
                <h4>{{lastPlayedLessons?.lessonTitle ? lastPlayedLessons?.lessonTitle : "Name not insert"}}</h4>
                <div class="progress-bar-sec pb-2">
                  <!-- <div class="lesson-hints">
                    <span class="w-100 lesson-label" [ngClass]="lastPlayedLessons.active_progress == 0  ? 'lesson-label-red'  
                    : 'lesson-label-green'">
                      <h3 class="text-center">{{lastPlayedLessons.active_progress == 0 ?
                        global.termsArray["lbl_not_started_yet"] :
                        global.termsArray["lbl_your_progress"] }}</h3>
                    </span>
                  </div> -->
                  <div class="down-navs">
                    <ul>
                      <li>
                        <div class="progress">
                          <span class="progress-bar" role="progressbar"
                            [style.width]="lastPlayedLessons?.active_progress + '%'" aria-valuenow="25"
                            aria-valuemin="0" aria-valuemax="100"></span>
                        </div>
                        <a href="javascript:;" class="#">{{ global.termsArray["lbl_hearActively"]?
                          global.termsArray["lbl_hearActively"]
                          : "Hear actively"}}</a>
                      </li>

                      <li>
                        <div class="progress">
                          <span class="progress-bar" role="progressbar"
                            [style.width]="lastPlayedLessons?.speak_progress + '%'" aria-valuenow="25" aria-valuemin="0"
                            aria-valuemax="100">
                          </span>
                        </div>
                        <a href="javascript:;" class="#">{{global.termsArray["lbl_method_speak"]?
                          global.termsArray["lbl_method_speak"]
                          : "Speak"}}</a>
                      </li>

                      <li>
                        <div class="progress">
                          <span class="progress-bar" role="progressbar"
                            [style.width]="lastPlayedLessons?.repeat_progress + '%'" aria-valuenow="25"
                            aria-valuemin="0" aria-valuemax="100">
                          </span>
                        </div>
                        <a href="javascript:;" class="#">{{global.termsArray["lbl_repetition"]?
                          global.termsArray["lbl_repetition"]
                          : "Repeat"}}</a>
                      </li>

                      <li>
                        <div class="progress">
                          <span class="progress-bar" role="progressbar"
                            [style.width]="lastPlayedLessons?.passive_progress + '%'" aria-valuenow="25"
                            aria-valuemin="0" aria-valuemax="100">
                          </span>
                        </div>
                        <a href="javascript:;" class="active">{{global.termsArray["lbl_listen_passively"]
                          ? global.termsArray["lbl_listen_passively"] : "listen passively"}}</a>
                      </li>
                    </ul>
                  </div>
                  <!-- <div class="btn-group  bttn-blue  pt-2 text-center w-100">
                    <button class="btn mx-auto"  (click)="
                    setLessonTitle(lastPlayedLessons);navigateLastPlayed(lastPlayedLessons?.is_demo==1 ? 'demo' : 'sentences', lastPlayedLessons,template);">{{ global.termsArray["lbl_continue"] ?
                      global.termsArray["lbl_continue"] : "Fortfahren" }}</button>
                  </div> -->

                  <div class="btn-group  bttn-blue  pt-2 text-center w-100">
                    <a class="btn mx-auto" href="javascript:;" (click)=" setLessonTitle(lastPlayedLessons);navigateLastPlayed(lastPlayedLessons?.is_demo==1 ? 'demo' : 'sentences', lastPlayedLessons,template);" 
                    [ngClass]="(lastPlayedLessons.active_progress == 100 && lastPlayedLessons.passive_progress == 100 && lastPlayedLessons.repeat_progress == 100 && lastPlayedLessons.speak_progress == 100 ) ?'repeatation' : ''">
                      {{ (lastPlayedLessons.active_progress == 100 && lastPlayedLessons.passive_progress == 100 && lastPlayedLessons.repeat_progress == 100 && lastPlayedLessons.speak_progress == 100 ) ? global.termsArray["label_new_repeatation"] :  lastPlayedLessons.active_progress == 0 ? global.termsArray["lbl_start"] ? global.termsArray["lbl_begin"] : "Beginnen" : global.termsArray["lbl_continue"] ? global.termsArray["lbl_continue"] : "Fortfahren" }}
                    </a>
                  </div>
                </div>
              </div></div>
             
            </ng-container>
            <div *ngFor="let family of LessonFamily.family; let i = index"
              [ngClass]="i==0 ? 'thems-box hide' : 'thems-box'">
              <div class="upper-lesson-family">
                <div class="lesson-img-cover">
                  <figure>
                    <img src="{{ LessonFamily?.imageUrl + family.image }}" alt="lesson family image"
                      (click)="setFamilyTitle(family); navigate('sentences', family);" />
                  </figure>
                  <h6 class="demo-name-text" (click)="setFamilyTitle(family); navigate('sentences', family);">
                    <b>{{ family?.term ? global.termsArray[family?.term] : "Name not insert" }}</b>
                  </h6>
                </div>

              </div>
              <div class="thems-box-in">

                <div class="progress-bar-sec">
                  <div class="thems-box-in them-unline">
                    <ng-container *ngIf="family.started == 1">
                      <h6>{{ global.termsArray["lbl_completeLesson"] ? global.termsArray["lbl_completeLesson"] :
                        "Completed lessons" }}</h6>
                    </ng-container>
                    <ng-container *ngIf="family.started == 0">
                      <h6 class="red-label">{{ global.termsArray["lbl_not_started_yet"] ?
                        global.termsArray["lbl_not_started_yet"] : "Completed lessons" }}</h6>
                    </ng-container>
                  </div>


                  <div class="row inner-pr-br" *ngIf="family?.level1HasLessons">
                    <div class="col-md-4">
                      <a href="javascript:;" (click)="lessonDes(1,family)">
                        <h6>{{ global.termsArray['lbl_level_one'] }}</h6>
                      </a>
                    </div>
                    <div class="col-md-6">
                      <div class="progress-cover">
                        <div class="progress">
                          <div class="progress-bar" role="progressbar" [style.width]="(family?.level1progress) + '%'"
                            aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <span>{{ family?.level1progress }}%</span>
                    </div>
                  </div>


                  <div class="row inner-pr-br" *ngIf="family?.level2HasLessons">
                    <div class="col-md-4">
                      <a href="javascript:;" (click)="lessonDes(2,family)">
                        <h6>{{ global.termsArray['lbl_level_two'] }}</h6>
                      </a>
                    </div>
                    <div class="col-md-6">
                      <div class="progress-cover">
                        <div class="progress">
                          <div class="progress-bar" role="progressbar" [style.width]="(family?.level2progress) + '%'"
                            aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <span>{{ family?.level2progress }}%</span>
                    </div>
                  </div>

                  <div class="row inner-pr-br" *ngIf="family?.level3HasLessons">
                    <div class="col-md-4">
                      <a href="javascript:;" (click)="lessonDes(3,family)">
                        <h6>{{ global.termsArray['lbl_level_three'] }}</h6>
                      </a>
                    </div>
                    <div class="col-md-6">
                      <div class="progress-cover">
                        <div class="progress">
                          <div class="progress-bar" role="progressbar" [style.width]="(family?.level3progress) + '%'"
                            aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <span>{{ family?.level3progress }}%</span>
                    </div>
                  </div>

                </div>

                <div class="btn-group  bttn-blue  pt-2 text-center w-100 ">
                  <button class="btn lesson-btn mx-auto" (click)="setFamilyTitle(family); navigate(i == 0 ? 'demo' : 'sentences', family);">
                    {{ family.started == 1 ? global.termsArray["lbl_choose"] ?
                    global.termsArray["lbl_choose"] : "Choose" : global.termsArray["lbl_begin"] }}</button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <app-app></app-app>
    </div>
  </section>
</div>


<ng-template #template>
  <div class="modal-body like-sec" style="
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      border-color: black;
    ">
    <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()" style="align-self: flex-start">
      <span aria-hidden="true" style="color: black">&times;</span>
    </button>
    <h6 class="modal-title">
      {{ global.termsArray["msg_first_14_days_free"] }}
    </h6>
    <div class="d-flex">
      <button type="submit" class="btn btn-info modal-button" (click)="navigateToSubscription(); modalRef.hide()">
        {{ global.termsArray["lbl_see_pricing"] }}
      </button>
    </div>
  </div>
</ng-template>