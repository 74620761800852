<div class="inner-wraper">
  <section class="lesson-cont-sect lesson-cover-sect wrap-space">
    <div class="container">
      <div class="lektionen-cover-new">
        <article>
          <div class="setting-arrow inner-arrow">
            <a href="javascript:;" (click)="goBack()">
              <i class="ri-arrow-left-s-line"></i>
              <h6>{{ global.termsArray["lbl_Settings"] }}</h6>
            </a>
          </div>
          <div class="sub-list-sentens text-center ">
            <div class="in-list-setting">
              <div class="account-title">
                <ng-container *ngIf="showFakeFireButtons()">
                  <button class="btn btn-success" (click)="fireEvent('buying-process-completed')">Test buying-process-completed event</button>
                </ng-container>
                <h4>{{ global.termsArray["lbl_subscription_title"] }}</h4>
                <div class="account-title" style="padding-bottom: 0px;">
                  <p>{{ subContent?.heading }}</p>
                </div>
              </div>

              <div class="sub-blue-box">
                <div *ngIf="subscribedFromApp && !is_unlimited_subscribed" class="subscribedFromApp">
                  <i class="ri-error-warning-line mr-2 fs-18"></i>
                  {{global.termsArray["lbl_subscribed_from_app"] ? global.termsArray["lbl_subscribed_from_app"] : "Your current subscription was purchased from Application. You can't manage it from here. You have to manage from the application itself"}}
                </div>
                <ul class="ul-scroll">
                  <li [ngClass]="selectedPlanID && selectedPlanID == subscription?._id ? '' : subscription?.status == 1 && (subscription?.payment_status == '' ||subscription?.payment_status == 'trialing' || subscription.is_cancel) ? '' : '' " (click)="choosePlan(subscription)" *ngFor="let subscription of subscriptionList; let i = index;">
                    
                    <a href="javascript:void(0);">
                      
                      <div class="abonnement-kasten font-weight-bold">
                        <div class="you-save" *ngIf="subscription.validity == '6'">
                          <ng-container *ngIf="subscription.subscription_saving_text; else subscription6template">
                            <h6>{{ subscription.subscription_saving_text }}</h6>
                          </ng-container>
                          <ng-template #subscription6template>
                            <h6>{{ global.termsArray['lbl_safe'] ? global.termsArray['lbl_safe'] : 'Sie sparen' }}<br>
                              € {{ subscription.subscription_saving }}.</h6>
                          </ng-template>
                        </div>
                        <div class="you-save" *ngIf="subscription.validity == '12'">
                          <ng-container *ngIf="subscription.subscription_saving_text; else subscription6template">
                            <h6>{{ subscription.subscription_saving_text }}</h6>
                          </ng-container>
                          <ng-template #subscription6template>
                            <h6>{{ global.termsArray['lbl_safe'] ? global.termsArray['lbl_safe'] : 'Sie sparen' }}<br>
                              € {{ subscription.subscription_saving }}.</h6>
                          </ng-template>
                        </div>
                        <div class="you-save" *ngIf="subscription.validity == '240'">
                          <ng-container *ngIf="subscription.subscription_saving_text; else subscription6template">
                            <h6>{{ subscription.subscription_saving_text }}</h6>
                          </ng-container>
                          <ng-template #subscription6template>
                            <h6>{{ global.termsArray['lbl_access_forever'] ? global.termsArray['lbl_access_forever'] : 'Zugriff für immer' }}<br></h6>
                          </ng-template>
                        </div>
                        <ng-container *ngIf="subscription.name != 'Unbegrenzt'">
                          <h6 *ngIf="subscription?.validity=='0'">
                            Daily
                          </h6>
                          <h6 *ngIf="subscription?.validity!='0'">
                            {{ subscription?.validity }}{{ subscription?.validity != 1 ? '-' + global.termsArray["lbl_monthly_subscription"] : global.termsArray["lbl_month"] }}
                          </h6>
                        </ng-container>
                        <ng-container *ngIf="subscription.name == 'Unbegrenzt'">
                          <h6>{{ global.termsArray["lbl_Lifetime_access"] ? global.termsArray["lbl_Lifetime_access"] : "" }}</h6>
                        </ng-container>
                      </div>
                      <div class="d-flex-sp position-relative">
                        <div class="trial_expire" *ngIf="(subscription?.payment_status)=='trialing'">
                          {{global.termsArray["lbl_trial_ends_on"] ? global.termsArray["lbl_trial_ends_on"] : ""}} : {{transform(subscription?.expire)}}
                        </div>
                        <div class="account-point-right">
                          <h6 *ngIf="subscription?.validity=='0'" [ngStyle]="subscription?.name == 'Unbegrenzt' ? {'margin-bottom': '20px'} : {'margin-bottom': '0px'}" >
                            {{ subscription?.price | currency: "EUR":"symbol":"1.2-2" }}
                            {{' | Daily'  }}
                          </h6>
                          <h6 *ngIf="subscription?.validity!='0'" [ngStyle]="subscription?.name == 'Unbegrenzt' ? {'margin-bottom': '20px'} : {'margin-bottom': '0px'}" >
                            {{ subscription?.total | currency: "EUR":"symbol":"1.2-2" }}
                            <ng-container *ngIf="subscription?.name != 'Unbegrenzt'; else unlimitedCaseHeading">
                              {{ global.termsArray["lbl_all"]+' '+subscription?.validity+' '+global.termsArray["lbl_subs_month"] }}
                            </ng-container>
                            <ng-template #unlimitedCaseHeading>
                              {{global.termsArray["lbl_unique"]}}
                            </ng-template>
                          </h6>
                          <ng-container *ngIf="subscription?.name != 'Unbegrenzt'">
                            <h4 class="mt-2">
                              {{ subscription?.price | currency: "EUR":"symbol":"1.2-2" }}
                              {{ global.termsArray["lbl_per_month"] }}
                            </h4>
                          </ng-container>
                          <div class="price_description fs14" [innerHtml]="subscription?.description"></div>
                          <ng-container *ngIf="subscription?.name == 'Unbegrenzt'">
                          </ng-container>
                          <!-- <ng-container *ngIf="subscription?.name != 'Unbegrenzt'">
                            <h4>
                              {{ global.termsArray["lbl_subs_total_price"] }}
                              {{ subscription?.total | currency : "EUR" : "symbol" : "1.2-2" }}
                            </h4>
                          </ng-container> -->
                        </div>
                      </div>

                      <ng-container *ngIf="(subscription?.validity == 240 && subscription?.is_cancel == 1) || (subscription?.validity == 240 && subscription?.status == '') || subscription?.validity != 240">
                        <ng-container *ngIf="subscription?.payment_status != 'trialing' && subscription?.status !== 1 && !is_unlimited_subscribed">
                          <div class="d-flex">
                            <div class="privacycheck">
                              <label class="form-check-label terms-check-label" for="exampleCheck_{{subscription._id}}">
                                <input type="checkbox" class="form-check-input1 exampleCheck_{{subscription._id}}" id="exampleCheck_{{subscription._id}}" name="acceptTerms" (change)="doSomething($event); $event.stopPropagation();" />
                                {{ global.termsArray["msg_privacy_policy_web"] }}</label>
                              <a class="privacy_page_link new_link color-black" href="javascript:void(0);" routerLink="/agb" target="_blank" (click)="$event.stopPropagation();">{{ global.termsArray["msg_privacy_policy_link"] }}</a>
                            </div>
                          </div> 
                        </ng-container>

                        <ng-container *ngIf="(subscription.status == 1 && subscription?.is_cancel == 1) || (subscription.status != 1 && subscription?.is_cancel == '')">
                          <div class="validation_err_color" [ngClass]="(show== 'exampleCheck_'+subscription._id) ? 'd-flex show' : 'hide'">
                            {{ global.termsArray["err_terms_condition"] }}
                          </div>
                        </ng-container>

                      </ng-container>
                      
                      <div class="account-point-box">
                        <div class="account-point-bttn">
                          <ng-container *ngIf="subscription.validity != 240 && !subscribedFromApp">
                            <h6 class="color-black" id="cancel-subscription" *ngIf="subscription?.status == 1 && !subscription.is_cancel && !subscription.cancel_scheduled && subscription.payment_status != 'processing'" [ngClass]="(selectedPlanID && selectedPlanID == subscription?._id) || subscription?.status == 1 ? '' : 'inactive-h6'" (click)="initMypopup(cancelSubModel,'big-model-dialog2');">
                               {{ global.termsArray["lbl_cancel_subscription"] }}
                            </h6>
                            <h6 class="color-black" *ngIf="subscription?.status == 1 && !subscription.is_cancel && (subscription?.cancel_scheduled)" [ngClass]="(selectedPlanID && selectedPlanID == subscription?._id) || subscription?.status == 1 ? '' : 'inactive-h6'">
                              <ng-container *ngIf="subscription?.cancel_scheduled">
                                {{ global.termsArray["lbl_scheduled_cancel"] }} | {{ transform(subscription?.payment_status == "trialing" ? subscription.plan_ended : subscription.expire) }}
                              </ng-container>
                            </h6>
                          </ng-container>
                          <h6 class="color-black" id="cancel-subscription" *ngIf="subscription.is_cancel" [ngClass]="(selectedPlanID && selectedPlanID == subscription?._id) || subscription?.status == 1 ? '' : 'inactive-h6'">
                            {{ global.termsArray["lbl_subscription_cancelled"] }}
                          </h6>

                          <!-- Purchase plan button -->
                          <!-- For unlimited subscribed info -->
                          <ng-container *ngIf="subscription.name == 'Unbegrenzt'">
                            <ng-container *ngIf="subscription?.status == 1">
                              <button class="btn sky-btn" disabled style="background: #d5d5d5; color: #818181;">
                                <ng-container *ngIf="subscription.payment_status == 'processing'; else notInProcessingUnlimited">
                                  {{ global.termsArray["lbl_processing_your_subscription"] ? global.termsArray["lbl_processing_your_subscription"] : "Processing" }} 
                                </ng-container>
                                <ng-template #notInProcessingUnlimited>
                                  {{ global.termsArray["lbl_subscripbed_for_unlimited"] ? global.termsArray["lbl_subscripbed_for_unlimited"] : "Unlimited" }}
                                </ng-template>
                              </button>
                            </ng-container>

                            <ng-container *ngIf="subscription?.status == 0 && subscription?.payment_status == 'processing'">
                              <button class="btn sky-btn" disabled style="background: #d5d5d5; color: #818181;">
                                {{ global.termsArray["lbl_payment_in_process"] }}
                              </button>
                            </ng-container>

                            <ng-container *ngIf="subscription?.status != 1 && subscription?.payment_status != 'processing' && !subscribedFromApp">
                              <!-- <button class="btn sky-btn {{subscription?._id}}" id="{{subscription?._id}}" (click)="acceptTerms=='exampleCheck_'+subscription._id ? openModal(couponcode, 'big-model-dialog2',subscription, true): '' ">
                                {{ global.termsArray["lbl_choose"] ? global.termsArray["lbl_choose"] : "Choose" }}
                              </button> -->
                              <button class="btn sky-btn {{subscription?._id}}" id="{{subscription?._id}}" (click)="acceptTerms=='exampleCheck_'+subscription._id ? openModal(choosePaymentType, 'big-model-dialog2 modal-dialog-centered',subscription, true): '' ">
                                {{ global.termsArray["lbl_choose"] ? global.termsArray["lbl_choose"] : "Choose" }}
                              </button>
                            </ng-container>
                          </ng-container>

                          <ng-container *ngIf="subscription.name != 'Unbegrenzt'">
                            <ng-container *ngIf="subscription?.status == 1">
                              <button class="btn sky-btn" disabled style="background: #d5d5d5; color: #818181;">
                                <ng-container *ngIf="subscription?.payment_status == 'processing'; else notInProcessing">
                                  <p>
                                    {{ global.termsArray["lbl_processing_your_subscription"] ? global.termsArray["lbl_processing_your_subscription"] : "Processing" }} 
                                  </p>
                                </ng-container>
                                <ng-template #notInProcessing>
                                  <p *ngIf="subscription?.expire && subscription?.payment_status == 'not_started'">
                                    {{ global.termsArray["lbl_subscription_scheduled_start"]}} |
                                    {{ transform(subscription.startdate) }}
                                  </p>
                                  <p *ngIf="subscription?.expire && subscription?.payment_status != 'not_started'">
                                    {{ global.termsArray["lbl_Your_current_subscription"] ? global.termsArray["lbl_Your_current_subscription"] : "Ihr aktuelles Abo" }} | {{ global.termsArray["lbl_Date_of_Expiry"] ? global.termsArray["lbl_Date_of_Expiry"] : "Gültig bis" }} {{ transform(subscription?.payment_status == "trialing" ? subscription.expire : subscription.expire) }}
                                  </p>
                                </ng-template>
                              </button>  
                            </ng-container>
                            
                            <ng-container *ngIf="subscription?.status != 1 && !is_unlimited_subscribed && !subscribedFromApp">
                              <button class="btn sky-btn" *ngIf="subscription?.payment_status == 'trialing'" disabled style="background: #d5d5d5; color: #818181;">
                                {{ global.termsArray["lbl_end_date"] }}
                                {{transform(subscription?.expire)}}
                              </button>
                              <!-- <button *ngIf="subscription?.payment_status != 'trialing'" class="btn sky-btn {{subscription?._id}}" id="{{subscription?._id}}" (click)="acceptTerms=='exampleCheck_'+subscription._id ? openModal(couponcode, 'big-model-dialog2',subscription) : ''">
                                {{ global.termsArray["lbl_choose"] ? global.termsArray["lbl_choose"] : "Choose" }}
                              </button> -->
                              <button *ngIf="subscription?.payment_status != 'trialing'" class="btn sky-btn {{subscription?._id}}" id="{{subscription?._id}}" (click)="acceptTerms=='exampleCheck_'+subscription._id ? openModal(choosePaymentType, 'big-model-dialog2 modal-dialog-centered',subscription) : ''">
                                {{ global.termsArray["lbl_choose"] ? global.termsArray["lbl_choose"] : "Choose" }}
                              </button>
                            </ng-container>
                          </ng-container>
                          <!-- End of purchase plan button -->
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="account-title">
               
                <div class="account-title" style="padding-bottom: 0px;">
                  <p>Infos zu den Abos:</p>
                  <ul class="sub-content-sec">
                    <li *ngFor="let content of subContent?.description">
                      <p>
                        {{content}}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </article>
        <app-app></app-app>
      </div>
    </div>
  </section>
</div>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title" style="font-size: 25px; font-family: Akkurat-Bold; margin: 0; color: #4782c5;">
      {{ global.termsArray["lbl_cancel_subscription"] }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="font-size: 16px;font-family: Akkurat-Bold;margin: 0;color: #4782c5;">
    {{ global.termsArray["lbl_cancel_subs_confirm"] }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" style="font-size: 15px; font-family: Akkurat-Bold" (click)="modalRef.hide()">
      {{ global.termsArray["lbl_no"] }}
    </button>
    <button type="button" class="btn btn-primary" style="font-size: 15px; font-family: Akkurat-Bold" (click)="modalRef.hide(); cancelSubscription(subscribedId)">
      {{ global.termsArray["lbl_yes"] }}
    </button>
  </div>
</ng-template>

<ng-template #cancelSubModel>
  <div class="modal-header" style="border-bottom-width: 0px">
    <h4 class="modal-title" style="font-size: 25px; font-family: Akkurat-Bold; margin: 0; color: white;">
      {{ global.termsArray["lbl_cancel_subs_confirm"] }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-footer coupon-modal-footer">
    <button type="button" class="btn btn-basic" style="font-size: 15px; font-family: Akkurat-Bold; color: #3d76ce" (click)="modalRef.hide();">
      {{ global.termsArray["lbl_no"] }}
    </button>
    <button type="button" class="btn btn-primary" style="font-size: 15px;font-family: Akkurat-Bold;background-color: rgb(83, 179, 214);color: white;" (click)="modalRef.hide();cancelSubscription(subscribedId);">
      {{ global.termsArray["lbl_yes"] }}
    </button>
  </div>
</ng-template>

<!-- <ng-template #couponcodeForStripe>
  <div class="modal-header" style="border-bottom-width: 0px">
    <h4 class="modal-title" style="font-size: 25px; font-family: Akkurat-Bold; margin: 0; color: white;">{{ global.termsArray["lbl_enter_promo_code"] }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modalRef.hide();">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <input type="email" class="form-control-input" id="exampleInputEmail1" aria-describedby="emailHelp" [(ngModel)]="couponCode" [placeholder]="global.termsArray['lbl_promo_code']" />
  </div>
  <div class="modal-footer coupon-modal-footer">
    <div class="paypal_config_box">
      <button type="button" class="btn btn-basic paypal_config_none" style="font-size: 15px; font-family: Akkurat-Bold; color: #3d76ce">
        {{ global.termsArray["lbl_skip"] }}
      </button>
    </div>
    <button type="button" class="btn btn-primary" style="font-size: 15px;font-family: Akkurat-Bold;background-color: rgb(83, 179, 214);color: white;" (click)="applyCouponCode(couponCode, couponDiscountResult)">
      {{ global.termsArray["lbl_Submit"] }}
    </button>
  </div>
</ng-template> -->

<ng-template #choosePaymentType>
  <div class="modal-header payment-options-modal-header" style="border-bottom-width: 0px">
    <h4 class="modal-title" style="font-size: 25px; font-family: Akkurat-Bold; margin: 0; color: white;">{{ global.termsArray["lbl_payment_method"] }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modalRef.hide(); resetForm(paymentOptionsForm)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
 
  
  <div class="modal-footer coupon-modal-footer bg-white payment-options-modal-body">
    <form class="w-100 text-center" [formGroup]="paymentOptionsForm" (ngSubmit)="subscribeMyplan()">
      <ul class="w-100 payment_options">
        <!-- Paymet options only for unlimited subscription <<< -->
        <li  *ngIf="is_unlimited"> 
          <div class="pay_option_container">
            <img src="assets/images/gift-line.png" alt="gift.png" class="credit-card">
            {{ global.termsArray["lbl_gift_card"]?global.termsArray["lbl_gift_card"]:"Gift Card" }}
            <input type="radio" formControlName="payment_method" id="radio_card" value="gift_card" />
            <label for="radio_card"></label>
          </div>
        </li>
        <!-- Paymet options for short subscription >>> -->
        <!-- For credit card >>> -->
        <li *ngIf="isActiveMethod('card')">
          <!-- <li (click)="subscribeToStripe(subData, 'card')"> -->
          <div class="pay_option_container">
            <img src="assets/images/credit-card.png" alt="card.png" class="credit-card">
            {{ global.termsArray["lbl_credit_card"]?global.termsArray["lbl_credit_card"]:"Credit Card" }}
            <input type="radio" formControlName="payment_method" id="radio_card" value="card" />
            <label for="radio_card"></label>
          </div>
        </li>
        <!-- For credit card <<< -->
  
        <!-- For Sepa debit >>> -->
        <!-- (click)="subscribeToStripe(subData, 'sepa_debit')" -->
        <li *ngIf="enableSofort && isActiveMethod('sepa')">
          <div class="pay_option_container">
            <img src="assets/images/sepa.png" alt="sepa.png">
            {{ global.termsArray["lbl_sepa_debit"]?global.termsArray["lbl_sepa_debit"]:"SEPA" }}
            <input type="radio" formControlName="payment_method" id="radio_sepa_debit" value="sepa_debit" />
            <label for="radio_sepa_debit"></label>
          </div>
        </li>
        <!-- For Sepa debit <<< -->
  
        <!-- For sofort >>> -->
        <!-- (click)="subscribeToStripe(subData, 'sofort')" -->
        <li *ngIf="enableSofort && isActiveMethod('sofort')">
          <div class="pay_option_container">
            <img src="assets/images/sofort.png" alt="sofort.png">
            {{ global.termsArray["lbl_sofort"]?global.termsArray["lbl_sofort"]:"Sofort" }}
            <input type="radio" formControlName="payment_method" id="radio_sofort" value="sofort" />
            <label for="radio_sofort"></label>
          </div>
        </li>
        <!-- For sofort <<< -->
        <!-- Paymet options for short subscription <<< -->
  
        <!-- Paymet options only for unlimited subscription >>> -->
        <ng-container *ngIf="is_unlimited">
          <!-- For giropay >>> -->
          <li *ngIf="userCountry == 'Germany' && isActiveMethod('giropay')">
            <div class="pay_option_container">
              <img src="assets/images/giropay.png" alt="giropay.png">
              {{ global.termsArray["lbl_giropay"]?global.termsArray["lbl_giropay"]:"Giropay" }}
              <input type="radio" formControlName="payment_method" id="radio_giropay" value="giropay" />
              <label for="radio_giropay"></label>
            </div>
          </li>
          <!-- For giropay <<< -->
          
          <!-- For eps >>> -->
          <li *ngIf="userCountry == 'Austria' && isActiveMethod('eps')">
            <div class="pay_option_container">
              <img src="assets/images/eps.png" alt="eps.png">
              {{ global.termsArray["lbl_eps"]?global.termsArray["lbl_eps"]:"EPS" }}
              <input type="radio" formControlName="payment_method" id="radio_eps" value="eps" />
              <label for="radio_eps"></label>
            </div>
          </li>
          <!-- For eps <<< -->
  
          <!-- For paypal >>> -->
          <!-- (click)="requestToPaypal(subData, this.couponCodeData)" -->
          <li *ngIf="isActiveMethod('paypal')">
            <div class="pay_option_container">
              <img src="assets/images/paypal.png" alt="paypal.png">
              {{ global.termsArray["lbl_paypal"]?global.termsArray["lbl_paypal"]:"Paypal" }}
              <input type="radio" formControlName="payment_method" id="radio_paypal" value="paypal" />
              <label for="radio_paypal"></label>
            </div>
          </li>
          <!-- For paypal <<< -->
      </ng-container>
      </ul>
      <!-- <div id="paypal_button_container"></div> -->
      <button class="btn sky-btn mt-4 mb-3" type="submit" [attr.disabled]="paymentOptionsForm.invalid?true:null">{{ global.termsArray["lbl_proceed"] }}</button>
    </form>
  </div>
</ng-template>


<ng-template #couponcode>
  <div class="modal-header" style="border-bottom-width: 0px">
    <h4 class="modal-title" style="font-size: 25px; font-family: Akkurat-Bold; margin: 0; color: white;">{{ global.termsArray["lbl_enter_promo_code"] }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modalRef.hide();">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <input type="email" class="form-control-input" id="exampleInputEmail1" aria-describedby="emailHelp" [(ngModel)]="couponCode" [placeholder]="global.termsArray['lbl_promo_code']" />
  </div>
  <div class="modal-footer coupon-modal-footer">
    <!-- Button for paypal -->
      <ng-container *ngIf="paymentMethod == 'paypal'; else showStripeOption;">
        <div class="paypal_config_box">
          <button type="button" class="btn btn-basic paypal_config_none"
            style="font-size: 15px; font-family: Akkurat-Bold; color: #3d76ce">
            {{ global.termsArray["lbl_skip"] }}
          </button>
          <div id="paypal_button_container" class="paypal_custom_button paypal_config_trigger"></div>
        </div>
      </ng-container>
      <!-- Button for paypal -->
      <ng-template #showStripeOption>
        <button type="button" class="btn btn-basic paypal_config_none" (click)="subscribeToStripe()" style="font-size: 15px; font-family: Akkurat-Bold; color: #3d76ce">
          {{ global.termsArray["lbl_skip"] }}
        </button>
      </ng-template>
    <button type="button" class="btn btn-primary" style="font-size: 15px;font-family: Akkurat-Bold;background-color: rgb(83, 179, 214);color: white;" (click)="applyCouponCode(couponCode, couponDiscountResult)">
      {{ global.termsArray["lbl_Submit"] }}
    </button>
  </div>
</ng-template>


<ng-template #couponDiscountResult>
  <div class="modal-header" style="border-bottom-width: 0px">
    <h4 class="modal-title" style="font-size: 25px; font-family: Akkurat-Bold; margin: 0; color: white;">{{ global.termsArray["lbl_your_order"] }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modalRef.hide();">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body body-parent">
    <div class="child-body">
      <h3>{{ this.global.termsArray["lbl_total_price"] }}</h3>
      <h3>{{ this.global.termsArray["lbl_subscription_amount"] }}</h3>
      <h3>{{ this.global.termsArray["lbl_discount"] }}</h3>
    </div>
    <div class="child-body" *ngIf="couponCodeData">
      <h3>: {{ couponCodeData["total"] | currency: "EUR":"symbol":"1.2-2" }}</h3>
      <h3>: {{ couponCodeData["discount_less"] | currency: "EUR":"symbol":"1.2-2" }}</h3>
      <h3>: {{ couponCodeData["discount_amount"] | currency: "EUR":"symbol":"1.2-2" }}</h3>
    </div>
  </div>
 
  
  <div class="modal-footer coupon-modal-footer">
    <!-- Button for paypal -->
    <ng-container *ngIf="paymentMethod == 'paypal'; else showStripeOkOption;">
      <div class="paypal_config_box">
        <button type="button" class="btn btn-primary paypal_config_none" style="font-size: 15px;font-family: Akkurat-Bold;background-color: rgb(83, 179, 214);color: white;">
          {{ global.termsArray["lbl_ok"] }}
        </button>
        <div id="paypal_button_container2" class="paypal_custom_button paypal_config_trigger"></div>
      </div>
    </ng-container>
    <!-- Button for paypal -->
    <ng-template #showStripeOkOption>
      <button type="button" class="btn btn-primary paypal_config_none" (click)="subscribeToStripe()" style="font-size: 15px;font-family: Akkurat-Bold;background-color: rgb(83, 179, 214);color: white;">
        {{ global.termsArray["lbl_ok"] }}
      </button>
    </ng-template>
  </div>
</ng-template>

<ng-template #giftcode>
  <div class="modal-header" style="border-bottom-width: 0px">
    <h4 class="modal-title" style="font-size: 25px; font-family: Akkurat-Bold; margin: 0; color: white;">{{ global.termsArray["lbl_enter_gift_code"] ? global.termsArray["lbl_enter_gift_code"] :'Enter Gift Code' }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modalRef.hide();">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <input type="text" class="form-control-input" id="exampleInputEmail1" aria-describedby="emailHelp" [(ngModel)]="giftCode" [placeholder]="global.termsArray['lbl_gift_code']" />
  </div>
  <div class="modal-footer coupon-modal-footer">
    <button type="button" class="btn btn-primary" style="font-size: 15px;font-family: Akkurat-Bold;background-color: rgb(83, 179, 214);color: white;" (click)="applyGiftCardCode(giftCode,giftCodeDiscountResult)">
      {{ global.termsArray["lbl_Submit"] }}
    </button>
  </div>
</ng-template>

<ng-template #giftCodeDiscountResult>
  <div class="modal-header" style="border-bottom-width: 0px">
    <h4 class="modal-title" style="font-size: 25px; font-family: Akkurat-Bold; margin: 0; color: white;">{{ global.termsArray["lbl_your_order"] }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modalRef.hide();">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body body-parent" style="justify-content: left;">
    <div class="child-body ">
      <h3>{{ this.global.termsArray["lbl_total_price"] }}</h3>
      <h3>{{ this.global.termsArray["lbl_subscription_amount"] }}</h3>
      <h3>{{ this.global.termsArray["lbl_discount"] }}</h3>
    </div>
    <div class="child-body" *ngIf="giftCodeData">
      <h3>: {{ giftCodeData["total"] | currency: "EUR":"symbol":"1.2-2" }}</h3>
      <h3>: {{ giftCodeData["discount_less"] | currency: "EUR":"symbol":"1.2-2" }}</h3>
      <h3>: {{ giftCodeData["discount_amount"] | currency: "EUR":"symbol":"1.2-2" }}</h3>
    </div>
  </div>
 
  
  <div class="modal-footer coupon-modal-footer">
    <button type="button" class="btn btn-primary paypal_config_none" (click)="manageGiftCodePaymentResult()" style="font-size: 15px;font-family: Akkurat-Bold;background-color: rgb(83, 179, 214);color: white;">
      {{ global.termsArray["lbl_ok"] }}
    </button>
  </div>
</ng-template>

<ng-template #chooseGiftCardSubscriptionPaymentType>
  <div class="modal-header payment-options-modal-header" style="border-bottom-width: 0px">
    <h4 class="modal-title" style="font-size: 25px; font-family: Akkurat-Bold; margin: 0; color: white;">{{ global.termsArray["lbl_payment_method"] }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modalRef.hide(); resetForm(paymentOptionsForm)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-footer coupon-modal-footer bg-white payment-options-modal-body">
    <form class="w-100 text-center" [formGroup]="giftCardPaymentOptionsForm" (ngSubmit)="subscribeByGiftCards()">
      <ul class="w-100 payment_options">
        <li *ngIf="isActiveMethod('credit_card')" >
          <div class="pay_option_container">
            <img src="assets/images/credit-card.png" alt="card.png" class="credit-card">
            {{ global.termsArray["lbl_credit_card"]?global.termsArray["lbl_credit_card"]:"Credit Card" }}
            <input type="radio" formControlName="gift_card_payment_method" id="radio_card" value="card" />
            <label for="radio_card"></label>
          </div>
        </li>
        <li *ngIf="enableSofort && isActiveMethod('sepa')">
          <div class="pay_option_container">
            <img src="assets/images/sepa.png" alt="sepa.png">
            {{ global.termsArray["lbl_sepa_debit"]?global.termsArray["lbl_sepa_debit"]:"SEPA" }}
            <input type="radio" formControlName="gift_card_payment_method" id="radio_sepa_debit" value="sepa_debit" />
            <label for="radio_sepa_debit"></label>
          </div>
        </li>
        <li *ngIf="enableSofort && isActiveMethod('sofort')">
          <div class="pay_option_container">
            <img src="assets/images/sofort.png" alt="sofort.png">
            {{ global.termsArray["lbl_sofort"]?global.termsArray["lbl_sofort"]:"Sofort" }}
            <input type="radio" formControlName="gift_card_payment_method" id="radio_sofort" value="sofort" />
            <label for="radio_sofort"></label>
          </div>
        </li>
        <ng-container *ngIf="is_unlimited">
          <li *ngIf="userCountry == 'Germany' && isActiveMethod('giropay')">
            <div class="pay_option_container">
              <img src="assets/images/giropay.png" alt="giropay.png">
              {{ global.termsArray["lbl_giropay"]?global.termsArray["lbl_giropay"]:"Giropay" }}
              <input type="radio" formControlName="gift_card_payment_method" id="radio_giropay" value="giropay" />
              <label for="radio_giropay"></label>
            </div>
          </li>
          <li *ngIf="userCountry == 'Austria' && isActiveMethod('eps')">
            <div class="pay_option_container">
              <img src="assets/images/eps.png" alt="eps.png">
              {{ global.termsArray["lbl_eps"]?global.termsArray["lbl_eps"]:"EPS" }}
              <input type="radio" formControlName="gift_card_payment_method" id="radio_eps" value="eps" />
              <label for="radio_eps"></label>
            </div>
          </li>
          <li *ngIf="isActiveMethod('paypal')" (click)="doAnotherthing();">
            <div class="pay_option_container">
              <img src="assets/images/paypal.png" alt="paypal.png">
              {{ global.termsArray["lbl_paypal"]?global.termsArray["lbl_paypal"]:"Paypal" }}
              <input type="radio" formControlName="gift_card_payment_method" id="radio_paypal" value="paypal" />
              <label for="radio_paypal"></label>
            </div>
          </li>
      </ng-container>
      </ul>

      <!-- <div id="paypal_button_container3" class="paypal_custom_button paypal_config_trigger"></div> -->
        <div class="paypal_config_box" [ngClass]="giftCardPaymentOptionsForm.get('gift_card_payment_method').value == 'paypal' ? 'd-inline-block':'d-none'">
          <button type="submit" class="btn sky-btn mt-4 mb-3 paypal_config_none">
            {{ global.termsArray["lbl_proceed"] }}
          </button>
          <div id="paypal_button_container3" class="paypal_custom_button paypal_config_trigger giftcard_paypal"></div>
        </div>

        <button type="submit" class="btn sky-btn mt-4 mb-3 paypal_config_none" [attr.disabled]="giftCardPaymentOptionsForm.invalid?true:null"  [ngClass]="giftCardPaymentOptionsForm.get('gift_card_payment_method').value != 'paypal' ? '':'d-none'">
          {{ global.termsArray["lbl_proceed"] }}
        </button>
      <!-- <button class="btn sky-btn mt-4 mb-3" type="submit" [attr.disabled]="giftCardPaymentOptionsForm.invalid?true:null">{{ global.termsArray["lbl_proceed"] }}</button> -->
    </form>
  </div>
</ng-template>