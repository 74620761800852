import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewAudioService {
  public audio: HTMLAudioElement = new Audio();
  private isPlayingSubject = new BehaviorSubject<number>(0);
  /** 0: Not playing
   * 1: playing
   * 2: ended
   * 3:ended
   */
  constructor() {}

  play(url: string, speed: number = 1, isLoop: boolean, onEndedCallback?: () => void): void {
    this.audio.src = url;
    this.audio.playbackRate = speed;
    this.audio.loop = isLoop;
    this.audio.load();
  
    // Set status to 3 when audio starts playing
    this.audio.addEventListener('playing', () => {
      console.log("started playing");
      this.isPlayingSubject.next(1)
    });
  
    if (onEndedCallback) {
      this.audio.addEventListener('ended', () => {
        console.log("ended");
        onEndedCallback();
        this.isPlayingSubject.next(2)
      });
    }
  
    this.audio.play();
  }
  
  

  pause(): void {
    this.audio.pause();
    this.isPlayingSubject.next(3);
  }

  getIsPlaying() {
    return this.isPlayingSubject.value;
  }

  resetAudio(){
    this.pause();
    this.audio.src=null
  }
}
