<div class="inner-wraper login-bg-cover">
  <section class="abg-form-sect">
    <div class="container">
      <div class="login-in-color">
        <div class="setting-arrow">
          <a routerLink="/login" href="javascript:;" style="width: fit-content; word-break: initial">
            <i class="fas fa-long-arrow-alt-left"></i>
            <h6>Zurück</h6>
          </a>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="log-wd-max">
              <form [formGroup]="formData" (ngSubmit)="submit(formData.value)">
                <h1 class="heading-log">{{ global.termsArray["lbl_forgot_password"] }}</h1>
                <div class="form-group pb-3">
                  <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="email" [placeholder]="global.termsArray['lbl_email_address']"/>
                  <div *ngIf="formData.controls['email'].invalid && (formData.controls['email'].dirty || formData.controls['email'].touched || this.formSubmitAttempt)">
                    <div *ngIf="formData.controls['email'].errors.required" class="validation_err validation_err_color" style="font-size: 14px;"
                    > {{ global.termsArray["msg_enter_email"] }} </div>
                    <div *ngIf="formData.controls['email'].errors.pattern" class="validation_err validation_err_color" style="font-size: 14px;"
                    >{{ global.termsArray["err_valid_email"] }}</div>
                  </div>
                </div>
                <button type="submit" class="btn btn-primary mb-3">
                  {{ global.termsArray["lbl_update"] }}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
