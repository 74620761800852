import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-badge-progress',
  templateUrl: './badge-progress.component.html',
  styleUrls: ['./badge-progress.component.scss']
})
export class BadgeProgressComponent implements OnInit, OnChanges {
  @Input() value:number=0;
  @Input() height:number=82;
  @Input() width:number=82;
  @Input() scale:number=1;
  @Input() customClass:string="";
  progress:number=0;
  maxValue:number = 100;
  constructor() { }

  ngOnInit() {
    this.initValue();
  }
  ngOnChanges() {
    this.initValue();
  }
  initValue(){
    this.progress = this.maxValue - this.value;
    if(this.progress > this.maxValue){
      this.progress=this.maxValue;
    }

    // this.progress1 = this.maxValue - this.value1;
    // if(this.progress1 > this.maxValue){
    //   this.progress1=this.maxValue;
    // }
    // this.progress2 = this.maxValue - this.value2;
    // if(this.progress2 > this.maxValue){
    //   this.progress2=this.maxValue;
    // }
  }

}
