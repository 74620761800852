import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalService } from "../../global.service";
import { EnvService } from 'src/app/env.service';
import { NgxNavigationWithDataComponent } from "ngx-navigation-with-data";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import {
  EmailValidation,
  PasswordValidation,
  RepeatPasswordEStateMatcher,
  RepeatPasswordValidator,
} from "../../validator.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {

  formData;
  passwordsMatcher = new RepeatPasswordEStateMatcher();
  formSubmitAttempt = false;
  haveStripeSource: Boolean = this.global.user && this.global.user.customer_id ? true : false;
  selectCountryTermFlag: any = this.global.termsArray[this.global.user && this.global.user.country && this.global.user.country.term ? this.global.user.country.term : 'lbl_choose_country'];
  userCountry: any = this.global.user && this.global.user.country && this.global.user.country.term ? this.global.user.country : "";
  countries: any = [];
  cardInfo: any = {};
  subscriptionList: any = [];
  SUBSCRIBED_FROM_WEB = "website";
  subscribed: boolean = false;
  subscribedFrom: string = null;
  userOldEmail:String = "";
  constructor(
    private route: Router,
    public global: GlobalService,
    private formBuilder: FormBuilder,
    public location: Location,
    private ngxService: NgxUiLoaderService,
    public envservice: EnvService,
  ) {
    this.userOldEmail = this.global.user.email;
    this.formData = this.formBuilder.group({
      email: EmailValidation,
      username: new FormControl("", Validators.required),
      selectCountry: new FormControl("", Validators.required),
      password: new FormControl(""),
      // surname: new FormControl("", Validators.required),
      // name: new FormControl("", Validators.required),
      //gender: new FormControl(""),
    });
    this.getProfileData();
    this.global.profileTab = 2;
  }


  ngOnInit() {
    this.getCountries();
    if (this.haveStripeSource) {
      this.getCardInfo();
    }
    this.getSubscriptionData();
  }
  /** Edit card info >>>>> */
  getCardInfo() {
    this.global.get(`getCardInfo?cust_id=${this.global.user.customer_id}`, (response: any) => {
      this.cardInfo = response.data;
    }, (err: any) => {
      this.global.showDangerToast("", this.global.termsArray[err.message]);
    })
  }
  editCardInfo(cardToken: string) {
    this.global.post('/editCardInfo', { token: cardToken }, (response: any) => {
      if (response.success) {
        this.global.showToast("", this.global.termsArray[response.message]);
        this.getCardInfo();
      } else {
        this.global.showDangerToast("", this.global.termsArray[response.message]);
      }
    }, (err: any) => {
      this.global.showDangerToast("", this.global.termsArray[err.message]);
    })
  }
  openEditCard() {
    this.global.loader = true;
    this.ngxService.start();
    var handler = (<any>window).StripeCheckout.configure({
      key: this.envservice.settings.payment_mode == 1 ? this.envservice.settings.public_key_live : this.envservice.settings.public_key_test,
      locale: "auto",
      currency: "eur",
      closed: () => {
        setTimeout(() => {
          this.global.loader = false;
          this.ngxService.stop();
        }, 3000);
      },
      panelLabel: "Update",
      token: (token: any) => {
        this.editCardInfo(token);
      },
    });
    handler.open({
      image: "https://stripe.com/img/documentation/checkout/marketplace.png",
      name: "Birlingo",
      // amount: 100 * price,
      allowRememberMe: false,
      description: `${this.global.termsArray["lbl_update_card"]}`,
      email: this.global.user.email
    });
  }
  /** Edit card info <<<<<< */


  getCountries() {
    this.global.get('/countries', (data: any) => {
      this.countries = data.data;
    }, (err: any) => {
      this.global.showDangerToast("", err.message);
    })
  }
  selectGender(gender) {
    this.formData.controls["gender"].value = gender;
    this.formData.value.gender = gender;
  }

  getProfileData() {
    this.global.get(
      "getManageAccount/" + this.global.user._id,
      (data) => {
        if (data.success) {
          let user = data.data.user_info;
          this.formData.get("email").setValue(user.email);
          this.formData.get("username").setValue(user.username ? user.username : user.name + " " + user.surname);
          this.formData.get("selectCountry").setValue(this.userCountry);

          // this.formData.get("name").setValue(user.name);
          // this.formData.get("surname").setValue(user.surname);
          //this.formData.get("gender").setValue(user.gender);
        } else {
          this.global.showDangerToast("", this.global.termsArray[data.message]);
        }
      },
      (err) => {
        this.global.showDangerToast("", err.message);
      },
      true
    );
  }

  onClickSubmit(value) {
    // this.formData.get("name").setValue(this.removeEmojis(value.name));
    // this.formData.get("surname").setValue(this.removeEmojis(value.surname));
    this.formSubmitAttempt = true;
    if (!this.formData.valid) {
      return;
    } else {
      this.callRegisterApi(value);
    }
  }

  callRegisterApi(value:any) {
    let body = {};
    // body["surname"] = value.surname;
    // body["name"] = this.capitalize(value.name);
    body["username"] = this.capitalize(value.username);
    body["user_id"] = this.global.user._id;
    body["country"] = value.selectCountry;
    body["email"] = value.email.toLowerCase();
    body["password"] = value.password;
    //body["gender"] = value.gender;
    this.global.post(
      "updateManageAccount",
      body,
      (data) => {
        if (data.success) {
          this.global.user = data.data.user;
          localStorage.setItem(
            btoa("user"),
            btoa(JSON.stringify(data.data.user))
          );
          if(data.data && data.data.type && data.data.type=="new_email_verification"){
            this.route.navigate(["verify"],{queryParams:{
              verify:true,
              slug:data.data.type,
              email:data.data.user.email,
              new_email:data.data.user.new_email,
            }});
          }
          this.global.showToast("", this.global.termsArray[data.message]);
        } else {
          this.global.showDangerToast("", this.global.termsArray[data.message]);
        }
      },
      (err) => {
        this.global.showDangerToast("", err.message);
      },
      true
    );
  }

  removeEmojis(string) {
    var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    return string.replace(regex, "");
  }

  goBack() {
    this.route.navigate(["setting"], { replaceUrl: true });
  }

  capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  deleteAccount() {
    if (this.subscribed) {
      (document.getElementById("showCancelSubsNotificationbutton") as any).click();
    } else {
      this.global.post("deleteUser", {}, (data) => {
        if (data.success) {
          this.global.showToast("", this.global.termsArray[data.message]);
          this.logout();
        } else {
          this.global.showDangerToast("", this.global.termsArray[data.message]);
        }
      },
      (err:any) => {
        this.global.showDangerToast("", err.message);
      }, true);
    }
  }

  logout() {
    localStorage.removeItem(btoa("AuthToken"));
    localStorage.removeItem(btoa("routeParams"));
    this.global.routeParams = null;
    this.global.isLogin = false;
    this.global.AuthToken = null;
    this.signOut();
  }

  signOut() {
    localStorage.removeItem(btoa("user"));
    this.global.lessonfamily_id = null;
    this.global.baselesson_id = null;
    this.global.lesson_id = null;
    this.global.lesson_title = null;
    this.global.max_read_slide = null;
    localStorage.removeItem(btoa("lessonfamily_id"));
    localStorage.removeItem(btoa("baselesson_id"));
    localStorage.removeItem(btoa("lesson_id"));
    localStorage.removeItem(btoa("lesson_title"));
    localStorage.removeItem(btoa("max_read_slide"));
    this.global.user = null;
    document.body.classList.remove("texture-bg-gry");
    this.route.navigate(["/login"], { replaceUrl: true });
  }



  selectCountry(country: any) {
    this.selectCountryTermFlag = this.global.termsArray[country.term];
    this.global.learningLanguage = country;
    this.formData.get("selectCountry").setValue(country);
  }


  getSubscriptionData() {
    let body = {};
    body["language_id"] = this.global.selectLanguage;
    body["user_id"] = this.global.user._id;
    this.global.post("subscriptions", JSON.stringify(body), (data) => {
      if (data.success) {
        this.subscriptionList = data.data;
        const subLength = this.subscriptionList.length;
        for (let i = 0; i < subLength; i++) {
          if (this.subscriptionList[i].status == "1" && this.subscriptionList[i].subs_source) {
            this.subscribed = true;
            this.subscribedFrom = this.subscriptionList[i].subs_source;
            break;
          }
        };
      } else {
        this.global.showDangerToast("Error", this.global.languageArray[data.message]);
      }
    }, (err) => {
      this.global.showDangerToast("Error", err.message);
    }, true);
  }

}
