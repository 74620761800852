import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { GlobalService } from "../global.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { url } from "inspector";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  languageArray: any;
  login_with_social: any;
  gapi: any;
  public auth2: any;
  public showBtn: boolean = false;
  public showCross: boolean = false;
  max_767_width: any = false;
  hideHeader: boolean = false;
  public currentRoute: any = "";
  storeUrls:any={};
  constructor(
    private titleService: Title,
    public global: GlobalService,
    public router: Router,
    public location: Location,
    public breakpointObserver: BreakpointObserver,
  ) {
    this.getLanguageList();
    router.events.subscribe((url: any) => {
      // console.log(router.routerState.snapshot.url)
      if (router.routerState.snapshot.url == "/") this.showBtn = true; else this.showBtn = false;
      this.currentRoute = this.router.routerState.snapshot.url;
      if (this.currentRoute == '/choose-lesson') {
        this.hideHeader = false;
      } else {
        this.hideHeader = true;
      }
    });
  }

  ngOnInit() {
    this.fbLibrary();
    // console.log("this is library");
    this.breakpointObserver.observe(["(max-width: 767px)"]).subscribe((result) => {
      // console.log("this is break point observer",this.breakpointObserver);
      if (result.matches) {
        this.max_767_width = false;
        // console.log("this max 767 ",this.max_767_width);
      } else {
        this.max_767_width = true;
        // console.log("this max 767 width ",this.max_767_width);

      }
    });
    this.global.storeUrls.subscribe((value)=>{
      this.storeUrls = value;
    })
  }

  setPageTitle(title: string) {
    this.titleService.setTitle(title);
    var element = document.getElementById("navbarSupportedContent");
    element.classList.remove("show");
  }

  logout() {
    sessionStorage.setItem(btoa("backToHome"), btoa("true"));
    localStorage.removeItem(btoa("blogData"));
    if (window.confirm(this.global.termsArray["msg_logout_confirm"])) {
      this.global.submitLoginHours();
      localStorage.removeItem(btoa("AuthToken"));
      localStorage.removeItem(btoa("routeParams"));
      this.global.routeParams = null;
      this.global.isLogin = false;
      this.global.AuthToken = null;
      this.signOut();
      this.getLanguageList();
    }
  }

  fbLibrary() {
    (window as any).fbAsyncInit = function () {
      window["FB"].init({
        appId: "434838987192361",
        cookie: true,
        xfbml: true,
        version: "v3.1",
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }

  signOut() {
    if (this.global.user.login_with_social == 1) {
      window["gapi"].load("auth2", () => {
        this.auth2 = window["gapi"].auth2.getAuthInstance();
        this.auth2.signOut().then(() => { });
        //this.attachSignout(document.getElementById('googleBtn2'));
      });
      window["FB"].logout(function (response) {
        console.log("User signed out.", response);
      });
    }
    localStorage.removeItem(btoa("user"));
    this.global.lessonfamily_id = null;
    this.global.baselesson_id = null;
    this.global.lesson_id = null;
    this.global.max_read_slide = null;
    this.global.lesson_title = null;
    localStorage.removeItem(btoa("lessonfamily_id"));
    localStorage.removeItem(btoa("baselesson_id"));
    localStorage.removeItem(btoa("lesson_id"));
    localStorage.removeItem(btoa("max_read_slide"));
    localStorage.removeItem(btoa("lesson_title"));
    this.global.user = null;
    document.body.classList.remove("texture-bg-gry");
    this.router.navigate(["/login"]);
  }

  getLanguageList() {
    this.global.get("languages", (data) => {
      localStorage.setItem(btoa("termArray"), btoa(JSON.stringify(data.data)));
      this.languageArray = data.data;
      this.languageArray.map((ele) => {
        if (ele._id == this.global.selectLanguage) {
          this.global.selectedLabel = this.global.termsArray[ele.term];
        }
      });
      if (data.data.lenght == 1) {
        this.selectLanguage(data.data[0]._id, data.data[0].label);
      }
      this.global.languageArray = data.data;
    },
      (err) => { },
      true);
  }

  selectLanguage(id, label) {
    console.log(id);
    this.global.selectedLabel = label;
    this.global.selectLanguage = id;
    localStorage.setItem(btoa("selectLanguage"), btoa(id));
    this.global.getTermsData();
    this.getLearningLanguage();
    location.reload();
  }

  getLearningLanguage() {
    this.global.get(
      "learningLanguages/" + this.global.selectLanguage,
      (data) => {
        this.global.learningLanguages = data.data.filter((ele) => {
          return ele._id != this.global.selectLanguage;
        });
        console.log("learning_language", data);
      },
      (err) => { }
    );
  }

  openUrl(type) {
    const _this = this;
    let obj = {
      1: _this.storeUrls.play_url,
      2: _this.storeUrls.apple_url
    };
    window.open(obj[type], 'blank');
}

crossBtnClicked(route = "") {
  // console.log("route: ", route);
  // console.log("currentRoute: ", this.currentRoute);
  if (route == this.currentRoute && route != "" && this.currentRoute != "") return;
  this.showCross = !this.showCross;
}
}
