<div class="inner-wraper">
  <section class="lesson-cont-sect wrap-space">
    <div class="container">
      <div class="lektionen-cover-new">
        <article>
          <div class="setting-arrow inner-arrow">
            <a href="javascript:;" (click)="location.back()">
              <i class="ri-arrow-left-s-line"></i>
              <h6>{{ global.termsArray["lbl_Settings"] }}</h6>
            </a>
          </div>
          <div [ngClass]=" termArray == null? 'list-sentens text-center newCss' : 'list-sentens text-center'">
            <div class="in-list-setting">
              <div class="account-title">
                <h4>{{ this.global.termsArray["lbl_terms_title"] ? this.global.termsArray["lbl_terms_title"] : 'Allgemeine Geschäftsbedingungen' }}</h4>
              </div>
              <app-no-data-found *ngIf="!contentLoader"></app-no-data-found>
                <div class="in-list-leson trem-sec" style="text-align: left">
                  <div
                    class="list-lesson-rrow text-white"
                    id="cmspages_id1"
                    style="display: block !important"
                  ></div>
                </div>
              </div>
          </div>
        </article>
        <app-app></app-app>
      </div>
    </div>
  </section>
</div>
