import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GlobalService } from "../global.service";
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: "agb-app",
  templateUrl: "./agb.component.html",
  styleUrls: ["./agb.component.sass"]
})
export class AgbComponent implements OnInit {
  languageId: any;
  agbArray: any = null;
  contentLoader:any=true;
  constructor(private route: ActivatedRoute, public global: GlobalService, public router: Router, private titleService: Title,  
    private metaService: Meta) {
    let user = localStorage.getItem(btoa("user")) ? atob(localStorage.getItem(btoa("user"))) : null;
    user = JSON.parse(user);
    // if (user) {
    //   this.router.navigate(["user"],{replaceUrl:true});
    // }

    this.route.paramMap.subscribe(params => {
      this.languageId = params.get("id") ? params.get("id") : this.global.selectLanguage ? this.global.selectLanguage : "5dea0938ec155c3df111d13c";
    });
  }

  ngOnInit() {
    this.titleService.setTitle("Allgemeine Geschäftsbedingungen");
    this.metaService.updateTag({ name: 'description', content: 'Lesen Sie hier die allgemeinen Geschäftsbedingungen für den Erwerb einer unserer Sprachlernkurse.' });
    this.getList();
    localStorage.removeItem(btoa("routeParams"));
    this.global.routeParams = null
  }

  getList() {
    let body = {};
    // console.log("this is a body",body);
    body["slug"] = "agb";
    body["language_id"] = this.languageId?this.languageId:this.global.selectLanguage;
    // console.log("this is body language",body["language_id"]);
    body["device"] = "web";
    this.global.post("getCmsPageData", JSON.stringify(body), data => {
      this.agbArray = data.data[0] ? data.data[0] : null;

      if(!this.agbArray || this.agbArray==null || this.agbArray.length==0) {
        this.contentLoader=false;
      }
      if(document.getElementById("cmspages_id")){
        document.getElementById("cmspages_id").innerHTML = this.agbArray['description'] ? this.agbArray['description'] : null;
      }
      
    },
    err => { },true);
  }
}
